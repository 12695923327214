/* eslint-disable no-throw-literal */
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { createAsyncThunk, createEntityAdapter, createSlice, current, Dictionary, EntityState } from "@reduxjs/toolkit";
import currency from "currency.js";
import { cloneDeep } from "lodash";
import moment from "moment";
import { getOptionsPrice } from "../../components/ShoppingCart/ShoppingCart2";
import Logo from "../../images/Logo2.png";
import callGraphQL, { callGraphQLGeneric } from "../../models/graphql-api";
import {
  GetAvailableItemsInCleverlynkQuery,
  PFXCleverlynk,
  PFXCleverlynkGroup,
  PFXCleverlynkGroupType,
  PFXCompany,
  PFXCredentialsResponse,
  PFXCriteriaForOrder,
  PFXCurrency,
  PFXDeliveryInfo,
  PFXDeliveryPriceType,
  PFXItemForOrder,
  PFXOrder,
  PFXOrderFulfillmentType,
  PFXPaymentMethod,
  PFXSKU,
  PFXSubItemChosenOption,
} from "../../models/Interfaces";
import { generateCart, insertOrder, setImageForOrder } from "../../models/Mutations/Orders";
import { calculateService, calculateServiceQuery } from "../../models/Queries/CleverlynkCheckoutQueries";
import {
  fetchCleverlynkInfoForRender,
  fetchCleverlynkInfoForRenderQuery,
} from "../../models/Queries/FetchCleverlynkInfoForRender";
import { fetchLandingPageInfoForRenderQuery } from "../../models/Queries/fetchLandingPageInfoForRender";
import { fetchTemplateInfoForRenderQuery } from "../../models/Queries/FetchTemplateInfoForRender";
import {
  getAvailableItemsInCleverlynk,
  getAvailableItemsInCleverlynkQuery,
} from "../../models/Queries/GetAvailableItemsInCleverlynk";
import {
  cleverlynkForRender,
  getCleverlynkForRenderQuery,
  IGetCleverlynkForRenderQuery,
} from "../../models/Queries/GetCleverlynkForRender";
import {
  getOpenCloseClynksInGroup,
  IClynkGroupMember,
  transformOpenClynks,
} from "../../models/Queries/GetCleverlynkGroupOpenClose";
import { getCompanyBillingInfo } from "../../models/Queries/GetCompanyBillingInfo";
import { getOrderForSummary, getOrderForSummaryQuery, IOrderSummary } from "../../models/Queries/GetOrderForSummay";
import { getCart, getSendOrder, getSendOrderQuery } from "../../models/Queries/GetSendOrder";
import { ISaveOrder } from "../../pages/CleverlynkCheckout";
import { getOrderStatus, IOrderState } from "../../pages/OrderSummary";
import { RootState } from "../rootReducer";
import { LupapCityCodes, MUCityCodes, QueryState } from "./mapServiceSlice";
import { PFXCategoryItem } from "./templateSlice";

export const REDEBAN_NUEVA_ORDEN_STATUS = 400;
export const EPAYCO_NUEVA_ORDEN_STATUS = 100;
export const RAPPI_NUEVA_ORDEN_STATUS = 1500;
export const PAYMENTEZ_NUEVA_ORDEN_STATUS = 1300;
export const MERCADOPAGO_NUEVA_ORDEN_STATUS = 600;

export interface IDeliveryCheckoutInfo extends PFXDeliveryInfo {
  deliveryPrice?: number;
  deliveryDate?: string;
  deliveryHourRange?: string[];
  deliveryTime?: string;
}
export interface IUnavailableDialog {
  open: boolean;
  image: string | undefined;
  title: string | undefined;
  subtitle?: string | undefined;
  backgroundColor: string;
  textColor: string;
  closed?: boolean;
  url?: string;
}
export interface PFXSnackbar {
  type: "success" | "info" | "warning" | "error";
  message: string;
  time?: number | null;
  disableClickAway?: boolean;
  anchor?: { horizontal: "center" | "left" | "right"; vertical: "bottom" | "top" };
}

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface IGeolocationInfo {
  location: ILatLng;
  polygon: ILatLng[];
  radius: number;
  polyType: "circle" | "polygon";
  isUsingGeolocation: boolean;
}

export interface IGeoDeliveryPrice {
  type: "geolocation" | "fixed" | "price";
  unitDistance: number;
  pricePerUnit: number;
  minPrice?: number;
  basePrice?: number;
}

export interface IIdenticalItemsInCart {
  [key: string]: number;
}

export interface CleverlynkGroupError {
  id: string;
  name: string;
}

export interface CleverlynkForRenderError {
  contactMessage: string;
  logoUrl: string;
  hours?: [string, string];
  salt?: string;
  millis?: number;
  template?: any;
  arrOfOpenCleverlynks?: [CleverlynkGroupError];
}
interface PFXPriceForOrder {
  subTotal?: number;
  discount?: number;
  total: number;
}
export interface PFXChosenClynkWithinGroup {
  id: string;
}

export interface RedirectError {
  pushParam: string;
}

export interface ErrorData {
  // STORE_CLOSED
  contactMessage: string;
  logoUrl: string;
  hours?: [string, string];
  salt?: string;
  millis?: number;
  template?: any;
  timezone?: string;
  arrOfOpenCleverlynks?: [CleverlynkGroupError];
  // 404 NOT FOUND
  pushParam: string;
  // WILL_REDIRECT
  id: string;
  name: string;
}

export const TAKEOUT_LABEL = "Lugar de recogida";

export enum ErrorType {
  "STORE_CLOSED",
  "STORE_UNAVAILABLE",
  "STORE_WITH_PIN",
  "404_NOT_FOUND",
  "WILL_REDIRECT",
}

export interface IError {
  type: ErrorType;
  data: ErrorData | undefined;
}

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};

interface AbandondedCartAsyncState extends AsyncState {
  orderId?: string;
}
interface CreateOrderAsyncState extends AsyncState {
  orderId?: string;
  total?: number;
}
interface CreateCartAsyncState extends AsyncState {
  orderId?: string;
  total?: number;
}

const criteriaAdapters = createEntityAdapter<PFXCriteriaForOrder>({
  selectId: c => c.label!,
  sortComparer: (cA, cB) => (cA.label! > cB.label! ? -1 : 1),
});

interface CleverlynkState {
  // hmm, no se como hacerlo aun
  //hmm
  activeGroupMembers: IClynkGroupMember[];
  beginDay: string | undefined;
  cartIsOpen: boolean;
  cleverlynk: PFXCleverlynk | undefined;
  cleverlynkGroup: PFXCleverlynkGroup | undefined;
  cleverlynkStage: number;
  closed: boolean;
  company: PFXCompany | undefined;
  confirmLocationLater: boolean;
  createAbandonedCartOrder: AbandondedCartAsyncState;
  createCart: CreateCartAsyncState;
  createOrder: CreateOrderAsyncState;
  createSendOrder: CreateOrderAsyncState;
  credentials: PFXCredentialsResponse;
  deliveryCheckoutInfo: IDeliveryCheckoutInfo | undefined;
  deliveryDelay: number;
  deliveryType: PFXOrderFulfillmentType | undefined;
  error: IError | undefined;
  getActiveGroupMembers: AsyncState;
  getAsyncDeliveryPrice: AsyncState;
  getOrder: AsyncState;
  googleClientId: string | undefined;
  identicalItemsInCart: IIdenticalItemsInCart;
  identification: string;
  isMobile: boolean;
  isShowroom: boolean;
  itemsInCart: PFXItemForOrder[];
  loadedLocalStorage: boolean;
  loadInitialData: AsyncState;
  loadInitialDataFromId: AsyncState;
  loadOrderSummary: AsyncState;
  loadUserInfo: AsyncState;
  logoUrl: string;
  mandatoryCriteria: EntityState<PFXCriteriaForOrder>;
  maxOrderHour: string | undefined;
  openedUpselling: boolean;
  openGeo: boolean;
  optionalCriteria: EntityState<PFXCriteriaForOrder>;
  order: PFXOrder | undefined;
  orderState: IOrderState | undefined;
  orderSummary: IOrderSummary | undefined;
  shoppingCartPrice: PFXPriceForOrder;
  primaryColor: string;
  setTransferOrderImage: AsyncState;
  snackbar: PFXSnackbar | null;
  swapItemsBetweenCleverlynks: AsyncState;
  triggerNavToUserForm: boolean;
  unavailable: IUnavailableDialog | undefined;
  chosenClynkWithinGroup: PFXChosenClynkWithinGroup | undefined;
  noLanding: boolean;
}

const initialAsyncState: AsyncState = { status: "idle" };
export const initialState: CleverlynkState = {
  primaryColor: "#000",
  logoUrl: "",
  credentials: {},
  cartIsOpen: false,
  cleverlynk: undefined,
  cleverlynkGroup: undefined,
  closed: false,
  deliveryCheckoutInfo: undefined,
  deliveryType: undefined,
  error: undefined,
  identification: "",
  isShowroom: false,
  itemsInCart: [],
  identicalItemsInCart: {},
  mandatoryCriteria: criteriaAdapters.getInitialState(),
  optionalCriteria: criteriaAdapters.getInitialState(),
  openGeo: false,
  order: undefined,
  cleverlynkStage: 1,
  unavailable: undefined,
  company: undefined,
  snackbar: null,
  chosenClynkWithinGroup: undefined,
  shoppingCartPrice: {
    total: 0,
  },
  triggerNavToUserForm: false,
  confirmLocationLater: false,
  orderSummary: undefined,
  orderState: undefined,
  isMobile: false,
  loadedLocalStorage: false,
  activeGroupMembers: [],
  loadInitialDataFromId: initialAsyncState,
  loadInitialData: initialAsyncState,
  loadUserInfo: initialAsyncState,
  getOrder: initialAsyncState,
  createSendOrder: initialAsyncState,
  createCart: initialAsyncState,
  setTransferOrderImage: initialAsyncState,
  createOrder: initialAsyncState,
  createAbandonedCartOrder: initialAsyncState,
  swapItemsBetweenCleverlynks: initialAsyncState,
  loadOrderSummary: initialAsyncState,
  getAsyncDeliveryPrice: initialAsyncState,
  getActiveGroupMembers: initialAsyncState,
  googleClientId: undefined,
  openedUpselling: false,
  deliveryDelay: 0,
  maxOrderHour: undefined,
  beginDay: undefined,
  noLanding: false,
};

const getIdenticalItemsInCart = (itemsInCart: PFXItemForOrder[]) => {
  const n: IIdenticalItemsInCart = {};
  itemsInCart.forEach(i => {
    if (n[i.id!]) {
      n[i.id!] = n[i.id!] + (i.quantity ?? 0);
    } else {
      n[i.id!] = i.quantity ?? 0;
    }
  });
  return n;
};

const currencyAwareRound = (num: number, currency: PFXCurrency) => {
  return currency === PFXCurrency.COP ? Math.round(num) : Math.round(num * 100) / 100;
};

export const currencyFormatter = (price, curr?: PFXCurrency) => {
  if (price === 0) return "";
  switch (curr) {
    case PFXCurrency.COP:
      return currency(price, { separator: ",", precision: 0 }).format();
    case PFXCurrency.USD:
    case PFXCurrency.MXN:
      return currency(price, {}).format();
    case PFXCurrency.PEN:
      return currency(price, { symbol: "S/ " }).format();
    default:
      return currency(price, { separator: ",", precision: 0 }).format();
  }
};

export const getOrder = createAsyncThunk(
  "cleverlynk/getOrder",
  async (info: { isStart; orderId; catalogItems; skuList; addDiscount; setSnackbar; loadDeliveryInfo }, thunkApi) => {
    const { orderId, isStart, catalogItems, skuList, addDiscount, setSnackbar, loadDeliveryInfo } = info;
    try {
      let orderInDb;
      let queryName;
      if (orderId.length === 36) {
        //es un carrito
        orderInDb = await callGraphQL<any>(getCart(orderId));
        queryName = "fetchCart";
      } else {
        //es una orden fallida
        orderInDb = await callGraphQL<any>(getSendOrderQuery(orderId));
        queryName = "fetchOrder";
      }
      const order = getSendOrder(orderInDb, queryName);
      loadDeliveryInfo(order.deliveryInfo);
      if (order.price?.discountCode && order.price?.discountCode !== "") {
        addDiscount(order.price.discountCode, (message, type) =>
          setSnackbar({
            message,
            type,
            anchor: { vertical: "top", horizontal: "center" },
            time: 10000,
            disableClickAway: true,
          })
        );
      }
      return {
        order,
        isStart,
        catalogItems,
        skuList,
      };
    } catch (error) {
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      return thunkApi.rejectWithValue(errors[0].message);
    }
  }
);

// no se que hacer con estos
function findItemsMochiMochi(pItem: PFXItemForOrder[], catalogItems: PFXCategoryItem[], skuList: Dictionary<PFXSKU>) {
  const newIt: PFXItemForOrder[] = [];

  pItem.forEach(it => {
    const itemPrice = (it.price ?? 0) - (it.chosenOptions?.reduce((acc, sub) => acc + (sub.value ?? 0), 0) ?? 0);
    const i = catalogItems.find(
      ci =>
        (ci?.name ?? "").toUpperCase().trim() === it.name?.toUpperCase().trim() && !ci.soldOut && itemPrice === ci.price
    );

    if (i) {
      const opti: PFXSubItemChosenOption[] | undefined = it.chosenOptions
        ?.reduce((acc, sub) => {
          const op = (i.subitems ?? []).find(s =>
            s.options?.some(
              so =>
                so.label?.toLocaleUpperCase().trim() === sub.label?.toLocaleUpperCase().trim() &&
                !so?.hidden &&
                (skuList[so.sku || ""] ?? 1) > 0
            )
          );
          if (op)
            acc.push({
              id: op?.options?.find(sop => sop.label === sub.label)?.id ?? "NotFound",
              label: sub.label,
              value: sub.value,
              posReference: op?.options?.find(sop => sop.label === sub.label)?.posReference,
              sku: sub.sku,
              hidden: sub.hidden,
              subitemId: op?.id ?? "NotFound",
              quantity: sub.quantity,
              controlsInventory: sub.controlsInventory,
            });
          return acc;
        }, [] as PFXSubItemChosenOption[])
        .filter(opt => opt.id !== undefined)
        .sort((a, b) => ((a.subitemId ?? "NotFound") > (b.subitemId ?? "NotFound") ? -1 : 1));
      if ((it.chosenOptions && opti?.length === it.chosenOptions.length) || !it.chosenOptions)
        newIt.push({
          id: i.id,
          price:
            (i.priceWithDiscount ?? 0) + (opti?.reduce((acum, curr) => acum + curr.value! * curr.quantity!, 0) ?? 0),
          name: i.name,
          quantity: it.quantity,
          subitems: i.subitems,
          images: i.images,
          posReference: i.posReference,
          og_price: i.price,
          sku: i.sku,
          chosenOptions: opti,
        });
    }
  });
  return newIt;
}

function findItems(pItem: PFXItemForOrder[], catalogItems: PFXCategoryItem[], skuList: Dictionary<PFXSKU>) {
  const newIt: PFXItemForOrder[] = [];
  pItem.forEach(it => {
    const itemPrice = (it.price ?? 0) - (it.chosenOptions?.reduce((acc, sub) => acc + (sub.value ?? 0), 0) ?? 0);
    const i = catalogItems.find(
      ci =>
        (ci?.name ?? "").toUpperCase().trim() === it.name?.toUpperCase().trim() &&
        !ci.soldOut &&
        itemPrice === ci.priceWithDiscount
    );

    if (i) {
      const opti = it.chosenOptions
        ?.map(sub => {
          const op = (i.subitems ?? []).find(s => s.options?.some(so => so.label === sub.label));

          return {
            id: op?.options?.find(sop => sop.label === sub.label)?.id ?? "NotFound",
            label: sub.label,
            value: sub.value,
            posReference: op?.options?.find(sop => sop.label === sub.label)?.posReference,
            sku: sub.sku,
            hidden: sub.hidden,
            subitemId: op?.id ?? "NotFound",
            quantity: sub.quantity,
            controlsInventory: sub.controlsInventory,
          };
        })
        .filter(opt => opt.id !== undefined)
        .sort((a, b) => (a.subitemId > b.subitemId ? -1 : 1));

      newIt.push({
        id: i.id,
        price: i.priceWithDiscount + (opti?.reduce((acum, curr) => acum + curr.value! * curr.quantity!, 0) ?? 0),
        name: i.name,
        quantity: it.quantity,
        subitems: i.subitems,
        images: i.images,
        posReference: i.posReference,
        og_price: i.price,
        sku: i.sku,
        chosenOptions: opti,
      });
    }
  });
  return newIt;
}

export const swapItemsBetweenCleverlynks = createAsyncThunk(
  "cleverlynk/swapItemsBetweenCleverlynks",
  async (
    info: {
      itemsInCart;
      clynkId: string;
      wipeLocalStorage;
      cleverlynkGroup;
    },
    thunkApi
  ) => {
    const { itemsInCart, clynkId, wipeLocalStorage, cleverlynkGroup } = info;
    const copy = cloneDeep(itemsInCart);
    wipeLocalStorage(clynkId, true);
    try {
      const ans: any = {};
      const res = getAvailableItemsInCleverlynk(
        await callGraphQLGeneric<GetAvailableItemsInCleverlynkQuery>(getAvailableItemsInCleverlynkQuery, {
          cleverlynkId: clynkId,
          itemsInCart: copy,
        })
      );
      if (res.available) ans.items = res.available;
      if (res.unavailable.length === copy.length) {
        ans.snackbar = {
          type: "warning",
          message: `Ningún producto de tu carrito está disponible para esta tienda`,
          time: 8000,
        };
      } else if (res.unavailable.length > 0) {
        ans.snackbar = {
          type: "warning",
          message: `Los siguientes productos no están disponibles ${
            cleverlynkGroup?.type === PFXCleverlynkGroupType.GEO ? "en tu ubicación:" : "en esta tienda:"
          } ${res.unavailable.map(i => i.name).join(", ")}`,
          time: 10000,
        };
      }
      return { ...ans, id: clynkId };
    } catch (err) {
      console.error(err);
      return thunkApi.rejectWithValue({
        id: clynkId,
        items: [],
        snackbar: {
          type: "error",
          message: `Hubo un problema, revisa tu carrito`,
          time: 10000,
        },
      });
    }
  }
);

const getCleverlynk = async (params: IGetCleverlynkForRenderQuery) => {
  return cleverlynkForRender(await callGraphQL<any>(getCleverlynkForRenderQuery(params)));
};

export const loadInitialDataFromId = createAsyncThunk(
  "cleverlynk/loadInitialDataFromId",
  async (info: { id: string }, thunkApi) => {
    try {
      return await getCleverlynk({ id: info.id, doNotCountRender: true });
    } catch (error: any) {
      console.error(error);
      let payload: any = JSON.parse(error.errors[0].message).payload;
      return thunkApi.rejectWithValue(payload);
    }
  }
);

export enum DefaultClynkPaths {
  EMPTY = "",
  LANDING = "/",
  USERFORM = "/info",
  TEMPLATE = "/menu",
  CHECKOUT = "/purchase",
  COVERAGE = "/coverage",
  SHARE = "/share",
  CLOSED = "/closed",
  UNDEFINED_UNAVAILABLE = "/undefined/unavailable",
  UNAVAILABLE = "/unavailable",
  UNDEFINED = "/undefined",
  REDIRECT = "/redirect",
}

export function isDefaultClynkPath(pathname) {
  for (const v of Object.values(DefaultClynkPaths)) {
    if (v === pathname) {
      return true;
    }
  }
  return false;
}

const validateRedirection = () => {
  if (
    (window.location.hostname === "www.clynk.me" || window.location.hostname === "test.clynk.me") &&
    window.location.pathname === "/"
  ) {
    // window.location.href = "https://www.cleverlynk.com";
  }
  if (window.location.hostname === "localhost") {
    return;
  }
};

const processClynkError = (err: any) => {
  console.error({ err });
  const errorObject = JSON.parse(err.errors[0].message);
  console.error(errorObject);
  if (errorObject.errorCode !== 1) {
    if (errorObject.arrOfOpenCleverlynks && errorObject.arrOfOpenCleverlynks?.length > 0) {
      return {
        type: ErrorType.WILL_REDIRECT,
        data: { pushParam: `/${errorObject.arrOfOpenCleverlynks[0].id}` },
      };
    } else {
      const type = errorObject.payload.hours ? ErrorType.STORE_CLOSED : ErrorType.STORE_UNAVAILABLE;
      return {
        type,
        data: {
          ...errorObject.payload,
          millis: Date.now(),
          ...errorObject.arrOfOpenCleverlynks,
        },
      };
    }
  } else {
    return {
      type: ErrorType["404_NOT_FOUND"],
      data: {
        ...errorObject.payload,
        millis: Date.now(),
        ...errorObject.arrOfOpenCleverlynks,
      },
    };
  }
};

const getTemplateInfoForRender = (
  clynkId: string | undefined,
  hostname: string | undefined
): Promise<{ status: string; response: any }> => {
  return callGraphQL<any>(fetchTemplateInfoForRenderQuery(clynkId, hostname))
    .then(response => {
      return { status: "fulfilled", response };
    })
    .catch(error => {
      const payload = error as GraphQLResult;
      console.error(payload);

      return { status: "rejected", response: payload };
    });
};

const getLandingInfoForRender = (
  clynkId: string | undefined,
  hostname: string | undefined
): Promise<{ status: string; response: any }> => {
  return callGraphQL<any>(fetchLandingPageInfoForRenderQuery(clynkId, hostname))
    .then(response => {
      return { status: "fulfilled", response };
    })
    .catch(error => {
      const payload = error as GraphQLResult;

      console.error(payload);

      return { status: "rejected", response: payload };
    });
};

const getCleverlynkInfoForRender = (
  clynkId: string | undefined,
  hostname: string | undefined
): Promise<{ status: string; response: any }> => {
  return callGraphQL<any>(fetchCleverlynkInfoForRenderQuery({ id: clynkId, hostname }))
    .then(response => {
      return { status: "fulfilled", response };
    })
    .catch(error => {
      const payload = error as GraphQLResult;
      console.error(error);
      return { status: "rejected", response: payload };
    });
};

export interface ILoadInitalData {
  initTemplate: any;
  initLanding: any;
  historyPush: any;
  location?: any;
  id?: string;
  handleSetNoLanding: (b: boolean) => void;
}

export const loadInitialData = createAsyncThunk(
  "cleverlynk/loadInitialData",
  async (info: ILoadInitalData, thunkApi) => {
    const { initTemplate, initLanding, location, historyPush, id, handleSetNoLanding } = info;

    const getClynkId = () => {
      if (!!id && id.length > 0) return id;
      else
        return !!location.pathname.split("/")[2] && !isDefaultClynkPath("/" + location.pathname.split("/")[2])
          ? location.pathname.split("/")[2]
          : !!location.pathname.split("/")[1] && !isDefaultClynkPath("/" + location.pathname.split("/")[1])
          ? location.pathname.split("/")[1]
          : undefined;
    };
    const preClynkId = getClynkId();
    const clynkId = preClynkId === "undefined" ? undefined : preClynkId;

    let cleverlynkPromise = getCleverlynkInfoForRender(clynkId, window.location?.hostname);
    let templatePromise = getTemplateInfoForRender(clynkId, window.location?.hostname);
    let landingPromise = getLandingInfoForRender(clynkId, window.location?.hostname);

    // const c = "pastaio.clynk.me";

    // let cleverlynkPromise = getCleverlynkInfoForRender(clynkId, c);
    // let templatePromise = getTemplateInfoForRender(clynkId, c);
    // let landingPromise = getLandingInfoForRender(clynkId, c);

    validateRedirection();
    return await Promise.all([cleverlynkPromise, templatePromise, landingPromise]).then(response => {
      const [cleverlynkResponse, templateResponse, landingResponse] = response;

      if (landingResponse.status === "fulfilled") {
        handleSetNoLanding(false);
        initLanding(
          landingResponse.response.data.fetchLandingPageInfoForRender,
          (cleverlynkResponse as any)?.response?.data?.fetchCleverlynkInfoForRender?.cleverlynkGroup?.landingPageId
        );
      } else {
        handleSetNoLanding(true);
      }
      if (cleverlynkResponse.status === "fulfilled" && templateResponse.status === "fulfilled") {
        if (window.location.pathname.includes("/unavailable")) {
          const s = window.location.pathname.replace("/unavailable", "");
          historyPush(s);
        }

        const redirect = !!window.location.pathname.includes("/redirect");
        const tdata = templateResponse.response.data?.fetchTemplateInfoForRender;
        const cdata = fetchCleverlynkInfoForRender(cleverlynkResponse.response.data?.fetchCleverlynkInfoForRender);
        initTemplate(tdata, cdata?.skus ?? []);
        return { ...cdata, templateLogo: tdata.logo, redirect };
      } else if (
        cleverlynkResponse.status === "rejected" &&
        cleverlynkResponse.response.errors.length > 0 &&
        cleverlynkResponse.response.errors[0]
      ) {
        const error = processClynkError(cleverlynkResponse.response);
        if (error?.data?.template && error?.type !== undefined) {
          if (templateResponse.status === "fulfilled") {
            const tdata = templateResponse.response.data?.fetchTemplateInfoForRender;
            initTemplate(tdata, []);
          }
          return thunkApi.rejectWithValue(error);
        } else if (error?.type === ErrorType.STORE_UNAVAILABLE) {
          return thunkApi.rejectWithValue(error);
        } else if (error?.type === ErrorType.WILL_REDIRECT) {
          historyPush(error?.data?.pushParam);
          return thunkApi.rejectWithValue(error);
        } else {
          return thunkApi.rejectWithValue({
            type: ErrorType["404_NOT_FOUND"],
            data: undefined,
          } as IError);
        }
      }
    });
  }
);

const generateOrder = async (info: ISaveOrder, isCart) => {
  const {
    paymentMethod,
    price,
    items,
    deliveryDate,
    deliveryHourRange,
    deliveryInfo,
    currency,
    mandatoryCriteria,
    optionalCriteria,
    identification,
    companyId,
    cleverlynkId,
    extras,
    userMarketingConsent,
    googleClientId,
    fulfillmentType,
    customerPromoCodeId,
    cleverlynkPromoCodeId,
    promoCodeId,
  } = info;

  const orderStatus = () => {
    switch (paymentMethod) {
      case "Selección":
      case "Descuento de nómina":
        return 701;
      case "Efectivo":
        return 1;
      case "Datafono":
        return 1201;
      case "Transferencia":
        return 200;
      case "Nequi":
        return 800;
      case "ePayCo":
        return EPAYCO_NUEVA_ORDEN_STATUS;
      case "MercadoPago":
        return MERCADOPAGO_NUEVA_ORDEN_STATUS;
      case "RedebanTarjeta":
      case "RedebanPSE":
        return REDEBAN_NUEVA_ORDEN_STATUS;
      case "Wompi":
        return 900;
      case "Payzen":
        return 1000;
      case "PayU":
        return 1100;
      case "PaymentezTarjeta":
        return 1300;
      case "Stripe":
        return 1400;
      case "Rappi":
        return RAPPI_NUEVA_ORDEN_STATUS;
      case "PayPal":
        return 1700;
      default:
        return 500;
    }
  };

  // esto le quita el atributo legaccy tableData de los
  // subitems en los catalogos muy viejos.
  const cleanItems = items => {
    const copy = cloneDeep(items);
    copy.forEach(i => {
      i.subitems = !i.subitems
        ? []
        : i.subitems.map(si => {
            delete si.tableData;
            si.options = si.options.map(o => {
              delete o.tableData;
              return o;
            });
            return si;
          });
    });
    return copy;
  };

  const deliveryLocation = info.takeout
    ? undefined
    : deliveryInfo?.coordinates && deliveryInfo?.coordinates?.lat
    ? deliveryInfo?.coordinates
    : null;

  const toLocaleTime = (day: string) => {
    const momento = moment(day, "HH:mm");
    return momento.format("HH:mm");
    // const stringHour = toTimeString(momento.hour());
    // const stringMinute = toTimeString(momento.minute());
    // const stringSecond = toTimeString(momento.second());
    // return `${stringHour}:${stringMinute}:${stringSecond}`;
  };

  const preppend0 = number => {
    if (number < 10) {
      return "0" + number;
    } else {
      return number + "";
    }
  };

  let localeDeliveryHourRange;

  if (deliveryHourRange) {
    localeDeliveryHourRange = deliveryHourRange.map(toLocaleTime);
  }

  const dInfo = {
    deliveryDate: deliveryDate
      ? deliveryDate.getFullYear() +
        "-" +
        preppend0(deliveryDate.getMonth() + 1) +
        "-" +
        preppend0(deliveryDate.getDate())
      : null,
    deliveryHourRange: localeDeliveryHourRange,
    deliveryAddress: info.takeout ? undefined : deliveryInfo?.address,
    deliveryAdditionalInfo: info.takeout ? undefined : deliveryInfo?.additionalInfo,
    deliveryLocation,
    deliveryPropertyTypeInfo: info.takeout ? undefined : deliveryInfo?.propertyInfo,
    deliveryCity: info.takeout ? undefined : deliveryInfo?.city,
  };

  const paymentStatus = orderStatus();
  const realOptionalCriteria = optionalCriteria.filter(o => o.value);
  const mattersForSubscription = Boolean([701, 1, 1201].find(o => o === paymentStatus));
  const status = paymentStatus % 100;
  const history = [{ status, date: moment().toISOString() }];

  const mutation = isCart ? generateCart : insertOrder;
  const input: any = {
    items: cleanItems(items),
    paymentMethod,
    mattersForSubscription,
    price: {
      ...price,
      currency: currency,
    },
    deliveryInfo: dInfo,
    cleverlynkId: cleverlynkId,
    companyId,
    status: status,
    mandatoryCriteria,
    optionalCriteria: realOptionalCriteria,
    identification: identification.trim(),
    hidden: false,
    newOrder: true,
    extras: extras,
    userMarketingConsent,
    googleClientId,
    paymentStatus,
    history,
    fulfillmentType: fulfillmentType ? fulfillmentType : PFXOrderFulfillmentType.DELIVERY,
  };
  if (!isCart) {
    input.cleverlynkPromoCodeId = cleverlynkPromoCodeId;
    input.promoCodeId = promoCodeId;
    input.customerPromoCodeId = customerPromoCodeId;
  }
  try {
    return await API.graphql(graphqlOperation(mutation, { input }));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createCart = createAsyncThunk("cleverlynk/createCart", async (info: ISaveOrder, thunkApi) => {
  const {
    price,
    items,
    takeout,
    deliveryInfo,
    currency,
    mandatoryCriteria,
    optionalCriteria,
    identification,
    companyId,
    cleverlynkId,
    fulfillmentType,
  } = info;
  try {
    const user_id = identification.trim() === "" ? null : identification.trim();
    return await API.graphql(
      graphqlOperation(generateCart, {
        input: {
          items,
          price: { ...price, delivery: 0, currency },
          deliveryInfo: {
            deliveryAddress: deliveryInfo?.address,
            deliveryAdditionalInfo: deliveryInfo?.additionalInfo,
            deliveryLocation: deliveryInfo?.coordinates,
            deliveryPropertyTypeInfo: deliveryInfo?.propertyInfo,
            deliveryCity: takeout ? undefined : deliveryInfo?.city,
          },
          cleverlynkId: cleverlynkId,
          companyId: companyId,
          status: 0,
          mandatoryCriteria,
          optionalCriteria,
          identification: user_id,
          paymentMethod: "Send",
          hidden: true,
          newOrder: false,
          paymentStatus: 500,
          fulfillmentType,
        } as PFXOrder,
      })
    );
  } catch (error) {
    console.error({ error });
    const payload = error as GraphQLResult;
    const errors = payload.errors!;
    return thunkApi.rejectWithValue(errors[0].message);
  }
});

export const createAbandonedCartOrder = createAsyncThunk(
  "cleverlynk/createAbandonedCartOrder",
  async (info: ISaveOrder, thunkApi) => {
    try {
      return await generateOrder(info, true);
    } catch (error) {
      console.error({ error });
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      return thunkApi.rejectWithValue(errors[0].message);
    }
  }
);

export const fetchCompanyBillingData = createAsyncThunk(
  "cleverlynk/loadCompanyBillingData",
  async (companyId: string, thunkApi) => {
    try {
      const ans = await callGraphQL<any>(getCompanyBillingInfo(companyId));
      return ans.data.getBillingInfo;
    } catch (err) {
      console.error(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const createOrder = createAsyncThunk("cleverlynk/createOrder", async (info: ISaveOrder, thunkApi) => {
  try {
    return await generateOrder(info, false);
  } catch (error) {
    console.error({ error });
    const payload = error as GraphQLResult;
    const errors = payload.errors!;
    return thunkApi.rejectWithValue(errors[0].message);
  }
});

export const getActiveGroupMembers = createAsyncThunk(
  "cleverlynk/getActiveGroupMembers",
  async (info: { groupId }, thunkApi) => {
    try {
      let data = await transformOpenClynks(await callGraphQL(getOpenCloseClynksInGroup(info.groupId)));
      data.sort((a, b) => (a.open !== b.open ? +b.open! - +a.open! : a.name!.localeCompare(b.name!)));
      return data;
    } catch (error) {
      console.error({ error });
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      return thunkApi.rejectWithValue(errors[0].message);
    }
  }
);

export const loadOrderSummary = createAsyncThunk(
  "cleverlynk/loadOrderSummary",
  async (
    info: {
      orderId: string;
      handleModifySocialsFooter: (sf: boolean) => void;
      handleModifyCurrency: (currency: PFXCurrency) => void;
    },
    thunkApi
  ) => {
    try {
      const { orderId, handleModifySocialsFooter, handleModifyCurrency } = info;
      const data = await getOrderForSummary(await callGraphQL<any>(getOrderForSummaryQuery(orderId)));
      if (!data.id) {
        return thunkApi.rejectWithValue({ message: "No such order exists" });
      }
      handleModifySocialsFooter(!!data.cleverlynk?.template?.socialsFooter);
      handleModifyCurrency(data.cleverlynk?.template?.currency);
      return data;
    } catch (error) {}
  }
);

export const setTransferOrderImage = createAsyncThunk(
  "cleverlynk/setTransgerOrderImage",
  async (info: { image; orderId }, thunkApi) => {
    try {
      const { orderId, image } = info;
      return await API.graphql(graphqlOperation(setImageForOrder(orderId!, image)));
    } catch (error) {
      console.error({ error });
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      return thunkApi.rejectWithValue(errors[0].message);
    }
  }
);

export const getAsyncDeliveryPrice = createAsyncThunk(
  "cleverlynk/getAsyncDeliveryPrice",
  async (
    info: {
      price: number;
      deliveryDate: Date | undefined;
      paymentMethod: PFXPaymentMethod;
      cleverlynk: PFXCleverlynk;
      deliveryInfo: QueryState;
      credentials: PFXCredentialsResponse;
      companyId: string;
    },
    thunkApi
  ) => {
    const { price, cleverlynk, companyId, deliveryInfo, credentials } = info;
    let { deliveryDate, paymentMethod } = info;
    if (!deliveryDate) {
      deliveryDate = new Date();
    }
    if (!paymentMethod) {
      paymentMethod = PFXPaymentMethod.CASH;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      if (cleverlynk?.deliveryPrice?.type === PFXDeliveryPriceType.MENSAJEROS) {
        const raw = {
          companyId,
          cleverlynkId: cleverlynk?.id,
          cityNumber:
            MUCityCodes[LupapCityCodes[deliveryInfo.city ?? ""]] ?? MUCityCodes[deliveryInfo.city ?? ""] ?? 500,
          coordinates: [
            {
              address: cleverlynk?.deliveryPrice?.deliveryAddress ?? "Cra. 11a #93-94",
              city: LupapCityCodes[deliveryInfo.city ?? ""] ?? "bogota",
              lat: cleverlynk?.geolocationInfo?.location?.lat,
              lon: cleverlynk?.geolocationInfo?.location?.lng,
            },
            {
              address: deliveryInfo.address,
              city: LupapCityCodes[deliveryInfo.city ?? ""] ?? "bogota",
              lat: deliveryInfo.coordinates?.lat,
              lon: deliveryInfo.coordinates?.lng,
            },
          ],
          parkingSurcharge: credentials?.MU?.extraCost ?? 0,
          utcDate: deliveryDate,
          declaredValue: price,
          paymentMethod: paymentMethod,
        };
        return await calculateService(
          await (API.graphql(
            graphqlOperation(calculateServiceQuery, { provider: "MU", input: { mensajeros: raw } })
          ) as Promise<GraphQLResult<any>>)
        );
      } else if (cleverlynk?.deliveryPrice?.type === PFXDeliveryPriceType.PICAP) {
        const raw = {
          companyId,
          cleverlynkId: cleverlynk?.id,
          deliveryAddress: {
            address: deliveryInfo.address,
            lat: deliveryInfo.coordinates?.lat,
            lon: deliveryInfo.coordinates?.lng,
          },
          dispatchAddress: {
            address: cleverlynk?.deliveryPrice?.deliveryAddress,
            lat: cleverlynk?.geolocationInfo?.location?.lat,
            lon: cleverlynk?.geolocationInfo?.location?.lng,
          },
          paymentMethod: paymentMethod,
          utcDate: deliveryDate,
          indications: deliveryInfo.propertyInfo,
          declaredValue: price,
          reference: `${deliveryDate}-${cleverlynk?.companyId}`,
        };

        const d = await calculateService(
          await (API.graphql(
            graphqlOperation(calculateServiceQuery, { provider: "PI", input: { picap: raw } })
          ) as Promise<GraphQLResult<any>>)
        );
        return d;
      } else if (cleverlynk.deliveryPrice?.type === PFXDeliveryPriceType.DOMIPRIME) {
        const raw = {
          companyId,
          cleverlynkId: cleverlynk?.id,
          deliveryAddress: {
            address: deliveryInfo.address,
            lat: deliveryInfo.coordinates?.lat,
            lng: deliveryInfo.coordinates?.lng,
            city: LupapCityCodes[deliveryInfo.city ?? ""] ?? "Barranquilla",
          },
          dispatchAddress: {
            address: cleverlynk?.deliveryPrice?.deliveryAddress,
            lat: cleverlynk?.geolocationInfo?.location?.lat,
            lng: cleverlynk?.geolocationInfo?.location?.lng,
            city: LupapCityCodes[deliveryInfo.city ?? ""] ?? "Barranquilla",
          },
        };
        return await calculateService(
          await (API.graphql(
            graphqlOperation(calculateServiceQuery, { provider: "DP", input: { domiprime: raw } })
          ) as Promise<GraphQLResult<any>>)
        );
      }
    } catch (error) {
      console.error(error);
      return thunkApi.rejectWithValue("Hubo un error calculando el precio del domicilio");
    }
  }
);

function getMaxDeliveryDelay(items) {
  let maximumDelay = 0;
  let maxOrderHour = undefined;
  let beginDay = undefined;
  for (const c of items) {
    if (c.deliveryDelay > maximumDelay) {
      maximumDelay = c.deliveryDelay;
      maxOrderHour = c.maxOrderHour;
      beginDay = c.beginDayHour;
    }
    //si ambos delivery days son iguales desempatamos por hora, si aun no se ha encontrado delay, seteamos la hora
    else if (c.deliveryDelay === maximumDelay || maximumDelay === 0) {
      if (c.maxOrderHour) {
        //Si aun no se ha seteado hora, la seteamos. Si la hora de este item es anterior a la seteada, actualizamos
        //pues este item deberia ser el que mas delay tiene
        if (!maxOrderHour || moment(c.maxOrderHour, "HH:mm").isBefore(moment(maxOrderHour, "HH:mm"))) {
          maxOrderHour = c.maxOrderHour;
          beginDay = c.beginDayHour;
        }
      }
    }
  }
  return { deliveryDelay: maximumDelay / 24, maxOrderHour, beginDay };
}

const cleverlynkSlice = createSlice({
  name: "cleverlynk",
  initialState,
  reducers: {
    setGoogleClientId(state, action) {
      state.googleClientId = action.payload;
    },
    setCredentials(state, action) {
      state.credentials = action.payload.credentials;
    },
    setCriteria(state, action) {
      const { optional, mandatory } = action.payload;
      criteriaAdapters.setAll(state.optionalCriteria, optional);
      criteriaAdapters.setAll(state.mandatoryCriteria, mandatory);
    },
    decreaseCleverlynkStage(state) {
      if (state.cleverlynkStage === 2) {
        state.triggerNavToUserForm = false;
      }
      if (state.cleverlynkStage > 1) {
        state.cleverlynkStage = state.cleverlynkStage - 1;
      }
    },
    modifyLoadedLocalStorage(state, action) {
      state.loadedLocalStorage = action.payload;
    },
    modifyMandatoryCriteria(state, action) {
      const crit = action.payload;
      criteriaAdapters.upsertOne(state.mandatoryCriteria, crit);
    },
    modifyManyMandatoryCriteria(state, action) {
      criteriaAdapters.upsertMany(state.mandatoryCriteria, action.payload);
    },
    modifyOptionalCriteria(state, action) {
      const crit = action.payload;
      criteriaAdapters.upsertOne(state.optionalCriteria, crit);
    },
    modifyManyOptionalCriteria(state, action) {
      criteriaAdapters.upsertMany(state.optionalCriteria, action.payload);
    },
    updatePrice(state, action) {
      const { items, currency } = action.payload;
      const subTotal = currencyAwareRound(
        items.reduce(
          (acum, item) =>
            acum + ((item?.og_price! ?? item.price!) + getOptionsPrice(item.chosenOptions ?? [])) * item.quantity!,
          0
        ),
        currency
      );
      const total = currencyAwareRound(
        items.reduce((acum, item) => acum + item.price! * item.quantity!, 0),
        currency
      );

      state.shoppingCartPrice = {
        subTotal: subTotal,
        discount: subTotal - total,
        total: total,
      };
    },
    setUnavailable(state, action) {
      state.unavailable = { ...state.unavailable, ...action.payload };
    },
    removeMandatoryCriteria(state, action) {
      criteriaAdapters.removeOne(state.mandatoryCriteria, action.payload);
    },
    setDeliveryCheckoutInfo(state, action) {
      state.deliveryCheckoutInfo = action.payload;
    },
    modifyDeliveryGeoInfo(state, action) {
      state.deliveryCheckoutInfo = { ...state.deliveryCheckoutInfo, ...action.payload };
    },
    setDeliveryType(state, action) {
      state.deliveryType = action.payload;
    },
    setConfirmLocationLater(state, action) {
      state.confirmLocationLater = action.payload;
    },
    setIdentification(state, action) {
      state.identification = action.payload;
    },
    setSnackbar(state, action) {
      state.snackbar = action.payload;
    },
    modifyTriggerNavToUserForm(state, action) {
      state.triggerNavToUserForm = action.payload;
    },
    modifySingleItemInCart(state, action) {
      const { quantity, index } = action.payload;
      state.itemsInCart[index].quantity = quantity;
    },
    modifyOrderSummary(state, action) {
      state.orderSummary = {
        ...state.orderSummary,
        ...action.payload,
      };
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    setCleverlynkStage(state, action) {
      state.cleverlynkStage = action.payload.stage;
      if (!action.payload.toggleNavToForm) {
        state.triggerNavToUserForm = false;
      }
    },
    setCurrentClynkWithinGroup(state, action) {
      state.chosenClynkWithinGroup = action.payload;
    },
    setOpenGeo(state, action) {
      state.openGeo = action.payload;
    },
    setOrderState(state, action) {
      state.orderState = action.payload;
    },
    setOpenedUpselling(state) {
      state.openedUpselling = true;
    },
    setCartIsOpen(state, action) {
      state.cartIsOpen = action.payload;
    },
    setItemsInCart(state, action) {
      state.itemsInCart = action.payload.items;
      const { deliveryDelay, maxOrderHour, beginDay } = getMaxDeliveryDelay(action.payload.items);
      state.deliveryDelay = deliveryDelay;
      state.maxOrderHour = maxOrderHour;
      state.beginDay = beginDay;
      state.identicalItemsInCart = getIdenticalItemsInCart(action.payload.items);
    },
    updateItemInCart(state, action) {
      const id = action.payload.item.id;
      const item = action.payload.item;
      const newItems = cloneDeep(state.itemsInCart).map(i => (i.id === id ? i : { ...i, ...item }));
      state.itemsInCart = newItems;
      state.identicalItemsInCart = getIdenticalItemsInCart(newItems);
    },
    deleteItemInCart(state, action) {
      const index = action.payload.index;
      const newItems = cloneDeep(state.itemsInCart);
      newItems.splice(index, 1);
      const { deliveryDelay, maxOrderHour } = getMaxDeliveryDelay(newItems);
      state.deliveryDelay = deliveryDelay;
      state.maxOrderHour = maxOrderHour;
      state.itemsInCart = newItems;
      state.identicalItemsInCart = getIdenticalItemsInCart(newItems);
    },
    wipeState(state) {
      state.cleverlynkStage = 1;
      if (state.cleverlynk) {
        state.itemsInCart = [];
        state.identicalItemsInCart = {};
        state.deliveryType = undefined;
        state.deliveryCheckoutInfo = undefined;
        state.identification = "";
      }
    },
    setNoLanding(state, action) {
      state.noLanding = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadInitialData.pending, (state, action) => {
      state.loadInitialData.status = "loading";
    });
    builder.addCase(loadInitialData.rejected, (state, action: any) => {
      state.error = action.error;
      if (action.payload && action.payload.data && action.payload.data.template) {
        state.error = action.payload;
        state.logoUrl = action.payload.data?.logoUrl ?? Logo;
        state.company = { ...state.company, logoUrl: action.payload.data.logoUrl, id: action.payload.data.companyId };
        state.loadInitialData.status = "succeeded";
      } else if (action.payload && action.payload.type === ErrorType.WILL_REDIRECT) {
      } else if (action.payload && action.payload.data) {
        state.logoUrl = action.payload.data?.logoUrl ?? Logo;
        state.company = { id: action.payload.data.companyId };
        state.error = action.payload;
        state.loadInitialData.status = "succeeded";
      } else {
        state.error = action.payload;
        state.loadInitialData.status = "succeeded";
      }
    });
    builder.addCase(loadInitialData.fulfilled, (state, action) => {
      const cleverlynk = action.payload as any;
      const { company, cleverlynkGroup, templateLogo, redirect } = action.payload as any;

      delete company.skus;
      state.company = company;

      state.triggerNavToUserForm = false;
      state.cleverlynkGroup = cleverlynkGroup;
      state.primaryColor = company?.primaryColor ?? "#000000";

      if (cleverlynkGroup?.mandatory && !redirect && state.chosenClynkWithinGroup === undefined) {
        state.openGeo = true;
      }

      delete cleverlynk.cleverlynkGroup;
      delete cleverlynk.company;
      delete cleverlynk.templateLogo;

      state.cleverlynk = cleverlynk;
      state.logoUrl = templateLogo ?? company?.logoUrl ?? Logo;
      state.loadInitialData.status = "succeeded";
      if (cleverlynk?.inPerson) {
        state.deliveryType = PFXOrderFulfillmentType.IN_PERSON;
      }
    });
    builder.addCase(swapItemsBetweenCleverlynks.pending, (state, action) => {
      state.swapItemsBetweenCleverlynks.status = "loading";
    });
    builder.addCase(swapItemsBetweenCleverlynks.rejected, (state, action: any) => {
      const { items, id, error, snackbar } = action.payload;
      if (error) state.error = error;
      if (snackbar) state.snackbar = snackbar;
      if (items) {
        state.itemsInCart = items;
        state.identicalItemsInCart = getIdenticalItemsInCart(items);
      }
      state.chosenClynkWithinGroup = { id };
      state.openGeo = false;
    });
    builder.addCase(swapItemsBetweenCleverlynks.fulfilled, (state, action) => {
      const { items, id, snackbar } = action.payload;
      if (snackbar) state.snackbar = snackbar;
      if (items) {
        state.itemsInCart = items;
        state.identicalItemsInCart = getIdenticalItemsInCart(items);
      }
      state.chosenClynkWithinGroup = { id };
      state.openGeo = false;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.getOrder.status = "failed";
    });
    builder.addCase(getOrder.pending, (state, action) => {
      state.getOrder.status = "loading";
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      const { order, isStart, catalogItems, skuList } = action.payload! as any;
      if (order) {
        if (order.orderItems) {
          if (isStart) {
            if (state.cleverlynk?.companyId === "fh5-ry7nseyKJkYr7HyeJ") {
              const n = findItemsMochiMochi(order.orderItems, catalogItems, skuList);
              state.itemsInCart = n;
              state.identicalItemsInCart = getIdenticalItemsInCart(n);
            } else {
              const n = findItems(order.orderItems, catalogItems, skuList);
              state.itemsInCart = n;
              state.identicalItemsInCart = getIdenticalItemsInCart(n);
            }
          } else {
            state.itemsInCart = order.orderItems!;
            state.identicalItemsInCart = getIdenticalItemsInCart(order.orderItems!);
            state.cleverlynkStage = 2;
          }
        }
        if (order.identification) {
          state.identification = order.identification;
        }
        if (order.mandatoryCriteria) criteriaAdapters.setAll(state.mandatoryCriteria, order.mandatoryCriteria);
        if (order.optionalCriteria) criteriaAdapters.setAll(state.optionalCriteria, order.optionalCriteria);
        if (order.price?.discountCode || order.extras) {
          state.order = order;
          state.shoppingCartPrice.discount = order.price?.discount;
        }
        if (isStart) {
          state.cleverlynkStage = 1;
        } else {
          state.cleverlynkStage = 2;
        }
        state.deliveryType = order.fulfillmentType;
        state.getOrder.status = "succeeded";
      }
    });
    builder.addCase(getAsyncDeliveryPrice.pending, state => {
      state.getAsyncDeliveryPrice.status = "loading";
    });
    builder.addCase(getAsyncDeliveryPrice.rejected, (state, action: any) => {
      state.getAsyncDeliveryPrice.status = "failed";
      state.getAsyncDeliveryPrice.error = action.payload.error;
      state.snackbar = {
        message: "Hubo un error procesando el costo del domicilio",
        type: "error",
      };
    });
    builder.addCase(getAsyncDeliveryPrice.fulfilled, (state, action) => {
      state.getAsyncDeliveryPrice.status = "succeeded";
      state.deliveryCheckoutInfo = { ...state.deliveryCheckoutInfo, deliveryPrice: (action.payload as number) ?? 0 };
    });
    builder.addCase(createOrder.pending, state => {
      state.createOrder.status = "loading";
      state.snackbar = { type: "info", message: "Estamos creando tu orden...", disableClickAway: true, time: null };
    });
    builder.addCase(createOrder.rejected, (state, action: any) => {
      state.createOrder.status = "failed";
      state.createOrder.error = action.payload.error;
      const data = JSON.parse(`{"payload": ${action.payload}}`);
      if (data.payload.badItems) {
        const itemsInCart = [...state.itemsInCart].map(i => current(i));
        const items = itemsInCart.filter(i => data.payload.badItems.map(bi => bi.id).includes(i.id));
        const subitems = itemsInCart.filter(i => data.payload.badSubitems.map(bi => bi.itemId).includes(i.id));
        const options = itemsInCart.filter(i => data.payload.badOptions.map(bi => bi.itemId).includes(i.id));

        const filtered = Array.from(
          new Set(itemsInCart.filter(ic => ![...items, ...subitems, ...options].map(di => di.id).includes(ic.id)))
        );

        const itemsMessage = `Los siguientes productos no están disponibles: ${data.payload.badItems
          .map(b => b.name)
          .join(", ")}`;

        const subitemsMessage = `Las siguientes opciones no están disponibles: ${data.payload.badOptions
          .map(b => {
            return b.optionName + " en " + b.itemName;
          })
          .join(", ")}  ${data.payload.badSubitems.map(b => b.subitemName + " en " + b.itemName).join(", ")}`;

        state.snackbar = {
          type: "error",
          message: `${data.payload.badItems.length > 0 ? itemsMessage : ""} ${
            data.payload.badOptions.length > 0 || data.payload.badSubitems.length > 0 ? subitemsMessage : ""
          }. Serán borrado(s) del carrito`,
        };
        state.itemsInCart = filtered;
      } else if (data.payload.stockFailed) {
        const failedSkus = data.payload.stockFailed.map(s => s.message).join(", ");
        state.snackbar = {
          type: "error",
          message: `No hay suficientes unidades de: ${failedSkus}`,
        };
      } else {
        state.snackbar = {
          type: "error",
          message: `Hubo un error creando la orden${data.payload.message ? `: ${data.payload.message}` : ""}`,
        };
      }
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.createOrder.status = "succeeded";
      state.createOrder.orderId = (action.payload as GraphQLResult<any>).data.insertOrder.id;
      state.createOrder.total = (action.payload as GraphQLResult<any>).data.insertOrder.price.total;
    });
    builder.addCase(createAbandonedCartOrder.pending, state => {
      state.createAbandonedCartOrder.status = "loading";
    });
    builder.addCase(createAbandonedCartOrder.rejected, (state, action: any) => {
      state.createAbandonedCartOrder.status = "failed";
      state.createAbandonedCartOrder.error = action.payload.error;
    });
    builder.addCase(createAbandonedCartOrder.fulfilled, (state, action) => {
      state.createAbandonedCartOrder.status = "succeeded";
      state.createAbandonedCartOrder.orderId = (action.payload as GraphQLResult<any>).data.generateCart.id;
    });
    builder.addCase(createCart.pending, state => {
      state.createCart.status = "loading";
    });
    builder.addCase(createCart.rejected, (state, action: any) => {
      state.createCart.status = "failed";
      state.createCart.error = action.payload.error;
      console.error(action.payload);
      state.snackbar = {
        type: "error",
        message: `Hubo un error creando la orden${action.payload ? `: ${action.payload}` : ""}`,
      };
    });
    builder.addCase(createCart.fulfilled, (state, action) => {
      state.createCart.status = "succeeded";
      state.createCart.orderId = (action.payload as GraphQLResult<any>).data.generateCart.id;
    });
    builder.addCase(setTransferOrderImage.pending, state => {
      state.setTransferOrderImage.status = "loading";
    });
    builder.addCase(setTransferOrderImage.rejected, (state, action: any) => {
      state.setTransferOrderImage.status = "failed";
      state.setTransferOrderImage.error = action.payload.error;
    });
    builder.addCase(setTransferOrderImage.fulfilled, (state, action) => {
      state.setTransferOrderImage.status = "succeeded";
    });
    builder.addCase(loadOrderSummary.pending, state => {
      state.loadOrderSummary.status = "loading";
    });
    builder.addCase(loadOrderSummary.rejected, (state, action: any) => {
      state.loadOrderSummary.status = "failed";
      state.loadOrderSummary.error = action.payload.error;
      console.error("error", action);
    });
    builder.addCase(loadOrderSummary.fulfilled, (state, action: any) => {
      state.loadOrderSummary.status = "succeeded";
      state.cleverlynk = { ...state.cleverlynk, ...action.payload?.cleverlynk };
      state.orderSummary = action.payload;
      state.company = action.payload?.company;
      state.primaryColor = action.payload?.company?.primaryColor ?? "#000";
      state.orderState = getOrderStatus(
        action.payload?.paymentStatus,
        action.payload?.status,
        action.payload?.fulfillmentType
      );
    });
    builder.addCase(getActiveGroupMembers.pending, state => {
      state.getActiveGroupMembers.status = "loading";
    });
    builder.addCase(getActiveGroupMembers.rejected, (state, action: any) => {
      state.getActiveGroupMembers.status = "failed";
      state.getActiveGroupMembers.error = action.payload.error;
    });
    builder.addCase(getActiveGroupMembers.fulfilled, (state, action) => {
      state.getActiveGroupMembers.status = "succeeded";
      state.activeGroupMembers = action.payload as any;
    });
    builder.addCase(fetchCompanyBillingData.fulfilled, (state, action) => {
      if (state.company) state.company = { ...state.company, ...action.payload };
      else state.company = action.payload;
    });
  },
});

export const {
  deleteItemInCart,
  decreaseCleverlynkStage,
  modifyMandatoryCriteria,
  setIsMobile,
  modifyOptionalCriteria,
  removeMandatoryCriteria,
  setCartIsOpen,
  setCleverlynkStage,
  modifyLoadedLocalStorage,
  modifyManyOptionalCriteria,
  setCredentials,
  setDeliveryCheckoutInfo,
  modifyOrderSummary,
  modifyTriggerNavToUserForm,
  modifySingleItemInCart,
  setNoLanding,
  setConfirmLocationLater,
  setDeliveryType,
  modifyManyMandatoryCriteria,
  setGoogleClientId,
  setOpenedUpselling,
  setIdentification,
  setItemsInCart,
  setOpenGeo,
  setCriteria,
  setOrderState,
  updatePrice,
  setUnavailable,
  setSnackbar,
  setCurrentClynkWithinGroup,
  modifyDeliveryGeoInfo,
  updateItemInCart,
  wipeState,
} = cleverlynkSlice.actions;

export default cleverlynkSlice.reducer;

export const selectPrice = (state: RootState) => state.cleverlynk.shoppingCartPrice;
export const selectDeliveryCheckoutInfo = (state: RootState) => state.cleverlynk.deliveryCheckoutInfo;
export const {
  selectEntities: selectOptionalCriteriaEntities,
  selectAll: selectOptionalCriteria,
  selectTotal: selectOptionalCriteriaTotal,
} = criteriaAdapters.getSelectors((state: RootState) => state.cleverlynk.optionalCriteria);
export const {
  selectEntities: selectMandatoryCriteriaEntities,
  selectAll: selectMandatoryCriteria,
  selectTotal: selectMandatoryCriteriaTotal,
} = criteriaAdapters.getSelectors((state: RootState) => state.cleverlynk.mandatoryCriteria);

export const selectActiveGroupMembers = (state: RootState) => state.cleverlynk.activeGroupMembers;
export const selectBeginDay = (state: RootState) => state.cleverlynk.beginDay;
export const selectCartIsOpen = (state: RootState) => state.cleverlynk.cartIsOpen;
export const selectCleverlynk = (state: RootState) => state.cleverlynk.cleverlynk;
export const selectCleverlynkGroup = (state: RootState) => state.cleverlynk.cleverlynkGroup;
export const selectCleverlynkId = (state: RootState) => state.cleverlynk.cleverlynk?.id;
export const selectCleverlynkStage = (state: RootState) => state.cleverlynk.cleverlynkStage;
export const selectCompany = (state: RootState) => state.cleverlynk.company;
export const selectConfirmLocationLater = (state: RootState) => state.cleverlynk.confirmLocationLater;
export const selectCredentials = (state: RootState) => state.cleverlynk.credentials;
export const selectCurrentClynkWithinGroup = (state: RootState) => state.cleverlynk.chosenClynkWithinGroup;
export const selectDeliveryDelay = (state: RootState) => state.cleverlynk.deliveryDelay;
export const selectDeliveryType = (state: RootState) => state.cleverlynk.deliveryType;
export const selectError = (state: RootState) => state.cleverlynk.error;
export const selectGoogleClientId = (state: RootState) => state.cleverlynk.googleClientId;
export const selectIdenticalItemsInCart = (state: RootState) => state.cleverlynk.identicalItemsInCart;
export const selectIdentification = (state: RootState) => state.cleverlynk.identification;
export const selectIsMobile = (state: RootState) => state.cleverlynk.isMobile;
export const selectItemsInCart = (state: RootState) => state.cleverlynk.itemsInCart;
export const selectItemsInCartAmount = (state: RootState) => state.cleverlynk.itemsInCart.length;
export const selectLogo = (state: RootState) => state.cleverlynk.logoUrl;
export const selectMaxOrderHour = (state: RootState) => state.cleverlynk.maxOrderHour;
export const selectOpenGeo = (state: RootState) => state.cleverlynk.openGeo;
export const selectOpenedUpselling = (state: RootState) => state.cleverlynk.openedUpselling;
export const selectOrder = (state: RootState) => state.cleverlynk.order;
export const selectOrderState = (state: RootState) => state.cleverlynk.orderState;
export const selectOrderSummary = (state: RootState) => state.cleverlynk.orderSummary;
export const selectPrimaryColor = (state: RootState) => state.cleverlynk.primaryColor;
export const selectSnackbar = (state: RootState) => state.cleverlynk.snackbar;
export const selectZendesk = (state: RootState) => state.cleverlynk.cleverlynk?.zendesk;
export const selectTriggerNavToUserForm = (state: RootState) => state.cleverlynk.triggerNavToUserForm;
export const selectUnavailable = (state: RootState) => state.cleverlynk.unavailable;
export const selectShoppingCartPrice = (state: RootState) => state.cleverlynk.shoppingCartPrice;
// ASYNC Selectors
export const selectLoadInitialData = (state: RootState) => state.cleverlynk.loadInitialData;
export const selectLoadInitialDataFromId = (state: RootState) => state.cleverlynk.loadInitialDataFromId;
export const selectCreateOrder = (state: RootState) => state.cleverlynk.createOrder;
export const selectCreateCart = (state: RootState) => state.cleverlynk.createCart;
export const selectCreateAbandonedCartOrder = (state: RootState) => state.cleverlynk.createAbandonedCartOrder;
export const selectLoadOrderSummary = (state: RootState) => state.cleverlynk.loadOrderSummary;
export const selectSetTransferOrderImage = (state: RootState) => state.cleverlynk.setTransferOrderImage;
export const selectGetAsyncDeliveryPrice = (state: RootState) => state.cleverlynk.getAsyncDeliveryPrice;
export const selectGetOrder = (state: RootState) => state.cleverlynk.getOrder;
export const selectNoLanding = (state: RootState) => state.cleverlynk.noLanding;
