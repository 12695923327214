import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography, Fade } from "@material-ui/core";
import { Check, Edit, ThumbUp } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import { ILatLng, selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { selectDeliveryInfo, selectMapServiceQuery } from "../../redux/slices/mapServiceSlice";
import { IMapProvider } from "./DeliveryInput";

interface IAddressInput {
  toggleSelected: (address: string) => void;
}

const AddressInput = ({ toggleSelected }: IAddressInput) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const mapServiceQuerySupervisor = useSelector(selectMapServiceQuery);
  const deliveryInfo = useSelector(selectDeliveryInfo);
  const isMobile = useSelector(selectIsMobile);

  const addressLength = useRef<number>(0);
  const numberOfWords = useRef<number>(0);
  const lastCharToEdit = useRef<boolean>(false);

  const { handleModifyDeliveryInfo, handleModifySnackbar } = useMapService();

  useEffect(() => {
    const iconbutton = document.getElementById("address-input-icon-button");
    const elem: any = document.getElementById("map-service-search-field");
    if (mapServiceQuerySupervisor.status === "succeeded") {
      if (elem) elem.value = deliveryInfo.address ?? "";
      if (iconbutton) iconbutton.classList.remove("Mui-disabled");
    } else if (mapServiceQuerySupervisor.status === "loading") {
      setEdit(false);
      if (iconbutton) iconbutton.classList.add("Mui-disabled");
      if (elem) elem.blur();
    }
  }, [mapServiceQuerySupervisor]);

  useEffect(() => {
    if ((!deliveryInfo.address || deliveryInfo.address === "") && shouldRender) setShouldRender(false);
    else if (deliveryInfo.address && deliveryInfo.address?.length > 0 && !shouldRender) setShouldRender(true);
  }, [deliveryInfo]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "absolute",
        top: isMobile ? 0 : 10,
        zIndex: 10,
        left: 0,
        height: 60,
        width: "100%",
        margin: 0,
        display: shouldRender ? "" : "none",
      },
      searchField: {
        backgroundColor: "#00000000",
        border: "none",
        outline: "none",
        height: "2.5rem",
        width: "calc(100% - 15px)",
        borderRadius: 5,
        color: "#15ad7d",
        paddingLeft: 8,
        fontFamily: "inherit",
        fontSize: "1rem",
        "&:focus-visible": {
          border: "solid 1pt #15ad7d6b",
        },
      },
      searchButton: {
        color: "#15ad7d",
        width: "100%",
        height: "100%",
        padding: 0,
        boxShadow: "1pt 1pt 3px #30a55f57",
        backgroundColor: "#fff",
        borderRadius: 15,
      },
      inputGrid: {
        height: "100%",
        borderRadius: edit ? 12 : "0px 12px 12px 0px",
        backgroundColor: "#effffa",
        transitionDuration: "400ms",
        maxWidth: edit && !isMobile ? 580 : 500,
        transitionProperty: "width, flex-basis, max-width, border-radius",
        paddingRight: "8px !important",
      },
      editGrid: {
        maxWidth: edit ? 0 : 80,
        padding: edit ? "0px !important" : "",
        transitionDuration: "400ms",
        transitionProperty: "width, flex-basis, max-width",
        height: "100%",
        borderRadius: "15px 0px 0px 15px",
        backgroundColor: edit ? "#54e8b9" : "#54e8b9",
      },
      editButton: {
        width: "100%",
        height: "100%",
        borderRadius: 12,
      },
    })
  );

  const classes = useStyles();

  const handleSubmit = () => {
    const address: any = document.getElementById("map-service-search-field");
    handleModifyDeliveryInfo({
      address: address?.value ?? deliveryInfo.address,
    });
    toggleSelected(address?.value);
  };

  const handleOnFocus = () => {
    handleModifySnackbar({ color: "#258098", message: "addressInput.addressWarning" });
  };

  const handleFocusInput = () => {
    setEdit(true);
    handleModifySnackbar({ color: "#258098", message: "addressInput.addressWarning" });
    const elem: any = document.getElementById("map-service-search-field");
    if (elem) elem.focus();
  };

  const handleOnChange = (e: any) => {
    const checkNumberOfWords = (v: string) => v.trimStart().split(" ").length;
    const validateValue = (v: string) => {
      const nWords = checkNumberOfWords(v);
      if (v === "" || nWords === 1) return true;
      if (nWords === 2 && v.length < addressLength.current) {
        handleModifySnackbar({ color: "#ef4850", message: "addressInput.cantEditFurther" });
        if (lastCharToEdit.current) {
          lastCharToEdit.current = false;
          return true;
        } else if (numberOfWords.current === 3 && nWords === 2) {
          lastCharToEdit.current = true;
        }
      }
      numberOfWords.current = nWords;
      addressLength.current = v.length;
    };

    if ((!e.target.value || validateValue(e.target.value)) && shouldRender) {
      setShouldRender(false);
      handleModifySnackbar(undefined);
    }
  };

  return (
    <Fade in={shouldRender} timeout={600}>
      <Grid container className={classes.root} alignItems="center" spacing={1} justifyContent={"center"} wrap="nowrap">
        <Grid item xs={edit ? undefined : 2} className={classes.editGrid}>
          {!edit && (
            <IconButton onClick={() => handleFocusInput()} className={classes.editButton}>
              <Edit />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs={edit ? 12 : 10}
          justifyContent="center"
          alignContent="center"
          container
          className={classes.inputGrid}>
          <Grid item xs={10}>
            <input
              id="map-service-search-field"
              type="text"
              className={classes.searchField}
              onFocus={handleOnFocus}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handleSubmit} className={classes.searchButton} id="address-input-icon-button">
              <Check />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};
export default AddressInput;
