import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Grid, Fab, DialogContentText } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";

export interface CheckoutConfirmationDialogProps {
  open: boolean;
  title?: string;
  text?: string;
  image?: string;
  cancelText?: string;
  hideCancel?: boolean;
  confirmText?: string;
  onClose?: (value?: any) => void;
  toggleSnackbar: Function;
}

function CheckoutConfirmationDialog(props: CheckoutConfirmationDialogProps) {
  const [value, setValue] = useState<any>(null);
  const [imageWidth, setImageWidth] = useState(100);

  useEffect(() => {
    if (!props.image) {
      setValue(true);
    }
  }, []);

  const handleCancel = () => {
    if (props.onClose) props.onClose(null);
  };

  const handleOk = () => {
    if (props.onClose) props.onClose(value);
  };

  return (
    <Dialog
      style={{ zIndex: 1305 }}
      maxWidth="md"
      fullWidth={props.image !== undefined}
      open={props.open}
      onClose={handleCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent
        dividers
        style={{ padding: "16px 0px" }}
        ref={(elem: any) => (elem ? setImageWidth(elem.clientWidth) : {})}>
        <DialogContentText style={{ whiteSpace: "pre-line", margin: "12px 24px" }} align="center">
          {props.text}
        </DialogContentText>
        {props.image && (
          <Grid container justifyContent="center" alignItems="center" direction="column" spacing={1}>
            <Grid item>
              <img
                src={props.image}
                alt={"Información bancaria"}
                style={{ maxHeight: "60vh", maxWidth: imageWidth - 16 }}
              />
            </Grid>
            <Grid item>
              <label htmlFor="uploadPhoto">
                <input
                  style={{ display: "none" }}
                  id="uploadPhoto"
                  name="uploadPhoto"
                  type="file"
                  accept="image/*,.pdf"
                  onChange={ev => {
                    if (ev.target.files) {
                      props.toggleSnackbar("El comprobante se subió correctamente", "success");
                      setValue(ev.target.files[0]);
                    }
                  }}
                />
                <Fab variant="extended" size="medium" component="span" color={value ? "primary" : "default"}>
                  <AddAPhoto style={{ marginRight: 8 }} />
                  {value ? value.name : "Subir comprobante"}
                </Fab>
              </label>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {!props.hideCancel && (
          <Button autoFocus onClick={handleCancel} id="cancel">
            {props.cancelText ?? "Cancelar"}
          </Button>
        )}
        <Button disabled={props.image !== undefined && !value} onClick={handleOk} id="confirm">
          {props.confirmText ?? "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CheckoutConfirmationDialog;
