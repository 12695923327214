import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { callGraphQLGeneric } from "../../models/graphql-api";
import {
  PFXCategory,
  PFXComponentType,
  PFXItem,
  PFXLandingPageResponse,
  PFXLandingSubtype,
} from "../../models/Interfaces";
import { insertClientForNewsletterQuery } from "../../models/Mutations/insertClientForNewsletter";
import {
  getComparableItemIdInClynk,
  getComparableItemIdInClynkQuery,
  IGetComparableItemIdInClynk,
} from "../../models/Queries/GetComparableItemIdInClynk";
import { RootState } from "../rootReducer";
import { PFXCategoryItem } from "./templateSlice";

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};

const initialAsyncState: AsyncState = { status: "idle" };

export interface LandingComponent {
  type: PFXComponentType;
  subtype: PFXLandingSubtype;
  data: any;
  hidden: boolean;
  id: string;
}

const componentAdapter = createEntityAdapter<LandingComponent>({
  selectId: c => c.id,
  sortComparer: (cA, cB) => (cA.id < cB.id ? 1 : -1),
});

const groupCategoryAdapter = createEntityAdapter<Partial<PFXCategory>>({
  selectId: c => c.id!,
  sortComparer: (cA, cB) => (cA.id! < cB.id! ? 1 : -1),
});

const groupItemAdapter = createEntityAdapter<PFXCategoryItem>({
  selectId: c => c.id!,
  sortComparer: (cA, cB) => (cA.id! < cB.id! ? 1 : -1),
});

interface ILandingSlice {
  components: EntityState<any>;
  componentsOrder: string[];
  footerComponent: any;
  mainComponent: any;
  componentDimensions: {
    width: number;
    height: number;
  };
  loadData: AsyncState;
  getComparableItemIdInCleverlynk: AsyncState;
  fonts: string[];
  id: string | undefined;
  groupCategories: EntityState<Partial<PFXCategory>>;
  groupItems: EntityState<PFXCategoryItem>;
  landingFromGroup: boolean;
  loadingItem: boolean;
}

export const initialState: ILandingSlice = {
  components: componentAdapter.getInitialState(),
  componentsOrder: [],
  fonts: [],
  footerComponent: { alignment: "center", backgroundColor: "#1a1a1a", color: "#ffffff", hidden: false },
  mainComponent: {
    backgroundColor: "#ff8484",
    margins: { x: 3, y: 3 },
    logo: { alignment: "center", shape: "CIRCLE", sizeMultiplier: 1, activate: true },
    title: {
      alignment: "center",
      color: "#fedfdf",
      fontFamily: "Montserrat",
      fontSizeMultiplier: 2,
      fontWeight: "bold",
      content: "¡Mi nueva página!",
    },
    button: {
      backgroundColor: "#faaaa6",
      borderRadius: 3,
      sizeMultiplier: 14,
      text: {
        alignment: "center",
        color: "#f06d6d",
        fontFamily: "Inter",
        fontSizeMultiplier: 1,
        fontWeight: "normal",
        content: "Ve al menú",
      },
    },
    verticalAlignment: "center",
    type: "CAROUSEL",
  },
  componentDimensions: {
    width: 400,
    height: 400,
  },
  loadData: initialAsyncState,
  getComparableItemIdInCleverlynk: initialAsyncState,
  id: undefined,
  groupCategories: groupCategoryAdapter.getInitialState(),
  groupItems: groupItemAdapter.getInitialState(),
  landingFromGroup: false,
  loadingItem: false,
};

export const getComparableItemIdInCleverlynk = async (info: { clynkId: string; item: PFXItem }) => {
  try {
    const { clynkId: cleverlynkId, item } = info;
    const res = getComparableItemIdInClynk(
      await callGraphQLGeneric<IGetComparableItemIdInClynk>(getComparableItemIdInClynkQuery, {
        cleverlynkId,
        item,
      })
    );
    return res;
  } catch (e) {
    console.error({ e });
  }
};

export const insertNewsletterClient = async (email: string, companyId: string) => {
  try {
    return await API.graphql(
      graphqlOperation(insertClientForNewsletterQuery, {
        email,
        companyId: companyId,
      })
    );
    // const res = insertClientForNewsletter();
  } catch (e) {
    console.error({ e });
  }
};

const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    modifyComponentDimensions(state, action) {
      state.componentDimensions = action.payload;
    },
    modifyLoadingItem(state, action) {
      state.loadingItem = action.payload;
    },
    loadLandingPage(state, action) {
      const { groupCategories, groupItems, id, groupLandingId } = action.payload;
      const { footerComponent, components, mainComponent, componentsOrder } = action.payload.pages[0];

      componentAdapter.setAll(
        state.components,
        components.map(c => ({ ...c, data: JSON.parse(c.data) }))
      );
      state.footerComponent = footerComponent;
      state.mainComponent = mainComponent;
      state.componentsOrder = componentsOrder;

      if (groupCategories) groupCategoryAdapter.setAll(state.groupCategories, groupCategories);
      if (groupItems) groupItemAdapter.setAll(state.groupItems, groupItems);
      if (!!groupLandingId) state.landingFromGroup = true;

      if ((groupCategories && groupCategories.length > 0) || (groupItems && groupItems.length > 0)) {
        state.landingFromGroup = true;
      }

      state.id = id;
      function toFlatPropertyMap(obj: object, keySeparator = ".") {
        const flattenRecursive = (obj: object, parentProperty?: string, propertyMap: Record<string, unknown> = {}) => {
          for (const [key, v] of Object.entries(obj)) {
            let value = v;
            const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
            if (property.endsWith(".data") && typeof value === "string") {
              value = JSON.parse(v);
            }
            if (value && typeof value === "object") {
              flattenRecursive(value, property, propertyMap);
            } else {
              propertyMap[property] = value;
            }
          }
          return propertyMap;
        };
        return flattenRecursive(obj);
      }
      const fonts: any[] = Array.from(
        new Set(
          Object.entries(
            toFlatPropertyMap({
              c: [...components, mainComponent, footerComponent],
            })
          )
            .filter(([k, _v]) => k.includes("fontFamily"))
            .map(([_k, v]) => v)
        )
      );

      state.fonts = fonts;
      state.loadData.status = "succeeded";
    },
  },
});

export const { modifyComponentDimensions, loadLandingPage, modifyLoadingItem } = landingSlice.actions;

export default landingSlice.reducer;

export const selectMainComponent = (state: RootState) => state.landing.mainComponent;
export const selectFooterComponent = (state: RootState) => state.landing.footerComponent;
export const selectComponentsOrder = (state: RootState) => state.landing.componentsOrder;
export const selectComponentDimensions = (state: RootState) => state.landing.componentDimensions;
export const selectLoadData = (state: RootState) => state.landing.loadData;
export const selectLandingFonts = (state: RootState) => state.landing.fonts;
export const selectLandingId = (state: RootState) => state.landing.id;
export const selectLandingFromGroup = (state: RootState) => state.landing.landingFromGroup;
export const selectLoadingItem = (state: RootState) => state.landing.loadingItem;

export const {
  selectAll: selectGroupCategories,
  selectTotal: selectGroupCategoriesAmount,
  selectEntities: selectGroupCategoriesEntities,
} = groupCategoryAdapter.getSelectors((state: RootState) => state.landing.groupCategories);

export const {
  selectAll: selectGroupItems,
  selectTotal: selectGroupItemsAmount,
  selectEntities: selectGroupItemsEntities,
} = groupItemAdapter.getSelectors((state: RootState) => state.landing.groupItems);

export const {
  selectAll: selectComponents,
  selectTotal: selectComponentsAmount,
  selectEntities: selectComponentsEntities,
  selectById: selectComponentById,
  selectIds: selectComponentsIds,
} = componentAdapter.getSelectors((state: RootState) => state.landing.components);
