import { combineReducers } from "@reduxjs/toolkit";
import template from "./slices/templateSlice";
import cleverlynk from "./slices/cleverlynkSlice";
import mapService from "./slices/mapServiceSlice";
import userform from "./slices/userFormSlice";
import landing from "./slices/landingSlice";

import checkout from "./slices/checkoutSlice";
const rootReducer = combineReducers({
  template,
  checkout,
  cleverlynk,
  mapService,
  userform,
  landing,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
