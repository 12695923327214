import React, { useContext, useState } from "react";
import awsconfig from "../aws-exports";
import { Auth } from "aws-amplify";
import Location from "aws-sdk/clients/location";
import useCleverlynkFunctions from "../hooks/useCleverlynkFunctions";

interface IMapServiceContext {
  AWSClient;
  initAWSClient;
}

interface IMapServiceContextProps {
  children: React.ReactNode;
}

export const MapServiceContext = React.createContext<IMapServiceContext | undefined>(undefined);

const MapServiceContextProvider = ({ children }: IMapServiceContextProps) => {
  const [AWSClient, setAWSClient] = useState<any>(undefined);

  const { handleModifySnackbar } = useCleverlynkFunctions();

  const initAWSClient = async () => {
    try {
      const credentials = await Auth.currentCredentials();
      const client = new Location({
        credentials,
        region: awsconfig.aws_project_region,
      });
      setAWSClient(client);
    } catch (error) {
      handleModifySnackbar({ type: "error", message: "Failure to initialize AWS Location" });
    }
  };

  return (
    <MapServiceContext.Provider
      value={{
        AWSClient,
        initAWSClient,
      }}>
      {children}
    </MapServiceContext.Provider>
  );
};

export const useMapServiceContext = () => useContext(MapServiceContext);

export default MapServiceContextProvider;
