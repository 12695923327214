import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOrderSummary, selectLoadOrderSummary } from "../../redux/slices/cleverlynkSlice";
import { selectCurrency } from "../../redux/slices/templateSlice";
import CheckoutItemCard from "../Checkout/CheckoutItemCard";

interface IProductsSummary {}

const ProductsSummary = (props: IProductsSummary) => {
  const orderSummary = useSelector(selectOrderSummary);

  return (
    <Grid item container direction="column" style={{}} xs={12}>
      {orderSummary?.orderItems?.map((item, i) => (
        <CheckoutItemCard inheritBR item={item} index={i} key={`CheckoutItemCard-${i}`} />
      ))}
    </Grid>
  );
};
export default ProductsSummary;
