import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import useTemplateFunctions from "../../hooks/useTemplateFunctions";
import { selectItemsEntities, selectRecentlyViewed } from "../../redux/slices/templateSlice";
import ListCard from "../Templates/ItemCards/ListCard";
import RecentItemCard from "./RecentItemCard";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IRecentItems {}

const RecentItems = (props: IRecentItems) => {
  const recentlyViewed = useSelector(selectRecentlyViewed);
  const catalogItems = useSelector(selectItemsEntities);

  const { t } = useTranslation("header");

  const { handleModifyItemToShow } = useTemplateFunctions();

  return (
    <Grid container style={{ marginTop: 80, marginBottom: 100 }}>
      <Grid item xs={12} container justifyContent="center" alignItems="center" style={{ height: 100 }}>
        <Typography variant="body1">{t("shoppingCart.anyRecentCravings")}</Typography>
      </Grid>
      {recentlyViewed.map((i, index) => (
        <RecentItemCard id={i} onClick={() => handleModifyItemToShow(catalogItems[i])} index={index} />
      ))}
    </Grid>
  );
};
export default RecentItems;
