import {
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowForward, Backup } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";
import Iframe from "react-iframe";
import { useSelector } from "react-redux";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import {
  selectCleverlynk,
  selectCompany,
  selectIsMobile,
  selectOrderState,
  selectOrderSummary,
} from "../../redux/slices/cleverlynkSlice";
import TransferPriceCard from "./TransferPriceCard";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IPendingTransfer {
  orderId: string;
}

export const TRANSFER_COLORS = {
  upload: {
    light: "#dae7ff",
    main: "#476aff",
  },
  send: {
    light: "#daffe4",
    main: "#45dc93",
  },
  error: {
    light: "#f9bbbb",
    main: "#ff4747",
  },
  loading: {
    main: "#40d0ac",
  },
};

const PendingTransfer = ({ orderId }: IPendingTransfer) => {
  const [transferPhotoInfo, setTransferPhotoInfo] = useState<any>(undefined);
  const [transferPhotoRenderable, setTransferPhotoRenderable] = useState<any>(undefined);
  const [showCompanyPhoto, setShowCompanyInfo] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const isMobile = useSelector(selectIsMobile);
  const company = useSelector(selectCompany);
  const cleverlynk = useSelector(selectCleverlynk);
  const orderSummary = useSelector(selectOrderSummary);

  const { t } = useTranslation("ordersummary");

  const { handleModifySnackbar, handleSetTransferOrderPhoto, handleLoadOrderSummary } = useCleverlynkFunctions();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      uploadCard: {
        height: 60,
        backgroundColor: TRANSFER_COLORS.upload.main,
        width: "100%",
        maxWidth: 500,
        marginTop: 20,
        // marginTop: -30,
        borderRadius: 12,
        transitionDuration: "300ms",
        transitionProperty: "background-color",
        "&.error": {
          backgroundColor: TRANSFER_COLORS.error.main,
        },
        "&.loading": {
          backgroundColor: TRANSFER_COLORS.loading.main,
        },
      },
      iconButtonDiv: {
        height: 70,
        width: 70,
        marginTop: -5,
        zIndex: 121,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "1pt 1pt 7px -2px #00000080",
        borderRadius: "5pc",
        "&.error": {
          backgroundColor: "#fff",
        },
        "&.send": {
          borderRadius: "1pc",
          transform: "rotate(45deg)",
          height: 60,
          width: 60,
        },
        "&.edit": {
          marginTop: -15,
          marginBottom: -15,
        },
      },
      iconButton: {
        fontSize: isMobile ? "3rem" : "6rem",
        color: TRANSFER_COLORS.upload.main,
        "&.error": {
          color: TRANSFER_COLORS.error.main,
        },
        "&.send": {
          color: TRANSFER_COLORS.send.main,
          fontSize: "3rem",
          transform: "rotate(-45deg)",
        },
        "&.edit": {
          fontSize: "3rem",
        },
        "&.fulfilled": {},
      },
      text: {
        color: "#fff",
        fontSize: "1.1rem",
        fontWeight: 400,
        textTransform: "none",
        "&.error": {
          fontSize: "1rem",
        },
        "&.button": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      infoPaper: {
        borderRadius: isMobile ? "9px 9px 30px 30px" : 9,
        zIndex: 100,
      },
      fulfilledEdit: {
        marginTop: -25,
        background: TRANSFER_COLORS.send.light,
        borderRadius: "0px 0px 0px 9px",
      },
      fulfilledButton: {},
      fulfilledText: {
        fontSize: "1.1rem",
        fontWeight: 400,
        "&.send": {
          fontSize: "1.1rem",
          fontWeight: 400,
          padding: 5,
          marginRight: -20,
          paddingRight: 20,
          borderRadius: "9px 0px 0px 9px",
          color: "white",
        },
        "&.edit": {
          fontSize: "1.1rem",
          fontWeight: 400,
          padding: 5,
          marginLeft: -20,
          paddingLeft: 30,
          borderRadius: "0px 9px 9px 0px",
          color: "white",
        },
      },
      totalPaper: {
        backgroundColor: "#1a1a1a",
        height: 60,
      },
      loadingProgress: {
        color: TRANSFER_COLORS.loading.main,
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    if (transferPhotoInfo) {
      setTransferPhotoRenderable(URL.createObjectURL(transferPhotoInfo));
      setShowCompanyInfo(false);
    }
  }, [transferPhotoInfo]);

  const imgType = React.useMemo(() => {
    return !transferPhotoInfo ? undefined : (transferPhotoInfo as File).type;
  }, [transferPhotoInfo]);

  const image = React.useMemo(() => {
    if (company?.bankTransferInfoURLs && company?.bankTransferInfoURLs?.length >= 1) {
      if (cleverlynk?.bankTransferImage) {
        return cleverlynk?.bankTransferImage;
      } else {
        return company?.bankTransferInfoURLs[0];
      }
    } else {
      return undefined;
    }
  }, [cleverlynk, company]);

  const handleClick = () => {
    const elem = document.getElementById("uploadPhoto");
    if (elem) {
      elem.click();
    }
  };

  const scrollDown = () => {
    const elem = document.getElementsByTagName("html");
    setTimeout(() => {
      if (elem && elem[0]) {
        elem[0].scrollTo({
          top: window.screen.height,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  const uploadTransferPhoto = () => {
    handleModifySnackbar(null);
    setLoading(true);
    if (transferPhotoInfo === null) return;
    handleModifySnackbar({
      type: "info",
      message: "Estamos creando la orden...",
      disableClickAway: true,
      time: null,
    });
    const renamedFile = new File(
      [transferPhotoInfo],
      `${uuidv4()}.${transferPhotoInfo.name.split(".")[transferPhotoInfo.name.split(".").length - 1]}`,
      {
        type: transferPhotoInfo.type,
      }
    );
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "transacciones",
      region: "us-east-1",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      s3Url: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.us-east-1.amazonaws.com`,
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(renamedFile)
      .then(data => {
        handleSetTransferOrderPhoto(data.location, orderSummary?.id!);
        setTimeout(() => {
          handleLoadOrderSummary(orderSummary?.id!);
          handleModifySnackbar(null);
        }, 5000);
      })
      .catch(e => {
        console.error(e);
        handleModifySnackbar({
          type: "error",
          message: t("pendingTransfer.uploadError"),
        });
      });
  };

  const uploadCard = React.useMemo(() => {
    switch (transferPhotoInfo) {
      case null:
        return (
          <Paper className={`error ${classes.uploadCard}`}>
            <Grid container style={{ height: "100%" }} justifyContent="center" onClick={handleClick}>
              <Grid item xs={4} container justifyContent="center" alignItems="center">
                <div className={`error ${classes.iconButtonDiv}`}>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      handleClick();
                    }}>
                    <Backup className={`error ${classes.iconButton}`} />
                  </IconButton>
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ paddingTop: 18 }}
                alignContent="flex-start">
                <Typography variant="body1" className={`error ${classes.text}`}>
                  {t("pendingTransfer.unknownError")}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      case undefined:
        return (
          <Paper className={classes.uploadCard}>
            <Grid container style={{ height: "100%" }} justifyContent="center" onClick={handleClick}>
              <Grid item xs={4} container justifyContent="center" alignItems="center">
                <div className={classes.iconButtonDiv}>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      handleClick();
                    }}>
                    <Backup className={classes.iconButton} />
                  </IconButton>
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ paddingTop: 18 }}
                alignContent="flex-start">
                <Typography variant="subtitle2" className={classes.text}>
                  {t("pendingTransfer.uploadHere")}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      default:
        if (loading)
          return (
            <Paper className={`loading ${classes.uploadCard}`}>
              <Grid container style={{ height: "100%" }} justifyContent="center">
                <Grid item xs={8} container justifyContent="center" alignContent="center">
                  <Typography variant="body1" className={`loading ${classes.text}`}>
                    {t("pendingTransfer.uploading")}
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="center" alignItems="center">
                  <div className={`loading ${classes.iconButtonDiv}`}>
                    <IconButton>
                      <CircularProgress className={classes.loadingProgress} disableShrink thickness={5} size={60} />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          );
        else
          return (
            <Paper className={`fulfilled ${classes.uploadCard}`}>
              <Grid container style={{ height: "100%" }} justifyContent="center">
                <Grid
                  item
                  xs={5}
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  style={{
                    backgroundColor: TRANSFER_COLORS.upload.main,
                    borderRadius: "9px 0px 0px 9px",
                  }}>
                  <div className={`edit ${classes.iconButtonDiv}`} onClick={handleClick}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleClick();
                      }}>
                      <Backup className={`edit ${classes.iconButton}`} />
                    </IconButton>
                  </div>
                  <Typography className={`edit ${classes.fulfilledText}`}>{t("pendingTransfer.edit")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{
                    paddingRight: 20,
                    backgroundColor: TRANSFER_COLORS.send.main,
                    borderRadius: "0px 9px 9px 0px",
                  }}
                  onClick={uploadTransferPhoto}>
                  <Typography className={`send ${classes.fulfilledText}`}>{t("pendingTransfer.send")}</Typography>
                  <div className={`send ${classes.iconButtonDiv}`} style={{ marginLeft: 15 }}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        uploadTransferPhoto();
                      }}>
                      <ArrowForward className={`send ${classes.iconButton}`} />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          );
    }
  }, [classes.iconButtonDiv, classes.uploadCard, classes.text, isMobile, transferPhotoInfo]);

  return (
    <Grid container>
      <Grid container item xs={12} direction="column" style={{ zIndex: 100 }}>
        {isMobile && (
          <Grid item style={{ marginTop: 15 }}>
            <TransferPriceCard />
          </Grid>
        )}
        {isMobile && uploadCard}
        <Grid item style={{ marginTop: 15 }} container justifyContent="space-between">
          <Typography>{t("pendingTransfer.bankInfo")}</Typography>
          {transferPhotoInfo !== null && transferPhotoInfo !== undefined && (
            <Button onClick={() => setShowCompanyInfo(p => !p)} style={{ height: 25 }}>
              <Typography variant="overline" style={{ textDecoration: "underline" }}>
                {showCompanyPhoto ? t("pendingTransfer.seeReceipt") : t("pendingTransfer.seeBankInfo")}
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item>
          <div className={classes.infoPaper}>
            <Collapse in={showCompanyPhoto} style={{ borderRadius: "inherit" }}>
              <img
                src={image}
                style={{ borderRadius: isMobile ? "12px" : "9px", boxShadow: "3pt 3pt 4px 0px #0000001f" }}
                height={"100%"}
                width={"100%"}
                alt="info-bancaria"
              />
            </Collapse>
            <Collapse in={!showCompanyPhoto}>
              {imgType && imgType.includes("pdf") ? (
                <Iframe url={transferPhotoRenderable} width="100%" height="500px" loading={"auto"} />
              ) : (
                <img src={transferPhotoRenderable} alt={"comprobante"} height={"100%"} width={"100%"} />
              )}
            </Collapse>
          </div>
        </Grid>
      </Grid>
      <div id="handle-upload-photo-click" onClick={uploadTransferPhoto}></div>
      <input
        style={{ display: "none" }}
        id="uploadPhoto"
        name="uploadPhoto"
        type="file"
        accept="image/*,.pdf"
        onChange={ev => {
          if (ev.target.files && ev.target.files.length > 0) {
            scrollDown();
            setTransferPhotoInfo(ev.target.files[0]);
          }
        }}
      />
    </Grid>
  );
};
export default PendingTransfer;
