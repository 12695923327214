import { PFXCategory, PFXCategoryStructure } from "../../redux/slices/templateSlice";
import { PFXItem, PFXTemplate } from "../Interfaces";

export const fetchLandingPageInfoForRenderQuery = (
  clynkId: string | undefined,
  hostname: string | undefined
) => `query q {
  fetchLandingPageInfoForRender(
      ${!!clynkId ? `id:"${clynkId}",` : ""},
      hostname: "${hostname}") {
    id
    groupCategories {
			name
      id      
      itemsAmount
      items {
        id
      }
    }
    groupItems {
      id
      soldOut
      price
      name
      priceWithDiscount
      images
      description
      sku
      subitems {
        sku
        choiceLimit
        controlsInventory
        id
        mandatory
        multiple
        posReference
        name
        hidden
        options {
          id
          label
          posReference
          value
          sku
          hidden
        }
        type
        timeFrame
        choiceMinimum
      }
      attachment
      new
      decorative
      deliveryDelay
      maxOrderHour      
    }
    pages {
      mainComponent {
        type
        backgroundImage
        opaqueBackground {
          color
          alpha
        }
        title {
          color
          content
          fontSizeMultiplier
          fontWeight
          fontFamily
          alignment
        }
        backgroundColor
        button {
          backgroundColor
          borderRadius
          text {
            color
            content
            fontSizeMultiplier
            fontWeight
            fontFamily
            alignment
          }
          sizeMultiplier
        }
        verticalAlignment
        logo {
          alignment
          sizeMultiplier
          shape
          activate
        }
        margins {
          y
          x
        }
      }
      components {
        type
        subtype
        data
        hidden
        id
      }
      footerComponent {
        backgroundColor
        color
        alignment
        hidden
      }
      id
      componentsOrder
    }
  }
}`;

export interface IGetLandingForRender {
  template: PFXTemplate;
  structure: PFXCategory;
  items: PFXItem[];
  categories: PFXCategoryStructure;
}
