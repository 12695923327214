import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  selectLoadOrderSummary,
  selectOrderSummary,
  currencyFormatter,
  selectOrderState,
  TAKEOUT_LABEL,
} from "../../redux/slices/cleverlynkSlice";
import { selectCurrency } from "../../redux/slices/templateSlice";
import {
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import RetryOrder from "./RetryOrder";
import ContactStore from "./ContactStore";
import { IOrderState } from "../../pages/OrderSummary";
import PendingTransfer from "./PendingTransfer";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IInfoSummary {}

const InfoSummary = (props: IInfoSummary) => {
  const [orderInfo, setOrderInfo] = useState<{ basic; delivery; price } | undefined>(undefined);

  const { t } = useTranslation("ordersummary");

  const orderSummary = useSelector(selectOrderSummary);
  const orderState = useSelector(selectOrderState);
  const orderSummarySupervisor = useSelector(selectLoadOrderSummary);
  const currency = useSelector(selectCurrency);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      emphasizedListItem: {
        backgroundColor: "black",
        color: "white",
        borderRadius: "0px 0px 15px 15px",
      },
      normalListItem: {
        padding: "0px 20px",
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    function getPrettyHourRange(orderSummary) {
      const range = orderSummary?.deliveryInfo?.deliveryHourRange;
      if (!range) return undefined;
      const [start, end] = range;
      const timeString = moment(start, "hh:mm:ss").format("h:mm A") + " - " + moment(end, "hh:mm:ss").format("h:mm A");
      return timeString;
    }
    function getPrettyDay(orderSummary) {
      const date = orderSummary?.deliveryInfo?.deliveryDate;
      if (!date) return undefined;
      return `${moment(date).format("dddd, MMMM D, YYYY")}`;
    }

    const getInfoToRender = () => {
      const basic = [...(orderSummary?.mandatoryCriteria ?? []), ...(orderSummary?.optionalCriteria ?? [])]
        .filter(c => c.label !== TAKEOUT_LABEL)
        .map(c => ({ label: c.label, value: c.value }));
      const takeout = (orderSummary?.mandatoryCriteria ?? []).find(o => o.label === TAKEOUT_LABEL);
      const delivery = [
        !!!takeout?.value
          ? {
              label: t("deliveryAddress"),
              value: orderSummary?.deliveryInfo?.deliveryAddress
                ? orderSummary?.deliveryInfo?.deliveryAddress
                : undefined,
            }
          : null,
        !!!takeout?.value
          ? {
              label: t("propertyType"),
              value: orderSummary?.deliveryInfo?.deliveryPropertyTypeInfo,
            }
          : null,
        !!!takeout?.value
          ? {
              label: t("additionalInfo"),
              value: orderSummary?.deliveryInfo?.deliveryAdditionalInfo,
            }
          : null,
        {
          label: t("pickupPlace"),
          value: takeout?.value,
        },
        {
          label: t("deliveryDate"),
          value: getPrettyDay(orderSummary),
        },
        {
          label: t("deliveryTime"),
          value: getPrettyHourRange(orderSummary),
        },
      ].filter(d => !!d && d.value);

      const price = [
        {
          id: "payment-method",
          label: t("paymentMethod"),
          value: orderSummary?.paymentMethod,
        },
        {
          id: "sub-total",
          label: "Subtotal",
          value: currencyFormatter(orderSummary?.price?.subTotal, currency),
        },
        orderSummary?.price?.tax && {
          id: "tax",
          label: t("tax"),
          value: currencyFormatter(orderSummary?.price?.tax, currency),
        },
        orderSummary?.price?.delivery && {
          id: "delivery-cost",
          label: t("deliveryCost"),
          value: currencyFormatter(orderSummary?.price?.delivery, currency),
        },
        orderSummary?.price?.discount && {
          id: "discount",
          label: t("discount"),
          value: currencyFormatter(orderSummary?.price?.discount, currency),
        },
        orderSummary?.price?.transaction && {
          id: "transaction-cost",
          label: t("transactionCost"),
          value: currencyFormatter(orderSummary?.price?.transaction, currency),
        },
        orderSummary?.price?.tip && {
          id: "trip",
          label: t("tip"),
          value: currencyFormatter(orderSummary?.price?.tip, currency),
        },
        orderSummary?.price?.total && {
          id: "total",
          label: "Total",
          value: currencyFormatter(orderSummary?.price?.total, currency),
          emphasize: true,
        },
      ].filter(p => p);
      return {
        basic,
        delivery,
        price,
      };
    };

    if (orderSummarySupervisor.status === "succeeded") setOrderInfo(getInfoToRender());
  }, [orderSummarySupervisor, orderSummary, currency]);

  const deliveryDetails = useMemo(
    () => (
      <>
        {Object.values(orderSummary?.deliveryInfo ?? {}).some(e => Boolean(e)) && (
          <Paper style={{ marginBottom: 10 }}>
            <Grid item style={{ padding: "10px 20px" }}>
              <Typography style={{ fontWeight: "bold" }}>{t("deliveryDetails")}</Typography>
              <Divider />
              <List dense>
                {orderInfo?.delivery.map(d => (
                  <ListItem key={d.label}>
                    <ListItemText primary={d.value} secondary={d.label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Paper>
        )}
      </>
    ),
    [orderSummary, orderInfo]
  );

  const paymentDetails = useMemo(
    () =>
      orderSummary?.paymentMethod ? (
        <Paper style={{ marginBottom: 10 }}>
          <Grid item style={{ paddingTop: 10 }}>
            <Typography style={{ fontWeight: "bold", padding: "0px 20px" }}>{t("payment")}</Typography>
            <Divider style={{ margin: "0px 20px" }} />
            <List dense style={{ paddingBottom: 0 }}>
              {orderInfo?.price.map(d => (
                <ListItem key={d.label} className={d.emphasize ? classes.emphasizedListItem : classes.normalListItem}>
                  <ListItemText
                    primary={`${d.emphasize ? `${d.label}: ` : ""} ${d.value}`}
                    secondary={d.emphasize ? undefined : d.label}
                    id={d.id}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Paper>
      ) : null,
    [orderSummary?.paymentMethod, orderInfo?.price, classes.emphasizedListItem, classes.normalListItem]
  );

  const userDetails = useMemo(
    () => (
      <Paper style={{ marginBottom: 10 }}>
        <Grid item style={{ padding: "10px 20px" }}>
          <Typography style={{ fontWeight: "bold" }}>{t("info")}</Typography>
          <Divider />
          <List dense>
            {orderInfo?.basic.map(d => (
              <ListItem key={d.label}>
                <ListItemText primary={d.value} secondary={d.label} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Paper>
    ),
    [orderInfo]
  );

  const componentsToRender = React.useMemo(() => {
    switch (orderState) {
      case IOrderState.TRANSFER:
        return (
          <React.Fragment>
            <PendingTransfer orderId={orderSummary?.id ?? ""} />
          </React.Fragment>
        );
      case IOrderState.ACCEPTED:
        return (
          <React.Fragment>
            {paymentDetails}
            <ContactStore />
            {deliveryDetails}
            {userDetails}
          </React.Fragment>
        );
      case IOrderState.CANCELLED:
        return (
          <React.Fragment>
            <ContactStore />
            <RetryOrder />
            {paymentDetails}
          </React.Fragment>
        );
      case IOrderState.DELIVERED:
        return (
          <React.Fragment>
            {/* <OrderRating /> */}
            <ContactStore />
            {deliveryDetails}
            {paymentDetails}
            {userDetails}
          </React.Fragment>
        );
      case IOrderState.PENDING:
        return (
          <React.Fragment>
            {paymentDetails}
            <ContactStore />
            {deliveryDetails}
            {userDetails}
          </React.Fragment>
        );
      case IOrderState.PREPARATION:
        return (
          <React.Fragment>
            {paymentDetails}
            <ContactStore />
            {deliveryDetails}
            {userDetails}
          </React.Fragment>
        );
      case IOrderState.REJECTED:
        return (
          <React.Fragment>
            <ContactStore />
            <RetryOrder />
            {paymentDetails}
          </React.Fragment>
        );
      case IOrderState.SHIPPING:
        return (
          <React.Fragment>
            {/* <CostumerReview /> */}
            {deliveryDetails}
            <ContactStore />
            {userDetails}
            {paymentDetails}
          </React.Fragment>
        );
      case IOrderState.PICKUP:
        return (
          <React.Fragment>
            {/* <CostumerReview /> */}
            {deliveryDetails}
            <ContactStore />
            {userDetails}
            {paymentDetails}
          </React.Fragment>
        );
      default:
        return null;
    }
  }, [orderState, deliveryDetails, paymentDetails, userDetails, orderSummary]);

  return componentsToRender;
};
export default InfoSummary;
