import { GraphQLResult } from "@aws-amplify/api";
import {
  PFXCleverlynk,
  PFXCriteriaForOrder,
  PFXDeliveryInfo,
  PFXItemForOrder,
  PFXOrder,
  PFXPrice,
} from "../Interfaces";
import { checkNull } from "./GetCleverlynkForRender";

export const getOrderForSummaryQuery = orderId => `
  query getOrderForSend {
    fetchOrder(id: "${orderId}"){
      id
      createdAt
      company{
        id
        name
        logoUrl
        primaryColor
        bankTransferInfoURLs
        domain
        subdomain
        hyperlinks {
          url
          name
        }
        socials {
          url
          page
        }
        phones
      }
      items {
        chosenOptions {
          label 
          quantity
          value
          input
          subitemId
          subitemType
          posReference
        }
        subitems{
          name
          id
        }
        images
        name
        og_price
        price
        quantity
        }
      cleverlynkId
      identification
      mandatoryCriteria{
        label
        value
      }
      price {
        delivery
        discount
        subTotal
        tip
        tax
        total
        transaction
      }
      optionalCriteria{
        label
        value
      }
      status
      cleverlynk {
        language
        bankTransferImage
        whatsapp
        phone
        template {
          socialsFooter
          currency
        }
        company {
          domain
          subdomain 
        }
      }
      fulfillmentType
      paymentMethod
      paymentStatus
      picapTrackingLink
      bankTransferImage
      muTrackingLink
      deliveryInfo {
        deliveryAddress
        deliveryLocation {
          lat
          lng
        }
        deliveryPropertyTypeInfo
        deliveryAdditionalInfo
        deliveryDate
        deliveryHourRange
      }
    }
  }
`;

export interface IOrderSummary extends PFXOrder {
  orderItems: PFXItemForOrder[];
  socialsFooter: boolean;
  cleverlynk: any;
}

export function getOrderForSummary(order: GraphQLResult<any>): IOrderSummary {
  const data = order.data?.fetchOrder;
  return {
    id: data?.id,
    orderItems: data?.items as PFXItemForOrder[],
    identification: checkNull(data?.identification),
    paymentMethod: data?.paymentMethod,
    createdAt: data?.createdAt,
    company: {
      subdomain: checkNull(data?.company.subdomain),
      domain: checkNull(data?.company.domain),
      id: checkNull(data?.company.id),
      name: checkNull(data?.company.name),
      logoUrl: checkNull(data?.company.logoUrl),
      primaryColor: checkNull(data?.company.primaryColor),
      hyperlinks: checkNull(data?.company?.hyperlinks),
      mails: checkNull(data?.company?.mails),
      phones: checkNull(data?.company?.phones),
      socials: checkNull(data?.company?.socials),
      bankTransferInfoURLs: checkNull(data?.company?.bankTransferInfoURLs),
    },
    socialsFooter: !!data?.cleverlynk?.template?.socialsFooter,
    cleverlynkId: data?.cleverlynkId,
    cleverlynk: data?.cleverlynk,
    status: data?.status,
    bankTransferImage: checkNull(data?.bankTransferImage),
    paymentStatus: checkNull(data?.paymentStatus),
    fulfillmentType: checkNull(data?.fulfillmentType),
    price: data?.price as PFXPrice,
    mandatoryCriteria: data?.mandatoryCriteria.map(
      c =>
        ({
          label: c.label,
          value: c.value,
          criteriaType: c.criteriaType,
        } as PFXCriteriaForOrder)
    ),
    optionalCriteria: data?.optionalCriteria?.map(
      c =>
        ({
          label: c.label,
          value: c.value,
          criteriaType: c.criteriaType,
        } as PFXCriteriaForOrder)
    ),
    deliveryInfo: {
      deliveryAddress: data?.deliveryInfo?.deliveryAddress,
      deliveryLocation: data?.deliveryInfo?.deliveryLocation,
      deliveryPropertyTypeInfo: data?.deliveryInfo?.deliveryPropertyTypeInfo,
      deliveryAdditionalInfo: data?.deliveryInfo?.deliveryAdditionalInfo,
      deliveryDate: data?.deliveryInfo?.deliveryDate,
      deliveryHourRange: data?.deliveryInfo?.deliveryHourRange,
    } as PFXDeliveryInfo,
  };
}
