import { Box, Card, CardActionArea, CardContent, Chip, Fade, Grid, Typography } from "@material-ui/core";
import { IKImage } from "imagekitio-react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useTemplateFunctions from "../../../hooks/useTemplateFunctions";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { PFXCardType } from "../../../models/Interfaces";
import { RootState } from "../../../redux/rootReducer";
import {
  currencyFormatter,
  selectCartIsOpen,
  selectIdenticalItemsInCart,
  selectIsMobile,
  selectCleverlynk,
} from "../../../redux/slices/cleverlynkSlice";
import { selectCurrency, selectItemById, selectSkuById } from "../../../redux/slices/templateSlice";

interface ILargeCard {
  onClick: () => void;
  id: string;
  index: number;
}

const LargeCard = ({ onClick, id, index }: ILargeCard) => {
  const { cardType, cardStyles, titleStyles } = useTemplateStyles();
  const { shouldHideSoldOutItem } = useTemplateFunctions();

  const [cardDimensions, setCardDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const item = useSelector((state: RootState) => selectItemById(state, id));
  const itemSku = useSelector((state: RootState) => selectSkuById(state, item?.sku ?? ""));
  const identicalItemsInCart = useSelector(selectIdenticalItemsInCart);
  const isMobile = useSelector(selectIsMobile);
  const cleverlynk = useSelector(selectCleverlynk);
  const currency = useSelector(selectCurrency);
  const cartIsOpen = useSelector(selectCartIsOpen);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: 0,
        position: "relative",
      },
      rootCard: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      actionAreaCard: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      },
      mediaCard: {
        height: cardDimensions.height,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        position: "relative",
        "&.large": {
          display: "flex",
          width: cardType === PFXCardType.VERTICAL ? "inherit" : "auto",
          justifyContent: "center",
        },
      },
      largeIKImage: {
        minWidth: cardType === PFXCardType.VERTICAL ? "" : cardDimensions.width,
        transition: "width 400ms",
        height: cardDimensions.height,
        position: "relative",
      },
      contentCard: {
        textAlign: "center",
        width: "100%",
        margin: "auto",
        padding: "4px 0px",
      },
      largeNewChip: {
        width: "30%",
        fontSize: "1rem",
      },
      largeNewChipDiv: {
        position: "absolute",
        top: 5,
        left: 15,
      },
      mediaSoldOut: {
        width: "100%",
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        background: "rgba(0, 0, 0, 0.6)",
        position: "absolute",
        top: 0,
      },
      soldOut: {
        bottom: 0,
        color: "#FFFFFF",
        textAlign: "center",
        background: "rgba(200, 0, 0, 0.4)",
      },
      discount: {
        position: "absolute",
        top: "12%",
        left: "15%",
        width: "115%",
        transform: "rotate(45deg)",
        msTransform: "rotate(45deg)",
        WebkitTransform: "rotate(45deg)",
        color: "white",
        textAlign: "center",
        background: "#ff4b4b",
        padding: "3px 0px",
      },
      quantity: {
        position: "absolute",
        zIndex: 1,
        height: 21,
        width: 21,
        padding: 3,
        top: -10,
        right: -10,
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        color: "#FFFFFF",
        textAlign: "center",
        background: "rgba(250, 70, 80, 1)",
      },
      priceWithDiscount: {
        opacity: 0.7,
        MozOpacity: 0.7,
        textDecoration: "line-through",
        // paddingRight: 8,
        textDecorationColor: "rgba(250, 0, 0, 1)",
      },
      button: {
        borderRadius: 0,
        MozBorderRadius: 0,
        WebkitBorderRadius: 0,
        "&.large": {
          borderRadius: 5,
          margin: "3px 0px",
          width: "40%",
        },
      },
      largeCardGrid: {
        width: cardDimensions.width,
        transition: "width 400ms",
        [theme.breakpoints.up("xs")]: {
          flexGrow: 0,
          padding: 8,
          maxWidth: "93%",
          flexBasis: "93%",
        },
        [theme.breakpoints.up("sm")]: {
          flexGrow: 0,
          padding: 8,
          maxWidth: "47%",
          flexBasis: "47%",
        },
        [theme.breakpoints.up("md")]: {
          flexGrow: 0,
          padding: 12,
          maxWidth: "33.3%",
          flexBasis: "33.3%",
        },
        [theme.breakpoints.up("lg")]: {
          flexGrow: 0,
          padding: 16,
          maxWidth: "33.3%",
          flexBasis: "33.3%",
          "&.VERTICAL": {
            maxWidth: "23%",
            flexBasis: "23%",
          },
        },
      },
      largeButtonDiv: {
        position: "absolute",
        bottom: 5,
        zIndex: 999,
      },
      text: {
        lineHeight: 1.45,
        maxWidth: "95%",
        marginRight: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis",
        msTextOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&.largeDescription": {
          fontFamily: titleStyles?.textFontFamily,
          fontSize: titleStyles?.fontSize,
          overflow: "hidden",
          textOverflow: "ellipsis",
          msTextOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        "&.largeName": {
          maxWidth: "100%",
          padding: item?.description && item?.description.trim() !== "" ? "" : "0.6em 0px",
          [theme.breakpoints.down("sm")]: {
            fontSize: `${cardStyles?.largeCardFontSize ?? 1}rem`,
            fontWeight: "bold",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: `${(cardStyles?.largeCardFontSize ?? 1) * 1.1}rem`,
            fontWeight: "bold",
          },
          [theme.breakpoints.up("lg")]: {
            fontSize: `${(cardStyles?.largeCardFontSize ?? 1) * 1.2}rem`,
            fontWeight: "bold",
          },
        },
        "&.largePrice": {
          textAlign: "center",
          fontFamily: titleStyles?.textFontFamily,
          fontWeight: "bold",
          // marginLeft: "auto",
          marginRight: 0,
          marginBottom: item?.priceWithDiscount! > 0 && !!item?.discount && item?.discount?.type !== "none" ? 0 : "",
          "&.discount": {
            lineHeight: "0.9",
            marginTop: 0,
            marginBottom: 0,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "0.95rem",
          },
          [theme.breakpoints.up("lg")]: {
            fontSize: "1rem",
          },
        },
      },
      largePriceDiv: {
        alignItems:
          item?.priceWithDiscount! > 0 && !!item?.discount && item?.discount?.type !== "none" ? "flex-start" : "center",
        justifyContent: "center",
        alignContent: "center",
      },
      largeCardBox: {
        height: "100%",
        width: cardDimensions.width === 0 ? "" : cardDimensions.width - (cartIsOpen ? 60 : 0),
        transition: "width 400ms",
      },
    })
  );

  const classes = useStyles();

  const stock = React.useMemo(() => {
    const itemStock = itemSku?.stock;
    const soldOut = item?.soldOut;

    if (soldOut) return 0;
    else if (itemStock === undefined) return Math.max() - 1;
    else return itemStock;
  }, [item, itemSku]);

  const shouldNotRender = React.useMemo(
    () => !item || item?.hidden || shouldHideSoldOutItem(item!),
    [item, shouldHideSoldOutItem]
  );

  const path = React.useMemo(
    () =>
      item?.images && item?.images.length > 0
        ? item?.images[0].split("cleverlynk-imgs.s3.amazonaws.com")[1]?.replace("resized-", "")
        : "",
    [item?.images]
  );

  const quantity = React.useMemo(() => {
    return (
      <Box className={classes.header}>
        {identicalItemsInCart[id] && identicalItemsInCart[id] > 0 && (
          <Box className={classes.quantity}>
            <Typography
              style={{ color: "#FFFFFF", fontSize: "0.875rem", fontWeight: "bold", width: "100%", textAlign: "center" }}
              variant="subtitle2"
              id={`quantity-item-${index}`}
              component="p">
              {identicalItemsInCart[id]}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }, [classes.header, classes.quantity, id, identicalItemsInCart]);

  return shouldNotRender ? null : (
    <Fade in={true} timeout={400}>
      <Grid item className={`${cardType} ${classes.largeCardGrid}`}>
        <Box className={classes.largeCardBox}>
          {quantity}
          <Card
            style={cardStyles}
            className={`cardWidth ${classes.rootCard}`}
            ref={(e: any) => {
              if (e && e.offsetWidth !== cardDimensions.width && cardDimensions.width === 0) {
                const height = cardType === PFXCardType.VERTICAL ? (e.offsetWidth ?? 0) * 1.6 : e.offsetWidth ?? 0;
                let width = cardType === PFXCardType.VERTICAL ? (e.offsetWidth ?? 0) * 1 : e.offsetWidth ?? 0;
                return setCardDimensions({ height, width });
              } else return null;
            }}>
            <CardActionArea className={classes.actionAreaCard} disabled={stock === 0} onClick={onClick}>
              <div className={`large ${classes.mediaCard}`}>
                {cardDimensions.width ? (
                  <IKImage
                    // key={`${item?.id}-${cartIsOpen ? "openCart" : "noCart"}`}
                    className={classes.largeIKImage}
                    path={path}
                    transformation={[
                      {
                        height: Math.max(isMobile ? 500 : 600, cardDimensions.width),
                        width: Math.max(isMobile ? 500 : 600, cardDimensions.width),
                      },
                    ]}
                    loading="lazy"
                    lqip={{ active: true, quality: 10, blur: 40 }}
                  />
                ) : (
                  <div style={{ height: 120, width: 120 }}></div>
                )}
                {!item?.decorative && stock === 0 ? (
                  <Box className={classes.mediaSoldOut}>
                    <Box py={1} className={classes.soldOut}>
                      <Typography
                        style={{ color: "#FFFFFF", fontSize: "1.4rem", textAlign: "center" }}
                        variant="h6"
                        component="p">
                        AGOTADO
                      </Typography>
                    </Box>
                  </Box>
                ) : !item?.decorative && item?.new ? (
                  <Grid container className={classes.largeNewChipDiv} justifyContent="flex-start">
                    <Chip className={classes.largeNewChip} label="Nuevo" color="secondary" />
                  </Grid>
                ) : null}
                {!item?.decorative &&
                  ((!!item?.discount && item?.discount?.type !== "none" && item?.priceWithDiscount! > 0) ||
                    (cleverlynk?.discount !== undefined &&
                      cleverlynk?.discount !== null &&
                      cleverlynk?.discount > 0)) && (
                    <Box className={classes.discount}>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "1rem", color: "inherit", textAlign: "center" }}
                        variant="subtitle2"
                        id={`card-price-${index}`}
                        component="p">
                        {item?.discount?.type === "percentage"
                          ? `${item?.discount?.amount}% Off`
                          : `-${currencyFormatter(item?.discount?.amount, currency)}`}
                      </Typography>
                    </Box>
                  )}
              </div>
              <CardContent
                className={classes.contentCard}
                style={{
                  backgroundColor: cardStyles?.cardColor,
                  maxWidth: cardDimensions.width,
                }}>
                <Grid container style={{ padding: "0px 0px 0px 10px" }}>
                  <Grid item xs={!item?.decorative ? 9 : 12} md={!item?.decorative ? 9 : 12}>
                    <Grid item xs={12}>
                      <Typography
                        className={`largeName ${classes.text}`}
                        align="left"
                        style={{ width: "100%" }}
                        variant="subtitle2"
                        id={`card-name-${index}`}
                        component="h2">
                        {item?.name ?? ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={`largeDescription ${classes.text}`}
                        align="left"
                        variant="subtitle1"
                        component="p"
                        id={`card-description-${index}`}>
                        {item?.description ? item?.description.slice(0, 75) : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!item?.decorative && (
                    <Grid item xs={3} md={3} container className={classes.largePriceDiv}>
                      {item?.priceWithDiscount! > 0 && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Typography
                              className={`largePrice ${classes.text}`}
                              align="left"
                              variant="subtitle1"
                              id={`card-price-${index}`}
                              component="p">
                              {currencyFormatter(item?.priceWithDiscount, currency)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              className={`discount largePrice ${classes.text}`}
                              align="left"
                              variant="subtitle1"
                              id={`card-price-${index}`}
                              component="p">
                              {item?.priceWithDiscount! > 0 && !!item?.discount && item?.discount?.type !== "none" && (
                                <Typography
                                  className={`${classes.priceWithDiscount} ${classes.text}`}
                                  variant="caption"
                                  id={`card-price-${index}`}
                                  component="span">
                                  {currencyFormatter(item?.price ?? 0, currency)}
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>
    </Fade>
  );
};
export default LargeCard;
