import { Grid, Typography, IconButton, Collapse, TextField, Button } from "@material-ui/core";
import { AddCircleOutline, Clear, DirectionsBike, EditRounded, HouseRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import { selectDeliveryInfo } from "../../redux/slices/mapServiceSlice";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface ISelectedAddressComponent {
  toggleEdit: () => void;
}

const SelectedAddressComponent = ({ toggleEdit }: ISelectedAddressComponent) => {
  const [additionalInfo, setAdditionalInfo] = useState<boolean>(false);

  const { t } = useTranslation("deliveryinput");

  const deliveryInfo = useSelector(selectDeliveryInfo);

  const { handleModifyDeliveryInfo } = useMapService();

  return (
    <Grid container justifyContent="space-between" spacing={2} style={{ padding: "10px 0px" }}>
      <Grid item container xs={10}>
        <Grid item>
          <div style={{ display: "block", padding: 10, paddingRight: 20 }}>
            <DirectionsBike />
          </div>
        </Grid>
        <Grid item>
          <Typography variant="body1">{deliveryInfo?.address}</Typography>
          <Typography variant="caption">{deliveryInfo?.city}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={() => toggleEdit()}>
          <EditRounded />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={true}>
          <TextField
            required
            fullWidth
            type="text"
            variant="outlined"
            value={deliveryInfo.propertyInfo ?? ""}
            label={t("addressInput.propertyType")}
            name="property_type_info"
            id="property-type-info-input"
            onChange={ev =>
              handleModifyDeliveryInfo({
                propertyInfo: ev.target.value,
              })
            }
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={!additionalInfo}>
          <Button endIcon={<AddCircleOutline fontSize="small" />} onClick={() => setAdditionalInfo(true)}>
            {t("addressInput.addAdditionalInfo")}
          </Button>
        </Collapse>
        <Collapse in={additionalInfo}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <TextField
                fullWidth
                label={t("addressInput.additionalInfo")}
                variant="outlined"
                name="additional_info"
                id="additional_info_input"
                type="text"
                // value={additionalInfo}
                onChange={(event: any) => {
                  // event.preventDefault();
                  // setAdditionalInfo(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} container justifyContent="center">
              <IconButton
                style={{ padding: 6 }}
                onClick={() => {
                  setAdditionalInfo(false);
                }}>
                <Clear />
              </IconButton>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
export default SelectedAddressComponent;
