import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StatusBanner from "../components/OrderSummary/StatusBanner";
import logo from "../images/logomini.png";
import SummaryTabs from "../components/OrderSummary/SummaryTabs";
import useCleverlynkFunctions from "../hooks/useCleverlynkFunctions";
import { useClynkTheme } from "../hooks/useClynkTheme";
import { useSelector } from "react-redux";
import {
  selectCleverlynk,
  selectIsMobile,
  selectLoadOrderSummary,
  selectOrderSummary,
} from "../redux/slices/cleverlynkSlice";
import LoadingSkeleton from "../components/OrderSummary/LoadingSkeleton";
import { SubscriptionValue } from "../models/graphql-api";
import { mapOnNewOrderSubscription, subscribeOrderQuery } from "../models/Subscriptions/OrderSummary";
import { API, graphqlOperation } from "aws-amplify";
import "../css/OrderSummary.css";
import SummaryDesktop from "../components/OrderSummary/SummaryDesktop";
import { useTranslation } from "react-i18next";
import "../i18n/config";
import { PFXOrderFulfillmentType } from "../models/Interfaces";
import axios from "axios";
import { ALIMENTEC_LIST } from "../components/UserForm/IdentificationField";

export enum IOrderState {
  TRANSFER = "transfer",
  SHIPPING = "shipping",
  PENDING = "pending",
  PREPARATION = "preparation",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  DELIVERED = "delivered",
  PICKUP = "pickup",
  NONEXISTANT = "non-existant",
}

export const getOrderStatus = (orderStatus, status, fulfillmentType): IOrderState => {
  if (orderStatus === 200) return IOrderState.TRANSFER;

  switch (status) {
    case 0:
      return IOrderState.PENDING;
    case 1:
      return IOrderState.ACCEPTED;
    case 2:
      return IOrderState.PREPARATION;
    case 2.5:
      return fulfillmentType === PFXOrderFulfillmentType.DELIVERY ? IOrderState.PREPARATION : IOrderState.PICKUP;
    case 3:
      return IOrderState.SHIPPING;
    case 4:
      return IOrderState.DELIVERED;
    case -1:
      return IOrderState.REJECTED;
    case -2:
      return IOrderState.CANCELLED;
    default:
      return IOrderState.PENDING;
  }
};

const OrderSummary = props => {
  const {
    handleLoadOrderSummary,
    handleModifyOrderState,
    handleModifySnackbar,
    handleModifyOrderSummary,
    handleWipeState,
  } = useCleverlynkFunctions();
  const { createOrderSummaryTheme } = useClynkTheme();

  const cleverlynk = useSelector(selectCleverlynk);
  const isMobile = useSelector(selectIsMobile);
  const orderSummary = useSelector(selectOrderSummary);
  const orderSummarySupervisor = useSelector(selectLoadOrderSummary);

  const [nonExistant, setNonExistant] = useState<boolean>(false);

  const { i18n } = useTranslation();

  const scrollUp = () => {
    const elem = document.getElementsByTagName("html");
    setTimeout(() => {
      if (elem && elem[0]) {
        elem[0].scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      document.body.style.backgroundColor = "#f2f2f2";
      document.title = "Tu Orden";
    }, 3000);
    return () => clearTimeout(t);
  }, []);

  useEffect(() => {
    if (cleverlynk?.language) {
      i18n.changeLanguage(cleverlynk?.language);
    }
  }, [cleverlynk]);

  useEffect(() => {
    handleLoadOrderSummary(props.match.params.orderId);
    handleModifySnackbar(null);
    handleWipeState();
    window.addEventListener("popstate", function (event) {
      const elem = document.getElementById("back-to-catalog");
      if (elem) {
        elem.classList.add("strobe");
        scrollUp();
      }
      window.history.pushState(null, document.title, window.location.href);
    });

    window.history.pushState(null, document.title, window.location.href);
  }, [props.match.params.orderId]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const payment = params.get("payment");
    const token = params.get("token");

    if (payment !== "paypal") {
      return;
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHECKOUT_SERVICES_URL}/paypal/captureOrder`,
      // lo enviamos asi para que pueda usar el webhook
      data: {
        event_type: "CHECKOUT.ORDER.APPROVED",
        resource: { id: token, purchase_units: [{ reference_id: props.match.params.orderId }] },
      },
    })
      .then(({ data }) => {
        // si se completo el pago entonces data.paypalStatus === 'COMPLETED'. Si ya se habia procesado y el status de la orden es distinto de 0
        // entonces data es {} (objeto vacio)
      })
      .catch(err => {
        console.error(err);
      });
  }, [props.location.search, props.match.params.orderId]);

  const disableAllTimeouts = () => {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0; i < highestTimeoutId; i++) {
      clearTimeout(i);
    }
  };

  useEffect(() => {
    if (orderSummarySupervisor.status === "succeeded") {
      const subscription = (
        API.graphql(graphqlOperation(subscribeOrderQuery(orderSummary?.id ?? ""))) as any
      ).subscribe({
        next: (response: SubscriptionValue<any>) => {
          const o = mapOnNewOrderSubscription(response);
          handleModifyOrderSummary(o);
          handleModifySnackbar(null);
          disableAllTimeouts();
        },
        error: (error: any) => {
          console.error(error);
          if (
            error?.error?.errors &&
            error?.error?.errors.length > 0 &&
            error.error.errors[0].message.includes("Connection handshake error") &&
            orderSummary?.status === 0
          ) {
            window.location.reload();
          }
        },
      });
      if (nonExistant) {
        setNonExistant(false);
      }
      return () => subscription.unsubscribe();
    } else if (orderSummarySupervisor.status === "failed") {
      setNonExistant(true);
    }
  }, [orderSummarySupervisor]);

  useEffect(() => {
    if (ALIMENTEC_LIST.includes(orderSummary?.cleverlynkId ?? "")) {
      const timeout = setTimeout(() => {
        window.location.href = "https://muncheralimentec.clynk.me/";
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [orderSummary?.cleverlynkId]);

  useEffect(() => {
    handleModifyOrderState(
      getOrderStatus(orderSummary?.paymentStatus, orderSummary?.status, orderSummary?.fulfillmentType)
    );
  }, [orderSummary]);

  const content = React.useMemo(() => {
    if (isMobile) {
      switch (orderSummarySupervisor.status) {
        case "loading":
          return <LoadingSkeleton />;
        case "succeeded":
          return <SummaryTabs />;
        default:
          return <SummaryTabs />;
      }
    } else {
      switch (orderSummarySupervisor.status) {
        case "loading":
          return <LoadingSkeleton desktop />;
        case "succeeded":
          return <SummaryDesktop />;
        default:
          return <SummaryDesktop />;
      }
    }
  }, [isMobile, orderSummarySupervisor, orderSummary, cleverlynk]);

  return (
    <div id="order-summary">
      <ThemeProvider theme={createOrderSummaryTheme()}>
        <StatusBanner title="Titulo" nonExistant={nonExistant} />
        {!nonExistant && content}
        <Grid item container justifyContent="center" xs={12} style={{ marginBottom: 24 }}>
          <Typography>Powered by</Typography>
          <img
            src={logo}
            alt={"Powered by Cleverlynk"}
            height={23}
            style={{ marginLeft: 15, cursor: "pointer" }}
            onClick={() => (window.location.href = "https://cleverlynk.com")}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
};
export default OrderSummary;
