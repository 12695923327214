import React from "react";
import { PFXCardType } from "../../models/Interfaces";
import { useHistory, useLocation } from "react-router-dom";
import useTemplateStyles from "../../hooks/useTemplateStyles";
import { useSelector } from "react-redux";
import { selectItemById } from "../../redux/slices/templateSlice";
import { useCleverlynkContext } from "../../contexts/CleverlynkContext";
import useTemplateFunctions from "../../hooks/useTemplateFunctions";
import { RootState } from "../../redux/rootReducer";
import ListCard from "./ItemCards/ListCard";
import ClassicCard from "./ItemCards/ClassicCard";
import LargeCard from "./ItemCards/LargeCard";

export interface IItemCard {
  id: string;
  index: number;
}

const ItemCard = ({ id, index }: IItemCard) => {
  const { cardType } = useTemplateStyles();
  const { handleModifyItemToShow } = useTemplateFunctions();

  const item = useSelector((state: RootState) => selectItemById(state, id));
  const cleverlynkContext = useCleverlynkContext();
  const basePath = React.useMemo(() => cleverlynkContext?.basePath ?? "", [cleverlynkContext]);
  const history = useHistory();
  const location = useLocation();

  const onClick = () => {
    handleModifyItemToShow(item);
    const queryParams = new URLSearchParams(location.search);
    queryParams.append("it", id);
    history.push({
      pathname: `${basePath}/menu`.replace("//", "/"),
      search: queryParams.toString(),
    });
  };

  return cardType === PFXCardType.LIST ? (
    <ListCard onClick={onClick} id={id} index={index} />
  ) : cardType === PFXCardType.NORMAL ? (
    <ClassicCard onClick={onClick} id={id} index={index} />
  ) : (
    <LargeCard onClick={onClick} id={id} index={index} />
  );
};

export default ItemCard;
