import { GraphQLResult } from "@aws-amplify/api";

export const getComparableItemIdInClynkQuery = /* GraphQL */ `
  query q3($cleverlynkId: ID!, $item: CatalogItemInput!) {
    getComparableItemIdInClynk(cleverlynkId: $cleverlynkId, item: $item) {
      success
      message
    }
  }
`;

export interface IGetComparableItemIdInClynk {
  success: boolean;
  id?: string | undefined;
}

export const getComparableItemIdInClynk = (data: GraphQLResult<any>): IGetComparableItemIdInClynk => {
  const d = data.data.getComparableItemIdInClynk;
  return { success: !!d.success, id: d?.message };
};
