import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectItemsInCart } from "../../redux/slices/cleverlynkSlice";
import EmptyCart from "./EmptyCart";
import FilledCart from "./FilledCart";

enum CartContentStage {
  "EMPTY",
  "FULL",
}

interface ICartContent {}

const CartContent = (props: ICartContent) => {
  const itemsInCart = useSelector(selectItemsInCart);

  const content = useMemo(() => {
    switch (itemsInCart.length) {
      case 0:
        return <EmptyCart />;
      default:
        return <FilledCart />;
    }
  }, [itemsInCart]);

  return content;
};
export default CartContent;
