import { Grid, Typography, createStyles, makeStyles, Theme, CircularProgress, OutlinedInput } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUserFormFunctions from "../../hooks/useUserFormFunctions";
import { selectIdentification, selectCleverlynk } from "../../redux/slices/cleverlynkSlice";
import { selectQueryCustomerInfo } from "../../redux/slices/userFormSlice";
import { useTranslation } from "react-i18next";
import "../../i18n/config";

export const ALIMENTEC_LIST = ["md8kLJjb", "RRSm8DlJ", "jPIm6clI", "Zt2gCHqZ"];

interface IIdentificationField {}

const IdentificationField = (props: IIdentificationField) => {
  const [intermediateId, setIntermediateId] = useState<string>("");
  const cleverlynk = useSelector(selectCleverlynk);
  const identification = useSelector(selectIdentification);
  const queryCustomerInfoSupervisor = useSelector(selectQueryCustomerInfo);

  const { handleModifyIdentification, isValidEmail } = useUserFormFunctions();

  const { t } = useTranslation("common");

  const { handleQueryCustomerInfo } = useUserFormFunctions();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      identificationFieldInput: {
        color: theme.palette.primary.main,
        "& ::placeholder": {
          color: theme.palette.primary.main,
        },
      },
      identificationField: {
        borderRadius: 5,
        backgroundColor: "white",
        "&.disabled": {
          backgroundColor: "#ccc",
        },
        "&.loading": {
          backgroundColor: theme.palette.secondary.main,
        },
        "&.invalid": {
          backgroundColor: "#fd4067",
        },
      },
      identificationFieldOutline: {
        borderColor: "#c3c3c3",
        "&:focused": {
          borderColor: "#c3c3c3",
          borderWidth: 2,
        },
      },
      emailLabel: {
        paddingBottom: 4,
        fontSize: "0.875rem",
        color: "#000000de",
      },
      circularProgress: {
        color: theme.palette.secondary.main,
      },
    })
  );

  const classes = useStyles();

  const debounceGetInfo = useCallback(
    debounce(id => {
      if (isValidEmail(id.trim())) {
        handleQueryCustomerInfo(id.trim());
      }
    }, 300),
    []
  );

  useEffect(() => setIntermediateId(identification), [identification]);

  useEffect(() => {
    if (ALIMENTEC_LIST.includes(cleverlynk?.id ?? "")) {
      setIntermediateId("alimentec@cleverlynk.com");
      handleModifyIdentification("alimentec@cleverlynk.com");
    }
  }, [cleverlynk]);

  const handleIdChange = id => {
    debounceGetInfo(id);
    setIntermediateId(id);
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          padding: 12,
          paddingTop: 6,
          display: ALIMENTEC_LIST.includes(cleverlynk?.id ?? "") ? "none" : "block",
          backgroundColor: "white",
          boxShadow: "none",
          margin: 8,
          borderRadius: 10,
        }}>
        <Typography variant="subtitle1" color="primary">
          <strong>{t("emailAddress")}</strong>
        </Typography>
        <Typography className={classes.emailLabel}></Typography>
        <OutlinedInput
          classes={{
            input: classes.identificationFieldInput,
            notchedOutline: classes.identificationFieldOutline,
          }}
          endAdornment={
            queryCustomerInfoSupervisor.status === "loading" ? (
              <CircularProgress className={classes.circularProgress} size={15} thickness={5} />
            ) : null
          }
          className={`${classes.identificationField}`}
          required
          placeholder={t("emailAddress")}
          id="email"
          fullWidth
          value={intermediateId}
          onChange={event => handleIdChange(event.target.value)}
          onBlur={() => handleModifyIdentification(intermediateId)}
        />
      </Grid>
    </React.Fragment>
  );
};
export default IdentificationField;
