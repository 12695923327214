import { createStyles, Fade, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMapServiceQuery, selectSnackbar } from "../../redux/slices/mapServiceSlice";

import { useTranslation } from "react-i18next";
import "../../i18n/config";
import useMapService from "../../hooks/MapServiceHooks/useMapService";

interface IMapSnackbar {
  lupap?: boolean;
}

const MapSnackbar = (props: IMapSnackbar) => {
  const snackbar = useSelector(selectSnackbar);
  const mapServiceQuery = useSelector(selectMapServiceQuery);

  const { handleModifySnackbar } = useMapService();

  const { t } = useTranslation("deliveryinput");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "absolute",
        top: 85,
        left: 0,
        zIndex: 11,
        width: "100%",
      },
      snackbar: {
        backgroundColor: snackbar?.color ?? "#d84c4c",
        // border: "1pt solid #d84c4c",
        borderRadius: 5,
        margin: "0 auto",
        minHeight: 40,
        width: "90%",
        boxShadow: "2pt 2pt 3px #00000069",
        paddingLeft: 8,
      },
      snackbarText: {
        fontSize: "0.6rem",
        color: "white",
        marginLeft: 8,
        display: "contents",
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    if (!!snackbar) {
      // let t = setTimeout(() => {
      //   handleModifySnackbar(undefined);
      // }, 5000);
      // return () => {
      //   clearTimeout(t);
      // };
    }
  }, [snackbar]);

  return (
    <Fade in={!!snackbar} timeout={{ appear: 400, exit: 0 }}>
      <div className={classes.root}>
        <Grid container className={classes.snackbar} justifyContent="space-between" alignItems="center">
          <Grid item container xs={11} justifyContent="center">
            <Typography className={classes.snackbarText} variant="overline">
              {t((snackbar?.message as any) ?? "addressInput.notFound")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton style={{ color: "white" }} size="small" onClick={() => handleModifySnackbar(undefined)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
export default MapSnackbar;
