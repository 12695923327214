import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";

export default function useLocalStorageFunction(clynkId?: string): {
  wipeLocalStorage: (cID: string, onlyItems: boolean) => void;
  hasActiveUserFormCookies: boolean;
} {
  const wipeLocalStorage = (cID: string, onlyItems: boolean) => {
    reactLocalStorage.remove(`items-${cID}`);
    if (!onlyItems) {
      reactLocalStorage.remove(`identification-${cID}`);
      reactLocalStorage.remove(`mandatoryCriteria-${cID}`);
      reactLocalStorage.remove(`optionalCriteria-${cID}`);
      reactLocalStorage.remove(`deliveryGeoInfo-${cID}`);
      reactLocalStorage.remove(`deliveryType-${cID}`);
      reactLocalStorage.remove(`stage-${cID}`);
    }
  };

  const hasActiveUserFormCookies = React.useMemo(() => {
    const identification = reactLocalStorage.getObject(`identification-${clynkId!}`);
    return Object.keys(identification).length > 0;
  }, [clynkId]);

  return { wipeLocalStorage, hasActiveUserFormCookies };
}
