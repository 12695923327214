import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { any } from "async";
import { IDeliveryAddressCookie } from "../../hooks/MapServiceHooks/useDeliveryAddressCookies";
import { IMapQuery } from "../../hooks/MapServiceHooks/useMapService";
import { RootState } from "../rootReducer";
import { ILatLng } from "./cleverlynkSlice";

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};
const initialAsyncState: AsyncState = { status: "idle" };

export type QueryState = {
  suggestions: any[];
  address?: string;
  coordinates?: {
    lat: number;
    lng: number;
  };
  city?: string;
  country?: string;
  byAddress?: boolean;
  propertyInfo?: string;
  additionalInfo?: string;
  distanceInMts?: number;
};

const cookieAdapters = createEntityAdapter<IDeliveryAddressCookie>({
  selectId: c => c.id,
  sortComparer: (a, b) =>
    a.favourite === b.favourite ? (a.createdAt > b.createdAt ? -1 : 1) : a.favourite < b.favourite ? -1 : 1,
});

interface IMapProviderInitialState {
  addressCookies: EntityState<IDeliveryAddressCookie>;
  deliveryInfo: QueryState;
  getUserLocation: AsyncState;
  googleLoaded: boolean;
  googleSessionToken: string;
  mapServiceQuery: AsyncState;
  locateCityFromClynk: AsyncState;
  selectedAddress: boolean;
  userLocation: ILatLng | undefined;
  snackbar: { message: string; color: string } | undefined;
  queryCalculateDistanceAWS: AsyncState;
}

export const initialState: IMapProviderInitialState = {
  addressCookies: cookieAdapters.getInitialState(),
  deliveryInfo: {
    suggestions: [],
  },
  getUserLocation: initialAsyncState,
  googleLoaded: false,
  googleSessionToken: "",
  locateCityFromClynk: initialAsyncState,
  mapServiceQuery: initialAsyncState,
  selectedAddress: false,
  userLocation: undefined,
  snackbar: undefined,
  queryCalculateDistanceAWS: initialAsyncState,
};

const typesToRemoveGoogle = [
  "postal_code",
  "country",
  "administrative_area_level_1",
  "administrative_area_level_2",
  "locality",
  "sublocality_level_1",
];

const typesToRemoveAWS = ["Municipality", "Region", "Country", "SubRegion", "Neighborhood"];

export const LupapCityCodes = {
  Armenia: "armenia",
  Barranquilla: "barranquilla",
  Bogotá: "bogota",
  Bogota: "bogota",
  "BogotáD.C.": "bogota",
  "Bogotá, D.C.": "bogota",
  "Bogota, D.C.": "bogota",
  "Bogotá D.C.": "bogota",
  "Bogota D.C.": "bogota",
  "Bogota,D.C.": "bogota",
  "Bogotá,D.C.": "bogota",
  "BogotáD.C": "bogota",
  "Bogotá, D.C": "bogota",
  "Bogota, D.C": "bogota",
  "Bogotá D.C": "bogota",
  "Bogota D.C": "bogota",
  "Bogota,D.C": "bogota",
  "Bogotá,D.C": "bogota",
  Bucaramanga: "bucaramanga",
  Cajica: "cun_cajica",
  Cajicá: "cun_cajica",
  Choconta: "cun_choconta",
  Chocontá: "cun_choconta",
  Guacheta: "cun_guacheta",
  Guachetá: "cun_guacheta",
  "La Calera": "cun_la_calera",
  Calera: "cun_la_calera",
  Cali: "cali",
  Cartagena: "cartagena",
  Chía: "chia",
  Chia: "chia",
  Cota: "cota",
  Envigado: "envigado",
  Funza: "funza",
  Ibagué: "ibague",
  Ibague: "ibague",
  Itagui: "ant_itagui",
  Itaguí: "ant_itagui",
  Itagüí: "ant_itagui",
  Itagüi: "ant_itagui",
  Manizales: "manizales",
  Medellín: "medellin",
  Medellin: "medellin",
  Montería: "monteria",
  Monteria: "monteria",
  Pereira: "pereira",
  Popayán: "popayan",
  Popayan: "popayan",
  "Santa Marta": "sta_marta",
  Tunja: "tunja",
  Valledupar: "pereira",
  Villavicencio: "villavicencio",
};

export const MUCityCodes = {
  bogota: 1,
  cali: 2,
  medellin: 3,
  barranquilla: 4,
  cartagena: 8,
  sta_marta: 9,
  bucaramanga: 10,
  ibague: 11,
  Monterrey: 41,
  "Ciudad de Mexico": 50,
};

export const LupapAutocompleteCityCodes = {
  Bogotá: "bogota",
  Medellín: "medellin",
  Villavicencio: "villavicencio",
  Envigado: "envigado",
};

export const LupapCountryCodes = {
  Colombia: "co",
};

export const AWSCountryCodes = {
  co: "COL",
};

export const formatResultToPlainValueGoogle = (result: google.maps.GeocoderResult) => {
  const termsToRemove: string[] = [];
  let country,
    city = "";
  result.address_components.forEach(ac => {
    if (ac.types.includes("locality")) city = ac.long_name;
    if (ac.types.includes("country")) country = ac.long_name;
    if (!ac.types.every(t => !typesToRemoveGoogle.includes(t))) {
      termsToRemove.push(ac.long_name, ac.short_name);
    }
  });
  let partialResult = result.formatted_address;
  termsToRemove.forEach(t => {
    let regex = new RegExp(`${t},|${t}`);
    partialResult = partialResult.replace(regex, "");
  });
  partialResult = partialResult.trimEnd();
  partialResult = partialResult.endsWith(",") ? partialResult.slice(0, partialResult.length - 1) : partialResult;
  return { address: partialResult, city, country };
};

export const formatResultToPlainValueAWS = result => {
  if (!result || !result.Label) return "";
  let res: string = result.Label + "";
  typesToRemoveAWS.forEach(t => {
    const regex = new RegExp(`,?\s?${result[t]},?`);
    res = res.replace(regex, "");
  });
  res = res.replace(/,\s*$/, "");
  if (res.trim() === "") return undefined;
  return res;
};

export const queryByAddressLupap = createAsyncThunk(
  "mapservice/queryByAddressLupap",
  async (info: { query: IMapQuery }, thunkApi) => {
    try {
      const { query } = info;
      const address = encodeURI(query.address ?? "").replace("#", " No. ");
      const data = await fetch(
        `https://uzmvzwaocb.execute-api.us-east-1.amazonaws.com/dev/address/co/${query.city}?a=${address}`
      );
      if (data.status === 400) {
        return thunkApi.rejectWithValue({ error: "¡No encontramos esa dirección! Usa el mapa." });
      }
      return await data.json();
    } catch (error) {
      console.error(error);
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const queryByCoordinatesAWS = createAsyncThunk(
  "mapservice/queryByCoordinatesAWS",
  async (info: { query: IMapQuery; AWSClient: any }, thunkApi) => {
    try {
      const { query, AWSClient } = info;
      const res = await AWSClient.searchPlaceIndexForPosition({
        IndexName: "CO_index",
        Position: [Number(query.coordinates?.lng), Number(query.coordinates?.lat)],
        MaxResults: 1,
      }).promise();
      const place = res.Results[0].Place;
      const address = formatResultToPlainValueAWS(place);
      if (address?.trim() === "") return thunkApi.rejectWithValue({ error: "No se encontró nada en esta zona" });
      return {
        address: address?.trim() === "" ? null : address,
        city: place.Municipality,
        coordinates: { lng: place.Geometry.Point[0], lat: place.Geometry.Point[1] },
        country: Object.keys(AWSCountryCodes).find(key => AWSCountryCodes[key] === place.Country),
      } as QueryState;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const queryCalculateDistanceAWS = createAsyncThunk(
  "mapservice/queryCalculateDistanceAWS",
  async (info: { query; AWSClient }, thunkApi) => {
    try {
      const { query, AWSClient } = info;

      const res = await AWSClient.calculateRoute({
        CalculatorName: "RouteCalculator",
        DeparturePosition: [...query.departure],
        DestinationPosition: [...query.destination],
        DepartNow: false,
      }).promise();
      return res.Summary.Distance * 1000;
    } catch (err) {
      console.error(err);
      return thunkApi.rejectWithValue({ err });
    }
  }
);

export const queryByCoordinatesLupap = createAsyncThunk(
  "mapservice/queryByCoordinatesLupap",
  async (info: { query: IMapQuery; fallback: (q: IMapQuery) => void }, thunkApi) => {
    try {
      const { query, fallback } = info;
      const data = await (
        await fetch(
          `https://uzmvzwaocb.execute-api.us-east-1.amazonaws.com/dev/coordinates?lon=${query.coordinates?.lng}&lat=${query.coordinates?.lat}`
        )
      ).json();
      if (data.address === null) {
        fallback(query);
        return thunkApi.rejectWithValue({ disableSnackbar: true });
      }
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const queryByCoordinatesGoogle = createAsyncThunk(
  "mapservice/queryByCoordinatesGoogle",
  async (info: { query: IMapQuery; geocoder: google.maps.Geocoder }, thunkApi) => {
    const { geocoder, query } = info;
    try {
      const data = {
        coordinates: query.coordinates,
      } as QueryState;
      await geocoder.geocode(
        { location: { lat: Number(query.coordinates!.lat), lng: Number(query.coordinates!.lng) } },
        (result, status) => {
          if (status === "OK") {
            if (result && result[0]) {
              const { address, city, country } = formatResultToPlainValueGoogle(result[0]);
              data.address = address;
              data.city = city;
              data.country = country;
            }
          }
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

const lupapAddressQueryPromise = (query: IMapQuery): Promise<any> => {
  return new Promise(async (res, rej) => {
    const address = encodeURI(query.address ?? "").replace("#", " No. ");
    const data = await fetch(
      `https://uzmvzwaocb.execute-api.us-east-1.amazonaws.com/dev/address/co/${query.city}?a=${address}`
    );
    if (data.status === 400) {
      res({ error: "¡No encontramos esa dirección! Usa el mapa." });
    }
    res(data.json());
  });
};

export const queryByAddressSuggestionsAWS = createAsyncThunk(
  "mapservice/queryByAddressSuggestionsAWS",
  async (info: { query: IMapQuery; AWSClient: any; clynkLocation: ILatLng }, thunkApi) => {
    const { query, AWSClient, clynkLocation } = info;
    try {
      const lupapFallback = lupapAddressQueryPromise(query);
      const res = AWSClient.searchPlaceIndexForText({
        IndexName: "CO_index",
        Text: `${query.address}, ${query.city}`,
        FilterCountries: [AWSCountryCodes[query.country ?? "co"] ?? query.country],
        BiasPosition: [clynkLocation.lat, clynkLocation.lng],
        MaxResults: 6,
      }).promise();

      return Promise.all([lupapFallback, res]).then(([lupap, aws]) => {
        if (!lupap.error) {
          return [lupap.Place, ...aws.Results.map(r => ({ ...r.Place }))];
        } else return aws.Results.map(r => ({ ...r.Place }));
      });
    } catch (error) {
      console.error({ error });
      thunkApi.rejectWithValue({ error });
    }
  }
);

export const queryByAddressSuggestionsLupap = createAsyncThunk(
  "mapservice/queryByAddressSuggestionsLupap",
  async (info: { query: IMapQuery }, thunkApi) => {
    try {
      const { query } = info;
      const address = encodeURI(query.address ?? "").replace("#", "%23");
      const data = await (
        await fetch(
          `https://uzmvzwaocb.execute-api.us-east-1.amazonaws.com/dev/suggestions?q=${address}&city=${query.city}`
        )
      ).json();
      if (data.status === 400) {
        return thunkApi.rejectWithValue({ error: "¡No encontramos esa dirección! Usa el mapa." });
      }
      return await data.json();
    } catch (error) {
      console.error(error);
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const queryByAddressSuggestionsGoogle = createAsyncThunk(
  "mapservice/queryByAddressSuggestionsGoogle",
  async (info: { query: IMapQuery; sessionToken; autocompleteService }, thunkApi) => {
    const { query, sessionToken, autocompleteService } = info;
    try {
      const suggestions: any[] = [];
      await (autocompleteService as any).getPlacePredictions(
        {
          input: query.address,
          componentRestrictions: { country: query.country },
          location: new google.maps.LatLng({
            lat: query.coordinates?.lat ?? 4.697468326274773,
            lng: query.coordinates?.lng ?? -74.03335135170484,
          }),
          radius: 10000,
          types: ["address"],
          sessionToken,
        },
        (results: any[]) => {
          if (results) {
            suggestions.push(...results);
          }
        }
      );
      return suggestions;
    } catch (error) {
      console.error(error);
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const locateCityAndCountryFromClynk = createAsyncThunk(
  "mapservice/locateCityAndCountryFromClynk",
  async (info: { location: ILatLng; AWSClient }, thunkApi) => {
    try {
      const { location, AWSClient } = info;
      const res = await AWSClient.searchPlaceIndexForPosition({
        IndexName: "CO_index",
        Position: [Number(location?.lng), Number(location?.lat)],
        MaxResults: 1,
      }).promise();
      const place = res.Results[0].Place;
      return { city: place.Municipality, country: place.Country };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

export const getUserLocation = createAsyncThunk("mapservice/getUserLocation", async (_info, thunkApi) => {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        res({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      err => {
        rej(err);
      },
      { enableHighAccuracy: true, timeout: 60000, maximumAge: 300000 }
    );
  })
    .then(p => p)
    .catch(e => thunkApi.rejectWithValue(e));
});

const mapServiceSlice = createSlice({
  name: "mapservice",
  initialState,
  reducers: {
    setGoogleLoaded(state) {
      state.googleLoaded = true;
    },
    setGoogleSessionToken(state, action) {
      state.googleSessionToken = action.payload;
    },
    addBatchAddressCookie(state, action) {
      cookieAdapters.addMany(state.addressCookies, action.payload);
    },
    modifySetSelectedAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    modifyDeliveryInfo(state, action) {
      state.deliveryInfo = { ...state.deliveryInfo, ...action.payload };
    },
    addAddressCookie(state, action) {
      cookieAdapters.addOne(state.addressCookies, action.payload);
    },
    deleteAddressCookie(state, action) {
      cookieAdapters.removeOne(state.addressCookies, action.payload);
    },
    modifyAddressCookie(state, action) {
      cookieAdapters.updateOne(state.addressCookies, {
        id: action.payload.id,
        changes: { ...action.payload },
      });
    },
    toggleFavouriteCookie(state, action) {
      cookieAdapters.updateOne(state.addressCookies, {
        id: action.payload.id,
        changes: { favourite: action.payload.favourite },
      });
    },
    modifySnackbar(state, action) {
      state.snackbar = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(queryByCoordinatesAWS.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByCoordinatesAWS.rejected, (state, action: any) => {
      state.mapServiceQuery.status = "failed";
      state.mapServiceQuery.error = action.payload;
    });
    builder.addCase(queryByCoordinatesAWS.fulfilled, (state, action: any) => {
      state.deliveryInfo = { ...state.deliveryInfo, ...action.payload, byAddress: false };
      state.mapServiceQuery.status = "succeeded";
    });
    builder.addCase(queryByCoordinatesLupap.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByCoordinatesLupap.rejected, (state, action: any) => {
      state.mapServiceQuery.error = action.payload;
      if (!action.payload.disableSnackbar) {
        state.mapServiceQuery.status = "failed";
      }
    });
    builder.addCase(queryByCoordinatesLupap.fulfilled, (state, action) => {
      state.deliveryInfo = {
        ...state.deliveryInfo,
        ...action.payload,
        country: LupapCountryCodes[action.payload.country],
        byAddress: false,
      };
      state.mapServiceQuery.status = "succeeded";
    });
    builder.addCase(queryByCoordinatesGoogle.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByCoordinatesGoogle.rejected, (state, action: any) => {
      state.mapServiceQuery.status = "failed";
      state.snackbar = {
        color: "#ff6262",
        message: "addressInput.notFound",
      };
      state.mapServiceQuery.error = action.payload;
    });
    builder.addCase(queryByCoordinatesGoogle.fulfilled, (state, action: any) => {
      state.deliveryInfo = { ...state.deliveryInfo, ...action.payload, byAddress: false };
      state.mapServiceQuery.status = "succeeded";
    });
    builder.addCase(queryByAddressLupap.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByAddressLupap.rejected, (state, action: any) => {
      state.mapServiceQuery.status = "failed";
      state.snackbar = {
        color: "#ff6262",
        message: "addressInput.notFound",
      };
      state.mapServiceQuery.error = action.payload;
    });
    builder.addCase(queryByAddressLupap.fulfilled, (state, action) => {
      if (action.payload.address) {
        state.deliveryInfo.suggestions = [...action.payload, ...state.deliveryInfo.suggestions];
      }
    });
    builder.addCase(queryByAddressSuggestionsGoogle.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByAddressSuggestionsGoogle.rejected, (state, action: any) => {
      state.mapServiceQuery.status = "failed";
      state.mapServiceQuery.error = action.payload;
    });
    builder.addCase(queryByAddressSuggestionsGoogle.fulfilled, (state, action) => {
      state.deliveryInfo.suggestions = action.payload as any;
      state.mapServiceQuery.status = "succeeded";
    });
    builder.addCase(queryByAddressSuggestionsAWS.pending, state => {
      state.mapServiceQuery.status = "loading";
    });
    builder.addCase(queryByAddressSuggestionsAWS.rejected, (state, action: any) => {
      console.error(action.payload);
      state.snackbar = {
        color: "#ff6262",
        message: "addressInput.notFound",
      };
      state.mapServiceQuery.status = "failed";
      state.mapServiceQuery.error = action.payload;
    });
    builder.addCase(queryByAddressSuggestionsAWS.fulfilled, (state, action) => {
      state.deliveryInfo.suggestions = action.payload;
      state.mapServiceQuery.status = "succeeded";
    });
    builder.addCase(getUserLocation.pending, state => {
      state.getUserLocation.status = "loading";
    });
    builder.addCase(getUserLocation.rejected, (state, action: any) => {
      state.getUserLocation.status = "failed";
      console.error({ error: action.payload });
      // state.getUserLocation.error = action.payload;
    });
    builder.addCase(getUserLocation.fulfilled, (state, action) => {
      state.getUserLocation.status = "succeeded";
      state.userLocation = action.payload as any;
    });
    builder.addCase(locateCityAndCountryFromClynk.pending, state => {
      state.locateCityFromClynk.status = "loading";
    });
    builder.addCase(locateCityAndCountryFromClynk.rejected, (state, action: any) => {
      state.locateCityFromClynk.status = "failed";
      state.locateCityFromClynk.error = action.payload;
    });
    builder.addCase(locateCityAndCountryFromClynk.fulfilled, (state, action) => {
      state.locateCityFromClynk.status = "succeeded";
      state.deliveryInfo.city = (action.payload as any).city;
      state.deliveryInfo.country = (action.payload as any).country;
    });
    builder.addCase(queryCalculateDistanceAWS.pending, state => {
      state.queryCalculateDistanceAWS.status = "loading";
    });
    builder.addCase(queryCalculateDistanceAWS.rejected, (state, action: any) => {
      state.queryCalculateDistanceAWS.status = "failed";
      state.queryCalculateDistanceAWS.error = action.payload.error;
    });
    builder.addCase(queryCalculateDistanceAWS.fulfilled, (state, action) => {
      state.queryCalculateDistanceAWS.status = "succeeded";
      state.deliveryInfo = { ...state.deliveryInfo, distanceInMts: action.payload as any };
    });
  },
});

export const {
  setGoogleLoaded,
  setGoogleSessionToken,
  addBatchAddressCookie,
  modifySnackbar,
  deleteAddressCookie,
  modifyDeliveryInfo,
  addAddressCookie,
  modifyAddressCookie,
  modifySetSelectedAddress,
  toggleFavouriteCookie,
} = mapServiceSlice.actions;

export default mapServiceSlice.reducer;

export const selectMapServiceQuery = (state: RootState) => state.mapService.mapServiceQuery;
export const selectDeliveryInfo = (state: RootState) => state.mapService.deliveryInfo;
export const selectSnackbar = (state: RootState) => state.mapService.snackbar;
export const selectGoogleLoaded = (state: RootState) => state.mapService.googleLoaded;
export const selectSelectedAddress = (state: RootState) => state.mapService.selectedAddress;
export const selectUserLocation = (state: RootState) => state.mapService.userLocation;
export const selectGetUserLocationSupervisor = (state: RootState) => state.mapService.getUserLocation;
export const selectGoogleSessionToken = (state: RootState) => state.mapService.googleSessionToken;
export const {
  selectAll: selectDeliveryCookies,
  selectTotal: selectDeliveryCookiesAmount,
  selectEntities: selectDeliveryCookiesEntities,
} = cookieAdapters.getSelectors((state: RootState) => state.mapService.addressCookies);
