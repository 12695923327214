import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../../hooks/MapServiceHooks/useMapService";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { selectCleverlynkGroup, selectIsMobile } from "../../../redux/slices/cleverlynkSlice";

interface ICityInput {}

const CityDisabled = ({}: ICityInput) => {
  const isMobile = useSelector(selectIsMobile);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);
  const { handleModifyDeliveryInfo } = useMapService();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      cityDisabled: {},
    })
  );

  useEffect(() => {
    handleModifyDeliveryInfo({ city: (cleverlynkGroup?.cities ?? ["Bogota"])[0] });
  }, [cleverlynkGroup]);

  const cityDisabled = () => (
    <Grid item xs={3} style={{ padding: 0 }} key="city-disabled-input">
      <TextField
        id="city-input"
        inputProps={{
          style: {
            fontWeight: "bold",
            height: isMobile ? "2.5rem" : "3.2rem",
            padding: "2px 8px",
          },
        }}
        value={(cleverlynkGroup?.cities ?? ["Bogota"])[0]}
        disabled
        variant="outlined"
      />
    </Grid>
  );

  return cityDisabled();
};
export default CityDisabled;
