import { Box, createStyles, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { ChevronRight, Replay, ShoppingCart } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CartCompanyInfo from "./CartCompanyInfo";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IEmptyCart {}

const EmptyCart = (props: IEmptyCart) => {
  const { t } = useTranslation("header");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 350,
        marginBottom: 10,
        marginTop: 10,
        transitionDuration: "300ms",
        transitionProperty: "background-color",
      },
      iconButton: {
        height: 160,
        width: 160,
        backgroundColor: "#ececec",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "1pt 1pt 7px -2px #00000080",
        borderRadius: "5pc",
      },
      text: {
        color: "#9c9c9c",
        fontSize: "1rem",
        textTransform: "none",
        "&.button": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    })
  );
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" direction="column">
      <CartCompanyInfo />
      <Grid item style={{ paddingTop: 20 }}>
        <Paper className={classes.root}>
          <Grid container style={{ height: "100%" }} justifyContent="center">
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              <Typography variant="body1" className={classes.text}>
                {t("shoppingCart.emptyCart")}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              <div className={classes.iconButton}>
                <ShoppingCart style={{ fontSize: "6rem", color: "#ccc" }} />
              </div>
            </Grid>
            <Grid item xs={12} container justifyContent="center" alignItems="flex-start"></Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default EmptyCart;
