/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id: "us-east-1:7a92ef17-ce66-40c3-ad04-4dc6389fa91d",
  aws_cognito_region: "us-east-1",
  oauth: {},
  aws_appsync_graphqlEndpoint: "https://hvxfe4fnffbzblmmoeosot3g2y.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};
export default awsmobile;
