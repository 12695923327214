import { Avatar, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCompany } from "../../redux/slices/cleverlynkSlice";

interface ICartCompanyInfo {}

const CartCompanyInfo = (props: ICartCompanyInfo) => {
  const company = useSelector(selectCompany);

  return (
    <Grid container direction="column" alignContent="center" style={{ marginTop: 90 }}>
      <Grid item container justifyContent="center">
        <Avatar
          style={{
            height: 80,
            width: 80,
            boxShadow: "#dadada 1pt 1pt 3px 1px",
          }}
          variant="circular"
          src={company?.logoUrl}
        />
      </Grid>
      <Grid item container justifyContent="center" style={{ marginTop: 20 }}>
        <Typography>{company?.name}</Typography>
      </Grid>
    </Grid>
  );
};
export default CartCompanyInfo;
