import { makeStyles, createStyles, Grid, Typography, Divider, Theme, Button, Collapse } from "@material-ui/core";
import { LocationOnOutlined, Search, ThumbUp } from "@material-ui/icons";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import {
  AWSCountryCodes,
  formatResultToPlainValueAWS,
  LupapCityCodes,
  QueryState,
  selectDeliveryInfo,
} from "../../redux/slices/mapServiceSlice";

import { useTranslation } from "react-i18next";
import "../../i18n/config";
interface IAWSSuggestionsBox {
  selectedAddress: boolean;
  toggleMap: () => void;
  toggleSelected: (cbk: Partial<QueryState>) => void;
}

const AWSSuggestionsBox = ({ selectedAddress, toggleMap, toggleSelected }: IAWSSuggestionsBox) => {
  const deliveryInfo = useSelector(selectDeliveryInfo);

  const { handleModifyDeliveryInfo, handleQueryByAddressLupap, handleCalculateRouteDistance } = useMapService();

  const { t } = useTranslation("deliveryinput");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      nameTextfield: {
        border: "none",
        "&:hover": {
          border: "1px",
        },
      },
      MuiOutlinedInput: {
        padding: 0,
        textAlign: "start",
      },
      MuiInputBase: {
        alignItems: "flex-start",
      },
      endIcon: {
        paddingRight: "5%",
        [theme.breakpoints.down("sm")]: {
          paddingRight: "3%",
        },
        color: "#3e3e3e",
      },
      formControl: {
        margin: theme.spacing(1),
        width: "100%",
      },
      listItem: {
        backgroundColor: "#f3f3f330",
        borderRadius: 5,
        boxShadow: "1pt 1pt 1px 0px #00000014",
        marginBottom: 5,
      },
      searchMapItem: {
        backgroundColor: "#cfe4f930",
        borderRadius: 5,
        boxShadow: "1pt 1pt 1px 0px #00000014",
        marginBottom: 5,
      },
      searchInputItem: {
        backgroundColor: "#fff",
        borderRadius: 5,
        boxShadow: "1pt 1pt 1px 0px #00000014",
        marginBottom: 5,
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    const elem = document.getElementById("suggestions-search-item");
    if (elem) elem.innerHTML = deliveryInfo.address ?? "";
  }, []);

  const handleOnAccept = place => {
    const a = {
      address: formatResultToPlainValueAWS(place),
      coordinates: { lat: place.Geometry.Point[1], lng: place.Geometry.Point[0] },
      city: place.Municipality,
      country: Object.keys(AWSCountryCodes).find(key => AWSCountryCodes[key] === place.Country),
    };
    handleModifyDeliveryInfo(a);
    toggleSelected(a);
  };

  const renderSuggestions = () => {
    if (!deliveryInfo.suggestions) return null;
    const optionRows = deliveryInfo.suggestions?.map((option, index) => {
      const plainValue = formatResultToPlainValueAWS(option);
      return !plainValue ? null : (
        <Collapse in={!selectedAddress} key={option.place_id}>
          <Grid container alignItems="center" className={classes.listItem} id={`suggestion-${index}`}>
            <Grid item container xs={12}>
              <Button
                style={{ width: "100%" }}
                classes={{
                  endIcon: classes.endIcon,
                }}
                endIcon={
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={7}>
                      <Typography style={{ fontSize: "0.9rem", fontWeight: 600 }} id={`suggestion-ok-${index}`}>
                        Ok
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <ThumbUp style={{ marginBottom: "-5px", paddingBottom: 0 }} />
                    </Grid>
                  </Grid>
                }
                onClick={() => handleOnAccept(option)}>
                <Grid container>
                  <Grid item xs={12} container justifyContent="flex-start">
                    <Typography style={{ fontWeight: 800, textTransform: "none", color: "#3e3e3e" }}>
                      {plainValue}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-start">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ paddingLeft: 10, textTransform: "none" }}>
                      {option.Municipality}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      );
    });

    const searchMapItem = () => (
      <Grid item container xs={12} className={classes.searchMapItem} key="searchmap-item">
        <Button
          style={{ width: "100%" }}
          color="secondary"
          classes={{
            endIcon: classes.endIcon,
          }}
          endIcon={<LocationOnOutlined />}
          onClick={toggleMap}>
          <Grid container>
            <Grid item xs={12} container justifyContent="flex-start">
              <Typography style={{ fontWeight: 800, textTransform: "none", color: "#3e3e3e" }}>
                {t("addressSuggestionBox.cantFindAddress")}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start">
              <Typography
                onClick={toggleMap}
                variant="caption"
                style={{ paddingLeft: 10, color: "#0c50ce", cursor: "pointer", textTransform: "none" }}>
                {t("addressInput.searchInMap")}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );

    return <React.Fragment>{[optionRows, searchMapItem()]}</React.Fragment>;
  };

  return renderSuggestions();
};
export default AWSSuggestionsBox;
