import { GraphQLResult } from "@aws-amplify/api";
import { PFXCleverlynk, PFXCleverlynkGroup, PFXCompany, PFXSKU } from "../Interfaces";

export interface IGetCleverlynkForRenderQuery {
  id?: string;
  hash?: string;
  hostname?: string;
  isDefault?: boolean;
  doNotCountRender?: boolean;
}

export const getCleverlynkForRenderQuery = ({
  id,
  hash,
  hostname,
  isDefault,
  doNotCountRender,
}: IGetCleverlynkForRenderQuery) => `
query myQuery {
  ${
    isDefault
      ? `getDefaultCleverlynkForRender(hostname:"${hostname}")`
      : `getCleverlynkForRender(id: "${id}" ${hash ? `,hash: "${hash}"` : ""} ${
          doNotCountRender ? ",doNotCountRender:true" : ""
        })`
  }{
    id
    companyId
    clynkHours
    ownerId
    bankTransferImage
    expirationDate
    status
    inPerson
    bankTransferImage
    cleverlynkGroup{
      cleverlynks{
        items{
          id
          name
          abandonedCartEmail
          geolocationInfo{
            location {
              lat
              lng
            }
            polyType
            polygon {
              lat
              lng
            }
            radius
          }
        }
      }
      openListFirst
      image
      mandatory
      cities
      name
      title
      type
      id
    }
    company {
      id
      domain
      URLParam
      name
      logoUrl
      userMarketingConsent
      domain
      abandonedCartEmail
      subdomain
      name
      bankTransferInfoURLs
      skus(limit:90000) {
        items {
          sku
          stock
        }
      }
      mails {
        mail
      }
      hyperlinks {
        url
        name
      }
      socials {
        url
        page
      }
      phones
      primaryColor
      termsURL
      pixelId
      googleAnalyticsId
      crossDomainGA
    }
    criteria {
      addition
      criteriaType
      label
      mandatory
      values
      characterLimit
      deliveryTypeExclusiveness
    }
    
    isUsingDeliveryTimes
    deliveryTimes {
      openHours
      immediateDeliveryRange
      scheduledDelivery {
        dispatchHours
        hourMargin
        weekdays
        selectedDeliveryDays
        ignoreDeliveryDays
        dayMargin
      }
    }
    deliveryPrice{
      type
      price
      basePrice
      minPrice
      pricePerUnit
      unitDistance
      deliveryAddress
      values {
        price
        range
      }
      percentageCovered
    }
    discount
    usesDiscountCodes
    geolocationInfo {
      location {
        lat
        lng
      }
      polyType
      polygon {
        lat
        lng
      }
      radius
    }
    isUsingGeolocation
    minimumAmount
    name
    language
    whatsapp
    phone
    email
    ownerId
    paymentMethods {
      bankTransfer
      cash
      dataphone
      ePayCo
      mercadoPago
      stripe
      nequi
      payzen
      paypal
      wompi
      payU
      paymentez
      redeban
      rappi
      showroom
      userPercentageEC
      userPercentageMP
      userPercentageNQ
      userPercentageRB
    }
    timezone{
      value
    }
    takeOut {
      criteriaType
      label
      values
    }
    serviceTip {
      message
      type
    }
  }
}
`;

export function checkNull(value): any | undefined {
  return value === null ? undefined : value;
}

export interface IGetCleverlynkForRender {
  skus: PFXSKU[];
  cleverlynk: PFXCleverlynk;
  cleverlynkGroup: PFXCleverlynkGroup | undefined;
  company: PFXCompany | undefined;
}

export function cleverlynkForRender(cleverlynk: GraphQLResult<any>): IGetCleverlynkForRender {
  const data = cleverlynk.data?.getCleverlynkForRender ?? cleverlynk.data?.getDefaultCleverlynkForRender;
  return {
    skus: data?.company?.skus?.items,
    cleverlynkGroup: data?.cleverlynkGroup
      ? ({
          ...checkNull(data?.cleverlynkGroup),
          image: checkNull(data?.cleverlynkGroup?.image!),
          title: checkNull(data?.cleverlynkGroup?.title!),
          cities: data?.cleverlynkGroup?.cities ?? [],
        } as PFXCleverlynkGroup)
      : undefined,
    company: {
      name: data?.company?.name ?? "",
      domain: data?.company?.domain ?? "",
      subdomain: data?.company?.subdomain ?? "",
      mails: data?.company?.mails?.map(m => m.mail) ?? [],
      phones: data?.company?.phones ?? [],
      hyperlinks: checkNull(data?.company?.hyperlinks ?? []),
      socials: checkNull(data?.company?.socials ?? []),
      logoUrl: data?.company?.logoUrl,
      primaryColor: data?.company?.primaryColor,
      id: checkNull(data?.companyId),
      bankTransferInfoURLs: data?.company?.bankTransferInfoURLs ?? [],
      userMarketingConsent: !!data?.company?.userMarketingConsent,
      abandonedCartEmail: data?.company?.abandonedCartEmail,
      termsURL: data?.company?.termsURL,
      googleAnalyticsId: data?.company?.googleAnalyticsId,
      pixelId: data?.company?.pixelId,
      crossDomainGA: data?.company?.crossDomainGA,
    },
    cleverlynk: {
      //@ts-ignore
      abandonedCartEmail: data?.abandonedCartEmail,
      bankTransferImage: checkNull(data?.bankTransferImage),
      clynkHours: checkNull(data?.clynkHours),
      company: data?.company,
      criteria: data?.criteria,

      deliveryPrice: checkNull(data?.deliveryPrice),
      deliveryTimes: checkNull(data?.deliveryTimes),
      discount: checkNull(data?.discount),
      email: checkNull(data?.email),
      expirationDate: checkNull(data?.expirationDate),
      geolocationInfo: checkNull(data?.geolocationInfo),
      id: checkNull(data?.id),
      isUsingDeliveryTimes: checkNull(data?.isUsingDeliveryTimes),
      isUsingGeolocation: checkNull(data?.isUsingGeolocation),
      language: checkNull(data?.language),
      maximumAmount: checkNull(data?.maximumAmount),
      minimumAmount: checkNull(data?.minimumAmount),
      name: data?.name,
      ownerId: data?.ownerId,
      paymentMethods: data?.paymentMethods,
      phone: checkNull(data?.phone),
      serviceTip: checkNull(data?.serviceTip),
      status: data?.status,
      takeOut: checkNull(data?.takeOut),
      usesDiscountCodes: checkNull(data?.usesDiscountCodes),
      whatsapp: checkNull(data?.whatsapp),
    } as PFXCleverlynk,
  };
}
