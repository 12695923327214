import React from "react";
import {
  IconButton,
  ListItemAvatar,
  ListItem,
  List,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Theme,
  createStyles,
  makeStyles,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { StarRounded, StarBorderRounded, ClearRounded } from "@material-ui/icons";
import useDeliveryAddressCookies, {
  IDeliveryAddressCookie,
} from "../../hooks/MapServiceHooks/useDeliveryAddressCookies";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import { useSelector } from "react-redux";
import { ILatLng, selectCleverlynk } from "../../redux/slices/cleverlynkSlice";
import { selectDeliveryCookies } from "../../redux/slices/mapServiceSlice";
import map from "../../images/map.png";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IHistoricAddress {
  onConfirm: (a: IDeliveryAddressCookie) => void;
  checkForCoverage?: boolean;
  toggleAddition?: () => void;
}

const XS_VIEWPORT = 370;

const HistoricAddress = ({ checkForCoverage, onConfirm, toggleAddition }: IHistoricAddress) => {
  const { handleToggleFavouriteCookie, handleDeleteCookie } = useDeliveryAddressCookies();
  const { isMarkerInsidePolygon, isMarkerInsideCircle } = useMapService();

  const xsViewPort = useMediaQuery(`(max-width: ${XS_VIEWPORT}px)`);

  const cookies = useSelector(selectDeliveryCookies);
  const cleverlynk = useSelector(selectCleverlynk);

  const { t } = useTranslation("deliveryinput");

  const segmentedAddresses = React.useMemo(() => {
    const favourite = cookies.filter(a => a.favourite);
    const historic = cookies.filter(a => !a.favourite).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    return { favourite, historic };
  }, [cookies]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      listitem: {
        boxShadow: " 0pt 1pt 3px 1px #0000001a",
        borderRadius: 9,
        marginTop: 4,
        transitionProperty: "background-color",
        transitionDuration: "600ms",
        "&:hover": {
          boxShadow: `1pt 1pt 4px -2px #a0a0a0`,
          backgroundColor: `whitesmoke`,
        },
      },
    })
  );
  const classes = useStyles();

  const isMarkerInside = (location: ILatLng) => {
    switch (cleverlynk?.geolocationInfo?.polyType) {
      case "circle":
        return isMarkerInsideCircle(
          cleverlynk?.geolocationInfo?.location as any,
          cleverlynk?.geolocationInfo?.radius!,
          location
        );
      case "polygon":
        return isMarkerInsidePolygon((cleverlynk?.geolocationInfo?.polygon as any) ?? [], location);
      default:
        return true;
    }
  };

  return (
    <div style={{ minHeight: 350, position: "relative" }}>
      {!!toggleAddition && (
        <div style={{ position: "absolute", right: 10, top: 8, zIndex: 30, display: cookies.length > 0 ? "" : "none" }}>
          <Button onClick={toggleAddition}>
            <Typography variant="caption" style={{ textDecoration: "underline" }} id="add-address">
              {xsViewPort ? "+" : t("historicAddresses.addA")} {t("historicAddresses.address")}
            </Typography>
          </Button>
        </div>
      )}
      {cookies.length === 0 && (
        <Grid container alignItems="center" justifyContent="center" direction="column" style={{ paddingTop: 30 }}>
          <Grid item>
            <img src={map} height="156" alt="no-addresses" />
          </Grid>
          <Grid item container justifyContent="center">
            <Button onClick={toggleAddition}>
              <Typography
                variant="body1"
                style={{ textDecoration: "underline", textTransform: "none" }}
                id="add-address">
                {t("historicAddresses.addAnAddress")}
              </Typography>
            </Button>
            <Typography variant="overline">{t("historicAddresses.useItAfter")}</Typography>
          </Grid>
        </Grid>
      )}
      {segmentedAddresses.favourite.length > 0 && (
        <List subheader={<ListSubheader>{t("historicAddresses.favouriteAddresses")}</ListSubheader>}>
          {segmentedAddresses.favourite.map((a: IDeliveryAddressCookie) => (
            <ListItem
              key={`historicaddressitem-${a.id}`}
              button
              disabled={checkForCoverage && a.coordinates && !isMarkerInside(a.coordinates)}
              onClick={() => onConfirm(a)}
              className={classes.listitem}>
              <ListItemAvatar>
                <IconButton
                  style={{ zIndex: 9 }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleToggleFavouriteCookie(a.id, false);
                  }}>
                  {a.favourite ? <StarRounded /> : <StarBorderRounded />}
                </IconButton>
              </ListItemAvatar>
              <ListItemText
                primary={`${a.address}, ${a.city}`}
                secondary={
                  checkForCoverage && a.coordinates && !isMarkerInside(a.coordinates) ? (
                    <Typography variant="overline" style={{ color: "red", fontSize: "0.6rem" }}>
                      {t("addressInput.outsideCoverageArea")}
                    </Typography>
                  ) : (
                    a.propertyInfo
                  )
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleDeleteCookie(a.id)}>
                  <ClearRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {segmentedAddresses.historic.length > 0 && (
        <List
          style={{ paddingBottom: 70 }}
          subheader={<ListSubheader>{t("historicAddresses.historicAddresses")}</ListSubheader>}>
          {segmentedAddresses.historic.map((a: IDeliveryAddressCookie) => (
            <ListItem
              key={`historicaddressitem-${a.id}`}
              button
              onClick={() => onConfirm(a)}
              disabled={checkForCoverage && a.coordinates && !isMarkerInside(a.coordinates)}
              className={classes.listitem}>
              <ListItemAvatar>
                <IconButton
                  style={{ zIndex: 9 }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleToggleFavouriteCookie(a.id, true);
                  }}>
                  {a.favourite ? <StarRounded /> : <StarBorderRounded />}
                </IconButton>
              </ListItemAvatar>
              <ListItemText
                primary={`${a.address}, ${a.city}`}
                secondary={
                  checkForCoverage && a.coordinates && !isMarkerInside(a.coordinates) ? (
                    <Typography variant="overline" style={{ color: "red", fontSize: "0.6rem" }}>
                      {t("addressInput.outsideCoverageArea")}
                    </Typography>
                  ) : (
                    a.propertyInfo
                  )
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleDeleteCookie(a.id)}>
                  <ClearRounded />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};
export default HistoricAddress;
