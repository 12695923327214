import { Tab, Tabs, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useDeliveryAddressCookies from "../../hooks/MapServiceHooks/useDeliveryAddressCookies";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import { selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import {
  LupapCityCodes,
  selectDeliveryInfo,
  selectGetUserLocationSupervisor,
  selectSelectedAddress,
} from "../../redux/slices/mapServiceSlice";
import ConfirmAddressMap from "./ConfirmAddressMap";
import DynamicAddress from "./DynamicAddress";
import DynamicMap from "./DynamicMap";
import HistoricAddress from "./HistoricAddresses";
import SelectedAddressComponent from "./SelectedAddressComponent";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

enum DeliveryStage {
  "MAP",
  "SELECTED",
  "HISTORIC",
  "SUGGESTIONS",
  "CONFIRM",
}

export enum IMapProvider {
  "GOOGLE",
  "LUPAP",
  "AWS",
}

export interface IActualMapProvider {
  autocomplete: IMapProvider;
  coordinates: IMapProvider;
}

const DeliveryInput = () => {
  const [stage, setStage] = useState<DeliveryStage | undefined>(undefined);

  const { cookies } = useDeliveryAddressCookies();
  const cookiesFirstTimeRef = useRef<boolean>(false);

  const { handleSetSelectedAddress, handleModifyDeliveryInfo, handleLocateCityFromClynk, handleGetUserLocation } =
    useMapService();

  const isMobile = useSelector(selectIsMobile);
  const deliveryInfo = useSelector(selectDeliveryInfo);
  const selectedAddress = useSelector(selectSelectedAddress);
  const getUserLocationSupervisor = useSelector(selectGetUserLocationSupervisor);

  const queryLocation = useMemo(
    () => ({ city: deliveryInfo.city ?? "Bogotá", countryCode: deliveryInfo.country ?? "co" }),
    [deliveryInfo]
  );

  const { t } = useTranslation("deliveryinput");

  const mapProvider: IActualMapProvider = useMemo(() => {
    const coordinates = LupapCityCodes[queryLocation.city] ? IMapProvider.LUPAP : IMapProvider.AWS;
    const autocomplete = IMapProvider.AWS;
    return { coordinates, autocomplete };
  }, [queryLocation]);

  useEffect(() => {
    if (getUserLocationSupervisor.status !== "succeeded") handleGetUserLocation();
    if (selectedAddress) setStage(DeliveryStage.SELECTED);
    else setStage(DeliveryStage.SUGGESTIONS);
  }, []);

  useEffect(() => {
    // if (getUserLocationSupervisor.status !== "succeeded") handleGetUserLocation();
    if (selectedAddress) setStage(DeliveryStage.SELECTED);
  }, [selectedAddress]);

  useEffect(() => {
    if (!selectedAddress) {
      if (!cookiesFirstTimeRef.current && cookies.length > 0) {
        setStage(DeliveryStage.HISTORIC);
        cookiesFirstTimeRef.current = true;
      } else if (cookiesFirstTimeRef.current && cookies.length === 0) {
        setStage(DeliveryStage.SUGGESTIONS);
        cookiesFirstTimeRef.current = false;
      }
    }
  }, [cookies]);

  useEffect(() => {
    switch (stage) {
      case DeliveryStage.SELECTED:
        handleSetSelectedAddress(true);
        break;
      case DeliveryStage.SUGGESTIONS:
        handleSetSelectedAddress(false);
        handleLocateCityFromClynk();
        break;
      default:
        // handleSetSelectedAddress(false);
        break;
    }
  }, [stage]);

  const renderStage = useMemo(() => {
    switch (stage) {
      case DeliveryStage.SELECTED:
        return <SelectedAddressComponent toggleEdit={() => setStage(DeliveryStage.MAP)} />;

      case DeliveryStage.MAP:
        return (
          <DynamicMap
            city={queryLocation.city}
            countryCode={queryLocation.countryCode}
            mapProvider={mapProvider.coordinates}
            toggleSelected={() => setStage(DeliveryStage.SELECTED)}
          />
        );

      case DeliveryStage.HISTORIC:
        return (
          <HistoricAddress
            checkForCoverage
            onConfirm={cookie => {
              handleModifyDeliveryInfo({
                address: cookie.address,
                city: cookie.city,
                coordinates: cookie.coordinates,
                country: cookie.country,
                propertyInfo: cookie.propertyInfo,
                additionalInfo: cookie.additionalInfo,
              });
              setStage(DeliveryStage.SELECTED);
            }}
          />
        );

      case DeliveryStage.CONFIRM:
        return (
          <ConfirmAddressMap
            toggleAddress={() => setStage(DeliveryStage.SUGGESTIONS)}
            toggleSelected={() => setStage(DeliveryStage.SELECTED)}
            bannerPosition={"TOP"}
          />
        );

      case DeliveryStage.SUGGESTIONS:
        return (
          <DynamicAddress
            toggleMap={() => setStage(DeliveryStage.MAP)}
            toggleSelected={() => setStage(DeliveryStage.CONFIRM)}
            city={queryLocation.city}
            countryCode={queryLocation.countryCode}
            mapProvider={mapProvider}
          />
        );

      default:
        return null;
    }
  }, [queryLocation, mapProvider, stage, selectedAddress]);

  const tabs = useMemo(
    () => (
      <Tabs
        value={stage}
        onChange={(e, v) => setStage(v)}
        centered
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons="off">
        {cookies.length > 0 && (
          <Tab
            value={DeliveryStage.HISTORIC}
            label={
              <Typography style={{ textTransform: "none", fontSize: isMobile ? "0.9rem" : "" }} id="my-address">
                {t("addressInput.myAddresses")}
              </Typography>
            }
          />
        )}
        <Tab
          value={DeliveryStage.SUGGESTIONS}
          label={
            <Typography style={{ textTransform: "none", fontSize: isMobile ? "0.9rem" : "" }} id="input-address">
              {t("addressInput.inputAddress")}
            </Typography>
          }
        />
        <Tab
          value={DeliveryStage.MAP}
          label={
            <Typography style={{ textTransform: "none", fontSize: isMobile ? "0.9rem" : "" }} id="search-in-map">
              {t("addressInput.searchInMap")}
            </Typography>
          }
        />
      </Tabs>
    ),
    [stage, cookies]
  );

  return (
    <Grid container justifyContent="center" direction="column" style={{ position: "relative" }}>
      {stage !== DeliveryStage.SELECTED && stage !== DeliveryStage.CONFIRM && tabs}
      {renderStage}
      {stage === DeliveryStage.HISTORIC && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "white",
            height: 55,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "5px 15px 5px 15px",
            zIndex: 120,
            left: 0,
            boxShadow: "0pt -1pt 2px #eaeaea",
          }}>
          <Typography variant="caption" style={{ color: "#848484" }}>
            {t("historicAddresses.disclaimer")}
          </Typography>
        </div>
      )}
    </Grid>
  );
};
export default DeliveryInput;
