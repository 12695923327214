import { API, graphqlOperation } from "aws-amplify";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCleverlynkContext } from "../contexts/CleverlynkContext";
import {
  PFXCriteriaForOrder,
  PFXCustomerFormInfo,
  PFXDeliveryPrice,
  PFXDeliveryPriceType,
  PFXOrderFulfillmentType,
} from "../models/Interfaces";
import { insertCustomerFormInfo, removeCustomerFormInfo } from "../models/Mutations/CustomerInfo";
import {
  modifyMandatoryCriteria,
  modifyManyMandatoryCriteria,
  modifyManyOptionalCriteria,
  modifyOptionalCriteria,
  removeMandatoryCriteria,
  selectCleverlynk,
  selectCompany,
  selectDeliveryType,
  selectIdentification,
  selectItemsInCart,
  selectMandatoryCriteria,
  selectMandatoryCriteriaEntities,
  selectMandatoryCriteriaTotal,
  selectOptionalCriteria,
  selectOptionalCriteriaEntities,
  setCleverlynkStage,
  setCriteria,
  setDeliveryCheckoutInfo,
  setDeliveryType,
  setIdentification,
  setSnackbar as sliceSetSnackbar,
  TAKEOUT_LABEL,
} from "../redux/slices/cleverlynkSlice";
import { selectDeliveryInfo } from "../redux/slices/mapServiceSlice";
import { queryCustomerInfo, setOpenedAuth, toggleConsent } from "../redux/slices/userFormSlice";
import useCleverlynkFunctions from "./useCleverlynkFunctions";
import useIdentificationCookies from "./useIdentificationCookies";

import { useTranslation } from "react-i18next";
import "../i18n/config";

export default function useUserFormFunctions() {
  const cleverlynk = useSelector(selectCleverlynk);
  const deliveryType = useSelector(selectDeliveryType);
  const deliveryInfo = useSelector(selectDeliveryInfo);
  const identification = useSelector(selectIdentification);
  const mandatoryCriteria = useSelector(selectMandatoryCriteriaEntities);
  const optionalCriteria = useSelector(selectOptionalCriteriaEntities);
  const mandatoryCriteriaArray = useSelector(selectMandatoryCriteria);
  const optionalCriteriaArray = useSelector(selectOptionalCriteria);
  const company = useSelector(selectCompany);

  const cleverlynkContext = useCleverlynkContext();
  const basePath = useMemo(() => cleverlynkContext?.basePath ?? "", [cleverlynkContext]);
  const dispatch = useDispatch();
  const history = useHistory();

  const setSnackbar = snckbr => {
    dispatch(sliceSetSnackbar(snckbr));
  };

  const handleModifyDeliveryType = (type: PFXOrderFulfillmentType) => dispatch(setDeliveryType(type));
  const handleModifyIdentification = (id: string) => dispatch(setIdentification(id));
  const handleModifyMandatoryCriteria = (crit: PFXCriteriaForOrder) => dispatch(modifyMandatoryCriteria(crit));
  const handleModifyOptionalCriteria = (crit: PFXCriteriaForOrder) => dispatch(modifyOptionalCriteria(crit));
  const handleModifyManyOptionalCriteria = (crits: PFXCriteriaForOrder[]) =>
    dispatch(modifyManyOptionalCriteria(crits));
  const handleModifyManyMandatoryCriteria = (crits: PFXCriteriaForOrder[]) =>
    dispatch(modifyManyMandatoryCriteria(crits));
  const handleSetCriteria = (mandatory: PFXCriteriaForOrder[], optional: PFXCriteriaForOrder[]) =>
    dispatch(setCriteria({ mandatory, optional }));
  const handleSetOpenedAuth = () => dispatch(setOpenedAuth());

  const { currencyAwareRound, handleModifyDeliveryCheckoutInfo } = useCleverlynkFunctions();
  const { handleAddIdentificationCookie } = useIdentificationCookies();

  const handleQueryCustomerInfo = (id: string) => dispatch(queryCustomerInfo({ id }));

  const { t } = useTranslation();

  const isValidEmail = (email: string) => {
    const mail = email.replace(/\s/g, "");
    const mailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailRegex.test(String(mail).toLowerCase());
  };

  const onCheckMandatory = () => {
    const c = cleverlynk?.criteria?.filter(
      c =>
        (!c.deliveryTypeExclusiveness || c.deliveryTypeExclusiveness === PFXOrderFulfillmentType[deliveryType ?? ""]) &&
        c.mandatory
    );
    return c?.every(mc => !!mandatoryCriteriaArray?.find(c => mc.label === c.label));
    // let amountOfMandatoryFields = cleverlynk
    //   ?.criteria!.filter(c => c.label !== TAKEOUT)
    //   .filter(crit => crit.mandatory).length;
    // if (PREFERENCE_DTYPE[deliveryType] === "TAKEOUT") amountOfMandatoryFields!++;
    // return amountOfMandatoryFields === mandatoryCriteriaAmount;
  };

  const calculateDistance = (): number => {
    const cleverlynkLocation = {
      lat: cleverlynk?.geolocationInfo?.location?.lat!,
      lng: cleverlynk?.geolocationInfo?.location?.lng!,
    };

    const lat1 = deliveryInfo?.coordinates?.lat ?? cleverlynkLocation.lat;
    const lng1 = deliveryInfo?.coordinates?.lng ?? cleverlynkLocation.lng;
    const lat2 = cleverlynkLocation.lat;
    const lng2 = cleverlynkLocation.lng;

    const kmEarthRadius = 6378.137; // Radius of earth in KM
    const distanceLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const distanceLon = (lng2 * Math.PI) / 180 - (lng1 * Math.PI) / 180;
    const a =
      Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(distanceLon / 2) *
        Math.sin(distanceLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = kmEarthRadius * c;
    return distance * 1000; // mts
  };

  const isMarkerInsidePolygon = () => {
    const polyPoints = cleverlynk?.geolocationInfo?.polygon?.map(p => ({ lat: p.lat!, lng: p.lng! })) || [];
    const x = deliveryInfo?.coordinates!.lat,
      y = deliveryInfo?.coordinates!.lng;

    let inside = false;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat,
        yi = polyPoints[i].lng;
      const xj = polyPoints[j].lat,
        yj = polyPoints[j].lng;

      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };
  const isMarkerInsideCircle = (radius: number) => {
    const distance = calculateDistance();
    if (distance > radius) return false;
    else return true;
  };

  const validateGeoInfo = () => {
    const geoInfo = cleverlynk?.geolocationInfo!;
    if (cleverlynk?.isUsingGeolocation && (!deliveryInfo?.address || deliveryInfo?.address === ""))
      setSnackbar({ type: "warning", message: "Debes poner tu dirección de envío" });
    else {
      let isInside = false;
      if (geoInfo.polyType === "circle") isInside = isMarkerInsideCircle(geoInfo.radius!);
      else isInside = isMarkerInsidePolygon();

      if (isInside || !cleverlynk?.isUsingGeolocation) {
        return true;
      }
    }
    return false;
  };

  // Creo no necesitarla.
  const getDefaultValue = (label: string, mandatory: boolean): string => {
    if (mandatory) {
      return mandatoryCriteria[label]?.value ?? "";
    } else {
      return optionalCriteria[label]?.value ?? "";
    }
  };

  const getValue = (label: string, mandatory): string | undefined => {
    if (mandatory) {
      return mandatoryCriteria[label]?.value;
    } else {
      return optionalCriteria[label]?.value;
    }
  };

  const onChangeDeliveryType = (option: PFXOrderFulfillmentType) => {
    if (option === PFXOrderFulfillmentType.TAKEOUT) {
      dispatch(setDeliveryCheckoutInfo(undefined));
    }
    if (option === PFXOrderFulfillmentType.DELIVERY) {
      dispatch(removeMandatoryCriteria(TAKEOUT_LABEL));
    }
    dispatch(setDeliveryType(option as string));
  };

  const onInputChange = (inputData: { label: string; value: string; type: string; mandatory: boolean }) => {
    const { label, value, type, mandatory } = inputData;
    if (mandatory) handleModifyMandatoryCriteria({ label, value, criteriaType: type as any });
    else handleModifyOptionalCriteria({ label, value, criteriaType: type as any });
  };

  const resetCriteria = () => {
    if (cleverlynk) {
      const mandatory = [...mandatoryCriteriaArray].filter(
        mc =>
          mc.label === TAKEOUT_LABEL ||
          !!(cleverlynk?.criteria ?? []).find(
            cc => mc?.label! === cc?.label! && mc.criteriaType === cc.criteriaType && cc.mandatory
          )
      );
      const optional = [...optionalCriteriaArray].filter(
        mc =>
          !!(cleverlynk?.criteria ?? []).find(
            cc => mc?.label! === cc?.label! && mc.criteriaType === cc.criteriaType && !cc.mandatory
          )
      );
      handleSetCriteria(mandatory, optional);
    }
  };

  const disableCustomerInfo = () => {
    API.graphql(
      graphqlOperation(removeCustomerFormInfo, {
        input: {
          email: identification,
        },
      })
      //@ts-ignore
    ).then(() => {
      dispatch(toggleConsent(false));
      setSnackbar({ type: "success", message: "La configuración fue guardada exitosamente" });
    });
  };

  const enableCustomerInfo = () => {
    dispatch(toggleConsent(true));
  };

  const toggleCustomerInfoConsent = (b: boolean) => {
    if (!b) {
      disableCustomerInfo();
    } else {
      enableCustomerInfo();
    }
  };

  const handleGetDeliveryPrice = (subTotal: number, takeout: boolean) => {
    if (takeout) {
      handleModifyDeliveryCheckoutInfo({ deliveryPrice: 0 });
    } else {
      handleModifyDeliveryCheckoutInfo({ deliveryPrice: getDeliveryPrice(subTotal) });
    }
  };

  const getDeliveryPrice = (subTotal: number) => {
    const geoDeliveryPrice: PFXDeliveryPrice = cleverlynk?.deliveryPrice!;
    if (!geoDeliveryPrice) return 0;

    const pricePerUnit: number = geoDeliveryPrice.pricePerUnit!;
    const unitDistance: number = geoDeliveryPrice.unitDistance!;
    const minPrice: number | undefined = geoDeliveryPrice.minPrice!;
    const basePrice: number | undefined = geoDeliveryPrice.basePrice!;

    const isPaisaland =
      company?.id === "CBAaZdGiNe" ||
      company?.id === "zz-_D8QR-KuehsBVD_ai" ||
      company?.id === "4ksQlffRd4fRTE8e_uCNg" ||
      company?.id === "S0HCZP7jbPx8lswU4QUBT";

    const distance: number = isPaisaland ? deliveryInfo.distanceInMts ?? calculateDistance() : calculateDistance();
    if (geoDeliveryPrice.type === PFXDeliveryPriceType.PRICE) {
      let price = 0;
      geoDeliveryPrice.values?.forEach(range => {
        if (subTotal >= range.range![0] && subTotal <= (range.range![1] === null ? Infinity : range.range![1])) {
          price = range.price!;
        }
      });
      return price;
    } else if (geoDeliveryPrice.type === PFXDeliveryPriceType.FIXED) {
      return geoDeliveryPrice.price;
    } else if (minPrice !== undefined && minPrice !== null && unitDistance > 0) {
      const distanceDiference = distance / unitDistance;
      const finalPrice: number = distanceDiference * pricePerUnit;
      if (finalPrice > minPrice) return currencyAwareRound(finalPrice);
      else {
        return minPrice;
      }
    } else if (minPrice) return minPrice;
    else if (basePrice && unitDistance > 0) {
      const distanceDiference = distance / unitDistance;
      const finalPrice: number = distanceDiference * pricePerUnit + basePrice;
      return currencyAwareRound(finalPrice);
    } else if (basePrice) return basePrice;
    else return 0;
  };

  const handleUploadCustomerData = async (customerInfo: PFXCustomerFormInfo) => {
    if (!!customerInfo.consent) {
      const nm = {
        ...customerInfo.mandatory,
      };
      const no = {
        ...customerInfo.optional,
      };
      mandatoryCriteriaArray?.forEach(mc => {
        nm[mc.label!] = mc;
      });
      optionalCriteriaArray?.forEach(oc => {
        no[oc.label!] = oc;
      });

      API.graphql(
        graphqlOperation(insertCustomerFormInfo, {
          input: {
            mandatory: JSON.stringify(nm),
            optional: JSON.stringify(no),
            email: identification,
          },
        })
      );
    }
  };

  const goToCheckout = () => {
    // console.log("here", deliveryType);
    if (!isValidEmail(identification)) {
      setSnackbar({ type: "warning", message: "No ha ingresado un correo valido, vuelva a intentarlo" });
    } else if (!onCheckMandatory()) {
      setSnackbar({ type: "warning", message: "Debe llenar los campos requeridos" });
    } else if ((cleverlynk?.geolocationInfo || cleverlynk?.takeOut) && !deliveryType && !!cleverlynk?.deliveryPrice) {
      setSnackbar({ type: "warning", message: "Debes escoger un tipo de domicilio" });
    } else if (
      deliveryType === PFXOrderFulfillmentType.DELIVERY &&
      cleverlynk?.isUsingGeolocation &&
      !validateGeoInfo()
    ) {
      setSnackbar({
        type: "error",
        message: `${t("outOfCoverageSnackbar1")} ${cleverlynk?.company?.phones![0]} ${t("outOfCoverageSnackbar2")}`,
      });
    } else {
      let path = `${basePath}/purchase`;
      history.push({
        pathname: path.replace("//", "/"),
      });
      handleAddIdentificationCookie(identification);
      return dispatch(setCleverlynkStage({ stage: 3 }));
    }
  };

  return {
    calculateDistance,
    disableCustomerInfo,
    handleSetOpenedAuth,
    getDefaultValue,
    getValue,
    goToCheckout,
    handleModifyManyMandatoryCriteria,
    handleModifyManyOptionalCriteria,
    handleQueryCustomerInfo,
    handleModifyDeliveryType,
    toggleCustomerInfoConsent,
    handleGetDeliveryPrice,
    handleModifyIdentification,
    handleModifyMandatoryCriteria,
    resetCriteria,
    handleModifyOptionalCriteria,
    handleUploadCustomerData,
    isValidEmail,
    onChangeDeliveryType,
    onInputChange,
  };
}
