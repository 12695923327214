import { useDispatch, useSelector } from "react-redux";
import { PFXPrice, PFXPromoCodeType, PFXServiceTipType } from "../models/Interfaces";
import {
  modifyPrice,
  modifySelectedPaymentMethod,
  modifyTip,
  removeDiscount,
  selectDiscount,
  selectSelectedPaymentMethod,
  selectTip,
  selectPrice,
  addPromoCode,
} from "../redux/slices/checkoutSlice";
import {
  selectCleverlynk,
  selectCleverlynkId,
  selectCompany,
  selectCredentials,
  selectDeliveryCheckoutInfo,
  selectIdentification,
  selectItemsInCart,
  selectPrice as selectDiscountPrice,
} from "../redux/slices/cleverlynkSlice";
import { selectCurrency } from "../redux/slices/templateSlice";
import useCleverlynkFunctions from "./useCleverlynkFunctions";

export const MUNCHER = [
  "c4z_tmYgpKx8bL3TRDqpW", //Mooi
  "X_znECnR_DdYd5s2QvUM9", //ohmy
  "juw7_edA4i85JvR5tt3GL", //franco
  "6XdqnWkId7S2RaK6xuzO9", //nimis
  "OW5LQ24ntvLux4ayr4eY8", //hungrymob
];

export default function useCheckoutFunctions() {
  const dispatch = useDispatch();

  const cleverlynkId = useSelector(selectCleverlynkId);
  const currency = useSelector(selectCurrency);
  const price = useSelector(selectPrice);
  const discountPrice = useSelector(selectDiscountPrice);
  const itemsInCart = useSelector(selectItemsInCart);
  const deliveryGeoInfo = useSelector(selectDeliveryCheckoutInfo);
  const discount = useSelector(selectDiscount);
  const credentials = useSelector(selectCredentials);
  const cleverlynk = useSelector(selectCleverlynk);
  const company = useSelector(selectCompany);
  const tip = useSelector(selectTip);
  const selectedPayment = useSelector(selectSelectedPaymentMethod);
  const identification = useSelector(selectIdentification);
  // const paymentMethods = useSelector(selectPaymentMethods);

  const { currencyAwareRound } = useCleverlynkFunctions();

  const handleModifyPrice = (price: PFXPrice) => dispatch(modifyPrice(price));
  const handleModifySelectedPaymentMethod = (mthd: number) => dispatch(modifySelectedPaymentMethod(mthd));
  const handleModifyTip = (tip: number) => dispatch(modifyTip(tip));
  const handleRemoveDiscount = () => dispatch(removeDiscount());
  const handleAddDiscount = (discountCode: string, snackbarCallback) => {
    const timezone = cleverlynk?.timezone;
    const productAmount = itemsInCart?.reduce((acc, it) => acc + it!.quantity!, 0);
    const priceDiscount = discountPrice?.discount;

    if (COURTESIES.includes(discountCode) && !identification.includes("wilmer.eraso@muncher.com.co")) {
      snackbarCallback("No tienes permiso para crear cortesías", "error");
      return;
    }

    dispatch(
      addPromoCode({
        cleverlynkId,
        code: discountCode,
        snackbarCallback,
        subTotal: price.subTotal ?? 0,
        currency,
        mail: identification,
        timezone,
        priceDiscount,
        productAmount,
      })
    );
    // if (cleverlynk?.company?.id === "AcJUecBWSAO-blmMptkUV" && Boolean(price.discount)) {
    //   snackbarCallback("Los descuentos no son acumulables", "error");
    //   return;
    // }
    // if (discountCode === "ARROCESDEOTROMUNDO" && Boolean(price.discount)) {
    //   snackbarCallback("Los descuentos no son acumulables", "error");
    //   return;
    // }
    // if (Boolean(price.discount) && MUNCHER.includes(cleverlynk?.company?.id ?? "") && discountCode.includes("BLACK")) {
    //   snackbarCallback("Los descuentos no son acumulables", "error");
    //   return;
    // }
    // dispatch(
    //   addDiscount({
    //     cleverlynkId: cleverlynkId ?? "",
    //     currency,
    //     discountCode,
    //     snackbarCallback,
    //     subTotal: price.subTotal ?? 0,
    //   })
    // );
  };

  function calculateTip(subTotal: number) {
    if (cleverlynk?.serviceTip?.type === PFXServiceTipType.PERCENTAGE) {
      return subTotal * (tip! / 100);
    }
    return cleverlynk?.serviceTip ? tip : undefined;
  }

  const calculatePrice = (epayco: boolean, mp: boolean, isFirstTime?: boolean) => {
    let subTotal = itemsInCart.reduce((acum, item) => acum + item.price! * item.quantity!, 0);
    subTotal = currencyAwareRound(subTotal);
    let delivery = isFirstTime ? 0 : currencyAwareRound(deliveryGeoInfo?.deliveryPrice ?? 0);
    let transaction = 0;
    if (selectedPayment !== -1 && epayco) {
      const commission = cleverlynk?.paymentMethods?.userPercentageEC ?? 0;
      const epaycoVarCommission = (credentials?.EC?.ECFee ?? 2.99) / 100;
      const epaycoFixedCommission = 900;
      transaction =
        (subTotal + delivery + epaycoFixedCommission * 1.19 * commission) /
          (1 - commission * (epaycoVarCommission * 1.19 + 0.015 + 0.002)) -
        (subTotal + delivery);
      transaction = currencyAwareRound(transaction);
    }
    if (selectedPayment !== -1 && mp) {
      const commission = cleverlynk?.paymentMethods?.userPercentageMP ?? 0;
      transaction = (subTotal + delivery) / (1 - commission * 0.036295) - (subTotal + delivery);
      transaction = currencyAwareRound(transaction);
    }

    let tipPrice = calculateTip(subTotal);
    tipPrice = tipPrice !== undefined ? currencyAwareRound(tipPrice) : undefined;

    const tax = currencyAwareRound((subTotal * (cleverlynk?.taxInfo?.tax ?? 0)) / 100);
    let discountValue: number | undefined = undefined;
    if (discount) {
      switch (discount?.type) {
        case PFXPromoCodeType.FIXED:
          discountValue = currencyAwareRound(Math.min(subTotal, discount.value!));
          break;
        case PFXPromoCodeType.PERCENTAGE:
          discountValue = currencyAwareRound((subTotal / 100) * discount!.value!);
          break;
        case PFXPromoCodeType.FREE_DELIVERY:
          discountValue = delivery;
          break;
        case PFXPromoCodeType.COURTESY:
          discountValue = subTotal + delivery + transaction + (tipPrice ?? 0) - (discountValue ?? 0) + (tax ?? 0);
      }
    }
    return {
      subTotal,
      delivery,
      transaction,
      tax,
      discountCode: discount?.code ?? undefined,
      discount: discountValue,
      tip: tipPrice,
      total: subTotal + delivery + transaction + (tipPrice ?? 0) - (discountValue ?? 0) + (tax ?? 0),
    };
  };

  return {
    handleAddDiscount,
    handleRemoveDiscount,
    calculatePrice,
    handleModifySelectedPaymentMethod,
    handleModifyPrice,
    handleModifyTip,
  };
}

const COURTESIES = ["SAC", "INV", "OPS", "MKT", "PLE"];
