import { GraphQLResult } from "@aws-amplify/api";
import { PFXCleverlynk } from "../Interfaces";

export interface IClynkGroupMember extends PFXCleverlynk {
  open: boolean;
}

export const getOpenCloseClynksInGroup = cleverlynkGroupId => `
query openClynks {
    openCleverlynksForGroup(groupId: "${cleverlynkGroupId}") {
      open{
        id
        name
        geolocationInfo{
          location {
            lat
            lng
          }
          polyType
          polygon {
            lat
            lng
          }
          radius
        }
      }
      closed{
        id
        name
        geolocationInfo{
          location {
            lat
            lng
          }
          polyType
          polygon {
            lat
            lng
          }
          radius
        }
      }
    }
  }
`;

export function transformOpenClynks(clynks: GraphQLResult<any>): IClynkGroupMember[] {
  const openClynks = clynks.data.openCleverlynksForGroup.open.map(item => ({ ...item, open: true }));
  const closedClynks = clynks.data.openCleverlynksForGroup.closed.map(item => ({ ...item, open: false }));
  return [...openClynks, ...closedClynks];
}
