import { GraphQLResult } from "@aws-amplify/api";
import { PFXCustomerFormInfo } from "../Interfaces";

export const getCustomerInfoQuery = (email: string) => `
  query q {
    fetchCustomerFormInfo(email:"${email}"){
      mandatory
      optional
      email
      consent
    }
  }`;

export function getCustomerInfo(customerInfo: GraphQLResult<any>): PFXCustomerFormInfo {
  const data = customerInfo.data?.fetchCustomerFormInfo;
  return {
    mandatory: JSON.parse(data?.mandatory ?? "{}"),
    optional: JSON.parse(data?.optional ?? "{}"),
    email: data?.email!,
    consent: data?.consent,
  };
}
