import { GraphQLResult } from "@aws-amplify/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callGraphQL, { callGraphQLGeneric } from "../../models/graphql-api";
import {
  AvailablePromoCodesInClynkQuery,
  GetDiscountCodeQuery,
  PFXCurrency,
  PFXDiscountCodeType,
  PFXPrice,
  PFXPromoCode,
  PFXPromoCodeType,
  PFXPromoRestrictionType,
  VerifyPromoCodeQuery,
} from "../../models/Interfaces";
import { availablePromoCodesInClynkQuery } from "../../models/Queries/AvailablePromoCodesInClynk";
import { getDiscountCode } from "../../models/Queries/getDiscountCode";
import { verifyPromoCodeQuery } from "../../models/Queries/VerifyPromoCodeQuery";
import { RootState } from "../rootReducer";
import { currencyFormatter } from "./cleverlynkSlice";

type ComponentStatusType = "idle" | "loading" | "succeeded" | "failed";
type AsyncState = {
  status: ComponentStatusType;
  error?: string;
};
const initialAsyncState: AsyncState = { status: "idle" };

interface ICheckoutSlice {
  price: PFXPrice;
  selectedPaymentMethod: number;
  tip: number;
  discount: PFXPromoCode | undefined;
  addDiscount: AsyncState;
  addPromoCode: AsyncState;
  promoCodeId: string | undefined;
  cleverlynkPromoCodeId: string | undefined;
  customerPromoCodeId: string | undefined;
  availablePromoCodesInClynk: AsyncState;
  usePromoCodeInput: boolean;
  immediateDeliveryRangeSelected: boolean | null;
}

export const initialState: ICheckoutSlice = {
  price: {},
  tip: 0,
  discount: undefined,
  selectedPaymentMethod: -1,
  addDiscount: initialAsyncState,
  addPromoCode: initialAsyncState,
  availablePromoCodesInClynk: initialAsyncState,
  promoCodeId: undefined,
  cleverlynkPromoCodeId: undefined,
  customerPromoCodeId: undefined,
  usePromoCodeInput: false,
  immediateDeliveryRangeSelected: null,
};

export const addPromoCode = createAsyncThunk(
  "checkout/addPromoCode",
  async (
    info: { cleverlynkId; code; snackbarCallback; currency; subTotal; mail; timezone; priceDiscount; productAmount },
    thunkApi
  ) => {
    const { cleverlynkId, code, snackbarCallback, currency, subTotal, mail, timezone, priceDiscount, productAmount } =
      info;
    const timezoneValue = timezone?.value ?? "America/Bogota";
    try {
      const { data } = await callGraphQLGeneric<VerifyPromoCodeQuery>(verifyPromoCodeQuery, {
        cleverlynkId,
        code,
        mail,
        timezone: timezoneValue,
      });
      if (data?.verifyPromoCode) {
        const { promoCode, cleverlynkPromoCodeId, customerPromoCodeId } = data?.verifyPromoCode;
        const { restrictions } = promoCode!;

        if (priceDiscount && priceDiscount > 0 && !promoCode?.cumulative) {
          snackbarCallback("Los descuentos no son acumulables", "error");
          return thunkApi.rejectWithValue({
            error: `Los descuentos no son acumulables`,
          });
        }
        if (restrictions) {
          for (const restriction of restrictions) {
            const { type, price, amount } = restriction;

            if (type === PFXPromoRestrictionType.MIN_PRICE && price && price > subTotal) {
              snackbarCallback(
                `El pedido debe ser de al menos ${currencyFormatter(price, currency)} para poder usar el descuento`,
                "error"
              );

              return thunkApi.rejectWithValue({
                error: `El pedido debe ser de al menos ${currencyFormatter(
                  price,
                  currency
                )} para poder usar el descuento`,
              });
            } else if (type === PFXPromoRestrictionType.MIN_PRODUCTS_AMOUNT && amount && productAmount < amount) {
              snackbarCallback(`El número de productos en el carrito debe ser al menos ${amount}`, "error");
              return thunkApi.rejectWithValue({
                error: `El número de productos en el carrito debe ser al menos ${amount}`,
              });
            }
          }
        }
        switch (promoCode?.type) {
          case PFXPromoCodeType.FIXED:
            snackbarCallback(`Se ha aplicado un descuento de ${currencyFormatter(promoCode?.value)}`, "success");
            break;
          case PFXPromoCodeType.PERCENTAGE:
            snackbarCallback(`Se ha aplicado un descuento de ${currencyFormatter(promoCode?.value)}%`, "success");
            break;
          case PFXPromoCodeType.FREE_DELIVERY:
            snackbarCallback(`Se descontó el precio del domicilio`, "success");
            break;
          case PFXPromoCodeType.COURTESY:
            snackbarCallback(`El código de descuento de cortesía ha sido aceptado`, "success");
            break;
        }
        return { promoCode, cleverlynkPromoCodeId, customerPromoCodeId };
      }
    } catch (error) {
      console.error(JSON.stringify(error, null, 2));
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      try {
        const { message, type } = JSON.parse(errors[0].message);
        snackbarCallback(transformErrorToClientError(type), "error");
        return thunkApi.rejectWithValue({ error: message });
      } catch (error) {
        return thunkApi.rejectWithValue({ error: errors[0].message });
      }
    }
  }
);

export const availablePromoCodesInClynk = createAsyncThunk<any, any, any>(
  "checkout/availablePromoCodesInClynk",
  async ({ cleverlynkId }, thunkApi) => {
    try {
      const { data } = await callGraphQLGeneric<AvailablePromoCodesInClynkQuery>(availablePromoCodesInClynkQuery, {
        cleverlynkId,
      });
      return data?.availablePromoCodesInClynk;
    } catch (error) {
      const payload = error as GraphQLResult;
      const errors = payload.errors!;
      return thunkApi.rejectWithValue(errors[0].message);
    }
  }
);

function transformErrorToClientError(type) {
  switch (type) {
    case 0:
      return "El código de descuento no existe";
    case 1:
      return "Este código de descuento ya alcanzó su máximo número de usos para este cliente";
    case 2:
      return "Este código de descuento no es válido en este rango de horas";
    case 3:
      return "Este código de descuento ya alcanzó su máximo número de usos";
    case 4:
      return "Este código no está disponible en estas fechas";
    case 5:
      return "Este código de descuento no se encuentra disponible";
  }
}

export const addDiscount = createAsyncThunk(
  "checkout/addDiscount",
  async (
    info: {
      cleverlynkId: string;
      discountCode: string;
      subTotal: number;
      currency: PFXCurrency;
      snackbarCallback: (message: string, type: string) => void;
    },
    thunkApi
  ) => {
    try {
      const { cleverlynkId, discountCode, subTotal, currency, snackbarCallback } = info;
      const discount = await (
        await callGraphQL<GetDiscountCodeQuery>(getDiscountCode(cleverlynkId, discountCode))
      ).data?.getDiscountCode;

      if (discount === null) {
        snackbarCallback("Este código de descuento no existe", "error");
        return thunkApi.rejectWithValue("No Code");
      } else if (discount!.minPrice && discount!.minPrice > subTotal!) {
        snackbarCallback(
          "El pedido debe ser de al menos " +
            currencyFormatter(discount!.minPrice, currency) +
            " para poder usar el descuento",
          "error"
        );
        return undefined;
      } else if (discount?.type === PFXDiscountCodeType.FIXED)
        snackbarCallback("Se agregó un descuento de " + currencyFormatter(discount!.value!, currency), "success");
      else if (discount?.type === PFXDiscountCodeType.PERCENTAGE)
        snackbarCallback("Se agregó un descuento de " + discount!.value + "%", "success");
      else snackbarCallback("Se descontó el precio de domicilio", "success");
      return discount;
    } catch (error) {
      info.snackbarCallback("Este código no existe", "error");
      return thunkApi.rejectWithValue("No Code");
    }
  }
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    removeDiscount(state) {
      state.discount = undefined;
    },
    createDiscount(state, action) {
      state.discount = action.payload;
    },
    modifyPrice(state, action) {
      state.price = action.payload;
    },
    modifySelectedPaymentMethod(state, action) {
      state.selectedPaymentMethod = action.payload;
    },
    modifyTip(state, action) {
      state.tip = action.payload;
    },
    setImmediateDeliveryRangeSelected(state, action) {
      state.immediateDeliveryRangeSelected = action.payload;
    },
  },
  extraReducers: builder => {
    // builder.addCase(addDiscount.pending, state => {
    //   state.addDiscount.status = "loading";
    // });
    // builder.addCase(addDiscount.rejected, (state, action: any) => {
    //   state.addDiscount.status = "failed";
    //   state.addDiscount.error = action.payload.error;
    //   state.discount = undefined;
    // });
    // builder.addCase(addDiscount.fulfilled, (state, action) => {
    //   state.addDiscount.status = "succeeded";
    //   state.discount = action.payload as PFXPromoCode;
    // });
    builder.addCase(addPromoCode.pending, state => {
      state.addPromoCode.status = "loading";
    });
    builder.addCase(addPromoCode.rejected, (state, action: any) => {
      state.addPromoCode.status = "failed";
      state.addPromoCode.error = action.payload.error;
      state.discount = undefined;
    });
    builder.addCase(addPromoCode.fulfilled, (state, action: any) => {
      state.addPromoCode.status = "succeeded";
      const { promoCode, cleverlynkPromoCodeId, customerPromoCodeId } = action.payload;

      state.discount = promoCode;
      state.promoCodeId = promoCode.id;
      state.cleverlynkPromoCodeId = cleverlynkPromoCodeId;
      state.customerPromoCodeId = customerPromoCodeId;
    });
    builder.addCase(availablePromoCodesInClynk.pending, state => {
      state.availablePromoCodesInClynk.status = "loading";
    });
    builder.addCase(availablePromoCodesInClynk.rejected, (state, action: any) => {
      state.availablePromoCodesInClynk.status = "failed";
      state.availablePromoCodesInClynk.error = action.payload;
    });
    builder.addCase(availablePromoCodesInClynk.fulfilled, (state, action: any) => {
      state.usePromoCodeInput = action.payload;
      state.availablePromoCodesInClynk.status = "succeeded";
    });
  },
});

export const {
  removeDiscount,
  modifyPrice,
  modifySelectedPaymentMethod,
  modifyTip,
  createDiscount,
  setImmediateDeliveryRangeSelected,
} = templateSlice.actions;

export default templateSlice.reducer;

export const selectAddDiscount = (state: RootState) => state.checkout.addDiscount;
export const selectAddPromoCode = (state: RootState) => state.checkout.addPromoCode;
export const selectImmediateDeliveryRangeSelected = (state: RootState) => state.checkout.immediateDeliveryRangeSelected;
export const selectDiscount = (state: RootState) => state.checkout.discount;
export const selectPrice = (state: RootState) => state.checkout.price;
export const selectTip = (state: RootState) => state.checkout.tip;
export const selectPromoCodeId = (state: RootState) => state.checkout.promoCodeId;
export const selectCustomerPromoCodeId = (state: RootState) => state.checkout.customerPromoCodeId;
export const selectCleverlynkPromoCodeId = (state: RootState) => state.checkout.cleverlynkPromoCodeId;
export const selectSelectedPaymentMethod = (state: RootState) => state.checkout.selectedPaymentMethod;
export const selectUsePromoCodeInput = (state: RootState) => state.checkout.usePromoCodeInput;
export const selectAvailablePromoCodesInClynk = (state: RootState) => state.checkout.availablePromoCodesInClynk;
