import { GraphQLResult } from "@aws-amplify/api";
import { QueryState } from "../../redux/slices/mapServiceSlice";
import { PFXCriteriaForOrder, PFXDeliveryInfo, PFXItemForOrder, PFXOrder } from "../Interfaces";
import { checkNull } from "./GetCleverlynkForRender";

export const getCart = cartId => `
query getOrderForSend {
  fetchCart(id: "${cartId}") {
    items {
      chosenOptions {
        controlsInventory
        hidden
        id
        label
        quantity
        sku
        subitemId
        subitemType
        posReference
        input
        value
      }
      id
      images
      name
      og_price
      price
      posReference
      quantity
      sku
      subitems {
        id
        choiceLimit
        controlsInventory
        mandatory
        multiple
        name
        type
        options {
          hidden
          id
          posReference
          label
          sku
          value
        }
      }
    }
    identification
    price {
      discountCode
    }
    extras
    mandatoryCriteria {
      label
      value
      criteriaType
    }
    optionalCriteria {
      label
      value
      criteriaType
    }
    fulfillmentType
    deliveryInfo {
      deliveryAddress
      deliveryLocation {
        lat
        lng
      }
      deliveryPropertyTypeInfo
      deliveryAdditionalInfo
    }
  }
}
`;

export const getSendOrderQuery = orderId => `
query getOrderForSend {
  fetchOrder(id: "${orderId}") {
    items {
      chosenOptions {
        controlsInventory
        hidden
        id
        label
        quantity
        sku
        subitemId
        subitemType
        posReference
        input
        value
      }
      id
      images
      name
      og_price
      price
      posReference
      quantity
      sku
      subitems {
        id
        choiceLimit
        controlsInventory
        mandatory
        multiple
        name
        type
        options {
          hidden
          id
          posReference
          label
          sku
          value
        }
      }
    }
    identification
    price {
      discountCode
    }
    extras
    mandatoryCriteria {
      label
      value
      criteriaType
    }
    optionalCriteria {
      label
      value
      criteriaType
    }
    fulfillmentType
    deliveryInfo {
      deliveryAddress
      deliveryLocation {
        lat
        lng
      }
      deliveryPropertyTypeInfo
      deliveryAdditionalInfo
    }
  }
}`;

export interface SendPFXOrder extends PFXOrder {
  orderItems: PFXItemForOrder[];
}

export function getSendOrder(order: GraphQLResult<any>, queryName): SendPFXOrder {
  const data = order.data ? order.data[queryName] : undefined;
  const deliveryInfo = () => {
    let d: PFXDeliveryInfo | undefined = {};
    if (!!data?.deliveryInfo?.deliveryAddress && data?.deliveryInfo?.deliveryAddress !== "") {
      d.deliveryAddress = data?.deliveryInfo?.deliveryAddress;
      d.deliveryLocation = data?.deliveryInfo?.deliveryLocation;
      d.deliveryPropertyTypeInfo = data?.deliveryInfo?.deliveryPropertyTypeInfo;
      d.deliveryAdditionalInfo = data?.deliveryInfo?.deliveryAdditionalInfo;
    } else d = undefined;
    return d;
  };

  return {
    orderItems: data?.items as PFXItemForOrder[],
    identification: checkNull(data?.identification),
    mandatoryCriteria: data?.mandatoryCriteria.map(
      c =>
        ({
          label: c.label,
          value: c.value,
          criteriaType: c.criteriaType,
        } as PFXCriteriaForOrder)
    ),
    optionalCriteria: data?.optionalCriteria?.map(
      c =>
        ({
          label: c.label,
          value: c.value,
          criteriaType: c.criteriaType,
        } as PFXCriteriaForOrder)
    ),
    deliveryInfo: deliveryInfo(),
    price: {
      discountCode: checkNull(data?.price.discountCode),
    },
    extras: data?.extras,
    fulfillmentType: data?.fulfillmentType,
  };
}
