import { Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import useTemplateStyles from "../../hooks/useTemplateStyles";
import { selectCleverlynk, selectCleverlynkGroup, selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { selectGoogleLoaded, selectGoogleSessionToken } from "../../redux/slices/mapServiceSlice";
import CityDisabled from "./CityInputs.tsx/CityDisabled";
import CityInput from "./CityInputs.tsx/CityInput";
import CitySelector from "./CityInputs.tsx/CitySelector";
import { IActualMapProvider, IMapProvider } from "./DeliveryInput";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

enum SuggestionInputType {
  "NONE",
  "DISABLED",
  "SELECTOR",
  "INPUT",
}
interface ISuggestionsInput {
  city: string;
  countryCode: string;
  mapProvider: IActualMapProvider;
  toggleMap: () => void;
  citySelector?: boolean;
  toggleFocus?: () => void;
}

const SuggestionsInput = ({
  city,
  countryCode,
  mapProvider,
  toggleMap,
  citySelector,
  toggleFocus,
}: ISuggestionsInput) => {
  const [contentType, setContentType] = useState<number>(SuggestionInputType.NONE);

  const sessionToken = useSelector(selectGoogleSessionToken);
  const googleLoaded = useSelector(selectGoogleLoaded);
  const cleverlynk = useSelector(selectCleverlynk);
  const isMobile = useSelector(selectIsMobile);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);

  const { primaryColor } = useTemplateStyles();

  const { t } = useTranslation("deliveryinput");

  const autocompleteService = useRef<any>(null);

  const { queryWithSuggestionsDebounce } = useMapService();

  useEffect(() => {
    // mapProvider !== IMapProvider.LUPAP &&
    if (!autocompleteService.current && googleLoaded) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
  }, [mapProvider, googleLoaded]);

  useEffect(() => {
    if (!citySelector) return;
    switch (cleverlynkGroup?.cities?.length) {
      case 0:
        setContentType(SuggestionInputType.INPUT);
        break;
      case 1:
        setContentType(SuggestionInputType.DISABLED);
        break;
      default:
        setContentType(SuggestionInputType.SELECTOR);
        break;
    }
  }, [citySelector, cleverlynkGroup?.cities?.length]);

  useEffect(() => {
    const address: any = document.getElementById("map-service-search-field");
    handleAWSQuerySuggestions(address?.value ?? "");
  }, [city]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        // position: "absolute",
        top: 0,
        zIndex: 10,
        left: 0,
        width: "100%",
        padding: "10px 0px 10px 0px",
        margin: 0,
      },
      searchField: {
        height: isMobile ? "2.5rem" : "3.2rem",
        borderRadius: 5,
        color: "#484848",
        paddingLeft: 8,
        fontSize: "1rem",
        fontFamily: theme.typography.body1.fontFamily,
        width: "calc(100% - 16px)",
        border: `1px solid rgba(0,0,0,.2)`,
        boxShadow: "1px 2px 5px 0 rgb(0 0 0 / 10%)",
        transition: "box-shadow linear 40ms,border-color linear .2s",
        "&:focus-visible": {
          borderColor: "#334bfa",
          outline: "none",
          boxShadow: "0 4px 14px 0 rgb(13 36 209 / 10%)",
        },
      },
      searchButton: {
        backgroundColor: primaryColor,
        padding: "8px 0px",
        borderRadius: 5,
        color: theme.palette.primary.contrastText,
        textTransform: "none",
        boxShadow: "1pt 1pt 3px 1px #00000012",
        width: "100%",
        maxWidth: 40,
        height: isMobile ? "2.5rem" : "3.2rem",
        transitionProperty: "max-width",
        transitionDuration: "200ms",
        "&:hover": {
          maxWidth: 90,
          color: theme.palette.primary.contrastText,
          backgroundColor: primaryColor,
        },
      },
    })
  );

  const classes = useStyles();

  const handleQuerySuggestions = (input: string) => {
    handleAWSQuerySuggestions(input);
  };

  const handleGoogleQuerySuggestions = (input: string) => {
    queryWithSuggestionsDebounce(
      {
        address: input,
        coordinates: {
          lat: cleverlynk?.geolocationInfo?.location?.lat!,
          lng: cleverlynk?.geolocationInfo?.location?.lng!,
        },
        city,
        country: countryCode,
      },
      mapProvider,
      sessionToken,
      autocompleteService.current
    );
  };

  const handleAWSQuerySuggestions = (input: string) => {
    queryWithSuggestionsDebounce(
      {
        address: input,
        coordinates: {
          lat: cleverlynk?.geolocationInfo?.location?.lat!,
          lng: cleverlynk?.geolocationInfo?.location?.lng!,
        },
        city,
        country: countryCode,
      },
      mapProvider
    );
  };

  const addressInputField = React.useCallback(
    (size: any) => (
      <Grid item xs={size} style={{ padding: 0 }} key="suggestions-input">
        <input
          onChange={e => {
            handleQuerySuggestions(e.target.value);
          }}
          onFocus={toggleFocus}
          id="map-service-search-field"
          type="text"
          className={classes.searchField}
          placeholder={t("addressSuggestionBox.searchYourAddress")}
        />
      </Grid>
    ),
    [classes.searchField]
  );

  const content = React.useMemo(() => {
    switch (contentType) {
      case SuggestionInputType.DISABLED:
        return [addressInputField(9), <CityDisabled key="citydisabled" />];

      case SuggestionInputType.SELECTOR:
        return [<CitySelector key="cityselector" />, addressInputField(8)];

      case SuggestionInputType.INPUT:
        return [<CityInput key="cityinput" />, addressInputField(8)];

      case SuggestionInputType.NONE:
        return addressInputField(12);

      default:
        return null;
    }
  }, [addressInputField, contentType, isMobile]);

  return (
    <Grid container className={classes.root} alignItems="center" spacing={2} justifyContent="space-between">
      {content}
    </Grid>
  );
};
export default SuggestionsInput;
