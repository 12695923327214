import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../../hooks/MapServiceHooks/useMapService";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { selectCleverlynkGroup, selectIsMobile } from "../../../redux/slices/cleverlynkSlice";

import { useTranslation } from "react-i18next";
import "../../../i18n/config";

interface ICityInput {}

const CityInput = ({}: ICityInput) => {
  const isMobile = useSelector(selectIsMobile);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);

  const { handleModifyDeliveryInfo } = useMapService();

  const { t } = useTranslation("deliveryinput");

  const { primaryColor } = useTemplateStyles();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      cityInput: {
        width: "100%",
        height: isMobile ? "2.5rem" : "3.2rem",
        paddingLeft: 8,
        borderRadius: 5,
        fontFamily: "inherit",
        fontSize: isMobile ? "1rem" : "1.1rem",
        border: `1px solid rgba(0,0,0,.2)`,
        boxShadow: "0 2px 8px 0 rgb(0 0 0 / 10%)",
        transition: "box-shadow linear 40ms,border-color linear .2s",
        "&:focus-visible": {
          borderColor: "#334bfa",
          outline: "none",
          boxShadow: "0 4px 14px 0 rgb(13 36 209 / 10%)",
        },
      },
      citySelector: {},
      cityDisabled: {},
      goToMapText: {
        fontSize: "0.75rem",
        color: "rgba(0, 0, 0, 0.54)",
        position: "absolute",
        bottom: -18,
        left: 0,
      },
      formControl: {
        margin: theme.spacing(1),
        width: "100%",
      },
    })
  );

  const classes = useStyles();

  const handleOnChange = React.useRef(
    debounce(() => {
      const elem: any = document.getElementById("city-input");
      handleModifyDeliveryInfo({ city: elem?.value });
    }, 300)
  ).current;

  const cityInput = () => (
    <Grid item xs={4} style={{ position: "relative", paddingRight: 18 }} key="city-input">
      <input
        onChange={handleOnChange}
        placeholder={t("cityInput.placeholder")}
        id="city-input"
        autoComplete="new-password"
        className={classes.cityInput}
      />
    </Grid>
  );

  return cityInput();
};
export default CityInput;
