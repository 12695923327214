import { String } from "aws-sdk/clients/batch";
import { debounce } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IActualMapProvider, IMapProvider } from "../../components/DeliveryMapService/DeliveryInput";
import { MapServiceContext, useMapServiceContext } from "../../contexts/MapServiceContext";
import { PFXGeolocationInfo } from "../../models/Interfaces";
import { ILatLng, selectCleverlynk } from "../../redux/slices/cleverlynkSlice";
import {
  queryByAddressLupap,
  queryByCoordinatesLupap,
  queryByAddressSuggestionsGoogle,
  queryByCoordinatesGoogle,
  setGoogleLoaded,
  setGoogleSessionToken,
  QueryState,
  modifyDeliveryInfo,
  queryByAddressSuggestionsLupap,
  modifySetSelectedAddress,
  queryByAddressSuggestionsAWS,
  queryByCoordinatesAWS,
  getUserLocation,
  locateCityAndCountryFromClynk,
  modifySnackbar,
  queryCalculateDistanceAWS,
} from "../../redux/slices/mapServiceSlice";

export interface IMapQuery {
  city?: string;
  country?: string;
  address?: string;
  coordinates?: { lat: number; lng: number };
}

export default function useMapService() {
  const mapServiceContext = useMapServiceContext();

  const dispatch = useDispatch();
  const AWSClient = mapServiceContext?.AWSClient;
  const cleverlynk = useSelector(selectCleverlynk);

  const previousSessionToken = React.useRef<string>("");
  const handleSetGoogleLoaded = () => dispatch(setGoogleLoaded());
  const handleSetGoogleSessionToken = (st: string) => dispatch(setGoogleSessionToken(st));
  const handleSetSelectedAddress = (sel: boolean) => dispatch(modifySetSelectedAddress(sel));
  const handleGetUserLocation = () => dispatch(getUserLocation());
  const handleInitAWSClient = () => mapServiceContext?.initAWSClient();

  const queryWithCoordinatesDebounce = React.useRef(
    debounce((query: IMapQuery, block: number, mapProvider: IMapProvider, geocoder?: google.maps.Geocoder | null) => {
      if (block > 2) {
        switch (mapProvider) {
          case IMapProvider.GOOGLE:
            queryWithCoordinatesGoogle(query, geocoder!);
            break;
          case IMapProvider.LUPAP:
            queryWithCoordinatesLupap(query);
            // queryWithCoordinatesAWS(query);
            break;
          case IMapProvider.AWS:
            handleQueryByCoordinatesAWS(query);
            break;
          default:
            break;
        }
      }
    }, 300)
  ).current;

  const queryWithSuggestionsDebounce = React.useRef(
    debounce((query: IMapQuery, mapProvider: IActualMapProvider, sessionToken?: string, autocompleteService?) => {
      const elem = document.getElementById("suggestions-search-item");
      const wrapper = document.getElementById("suggestions-search-wrapper");
      if (elem && wrapper && mapProvider.coordinates === IMapProvider.LUPAP) {
        wrapper.style.display = "block";
        elem.innerHTML = query.address ?? "";
      }
      if ((query.address ?? "").length > 5) queryWithAddressSuggestionsAWS(query);
    }, 300)
  ).current;

  const queryWithAddressSuggestionsAWS = (query: IMapQuery) => {
    dispatch(
      queryByAddressSuggestionsAWS({
        query,
        AWSClient,
        clynkLocation: cleverlynk?.geolocationInfo?.location as any,
      })
    );
  };

  const handleQueryByCoordinatesAWS = (query: IMapQuery) => {
    dispatch(queryByCoordinatesAWS({ query, AWSClient }));
  };

  const handleModifySnackbar = (s: any) => {
    dispatch(modifySnackbar(s));
  };

  const queryWithCoordinatesGoogle = (query: IMapQuery, geocoder: google.maps.Geocoder) => {
    dispatch(queryByCoordinatesGoogle({ query, geocoder }));
  };

  const queryWithCoordinatesLupap = (query: IMapQuery) => {
    dispatch(queryByCoordinatesLupap({ query, fallback: (q: IMapQuery) => handleQueryByCoordinatesAWS(q) }));
  };

  const handleQueryByAddressLupap = (query: IMapQuery) => {
    dispatch(queryByAddressLupap({ query }));
  };

  const handleLocateCityFromClynk = () => {
    const location = cleverlynk?.geolocationInfo?.location as ILatLng;
    if (location) dispatch(locateCityAndCountryFromClynk({ location, AWSClient }));
  };

  const handleCalculateRouteDistance = destination => {
    const departure = [cleverlynk?.geolocationInfo?.location?.lng, cleverlynk?.geolocationInfo?.location?.lat];
    const query = {
      destination,
      departure,
    };
    dispatch(queryCalculateDistanceAWS({ query, AWSClient }));
  };

  const handleModifyDeliveryInfo = (dinfo: Partial<QueryState>) => dispatch(modifyDeliveryInfo(dinfo));

  const calculateDistance = (clynkLoc: ILatLng, location: ILatLng): number => {
    const lat1 = location.lat;
    const lng1 = location.lng;
    const lat2 = clynkLoc.lat;
    const lng2 = clynkLoc.lng;

    const kmEarthRadius = 6378.137; // Radius of earth in KM
    const distanceLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const distanceLon = (lng2 * Math.PI) / 180 - (lng1 * Math.PI) / 180;
    const a =
      Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(distanceLon / 2) *
        Math.sin(distanceLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = kmEarthRadius * c;

    return distance * 1000; // mts
  };

  const isMarkerInsidePolygon = (polyPoints: ILatLng[], location: ILatLng) => {
    const x = location.lat,
      y = location.lng;

    let inside = false;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat,
        yi = polyPoints[i].lng;
      const xj = polyPoints[j].lat,
        yj = polyPoints[j].lng;

      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };

  const isMarkerInsideCircle = (clynkLoc: ILatLng, radius: number, location: ILatLng) => {
    const distance = calculateDistance(clynkLoc, location);
    if (distance > radius) return false;
    else return true;
  };

  const getCoordinatesArrayFromRadius = (center: ILatLng, radius: number) => {
    var km = radius / 1000;
    const points = 60;

    var ret: any = [];
    var distanceX = km / (111.32 * Math.cos((center.lat ?? 0 * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    Array(points)
      .fill(0)
      .forEach((_x, i) => {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([Number(center.lng ?? 0) + x, Number(center.lat ?? 0) + y]);
      });
    ret.push(ret[0]);
    return [ret];
  };

  const getCoordinatesArrayFromClynk = (geolocationInfo: PFXGeolocationInfo) => {
    switch (geolocationInfo.polyType) {
      case "circle":
        var km = geolocationInfo.radius! / 1000;
        const points = 60;

        var ret: any = [];
        var distanceX = km / (111.32 * Math.cos((geolocationInfo.location?.lat ?? 0 * Math.PI) / 180));
        var distanceY = km / 110.574;

        var theta, x, y;
        Array(points)
          .fill(0)
          .forEach((_x, i) => {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);

            ret.push([Number(geolocationInfo.location?.lng ?? 0) + x, Number(geolocationInfo.location?.lat ?? 0) + y]);
          });
        ret.push(ret[0]);
        return [ret];
      case "polygon":
        return [geolocationInfo?.polygon?.map(p => [p.lng, p.lat])];
      default:
        break;
    }
  };

  return {
    handleQueryByAddressLupap,
    queryWithCoordinatesDebounce,
    handleModifySnackbar,
    queryWithSuggestionsDebounce,
    queryWithCoordinatesLupap,
    handleInitAWSClient,
    handleLocateCityFromClynk,
    handleSetGoogleLoaded,
    handleSetGoogleSessionToken,
    handleGetUserLocation,
    handleSetSelectedAddress,
    isMarkerInsideCircle,
    isMarkerInsidePolygon,
    getCoordinatesArrayFromClynk,
    getCoordinatesArrayFromRadius,
    calculateDistance,
    handleModifyDeliveryInfo,
    handleCalculateRouteDistance,
  };
}
