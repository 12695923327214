import i18n from "i18next";
import en_checkout from "./en/checkout.json";
import en_common from "./en/common.json";
import en_datatreatment from "./en/datatreatment.json";
import en_deliveryinput from "./en/deliveryinput.json";
import en_groups from "./en/groups.json";
import en_header from "./en/header.json";
import en_ordersummary from "./en/ordersummary.json";
import en_socialfooter from "./en/socialfooter.json";
import en_unavailable from "./en/unavailable.json";
import en_upselling from "./en/upselling.json";
import en_coverage from "./en/coverage.json";
import es_checkout from "./es/checkout.json";
import es_common from "./es/common.json";
import es_datatreatment from "./es/datatreatment.json";
import es_deliveryinput from "./es/deliveryinput.json";
import es_groups from "./es/groups.json";
import es_header from "./es/header.json";
import es_ordersummary from "./es/ordersummary.json";
import es_socialfooter from "./es/socialfooter.json";
import es_unavailable from "./es/unavailable.json";
import es_upselling from "./es/upselling.json";
import es_coverage from "./es/coverage.json";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    checkout: en_checkout,
    common: en_common,
    datatreatment: en_datatreatment,
    deliveryinput: en_deliveryinput,
    header: en_header,
    ordersummary: en_ordersummary,
    socialfooter: en_socialfooter,
    unavailable: en_unavailable,
    upselling: en_upselling,
    groups: en_groups,
    coverage: en_coverage,
  },
  es: {
    checkout: es_checkout,
    common: es_common,
    datatreatment: es_datatreatment,
    deliveryinput: es_deliveryinput,
    header: es_header,
    ordersummary: es_ordersummary,
    socialfooter: es_socialfooter,
    unavailable: es_unavailable,
    upselling: es_upselling,
    groups: es_groups,
    coverage: es_coverage,
  },
} as const;

export const defaultNS = "common";

i18n.use(initReactI18next).init({
  lng: "es",
  defaultNS,
  ns: [
    "checkout",
    "common",
    "datatreatment",
    "deliveryinput",
    "header",
    "socialfooter",
    "unavailable",
    "upselling",
    "ordersummary",
    "groups",
    "coverage",
  ],
  resources,
});
