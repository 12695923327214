import React from "react";
import { QueryState } from "../../redux/slices/mapServiceSlice";
import AWSSuggestionsBox from "./AWSSuggestionsBox";
import { IActualMapProvider } from "./DeliveryInput";
import SuggestionsInput from "./SuggestionsInput";

interface IDynamicAddress {
  city: string;
  countryCode: string;
  mapProvider: IActualMapProvider;
  toggleMap: () => void;
  toggleSelected: (a?: Partial<QueryState>) => void;
  citySelector?: boolean;
  toggleFocus?: () => void;
}

const DynamicAddress = ({
  city,
  countryCode,
  mapProvider,
  toggleMap,
  toggleSelected,
  citySelector,
  toggleFocus,
}: IDynamicAddress) => {
  return (
    <div style={{ minHeight: 200, width: "inherit", transition: "height 400ms" }}>
      <SuggestionsInput
        city={city}
        countryCode={countryCode}
        mapProvider={mapProvider}
        toggleMap={toggleMap}
        citySelector={citySelector}
        toggleFocus={toggleFocus}
      />
      <AWSSuggestionsBox toggleMap={toggleMap} selectedAddress={false} toggleSelected={toggleSelected} />
    </div>
  );
};
export default DynamicAddress;
