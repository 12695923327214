import { CircularProgress, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { ISummaryBannerInfo, ISummaryLoader } from "./StatusBanner";

import { useTranslation } from "react-i18next";
import "../../i18n/config";
interface IStatusLoader {
  info: ISummaryBannerInfo;
}

const StatusLoader = ({ info }: IStatusLoader) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
  const [loaderXOffset, setLoaderXOffset] = useState<number>(0);

  const { loaders } = info;

  const { t } = useTranslation("ordersummary");

  const isMobile = useSelector(selectIsMobile);

  useEffect(() => {
    if (hoveredIndex === undefined) return;
    const elem = document.getElementById(`loader${hoveredIndex}`);
    const header = document.getElementById("order-summary-header");
    if (elem && header) {
      const headeroffset = header.getBoundingClientRect().x;
      const offset =
        elem.getBoundingClientRect().x + 12 - headeroffset - ((loaders[hoveredIndex]?.label ?? "")?.length / 2) * 3;
      setLoaderXOffset(offset);
    }
  }, [hoveredIndex]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isMobile) setHoveredIndex(undefined);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [hoveredIndex]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      loader: {
        transitionDuration: "200ms",
        transitionProperty: "height, width, opacity, margin-top",
        paddingBottom: 0,
        marginBottom: 4,
        "&.outlined": {
          height: hoveredIndex === undefined ? 13 : 10,
          width: hoveredIndex === undefined ? 13 : 10,
          borderRadius: "5pc",
          boxShadow: "rgb(0 0 0 / 11%) 1pt 1pt",
          opacity: hoveredIndex === undefined ? 1 : 0.5,
          "&.active": {
            height: 20,
            width: 20,
            opacity: 1,
            marginTop: -5,
          },
        },
        "&.solid": {
          height: hoveredIndex === undefined ? 17 : 14,
          width: hoveredIndex === undefined ? 17 : 14,
          borderRadius: "5pc",
          boxShadow: "rgb(0 0 0 / 11%) 1pt 1pt",
          opacity: hoveredIndex === undefined ? 1 : 0.5,
          "&.active": {
            height: 24,
            width: 24,
            opacity: 1,
            marginTop: -5,
          },
        },
        "&.loader": {
          opacity: hoveredIndex === undefined ? 1 : 0.5,
          animationDuration: "1000ms",
          // position: "absolute",
          // left: 0,
          "&.active": {
            opacity: 1,
            marginTop: -5,
          },
        },
      },

      title: {
        fontSize: "3.2rem",
        fontWeight: "bold",
        fontFamily: "system-ui",
        textAlign: "center",
        textShadow: "1.5pt 1.5pt 2px #00000021",
      },
      loaderlabel: {
        color: "#868686",
        transitionProperty: "margin-left",
        transitionDuration: "300ms",
        marginLeft: loaderXOffset,
        whiteSpace: "nowrap",
        width: 1,
      },
      grid: {
        paddingBottom: "3px !important",
        maxWidth: 45,
        position: "relative",
      },
      indicator: {
        color: "#868686",
        position: "absolute",
        top: -7,
        left: -4,
        fontSize: "0.7rem",
        whiteSpace: "nowrap",
      },
    })
  );
  const classes = useStyles();

  const renderLoader = (index: number, loader: ISummaryLoader) => {
    switch (loader.type) {
      case "outlined":
        return (
          <Grid
            key={`loader${index}`}
            id={`loader${index}`}
            item
            onMouseEnter={() => (!isMobile ? setHoveredIndex(index) : null)}
            onMouseLeave={() => (!isMobile ? setHoveredIndex(undefined) : null)}
            onClick={() => (isMobile ? setHoveredIndex(index) : null)}
            className={classes.grid}
            container
            alignContent="center"
            direction="column">
            <div
              className={`${hoveredIndex === index ? "active" : ""} outlined ${classes.loader}`}
              style={{
                border: `dotted 2pt ${loader.color}`,
              }}></div>
          </Grid>
        );
      case "loader":
        return (
          <Grid
            key={`loader${index}`}
            id={`loader${index}`}
            item
            onMouseEnter={() => (!isMobile ? setHoveredIndex(index) : null)}
            onMouseLeave={() => (!isMobile ? setHoveredIndex(undefined) : null)}
            onClick={() => (isMobile ? setHoveredIndex(index) : null)}
            className={classes.grid}
            container
            alignContent="center"
            direction="column">
            <div className={classes.indicator}>{t("youreHere")}</div>
            <CircularProgress
              disableShrink
              className={`${hoveredIndex === index ? "active" : ""} loader ${classes.loader}`}
              style={{ color: loader.color }}
              size={hoveredIndex === index ? 24 : hoveredIndex === undefined ? 17 : 14}
              thickness={5}
            />
          </Grid>
        );
      case "solid":
        return (
          <Grid
            key={`loader${index}`}
            id={`loader${index}`}
            item
            onMouseEnter={() => (!isMobile ? setHoveredIndex(index) : null)}
            onMouseLeave={() => (!isMobile ? setHoveredIndex(undefined) : null)}
            onClick={() => (isMobile ? setHoveredIndex(index) : null)}
            className={classes.grid}
            container
            alignContent="center"
            direction="column">
            <div
              className={`${hoveredIndex === index ? "active" : ""}  solid ${classes.loader}`}
              style={{
                backgroundColor: loader.color,
              }}></div>
          </Grid>
        );
      default:
        return (
          <Grid
            key={`loader${index}`}
            id={`loader${index}`}
            item
            onMouseEnter={() => (!isMobile ? setHoveredIndex(index) : null)}
            onMouseLeave={() => (!isMobile ? setHoveredIndex(undefined) : null)}
            onClick={() => (isMobile ? setHoveredIndex(index) : null)}
            className={classes.grid}
            container
            alignContent="center"
            direction="column">
            <div
              className={`${hoveredIndex === index ? "active" : ""}  solid ${classes.loader}`}
              style={{
                backgroundColor: loader.color,
              }}>
              <Clear style={{ width: "inherit", height: "inherit", color: "white" }} />
            </div>
          </Grid>
        );
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      spacing={3}
      style={{ justifyContent: "center", marginTop: 15, marginBottom: hoveredIndex === undefined ? 20 : "" }}>
      {loaders.map((l, i) => renderLoader(i, l))}
      {hoveredIndex !== undefined && (
        <Grid item xs={12} style={{ padding: 0, marginTop: -5 }}>
          <Typography variant="caption" className={classes.loaderlabel}>
            {loaders[hoveredIndex].label ?? ""}
          </Typography>
        </Grid>
      )}

      {hoveredIndex !== undefined && <Grid item xs={12} style={{ padding: 0, marginTop: -5, height: 20 }}></Grid>}

      {/* {hoveredIndex === undefined && (
        <Grid item xs={12} style={{ paddingTop: 0, display: "flex", justifyContent: "center" }}>
          <Typography
            variant="caption"
            style={{ textAlign: "center", color: "#868686", fontSize: isMobile ? "0.85rem" : "1rem" }}>
            {subtitle}
          </Typography>
        </Grid>
      )} */}
    </Grid>
  );
};
export default StatusLoader;
