import { useSelector } from "react-redux";
import { selectPrimaryColor } from "../redux/slices/cleverlynkSlice";
import {
  selectBanner,
  selectCardsAlignment,
  selectCardType,
  selectTemplateStyles,
  selectTemplateType,
} from "../redux/slices/templateSlice";

export default function useTemplateStyles() {
  const styles = useSelector(selectTemplateStyles);
  const cardType = useSelector(selectCardType);
  const templateType = useSelector(selectTemplateType);
  const cardsAlignment = useSelector(selectCardsAlignment);
  const primaryColor = useSelector(selectPrimaryColor);
  const banner = useSelector(selectBanner);

  return { ...styles, cardType, templateType, cardsAlignment, primaryColor, bannerFontFamily: banner?.textFontFamily };
}
