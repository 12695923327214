export const verifyPromoCodeQuery = /* GraphQL */ `
  query verifyPromoCodeQuery($cleverlynkId: ID, $code: String, $mail: AWSEmail, $timezone: String) {
    verifyPromoCode(cleverlynkId: $cleverlynkId, code: $code, mail: $mail, timezone: $timezone) {
      promoCode {
        cumulative
        type
        id
        code
        value
        restrictions {
          type
          beginDate
          endDate
          price
          weekRange {
            mon
            tue
            wed
            thu
            fri
            sat
            sun
          }
          amount
          
        }
      }
      cleverlynkPromoCodeId
      customerPromoCodeId
    }
  }
`;
