import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { selectIsMobile } from "../../../redux/slices/cleverlynkSlice";
import {
  PFXCategory,
  PFXCategoryItem,
  selectCatalog,
  selectCategoryEntities,
} from "../../../redux/slices/templateSlice";
import ItemCard from "../ItemCard";

export const scrollToCat = (id, backgroundColor) => {
  const elem = document.getElementById(`cat-box-${id}`);
  if (elem) {
    const y = elem.getBoundingClientRect().top + window.scrollY - 120;
    window.scroll({
      top: y,
      behavior: "smooth",
    });
  }
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    const clynkHeader = document.getElementById("clynk-header");
    const clynkHeaderLogo = document.getElementById("clynks-header-logo");
    setTimeout(() => {
      if (clynkHeader) {
        clynkHeader.style.backgroundColor = backgroundColor;
        clynkHeader.style.boxShadow = "0pt 1pt 4px 0px #00000061";
        if (clynkHeaderLogo) clynkHeaderLogo.style.height = "50px";
      }
    }, 50);
  }
};

const DrawerTemplate = () => {
  const isMobile = useSelector(selectIsMobile);
  const categories = useSelector(selectCategoryEntities);
  const catalog = useSelector(selectCatalog);

  const { navbarStyles, titleStyles } = useTemplateStyles();

  const styles = React.useMemo(
    () => ({
      categoryName: {
        textAlign: (titleStyles?.drawerCategoryTitleAlignment as any) ?? "left",
        fontWeight: (titleStyles?.drawerCategoryTitleWeight as any) ?? "bold",
        fontSize: titleStyles?.drawerCategoryTitleSize
          ? titleStyles?.drawerCategoryTitleSize
          : isMobile
          ? "1.1rem"
          : "1.4rem",
        color: titleStyles?.drawerCategoryTitleColor ?? navbarStyles?.navbarTextColor,
      },
      subcategoryName: {
        textAlign: (titleStyles?.drawerCategoryTitleAlignment as any) ?? "left",
        fontWeight: (titleStyles?.drawerCategoryTitleWeight as any) ?? "bold",
        fontSize: isMobile ? "0.95rem" : "1.2rem",
        color: titleStyles?.drawerCategoryTitleColor ?? navbarStyles?.navbarTextColor,
      },
    }),
    [
      titleStyles?.drawerCategoryTitleAlignment,
      titleStyles?.drawerCategoryTitleWeight,
      titleStyles?.drawerCategoryTitleSize,
      titleStyles?.drawerCategoryTitleColor,
      isMobile,
      navbarStyles?.navbarTextColor,
    ]
  );

  const useStyles = makeStyles({
    categoryName: styles.categoryName,
    subcategoryName: styles.subcategoryName,
    root: { overflow: "hidden", textOverflow: "ellipsis", msTextOverflow: "ellipsis", padding: "0px 6px" },
  });

  const classes = useStyles();

  const renderDrawerTemplate = React.useMemo(() => {
    const renderScrollableCategory = (root: PFXCategory, subcategory: boolean) => {
      return (
        <React.Fragment>
          {(root.categories ?? [])
            .map(c => categories[c.id])
            .filter(
              c =>
                (!!c && c?.items && (c?.items ?? []).length > 0) ||
                (!!c && c?.categories && (c?.categories ?? []).length > 0)
            )
            .map((c, i) => (
              <React.Fragment key={`cat-box-${c?.id}`}>
                {!c?.hidden && (
                  <Box
                    width="100vw"
                    id={`cat-box-${c?.id}`}
                    marginTop={i === 0 && !subcategory ? 0 : subcategory ? 3 : 6}>
                    <Typography
                      className={classes.root}
                      classes={{ root: `${subcategory ? classes.subcategoryName : classes.categoryName}` }}>
                      {c?.name}
                    </Typography>
                    {!titleStyles?.disableCategoryDivider && !subcategory && <Divider />}
                  </Box>
                )}
                {c?.categories && (c?.categories ?? []).length > 0
                  ? renderScrollableCategory(c, true)
                  : (c?.items ?? []).map((item: PFXCategoryItem, index) => (
                      <ItemCard key={`item-${item.id}`} id={item.id!} index={index} />
                    ))}
              </React.Fragment>
            ))}
        </React.Fragment>
      );
    };
    if (catalog?.categories && catalog?.categories.length > 0) {
      return renderScrollableCategory(catalog, false);
    } else if (catalog?.items && catalog.items.length > 0) {
      return (
        <React.Fragment>
          {(catalog.items ?? []).map((item: PFXCategoryItem, index) => (
            <ItemCard key={`item-${item.id}`} id={item.id!} index={index} />
          ))}
        </React.Fragment>
      );
    } else return null;
  }, [
    catalog,
    categories,
    classes.categoryName,
    classes.root,
    classes.subcategoryName,
    titleStyles?.disableCategoryDivider,
  ]);

  return renderDrawerTemplate;
};
export default React.memo(DrawerTemplate);
