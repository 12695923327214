import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { IKImage } from "imagekitio-react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";
import useTemplateFunctions from "../../../hooks/useTemplateFunctions";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { RootState } from "../../../redux/rootReducer";
import { currencyFormatter, selectCleverlynk, selectIdenticalItemsInCart } from "../../../redux/slices/cleverlynkSlice";
import { selectCurrency, selectItemById, selectSkuById } from "../../../redux/slices/templateSlice";

import { useTranslation } from "react-i18next";
import "../../../i18n/config";
import { Add } from "@material-ui/icons";

interface IListCard {
  onClick: () => void;
  id: string;
  fullWidth?: boolean;
  index: number;
}

const ListCard = ({ id, onClick, fullWidth, index }: IListCard) => {
  const { cardStyles } = useTemplateStyles();
  const { shouldHideSoldOutItem } = useTemplateFunctions();

  const { t } = useTranslation("common");

  const item = useSelector((state: RootState) => selectItemById(state, id));
  const itemSku = useSelector((state: RootState) => selectSkuById(state, item?.sku ?? ""));
  const identicalItemsInCart = useSelector(selectIdenticalItemsInCart);
  const currency = useSelector(selectCurrency);
  const cleverlynk = useSelector(selectCleverlynk);

  const path = React.useMemo(
    () =>
      item?.images && item?.images.length > 0
        ? item?.images[0].split("cleverlynk-imgs.s3.amazonaws.com")[1]?.replace("resized-", "")
        : "",
    []
  );

  const stock = React.useMemo(() => {
    const itemStock = itemSku?.stock;
    const soldOut = item?.soldOut;

    if (soldOut) return 0;
    else if (itemStock === undefined) return Math.max() - 1;
    else return itemStock;
  }, [item, itemSku]);

  const shouldNotRender = React.useMemo(
    () => !item || item?.hidden || shouldHideSoldOutItem(item!),
    [item, shouldHideSoldOutItem]
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: 0,
        position: "relative",
      },
      mediaSoldOut: {
        width: "100%",
        height: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        background: "rgba(0, 0, 0, 0.6)",
        position: "absolute",
        bottom: 0,
        top: 0,
      },
      soldOut: {
        bottom: 0,
        color: "#FFFFFF",
        textAlign: "center",
        background: "rgba(200, 0, 0, 0.4)",
      },
      discount: {
        position: "absolute",
        top: "12%",
        left: "15%",
        width: "115%",
        transform: "rotate(45deg)",
        msTransform: "rotate(45deg)",
        WebkitTransform: "rotate(45deg)",
        color: "white",
        textAlign: "center",
        background: "#ff4b4b",
        padding: "3px 0px",
      },
      quantity: {
        position: "absolute",
        zIndex: 1,
        height: 21,
        width: 21,
        padding: 3,
        top: -10,
        right: -10,
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        color: "#FFFFFF",
        textAlign: "center",
        background: "rgba(250, 70, 80, 1)",
      },
      priceWithDiscount: {
        "&.list": {
          textAlign: "end",
        },
        opacity: 0.7,
        MozOpacity: 0.7,
        textDecoration: "line-through",
        paddingRight: 8,
        textDecorationColor: "rgba(250, 0, 0, 1)",
      },
      button: {
        borderRadius: 0,
        MozBorderRadius: 0,
        WebkitBorderRadius: 0,
        "&.large": {
          borderRadius: 5,
          margin: "3px 0px",
          width: "40%",
        },
      },
      text: {
        lineHeight: 1.45,
        maxWidth: "95%",
        marginRight: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis",
        msTextOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: "left",
        "&.list": {
          fontSize: "0.9rem",
          maxWidth: "95%",
        },
        "&.listprice": {
          maxWidth: "95%",
          fontSize: "1rem",
        },
      },
      rootList: {
        display: "flex",
        borderRadius: 5,
        MozBorderRadius: 5,
        WebkitBorderRadius: 5,
        cursor: stock === 0 ? "" : "pointer",
      },
      actionAreaList: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      mediaList: {
        top: 0,
        overflow: "hidden",
        position: "relative",
        borderRadius: 5,
        MozBorderRadius: 5,
        WebkitBorderRadius: 5,
      },
      contentList: {
        textAlign: "left",
        margin: "auto",
        padding: 5,
      },
      actionsList: {
        padding: 8,
      },
      infogriditem: {
        maxWidth: `calc(83% - ${path ? 90 : 0}px)`,
        flexBasis: `calc(83% - ${path ? 90 : 0}px)`,
      },
    })
  );

  const classes = useStyles();

  const quantity = React.useMemo(() => {
    return (
      <Box className={classes.header}>
        {identicalItemsInCart[id] && identicalItemsInCart[id] > 0 && (
          <Box className={classes.quantity}>
            <Typography
              style={{ color: "#FFFFFF", fontSize: "0.875rem", fontWeight: "bold", width: "100%", textAlign: "center" }}
              variant="subtitle2"
              id={`quantity-item-${index}`}
              component="p">
              {identicalItemsInCart[id]}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }, [classes.header, classes.quantity, id, identicalItemsInCart]);

  return shouldNotRender ? null : (
    <Fade in={true} timeout={400}>
      <Grid item xs={12} md={fullWidth ? 12 : 6}>
        {quantity}
        <Card
          style={cardStyles}
          className={classes.rootList}
          elevation={5}
          onClick={() => (stock === 0 ? null : onClick())}>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            {path && (
              <Grid item style={{ maxWidth: 90, minWidth: 90, position: "relative" }} container alignItems="center">
                <IKImage
                  style={{ maxWidth: "100%", position: "relative" }}
                  path={path}
                  transformation={[
                    {
                      height: 90,
                      width: 90,
                    },
                  ]}
                  loading="lazy"
                />
                {!item?.decorative && stock === 0 ? (
                  <Box className={classes.mediaSoldOut}>
                    <Box py={1} className={classes.soldOut}>
                      <Typography style={{ color: "#FFFFFF", fontSize: "0.875rem" }} variant="body2" component="p">
                        AGOTADO
                      </Typography>
                    </Box>
                  </Box>
                ) : !item?.decorative && item?.new ? (
                  <Grid container style={{ position: "absolute", top: 5, left: 5 }} justifyContent="flex-start">
                    <Chip label="Nuevo" size="small" color="secondary" />
                  </Grid>
                ) : null}
                {!item?.decorative &&
                  ((!!item?.discount && item?.discount?.type !== "none" && item?.priceWithDiscount! > 0) ||
                    (cleverlynk?.discount !== undefined &&
                      cleverlynk?.discount !== null &&
                      cleverlynk?.discount > 0)) && (
                    <Box className={classes.discount}>
                      <Typography style={{ fontSize: "0.75rem", color: "inherit" }} variant="caption" component="p">
                        {item?.discount?.type === "percentage"
                          ? `${item?.discount?.amount}% Off`
                          : `-${currencyFormatter(item?.discount?.amount, currency)}`}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            )}
            <Grid item className={classes.infogriditem} container style={{ padding: 14 }}>
              <Grid item xs={12}>
                <Typography className={classes.text} variant="subtitle2" component="h2" id={`card-name-${index}`}>
                  {item?.name ?? ""}
                </Typography>
              </Grid>
              {!item?.decorative && !!item?.discount && item?.discount?.type !== "none" && (
                <Grid item style={{ paddingTop: 3 }} xs={item?.priceWithDiscount! > 0 ? 6 : 1}>
                  <Typography
                    className={`list ${classes.priceWithDiscount} ${classes.text}`}
                    variant="caption"
                    id={`card-price-${index}`}
                    component="p">
                    {currencyFormatter(item?.price ?? 0, currency)}
                  </Typography>
                </Grid>
              )}
              {!item?.decorative && item?.priceWithDiscount! > 0 && (
                <Grid
                  item
                  xs={item?.priceWithDiscount! > 0 && !!item?.discount && item?.discount?.type !== "none" ? 6 : 12}>
                  <Typography
                    className={`listprice ${classes.text}`}
                    variant="subtitle1"
                    component="p"
                    id={`card-price-${index}`}>
                    {currencyFormatter(item?.priceWithDiscount, currency)}
                  </Typography>
                </Grid>
              )}
              {item?.decorative && (
                <Typography
                  className={`list ${classes.text}`}
                  variant="subtitle1"
                  component="p"
                  id={`card-description-${index}`}>
                  {item?.description ? item?.description.slice(0, 50) : ""}
                </Typography>
              )}
            </Grid>
            {!item?.decorative && (
              <Grid
                item
                xs={2}
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  padding: 14,
                  paddingLeft: 8,
                  height: "100%",
                  backgroundColor: stock === 0 ? "#ccc" : cardStyles?.buttonColor,
                }}>
                <IconButton
                  disabled={stock === 0}
                  onClick={onClick}
                  id={`card-add-${index}`}
                  style={{ padding: 0, color: cardStyles?.textButtonColor }}>
                  <Add />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};
export default ListCard;
