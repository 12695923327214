export const getCompanyBillingInfo = (companyId: string) => `
  query q {
    getBillingInfo(companyId: "${companyId}") {
      alegra {
        businessName
        identificationNumber
        identificationType
      }
    }
  }`;
