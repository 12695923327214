import { GraphQLResult } from "@aws-amplify/api";
import { PFXCategory, PFXCategoryStructure } from "../../redux/slices/templateSlice";
import { PFXItem, PFXTemplate } from "../Interfaces";

export const fetchTemplateInfoForRenderQuery = (clynkId: string | undefined, hostname: string | undefined) => `query q {
  fetchTemplateInfoForRender(${!!clynkId ? `cleverlynkId:"${clynkId}",` : ""} hostname: "${hostname}") {
    disableNavbarFade
    showAllProductsTab
    currency
    drawerNavbarWhenMobile
    hideItemsWhenSoldOut
    upsellingIds
    logo
    type
    name
    socialsFooter
    cardSize
    banners{
      backgroundColor
      id
      subtitle
      title
      text
      textAlign
      textColor
      type
      desktopSize
      desktopImage
      mobileImage
      mobileSize
      textVerticalAlign
      opaqueBackground
      imageMobileXCut
      textFontFamily
    }
    styles {
      backgroundStyles{
        backgroundColor
        backgroundRepeat
        backgroundSize
        backgroundUrl
        useBackgroundImage
      }
      cardStyles{
        borderRadius
        buttonColor
        cardColor
        cardsAlignment
        secondColor
        shadow
        shadowSize
        textButtonColor
        largeCardFontSize
      }
      navbarStyles{
        headerColor
        navbarColor
        navbarTextColor
        navbarFontFamily
      }
      titleStyles {
        color
        fontFamily
        fontSize
        fontWeight
        textAlign
        textFontFamily
        disableCategoryDivider
        drawerCategoryTitleAlignment
        drawerCategoryTitleColor
        drawerCategoryTitleSize
        drawerCategoryTitleWeight
      }
      footerStyles{
        backgroundColor
        textColor
        alignment
      }
    }
    items
    structure
    categories
  }
}`;

export interface IGetCleverlynkForRender {
  template: PFXTemplate;
  structure: PFXCategory;
  items: PFXItem[];
  categories: PFXCategoryStructure;
}
