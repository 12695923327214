export const getDiscountCode = (clevId, code) => `
  query q {
    getDiscountCode(cleverlynkId: "${clevId}", code: "${code}"){
      type
      code
      value
      minPrice
    }
  }
`;
