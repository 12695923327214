import { PFXCleverlynk, PFXCleverlynkGroup, PFXSKU } from "../Interfaces";

export interface IGetCleverlynkForRenderQuery {
  id?: string;
  hash?: string;
  hostname?: string;
  isDefault?: boolean;
  doNotCountRender?: boolean;
}

export const fetchCleverlynkInfoForRenderQuery = ({ id, hostname, doNotCountRender }: IGetCleverlynkForRenderQuery) => `
  query myQuery { 
    fetchCleverlynkInfoForRender(
      ${!!id ? `id:"${id}",` : ""} 
      hostname: "${hostname}", 
      ${doNotCountRender ? "doNotCountRender:true" : ""}
      ){
        id
        companyId
        clynkHours
        ownerId
        bankTransferImage
        expirationDate
        status
        inPerson
        bankTransferImage
        zendesk
        taxInfo {
          shouldApplyTax
          tax
        }
        cleverlynkGroup{
          cleverlynks{
            items{
              id
              name
              abandonedCartEmail
              geolocationInfo{
                location {
                  lat
                  lng
                }
                polyType
                polygon {
                  lat
                  lng
                }
                radius
              }
            }
          }
          landingPageId
          openListFirst
          image
          mandatory
          cities
          name
          title
          type
          id
        }
        company {
          id
          domain
          URLParam
          name
          logoUrl
          userMarketingConsent
          domain
          abandonedCartEmail
          subdomain
          name
          bankTransferInfoURLs
          skus(limit:90000) {
            items {
              sku
              stock
            }
          }
          mails {
            mail
          }
          hyperlinks {
            url
            name
          }
          socials {
            url
            page
          }
          phones
          primaryColor
          termsURL
          pixelId
          googleAnalyticsId
          crossDomainGA
        }
        criteria {
          addition
          criteriaType
          label
          mandatory
          values
          characterLimit
          deliveryTypeExclusiveness
        }
        
        isUsingDeliveryTimes
        deliveryTimes {
          openHours
          immediateDeliveryRange
          scheduledDelivery {
            dispatchHours
            hourMargin
            weekdays
            selectedDeliveryDays
            ignoreDeliveryDays
            dayMargin
          }
        }
        deliveryPrice{
          type
          price
          basePrice
          minPrice
          pricePerUnit
          unitDistance
          deliveryAddress
          values {
            price
            range
          }
          percentageCovered
        }
        discount
        usesDiscountCodes
        geolocationInfo {
          location {
            lat
            lng
          }
          polyType
          polygon {
            lat
            lng
          }
          radius
        }
        isUsingGeolocation
        maximumAmount
        minimumAmount
        name
        language
        whatsapp
        phone
        email
        ownerId
        paymentMethods {
          bankTransfer
          cash
          dataphone
          ePayCo
          mercadoPago
          stripe
          nequi
          payzen
          wompi
          paypal
          payU
          paymentez
          redeban
          rappi
          showroom
          userPercentageEC
          userPercentageMP
          userPercentageNQ
          userPercentageRB
        }
        timezone{
          value
        }
        takeOut {
          criteriaType
          label
          values
        }
        serviceTip {
          message
          type
        }
      }
  }
`;

export function checkNull(value): any | undefined {
  return value === null ? undefined : value;
}

export interface IGetCleverlynkForRender extends Partial<PFXCleverlynk> {
  skus: PFXSKU[];
}

export function fetchCleverlynkInfoForRender(data: any): IGetCleverlynkForRender {
  return {
    skus: data?.company?.skus?.items,
    cleverlynkGroup: data?.cleverlynkGroup
      ? ({
          ...checkNull(data?.cleverlynkGroup),
          image: checkNull(data?.cleverlynkGroup?.image!),
          title: checkNull(data?.cleverlynkGroup?.title!),
          cities: data?.cleverlynkGroup?.cities ?? [],
        } as PFXCleverlynkGroup)
      : undefined,
    company: {
      name: data?.company?.name ?? "",
      domain: data?.company?.domain ?? "",
      subdomain: data?.company?.subdomain ?? "",
      mails: data?.company?.mails?.map(m => m.mail) ?? [],
      phones: data?.company?.phones ?? [],
      hyperlinks: checkNull(data?.company?.hyperlinks ?? []),
      socials: checkNull(data?.company?.socials ?? []),
      logoUrl: data?.company?.logoUrl,
      primaryColor: data?.company?.primaryColor,
      id: checkNull(data?.companyId),
      bankTransferInfoURLs: data?.company?.bankTransferInfoURLs ?? [],
      userMarketingConsent: !!data?.company?.userMarketingConsent,
      termsURL: data?.company?.termsURL,
      googleAnalyticsId: data?.company?.googleAnalyticsId,
      pixelId: data?.company?.pixelId,
      crossDomainGA: data?.company?.crossDomainGA,
    },
    //@ts-ignore
    abandonedCartEmail: data?.abandonedCartEmail,
    inPerson: !!data?.inPerson,
    bankTransferImage: checkNull(data?.bankTransferImage),
    clynkHours: checkNull(data?.clynkHours),
    criteria: data?.criteria,

    deliveryPrice: checkNull(data?.deliveryPrice),
    deliveryTimes: checkNull(data?.deliveryTimes),
    discount: checkNull(data?.discount),
    email: checkNull(data?.email),
    expirationDate: checkNull(data?.expirationDate),
    geolocationInfo: checkNull(data?.geolocationInfo),
    id: checkNull(data?.id),
    isUsingDeliveryTimes: checkNull(data?.isUsingDeliveryTimes),
    isUsingGeolocation: checkNull(data?.isUsingGeolocation),
    language: checkNull(data?.language),
    minimumAmount: checkNull(data?.minimumAmount),
    zendesk: checkNull(data?.zendesk),
    name: data?.name,
    ownerId: data?.ownerId,
    paymentMethods: data?.paymentMethods,
    phone: checkNull(data?.phone),
    serviceTip: checkNull(data?.serviceTip),
    status: data?.status,
    takeOut: checkNull(data?.takeOut),
    timezone: checkNull(data?.timezone),
    usesDiscountCodes: checkNull(data?.usesDiscountCodes),
    whatsapp: checkNull(data?.whatsapp),
    taxInfo: checkNull(data?.taxInfo),
  };
}
