import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  addAddressCookie,
  addBatchAddressCookie,
  deleteAddressCookie,
  modifyAddressCookie,
  QueryState,
  selectDeliveryCookies,
  selectDeliveryCookiesAmount,
  selectDeliveryCookiesEntities,
  selectDeliveryInfo,
  toggleFavouriteCookie,
} from "../../redux/slices/mapServiceSlice";
import { v4 } from "uuid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

export interface IDeliveryAddressCookie extends QueryState {
  favourite: boolean;
  createdAt: number;
  id: string;
}

const COOKIE_NAME = "clynk-addresses";
const TEN_YEARS_FROM_NOW = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

export default function useDeliveryAddressCookies() {
  const dispatch = useDispatch();

  const cookiesToSave = useSelector(selectDeliveryCookies);
  const emptyCookies = useSelector(selectDeliveryCookiesAmount);

  const deliveryInfo = useSelector(selectDeliveryInfo);

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  const init = () => {
    const list: IDeliveryAddressCookie[] = (cookies[COOKIE_NAME] ?? { addresses: [] }).addresses;
    dispatch(addBatchAddressCookie(list));
  };

  useEffect(() => {
    setCookie(
      COOKIE_NAME,
      JSON.stringify({ addresses: cookiesToSave.filter(c => !!c.address && c.address !== "undefined") }),
      { path: "/", expires: TEN_YEARS_FROM_NOW }
    );
  }, [cookiesToSave, setCookie]);

  const handleDeleteCookie = id => {
    dispatch(deleteAddressCookie(id));
  };

  const handleAddCookie = (a?: Partial<QueryState>) => {
    const info = { ...deliveryInfo, ...a };
    if (!info) return;
    info.suggestions = [];
    const existingIdenticalCookie = cookiesToSave.find(c => c.address?.toUpperCase() === info.address?.toUpperCase());
    if (!!existingIdenticalCookie) {
      dispatch(
        modifyAddressCookie({
          ...existingIdenticalCookie,
        })
      );
    } else {
      const id = v4();
      const createdAt = new Date().getMilliseconds();
      dispatch(
        addAddressCookie({
          ...info,
          id,
          createdAt,
          favourite: false,
        } as IDeliveryAddressCookie)
      );
    }
  };

  const handleToggleFavouriteCookie = (id, favourite) => {
    dispatch(toggleFavouriteCookie({ id, favourite }));
  };

  return {
    init,
    cookies: cookiesToSave,
    handleAddCookie,
    shouldRender: !!emptyCookies,
    handleDeleteCookie,
    handleToggleFavouriteCookie,
  };
}
