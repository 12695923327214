import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IOrderState } from "../../pages/OrderSummary";
import { selectOrderState, selectOrderSummary } from "../../redux/slices/cleverlynkSlice";
import InfoSummary from "./InfoSummary";
import ProductsSummary from "./ProductsSummary";
import PendingTransferDesktop from "./PendingTransferDesktop";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface ISummaryDesktop {}

const SummaryDesktop = (props: ISummaryDesktop) => {
  const orderState = useSelector(selectOrderState);
  const orderSummary = useSelector(selectOrderSummary);

  const { t } = useTranslation("ordersummary");

  const showTransferProof = React.useMemo(
    () => orderState === IOrderState.PENDING && orderSummary?.paymentStatus === 203,
    [orderState, orderSummary?.paymentStatus]
  );

  const transferProof = React.useMemo(
    () => (
      <div>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6">{t("sentReceipt")}</Typography>
          </Grid>
          <Grid item>
            <img
              src={orderSummary?.bankTransferImage}
              style={{ borderRadius: "9px", boxShadow: "3pt 3pt 4px 0px #0000001f" }}
              height={"100%"}
              width={"100%"}
              alt="info-bancaria"
            />
          </Grid>
        </Grid>
      </div>
    ),
    [orderSummary]
  );

  const content = React.useMemo(() => {
    switch (orderState) {
      case IOrderState.TRANSFER:
        return (
          <React.Fragment>
            <PendingTransferDesktop />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <Grid
              item
              container
              direction="column"
              xs={showTransferProof ? 4 : 6}
              style={{ maxWidth: showTransferProof ? 450 : 500 }}>
              <InfoSummary />
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={showTransferProof ? 4 : 6}
              style={{ maxWidth: showTransferProof ? 450 : 500 }}>
              <Grid item>
                <Typography variant="h6">{t("products")}</Typography>
              </Grid>
              <Grid item>
                <ProductsSummary />
              </Grid>
            </Grid>
          </React.Fragment>
        );
    }
  }, [orderState, showTransferProof]);

  return (
    <div style={{ margin: "80px 5px" }}>
      <Grid container spacing={4} justifyContent="center" style={{ width: "100%", margin: 0 }}>
        {content}
        {showTransferProof && (
          <Grid item xs={4} style={{ maxWidth: showTransferProof ? 450 : 500 }}>
            {transferProof}
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default SummaryDesktop;
