import React from "react";
import { CircularProgress } from "@material-ui/core";

const Fallback = props => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}>
      <CircularProgress size={35} thickness={7} style={{ color: "#262626" }} />
    </div>
  );
};
export default Fallback;
