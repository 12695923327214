import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import Fallback from "./components/Tools/Fallback";
import awsmobile from "./aws-exports.js";
import store from "./redux/store";
import Amplify from "aws-amplify";
import { IKContext } from "imagekitio-react";
import { Provider } from "react-redux";
import CleverlynkContextProvider from "./contexts/CleverlynkContext";
import MapServiceContextProvider from "./contexts/MapServiceContext";

const App = React.lazy(() => import("./App"));
require("dotenv").config();

awsmobile.aws_appsync_authenticationType = "AWS_IAM";
awsmobile["aws_appsync_authenticationType"] = "AWS_IAM";
Amplify.configure(awsmobile);
Amplify.Auth.currentCredentials();

ReactDOM.render(
  <Router>
    <MyComponent />
  </Router>,
  document.getElementById("root")
);

function MyComponent() {
  return (
    <IKContext
      urlEndpoint={process.env.REACT_APP_IMAGEKIT}
      transformationPosition="path"
      publicKey={"public_kwp+vy0/O3POPa51TzGRmpFlivA="}>
      <Provider store={store}>
        <Router>
          <MapServiceContextProvider>
            <CleverlynkContextProvider>
              <Suspense fallback={<Fallback />}>
                <App />
              </Suspense>
            </CleverlynkContextProvider>
          </MapServiceContextProvider>
        </Router>
      </Provider>
    </IKContext>
  );
}
