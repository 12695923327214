import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMapService from "../../../hooks/MapServiceHooks/useMapService";
import useTemplateStyles from "../../../hooks/useTemplateStyles";
import { selectCleverlynkGroup, selectIsMobile } from "../../../redux/slices/cleverlynkSlice";

import { useTranslation } from "react-i18next";
import "../../../i18n/config";

interface ICityInput {}

const CitySelector = ({}: ICityInput) => {
  const isMobile = useSelector(selectIsMobile);
  const cleverlynkGroup = useSelector(selectCleverlynkGroup);

  const { t } = useTranslation("deliveryinput");

  const { handleModifyDeliveryInfo } = useMapService();

  const { primaryColor } = useTemplateStyles();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      citySelector: {
        height: isMobile ? "2.5rem" : "3.1rem",
        padding: 2,
        paddingLeft: 15,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      formControl: {
        margin: theme.spacing(1),
        width: "100%",
      },
    })
  );

  const classes = useStyles();

  const handleOnChange = event => {
    handleModifyDeliveryInfo({ city: event.target.value });
  };

  const citySelector = () => (
    <Grid item xs={4} key="city-selector-input">
      <FormControl variant="outlined" style={{ width: "100%", margin: 0 }} className={classes.formControl}>
        <InputLabel id="city-select-label">{t("cityInput.placeholder")}</InputLabel>
        <Select
          labelId="city-select-label"
          id="city-input"
          label={t("cityInput.placeholder")}
          classes={{ root: classes.citySelector }}
          onChange={(e, v) => {
            handleOnChange(e);
          }}>
          {(cleverlynkGroup?.cities ?? []).map(c => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  return citySelector();
};
export default CitySelector;
