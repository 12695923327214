import React, { useEffect, useState, useRef } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import { ILatLng, selectCleverlynk, selectIsMobile } from "../../redux/slices/cleverlynkSlice";
import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { selectDeliveryInfo } from "../../redux/slices/mapServiceSlice";
import { useSelector } from "react-redux";
import { ArrowBack, ArrowLeft, ThumbUp } from "@material-ui/icons";
import useTemplateStyles from "../../hooks/useTemplateStyles";

import { useTranslation } from "react-i18next";
import "../../i18n/config";
interface IConfirmAddressMap {
  toggleSelected: (c: ILatLng) => void;
  toggleAddress: () => void;
  bannerPosition: "TOP" | "BOTTOM";
}

const TOLERATION_AREA_M = 80;

const ConfirmAddressMap = ({ toggleSelected, toggleAddress, bannerPosition }: IConfirmAddressMap) => {
  const [canConfirm, setCanConfirm] = useState<boolean>(true);

  const deliveryInfo = useSelector(selectDeliveryInfo);
  const isMobile = useSelector(selectIsMobile);

  const { t } = useTranslation("deliveryinput");

  const { getCoordinatesArrayFromRadius, calculateDistance, handleModifyDeliveryInfo, handleCalculateRouteDistance } =
    useMapService();

  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  const marker = useRef<any>(null);
  const area = useRef<any>(null);

  const [modifiedLng, setModifiedLng] = useState(-74.0259);
  const [modifiedLat, setModifiedLat] = useState(4.746);
  const block = useRef<number>(4);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      confirmBanner: {
        position: bannerPosition === "TOP" || !isMobile ? "absolute" : "relative",
        marginTop: bannerPosition === "TOP" || !isMobile ? "" : 10,
        top: bannerPosition === "TOP" || !isMobile ? 10 : isMobile ? 0 : "",
        left: 0,
        width: "inherit",
        zIndex: 100,
      },
      confirmBannerGridWrapper: {
        color: "#fff",
        minHeight: 60,
      },
      confirmBannerBackButton: {
        padding: 0,
        fontSize: "0.8rem",
        color: "white",
        height: "100%",
        width: "100%",
        borderRadius: 0,
      },
      confirmBannerBackButtonGrid: {
        transition: "background-color 400ms",
        boxShadow: "1pt 1pt 2px #828282",
        height: "100%",
        backgroundColor: canConfirm ? "#54e8b9" : "#00ce91",
        borderRadius: "15px 0px 0px 15px",
        maxWidth: 80,
      },
      confirmBannerOkButton: {
        transition: "background-color 400ms",
        backgroundColor: canConfirm ? "#00ce91" : "#a1ffdf5c",
        borderColor: "transparent !important",
        color: "white",
        boxShadow: "1pt 1pt 2px #828282",
        height: "100%",
        width: "100%",
        borderRadius: "0px 15px 15px 0px",
        "&:hover": {
          backgroundColor: "#06c38b",
        },
      },
      confirmTitle: {
        textTransform: "none",
        fontSize: isMobile ? "1.05rem" : "1.25rem",
      },
    })
  );
  const classes = useStyles();

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;

  const canModifyHere = () => {
    const diff = calculateDistance(deliveryInfo?.coordinates!, { lat: modifiedLat, lng: modifiedLng });
    setCanConfirm(diff <= TOLERATION_AREA_M);
  };

  const handleToggleSelected = () => {
    toggleSelected({ lng: modifiedLng, lat: modifiedLat });
    handleCalculateRouteDistance([modifiedLng, modifiedLat]);
    handleModifyDeliveryInfo({
      coordinates: { lat: modifiedLat, lng: modifiedLng },
    });
  };

  useEffect(() => {
    setModifiedLat(deliveryInfo?.coordinates?.lat!);
    setModifiedLng(deliveryInfo?.coordinates?.lng!);
  }, [deliveryInfo]);

  useEffect(() => {
    return () => map.current.remove();
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/gregorioospina/ckwehc3p40dst14nl5sjwnyxl",
      center: [deliveryInfo?.coordinates?.lng, deliveryInfo?.coordinates?.lat],
      zoom: 17,
    });
    marker.current = new mapboxgl.Marker({
      color: "#21c387",
      draggable: false,
    });
  });

  useEffect(() => {
    if (!map.current) return;
    // wait for map to initialize
    else {
      map.current!.on("move", () => {
        setModifiedLng(map.current.getCenter().lng.toFixed(4));
        setModifiedLat(map.current.getCenter().lat.toFixed(4));
      });

      if (block.current > 1) {
        map.current.on("dragend", canModifyHere);
        map.current.on("zoomend", canModifyHere);
      }
      block.current = block.current + 1;
    }
    if (!marker.current) return;
    else marker.current.setLngLat([map.current.getCenter().lng, map.current.getCenter().lat]).addTo(map.current);

    if (area.current) return;
    else {
      area.current = true;
      map.current!.on("load", async () => {
        const coordinates = getCoordinatesArrayFromRadius(deliveryInfo?.coordinates!, TOLERATION_AREA_M);
        await map.current!.addSource(`source-coverage`, {
          type: "geojson",
          data: {
            promoteId: "este",
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates,
            },
          },
        });
        await map.current!.addLayer({
          id: `layer-coverage`,
          type: "fill",
          source: `source-coverage`,
          paint: {
            "fill-color": "#91f5de",
            "fill-opacity": 0.25,
          },
        });
      });
    }
  });

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        ref={mapContainer}
        style={{
          height: isMobile ? window.innerHeight * 0.45 : 500,
          maxHeight: window.innerHeight * 0.45,
          width: "inherit",
          borderRadius: isMobile ? 15 : 5,
        }}></div>
      <div className={classes.confirmBanner}>
        <Grid
          container
          className={classes.confirmBannerGridWrapper}
          style={{ position: "relative", height: 60 }}
          justifyContent="center"
          alignContent="center">
          <Grid
            className={classes.confirmBannerBackButtonGrid}
            item
            xs={2}
            container
            justifyContent="center"
            alignContent="center">
            <IconButton className={classes.confirmBannerBackButton} onClick={toggleAddress}>
              <ArrowBack />
            </IconButton>
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
          {/* <Grid item xs={7} container justifyContent="center"></Grid> */}
          <Grid item xs={10} style={{ maxWidth: 500 }}>
            <Button
              variant="outlined"
              className={classes.confirmBannerOkButton}
              disabled={!canConfirm}
              onClick={() => handleToggleSelected()}>
              <Typography variant="h6" className={classes.confirmTitle} id="confirm-address-map">
                {t("confirmAddressMap.confirm")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ConfirmAddressMap;
