export interface PFXACCredsRes {
  __typename?: string;
  sendEmail?: boolean;
}

export interface PFXACInfo {
  __typename?: string;
  sendEmail?: boolean;
}

export interface PFXALCredsRes {
  __typename?: string;
  alegraId?: string;
  hasAL?: boolean;
}

export interface PFXAddressLatLng {
  __typename?: string;
  address?: string;
  lat?: number;
  lon?: number;
}

export interface PFXAlegra {
  __typename?: string;
  address?: string;
  alegraId?: string;
  businessName?: string;
  city?: string;
  country?: string;
  department?: string;
  email?: string;
  firstName?: string;
  greatContributor?: boolean;
  identificationNumber?: string;
  identificationType?: PFXIdentificationTypeAlegra;
  kindOfPerson?: PFXKindOfPersonAlegra;
  lastName?: string;
  nationalConsmptionTax?: boolean;
  notResponsibleForConsumption?: boolean;
  notVatResponsible?: boolean;
  ordinaryRegime?: boolean;
  phonePrimary?: string;
  selfRetainer?: boolean;
  specialRegime?: boolean;
  vatSalesTax?: boolean;
  withholdingAgent?: boolean;
  zipCode?: string;
}

export interface PFXAlegraCurrency {
  __typename?: string;
  code?: string;
  exchangeRate?: number;
  symbol?: string;
}

export interface PFXAnnouncement {
  __typename?: string;
  actionMessage?: string;
  actionPath?: string;
  id?: string;
  message?: string;
  urgency?: number;
}

export interface PFXBTCredsRes {
  __typename?: string;
  hasBT?: boolean;
  urls?: string[];
}

export interface PFXBTInfo {
  __typename?: string;
  urls?: string[];
}

export interface PFXBackgroundStyles {
  __typename?: string;
  backgroundColor?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundUrl?: string;
  useBackgroundImage?: boolean;
}

export interface PFXBanner {
  __typename?: string;
  backgroundColor?: string;
  desktopImage?: string;
  desktopSize?: PFXBannerSize;
  footer?: string;
  id?: string;
  image?: string;
  imageMobileXCut?: number;
  mobileImage?: string;
  mobileSize?: PFXBannerSize;
  notEraseable?: boolean;
  opaqueBackground?: boolean;
  size?: PFXBannerSize;
  subtitle?: string;
  text?: string;
  textAlign?: PFXTextAlignEnum;
  textColor?: string;
  textFontFamily?: string;
  textVerticalAlign?: PFXTextVerticalAlignEnum;
  title?: string;
  type?: string;
}

export interface PFXBarGraph {
  __typename?: string;
  name?: string;
  value?: number;
}

export interface PFXBasicComponent {
  __typename?: string;
  arrangement?: PFXBasicBasicArrangementElement[];
  backgroundColor?: string;
  backgroundImage?: string;
  body?: PFXTextAttributes;
  button?: PFXButtonAttributes;
  heightMultiplier?: number;
  images?: PFXBasicImage[];
  margins?: PFXMarginMultipliers;
  opaqueBackground?: PFXOpaqueBackgroundProperties;
  title?: PFXTextAttributes;
  verticalAlignment?: string;
  videoUrl?: string;
}

export interface PFXBasicImage {
  __typename?: string;
  cols?: number;
  url?: string;
}

export interface PFXBillingDashboardData {
  __typename?: string;
  amountOrders?: number;
  amountOrdersInPerson?: number;
  chargeableMonths?: number;
  currencyCode?: string;
  currentPlan?: PFXPlan;
  dayToPay?: number;
  lastInvoiceDate?: string;
  nextMonthPlan?: PFXPlan;
  salesOrders?: number;
  salesOrdersInPerson?: number;
}

export interface PFXBillingInfo {
  __typename?: string;
  alegra?: PFXAlegra;
  cancelDate?: string;
  companyId?: string;
  createdAt?: string;
  currency?: PFXAlegraCurrency;
  currentPS?: PFXWompiPS;
  currentPlan?: PFXPlan;
  dayToPay?: number;
  draft?: boolean;
  ignore?: string;
  lastInvoiceDate?: string;
  launchDate?: string;
  nextMonthPS?: PFXWompiPS;
  nextMonthPlan?: PFXPlan;
  updatedAt?: string;
  wompiCard?: PFXWompiCardPSInfo;
  wompiNequi?: PFXWompiNequiPSInfo;
}

export interface PFXButtonAttributes {
  __typename?: string;
  backgroundColor?: string;
  borderRadius?: number;
  sizeMultiplier?: number;
  text?: PFXTextAttributes;
  url?: string;
}

export interface PFXCardStyles {
  __typename?: string;
  borderRadius?: string;
  buttonColor?: string;
  cardColor?: string;
  cardsAlignment?: string;
  largeCardFontSize?: number;
  secondColor?: string;
  shadow?: string;
  shadowSize?: string;
  textButtonColor?: string;
}

export interface PFXCart {
  __typename?: string;
  bankTransferImage?: string;
  check?: boolean;
  cleverlynk?: PFXCleverlynk;
  cleverlynkId?: string;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  deliveryInfo?: PFXDeliveryInfo;
  extras?: any;
  fulfillmentType?: PFXOrderFulfillmentType;
  googleClientId?: string;
  hidden?: boolean;
  history?: PFXOrderHistory[];
  id?: string;
  identification?: string;
  items?: PFXItemForOrder[];
  mandatoryCriteria?: PFXCriteriaForOrder[];
  mattersForSubscription?: boolean;
  newOrder?: boolean;
  optionalCriteria?: PFXCriteriaForOrder[];
  orderStatusDetail?: number;
  paymentMethod?: string;
  paymentStatus?: number;
  price?: PFXPrice;
  shouldSendEmail?: boolean;
  status?: number;
  updatedAt?: string;
  userMarketingConsent?: boolean;
}

export interface PFXCatalogResponse {
  __typename?: string;
  categories?: PFXCategory[];
  templateId?: string;
  templateName?: string;
}

export interface PFXCategory {
  __typename?: string;
  id?: string;
  items?: PFXItem[];
  itemsAmount?: number;
  name?: string;
}

export interface PFXCleverlynk {
  __typename?: string;
  abandonedCartEmail?: boolean;
  amountOrders?: number;
  amountRenders?: number;
  amountSales?: number;
  bankTransferImage?: string;
  cleverlynkGroup?: PFXCleverlynkGroup;
  cleverlynkGroupId?: string;
  clynkHours?: string[][];
  company?: PFXCompany;
  companyId?: string;
  country?: PFXClynkCountry;
  createdAt?: string;
  criteria?: PFXCriteria[];
  deliveryDays?: PFXDeliveryDays;
  deliveryMethods?: PFXDeliveryMethods;
  deliveryPrice?: PFXDeliveryPrice;
  deliveryTimes?: PFXDeliveryTimes;
  discount?: number;
  discountCodes?: PFXDiscountCode[];
  domiprimeSiteId?: string;
  email?: string;
  expirationDate?: string;
  geolocationInfo?: PFXGeolocationInfo;
  hubserActive?: boolean;
  hubsterActive?: boolean;
  id?: string;
  inPerson?: boolean;
  isUsingDeliveryTimes?: boolean;
  isUsingGeolocation?: boolean;
  landingPage?: PFXLandingPage;
  landingPageId?: string;
  language?: PFXClynkLanguage;
  lastView?: string;
  maxOrders?: number;
  maxRenders?: number;
  maximumAmount?: number;
  minimumAmount?: number;
  muStoreId?: string;
  name?: string;
  newOrdersAmount?: number;
  ofCityCode?: number;
  orders?: PFXModelOrderConnection;
  ordersByDateRange?: PFXOrdersByDateRangeResponse;
  outOfBounds?: PFXModelOutOfBoundsConnection;
  owner?: PFXUser;
  ownerId?: string;
  paymentMethods?: PFXPaymentMethods;
  phone?: string;
  pinHash?: string;
  promoCodes?: PFXModelCleverlynkPromoCodeConnection;
  rappiStoreId?: string;
  renderInfo?: PFXRenderInfo;
  salt?: string;
  sendMessage?: string;
  serviceTip?: PFXServiceTip;
  status?: PFXStatus;
  tags?: string[];
  takeOut?: PFXTakeOut;
  taxInfo?: PFXTaxInfo;
  template?: PFXTemplate;
  templateId?: string;
  timezone?: PFXTimezoneSelection;
  tip?: string;
  updatedAt?: string;
  users?: PFXModelUserCleverlynkConnection;
  usesDiscountCodes?: boolean;
  whatsapp?: string;
  yapayaOfficeId?: number;
  zendesk?: string;
}

export interface PFXCleverlynkAnalytics {
  __typename?: string;
  average?: number;
  cleverlynksByCompany?: PFXBarGraph[];
  max?: number;
}

export interface PFXCleverlynkGroup {
  __typename?: string;
  cities?: string[];
  cleverlynks?: PFXModelCleverlynkConnection;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  id?: string;
  image?: string;
  landingPage?: PFXLandingPage;
  landingPageId?: string;
  mandatory?: boolean;
  name?: string;
  openListFirst?: boolean;
  title?: string;
  type?: PFXCleverlynkGroupType;
  unifiedLandingPage?: boolean;
  updatedAt?: string;
}

export interface PFXCleverlynkPromoCode {
  __typename?: string;
  cleverlynk?: PFXCleverlynk;
  cleverlynkId?: string;
  createdAt?: string;
  id?: string;
  promoCode?: PFXPromoCode;
  promoCodeId?: string;
  updatedAt?: string;
}

export interface PFXCommission {
  __typename?: string;
  type?: PFXCommissionType;
  value?: number;
}

export interface PFXCompany {
  __typename?: string;
  URLParam?: string;
  abandonedCartEmail?: boolean;
  activeIntegrations?: PFXIntegrations;
  bankTransferInfoURL?: string;
  bankTransferInfoURLs?: string[];
  cleverlynkFee?: number;
  cleverlynkGroups?: PFXModelCleverlynkGroupConnection;
  cleverlynks?: PFXModelCleverlynkConnection;
  contacts?: PFXModelContactConnection;
  createdAt?: string;
  crossDomainGA?: string;
  customerCoupons?: PFXModelCustomerCouponConnection;
  defaultCleverlynkId?: string;
  defaultTax?: PFXTaxInfo;
  defaultTemplateStyles?: PFXTemplateStylesStructure;
  domain?: string;
  facebookDomainVerification?: string;
  googleAdsId?: string;
  googleAnalyticsId?: string;
  googleTagManagerId?: string;
  hiddenColumns?: string[];
  hyperlinks?: PFXHyperlinks[];
  id?: string;
  items?: PFXModelItemConnection;
  logoUrl?: string;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority;
  mails?: PFXCompanyMail[];
  maxUsers?: number;
  name?: string;
  nequiURL?: string;
  newGoogleAnalyticsId?: string;
  notificationSubscriptions?: any;
  orderStatusNotificationPolicy?: PFXOrderStatusNotificationPolicy[];
  orders?: PFXModelOrderConnection;
  owner?: PFXUser;
  ownerId?: string;
  phones?: string[];
  pixelId?: string;
  primaryColor?: string;
  promoCodes?: PFXModelPromoCodeConnection;
  skus?: PFXModelSKUConnection;
  socials?: PFXSocials[];
  statusMailPolicies?: PFXStatusMailPolicy[];
  subItems?: PFXModelSubItemConnection;
  subdomain?: string;
  templateStyles?: PFXModelTemplateStylesConnection;
  templates?: PFXModelTemplateConnection;
  termsURL?: string;
  tsvGenerated?: boolean;
  updatedAt?: string;
  userMarketingConsent?: boolean;
  users?: PFXModelUserConnection;
  wildcardAliases?: string[];
}

export interface PFXCompanyConfigs {
  __typename?: string;
  bankTransferInfoURLs?: string[];
  defaultTemplateStyles?: PFXTemplateStylesStructure;
  hiddenColumns?: string[];
  mailSubscriptionPriority?: PFXMailSubscriptionPriority;
  notificationSubscriptions?: any;
  orderStatusNotificationPolicy?: PFXOrderStatusNotificationPolicy[];
  statusMailPolicies?: PFXStatusMailPolicy[];
  userMarketingConsent?: boolean;
  wildcardAliases?: string[];
}

export interface PFXCompanyData {
  __typename?: string;
  createdAt?: string;
  defaultCleverlynkId?: string;
  domain?: string;
  hyperlinks?: PFXHyperlinks[];
  logoUrl?: string;
  mails?: PFXCompanyMail[];
  name?: string;
  phones?: string[];
  primaryColor?: string;
  socials?: PFXSocials[];
  subdomain?: string;
  taxInfo?: PFXTaxInfo;
  termsURL?: string;
}

export interface PFXCompanyMail {
  __typename?: string;
  mail?: string;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority;
}

export interface PFXContact {
  __typename?: string;
  address?: string;
  company?: PFXCompany;
  companyConnectionId?: string;
  companyId?: string;
  createdAt?: string;
  email?: string;
  failedOrders?: number;
  lastName?: string;
  lastPurchase?: string;
  name?: string;
  phone?: string;
  successfulOrders?: number;
  totalPurchased?: number;
  updatedAt?: string;
  userMarketingConsent?: boolean;
  wildcards?: any;
}

export interface PFXConversionRateInfo {
  __typename?: string;
  conversionRate?: number;
  totalOrders?: number;
  totalRenders?: number;
}

export interface PFXCouponRestriction {
  __typename?: string;
  beginDate?: string;
  endDate?: string;
  type?: PFXCouponRestrictionType;
}

export interface PFXCredentialsResponse {
  __typename?: string;
  AC?: PFXACCredsRes;
  AL?: PFXALCredsRes;
  BT?: PFXBTCredsRes;
  DP?: PFXDPCredsRes;
  EC?: PFXECCredsRes;
  EK?: PFXEKCredsRes;
  FB?: PFXFBCredsRes;
  GA?: PFXGACredsRes;
  GTM?: PFXGTMCredsRes;
  GV?: PFXGVCredsRes;
  IG?: PFXIGCredsRes;
  MP?: PFXMPCredsRes;
  MU?: PFXMUCredsRes;
  NQ?: PFXNQCredsRes;
  OF?: PFXOFCredsRes;
  PI?: PFXPICredsRes;
  PPL?: PFXPPLCredsRes;
  PT?: PFXPTCredsRes;
  PU?: PFXPUCredsRes;
  PZ?: PFXPZCredsRes;
  RA?: PFXRACredsRes;
  RB?: PFXRBCredsRes;
  RP?: PFXRPCredsRes;
  RPI?: PFXRPICredsRes;
  ST?: PFXSTCredsRes;
  WP?: PFXWPCredsRes;
  YPY?: PFXYPYCredsRes;
}

export interface PFXCriteria {
  __typename?: string;
  addition?: boolean;
  characterLimit?: number;
  criteriaType?: PFXCriteriaType;
  deliveryTypeExclusiveness?: PFXDeliveryTypeExclusiveness;
  label?: string;
  mandatory?: boolean;
  values?: string[];
}

export interface PFXCriteriaForOrder {
  __typename?: string;
  criteriaType?: PFXCriteriaType;
  deliveryTypeExclusiveness?: PFXDeliveryTypeExclusiveness;
  label?: string;
  value?: string;
}

export interface PFXCustomerCoupon {
  __typename?: string;
  companyId?: string;
  createdAt?: string;
  email?: string;
  id?: string;
  name?: string;
  restrictions?: PFXCouponRestriction[];
  updatedAt?: string;
  value?: number;
}

export interface PFXCustomerFormInfo {
  __typename?: string;
  createdAt?: string;
  email?: string;
  mandatory?: any;
  mandatoryData?: PFXCriteriaForOrder[];
  optional?: any;
  optionalData?: PFXCriteriaForOrder[];
  updatedAt?: string;
  consent?: boolean;
}

export interface PFXCustomerPromoCode {
  __typename?: string;
  createdAt?: string;
  email?: string;
  id?: string;
  promoCode?: PFXPromoCode;
  promoCodeId?: string;
  updatedAt?: string;
  uses?: number;
}

export interface PFXDLInfo {
  __typename?: string;
  activeCatalog?: boolean;
  activeOrders?: boolean;
  channelLinkId?: string;
  hasDeliverect?: boolean;
}

export interface PFXDPCredsRes {
  __typename?: string;
  hasDP?: boolean;
  nit?: string;
}

export interface PFXDPInfo {
  __typename?: string;
  nit?: string;
}

export interface PFXDeliveryDays {
  __typename?: string;
  delay?: number;
  delivery?: string[];
  ignoreDeliveryDays?: string[];
  selectedDeliveryDays?: string[];
  startDelivery?: string[];
  timeFrame?: number;
  weekdays?: boolean[];
}

export interface PFXDeliveryInfo {
  __typename?: string;
  deliveryAdditionalInfo?: string;
  deliveryAddress?: string;
  deliveryCity?: string;
  deliveryDate?: string;
  deliveryHourRange?: string[];
  deliveryLocation?: PFXLocation;
  deliveryPropertyTypeInfo?: string;
  deliveryTime?: string;
}

export interface PFXDeliveryMethods {
  __typename?: string;
  mensajerosUrbanos?: boolean;
  picap?: boolean;
}

export interface PFXDeliveryPersonInfo {
  __typename?: string;
  idNumber?: string;
  location?: PFXLocation;
  name?: string;
  phone?: string;
  photoURL?: string;
  plates?: string;
}

export interface PFXDeliveryPrice {
  __typename?: string;
  basePrice?: number;
  deliveryAddress?: string;
  minPrice?: number;
  percentageCovered?: number;
  price?: number;
  pricePerUnit?: number;
  type?: PFXDeliveryPriceType;
  unitDistance?: number;
  values?: PFXRangePrice[];
}

export interface PFXDeliveryTimes {
  __typename?: string;
  immediateDeliveryRange?: number[];
  openHours?: string[][];
  scheduledDelivery?: PFXScheduledDelivery;
}

export interface PFXDiscountCode {
  __typename?: string;
  code?: string;
  minPrice?: number;
  type?: PFXDiscountCodeType;
  value?: number;
}

export interface PFXECCredsRes {
  __typename?: string;
  ECClient?: string;
  ECFee?: number;
  ECPKey?: string;
  ECPrivateKey?: string;
  ECPublicKey?: string;
  card?: boolean;
  hasEC?: boolean;
  pse?: boolean;
}

export interface PFXECInfo {
  __typename?: string;
  card?: boolean;
  client?: string;
  fee?: number;
  pKey?: string;
  privateKey?: string;
  pse?: boolean;
  publicKey?: string;
}

export interface PFXEKCredsRes {
  __typename?: string;
  apiToken?: string;
  companyCode?: string;
  hasEK?: boolean;
}

export interface PFXEKInfo {
  __typename?: string;
  apiToken?: string;
  companyCode?: string;
}

export interface PFXElementIdWithTemplateReference {
  __typename?: string;
  elementId?: string;
  templateId?: string;
}

export interface PFXExternalChannel {
  __typename?: string;
  companyId?: string;
  createdAt?: string;
  rappi?: PFXRPExternalInfo;
  updatedAt?: string;
}

export interface PFXFBCredsRes {
  __typename?: string;
  accessToken?: string;
  hasFB?: boolean;
  pixelId?: string;
}

export interface PFXFBInfo {
  __typename?: string;
  accessToken?: string;
  pixelId?: string;
}

export interface PFXFetchContactsResponse {
  __typename?: string;
  items?: PFXContact[];
  nextToken?: string;
}

export interface PFXFetchPromoCodesResponse {
  __typename?: string;
  items?: PFXPromoCodeResponse[];
  nextToken?: string;
}

export interface PFXFetchTemplatesResponse {
  __typename?: string;
  items?: PFXTemplate[];
  nextToken?: string;
}

export interface PFXFooterComponent {
  __typename?: string;
  alignment?: string;
  backgroundColor?: string;
  color?: string;
  hidden?: boolean;
}

export interface PFXFooterStyles {
  __typename?: string;
  alignment?: string;
  backgroundColor?: string;
  textColor?: string;
}

export interface PFXGACredsRes {
  __typename?: string;
  crossDomainGA?: string;
  googleAnalyticsId?: string;
  hasGA?: boolean;
}

export interface PFXGAInfo {
  __typename?: string;
  crossDomainGA?: string;
  googleAnalyticsId?: string;
}

export interface PFXGTMCredsRes {
  __typename?: string;
  googleTagManagerId?: string;
  hasGTM?: boolean;
}

export interface PFXGTMInfo {
  __typename?: string;
  googleTagManagerId?: string;
}

export interface PFXGVCredsRes {
  __typename?: string;
  googleCodeVerification?: string;
  hasGV?: boolean;
}

export interface PFXGVInfo {
  __typename?: string;
  googleCodeVerification?: string;
}

export interface PFXGenericResponse {
  __typename?: string;
  message?: string;
  success?: boolean;
}

export interface PFXGeolocationInfo {
  __typename?: string;
  location?: PFXLocation;
  polyType?: string;
  polygon?: PFXLocation[];
  radius?: number;
}

export interface PFXGeolocationInfoUser {
  __typename?: string;
  info?: PFXGeolocationInfo;
  minPrice?: number;
  pricePerUnit?: number;
  unitDistance?: number;
}

export interface PFXHBInfo {
  __typename?: string;
  activeOrders?: boolean;
  hasHubster?: boolean;
}

export interface PFXHyperlinks {
  __typename?: string;
  name?: string;
  url?: string;
}

export interface PFXIGCredsRes {
  __typename?: string;
  facebookDomainVerification?: string;
  hasIG?: boolean;
  tsvGenerated?: boolean;
}

export interface PFXIGInfo {
  __typename?: string;
  facebookDomainVerification?: string;
  tsvGenerated?: boolean;
}

export interface PFXIPInfo {
  __typename?: string;
  activeOrders?: boolean;
  apiKey?: string;
  deliveryProductId?: string;
  hasIntuiPOS?: boolean;
  storeId?: string;
  storeId2?: string;
  storeName?: string;
}

export interface PFXIncomeOrigin {
  __typename?: string;
  incomeOriginData?: PFXIncomeOriginData[];
}

export interface PFXIncomeOriginData {
  __typename?: string;
  name?: string;
  value?: number;
}

export interface PFXIntegrations {
  __typename?: string;
  deliverect?: boolean;
  hubster?: boolean;
  intuipos?: boolean;
  pirpos?: boolean;
  pirposRappi?: boolean;
  toteat?: boolean;
}

export interface PFXIntuiPOSResponse {
  __typename?: string;
  items?: PFXItemIntuiPOSResponse[];
}

export interface PFXInvoiceInfo {
  __typename?: string;
  amount?: number;
  companyId?: string;
  companyName?: string;
  createdAt?: string;
  date?: string;
  endInvoiceDate?: string;
  invoiceId?: string;
  invoiceName?: string;
  items?: PFXInvoiceItems;
  paid?: number;
  startInvoiceDate?: string;
  updatedAt?: string;
}

export interface PFXInvoiceItems {
  __typename?: string;
  amountOrders?: number;
  amountOrdersInPerson?: number;
  chargeableMonths?: number;
  fixedOrder?: number;
  fixedOrderVAT?: number;
  fixedSubscription?: number;
  fixedSubscriptionVAT?: number;
  implementation?: number;
  implementationVAT?: number;
  percentageOrder?: number;
  percentageOrderInPerson?: number;
  percentageOrderInPersonVAT?: number;
  percentageOrderVAT?: number;
  salesOrders?: number;
  salesOrdersInPerson?: number;
}

export interface PFXItem {
  __typename?: string;
  attachment?: string;
  beginDayHour?: string;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  decorative?: boolean;
  deliveryDelay?: number;
  description?: string;
  discount?: PFXItemDiscount;
  gid?: string;
  hidden?: boolean;
  id?: string;
  imagePlaceholder?: string;
  images?: string[];
  maxAmountPerInstance?: number;
  maxOrderHour?: string;
  name?: string;
  new?: boolean;
  posReference?: string;
  price?: number;
  priceWithDiscount?: number;
  sku?: string;
  soldOut?: boolean;
  subitems?: PFXSubItemData[];
  templates?: PFXModelItemTemplateConnection;
  updatedAt?: string;
  upsellingId?: string;
}

export interface PFXItemDiscount {
  __typename?: string;
  amount?: number;
  type?: string;
}

export interface PFXItemForOrder {
  __typename?: string;
  chosenOptions?: PFXSubItemChosenOption[];
  deliveryDelay?: number;
  frankieReference?: string;
  id?: string;
  images?: string[];
  maxAmountPerInstance?: number;
  maxOrderHour?: string;
  name?: string;
  og_price?: number;
  posReference?: string;
  price?: number;
  quantity?: number;
  sku?: string;
  subitems?: PFXSubItemData[];
}

export interface PFXItemFromTemplate {
  __typename?: string;
  item?: PFXItem;
  templateId?: string;
}

export interface PFXItemIntuiPOSResponse {
  __typename?: string;
  imageUrl?: string;
  name?: string;
  nameInIntuipos?: string;
  subitems?: PFXSubitemIntuiPOSResponse[];
}

export interface PFXItemTemplate {
  __typename?: string;
  companyId?: string;
  createdAt?: string;
  id?: string;
  item?: PFXItem;
  itemId?: string;
  template?: PFXTemplate;
  templateId?: string;
  updatedAt?: string;
}

export interface PFXItemUpdateRes {
  __typename?: string;
  Item?: PFXItem;
  failedTemplates?: string[];
}

export interface PFXItemsFromTemplate {
  __typename?: string;
  items?: PFXItemFromTemplate[];
  templateId?: string;
  templateName?: string;
}

export interface PFXItemsInCleverlynk {
  __typename?: string;
  available?: PFXItemForOrder[];
  unavailable?: PFXItemForOrder[];
}

export interface PFXLandingComponent {
  __typename?: string;
  data?: any;
  hidden?: boolean;
  id?: string;
  subtype?: PFXLandingSubtype;
  type?: PFXComponentType;
}

export interface PFXLandingPage {
  __typename?: string;
  active?: boolean;
  createdAt?: string;
  id?: string;
  pages?: PFXPage[];
  updatedAt?: string;
}

export interface PFXLandingPageResponse {
  __typename?: string;
  active?: boolean;
  createdAt?: string;
  groupCategories?: PFXCategory[];
  groupItems?: PFXItem[];
  id?: string;
  pages?: PFXPage[];
  updatedAt?: string;
}

export interface PFXLastEvalutedKeyIndex {
  __typename?: string;
  id?: string;
}

export interface PFXLocation {
  __typename?: string;
  lat?: number;
  lng?: number;
}

export interface PFXLogisticsInfo {
  __typename?: string;
  companyId?: string;
  createdAt?: string;
  domiprime?: PFXDPInfo;
  mensajerosUrbanos?: PFXMUInfo;
  oficios?: PFXOFInfo;
  picap?: PFXPIInfo;
  rapiboy?: PFXRAInfo;
  updatedAt?: string;
  yapaya?: PFXYPYInfo;
}

export interface PFXLogoAttributes {
  __typename?: string;
  activate?: boolean;
  alignment?: string;
  shape?: PFXLogoShape;
  sizeMultiplier?: number;
}

export interface PFXMPCredsRes {
  __typename?: string;
  card?: boolean;
  fixedCommission?: number;
  hasMP?: boolean;
  pse?: boolean;
  variableCommission?: number;
}

export interface PFXMPInfo {
  __typename?: string;
  accessToken?: string;
  card?: boolean;
  fixedCommission?: number;
  pse?: boolean;
  refreshToken?: string;
  refreshedAt?: string;
  variableCommission?: number;
}

export interface PFXMPSaveResponse {
  __typename?: string;
  message?: string;
  success?: boolean;
}

export interface PFXMUCredsRes {
  __typename?: string;
  clientId?: string;
  clientSecretKey?: string;
  extraCost?: number;
  hasFullMU?: boolean;
  hasMU?: boolean;
  idCompany?: number;
  idUser?: string;
  stores?: PFXMUStore[];
}

export interface PFXMUInfo {
  __typename?: string;
  clientId?: string;
  clientSecretKey?: string;
  extraCost?: number;
  idCompany?: number;
  idUser?: number;
  stores?: PFXMUStore[];
}

export interface PFXMUStore {
  __typename?: string;
  storeAddress?: string;
  storeCity?: PFXMUCity;
  storeId?: string;
  storeName?: string;
  storeParkingCost?: number;
  storePhone?: string;
  storeSchedule?: string;
}

export interface PFXMainComponent {
  __typename?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  button?: PFXButtonAttributes;
  logo?: PFXLogoAttributes;
  margins?: PFXMarginMultipliers;
  opaqueBackground?: PFXOpaqueBackgroundProperties;
  title?: PFXTextAttributes;
  type?: PFXMainComponentType;
  verticalAlignment?: string;
}

export interface PFXMarginMultipliers {
  __typename?: string;
  x?: number;
  y?: number;
}

export interface PFXMarketingInfo {
  __typename?: string;
  abandonedCart?: PFXACInfo;
  companyId?: string;
  createdAt?: string;
  ekkofy?: PFXEKInfo;
  facebookPixel?: PFXFBInfo;
  googleAnalytics?: PFXGAInfo;
  googleTagManager?: PFXGTMInfo;
  googleVerification?: PFXGVInfo;
  instagram?: PFXIGInfo;
  updatedAt?: string;
}

export interface PFXModelBillingInfoConnection {
  __typename?: string;
  items?: PFXBillingInfo[];
  nextToken?: string;
}

export interface PFXModelCartConnection {
  __typename?: string;
  items?: PFXCart[];
  nextToken?: string;
}

export interface PFXModelCleverlynkConnection {
  __typename?: string;
  items?: PFXCleverlynk[];
  nextToken?: string;
}

export interface PFXModelCleverlynkGroupConnection {
  __typename?: string;
  items?: PFXCleverlynkGroup[];
  nextToken?: string;
}

export interface PFXModelCleverlynkPromoCodeConnection {
  __typename?: string;
  items?: PFXCleverlynkPromoCode[];
  nextToken?: string;
}

export interface PFXModelCompanyConnection {
  __typename?: string;
  items?: PFXCompany[];
  nextToken?: string;
}

export interface PFXModelContactConnection {
  __typename?: string;
  items?: PFXContact[];
  nextToken?: string;
}

export interface PFXModelCustomerCouponConnection {
  __typename?: string;
  items?: PFXCustomerCoupon[];
  nextToken?: string;
}

export interface PFXModelCustomerFormInfoConnection {
  __typename?: string;
  items?: PFXCustomerFormInfo[];
  nextToken?: string;
}

export interface PFXModelCustomerPromoCodeConnection {
  __typename?: string;
  items?: PFXCustomerPromoCode[];
  nextToken?: string;
}

export interface PFXModelExternalChannelConnection {
  __typename?: string;
  items?: PFXExternalChannel[];
  nextToken?: string;
}

export interface PFXModelInvoiceInfoConnection {
  __typename?: string;
  items?: PFXInvoiceInfo[];
  nextToken?: string;
}

export interface PFXModelItemConnection {
  __typename?: string;
  items?: PFXItem[];
  nextToken?: string;
}

export interface PFXModelItemTemplateConnection {
  __typename?: string;
  items?: PFXItemTemplate[];
  nextToken?: string;
}

export interface PFXModelLandingPageConnection {
  __typename?: string;
  items?: PFXLandingPage[];
  nextToken?: string;
}

export interface PFXModelLogisticsInfoConnection {
  __typename?: string;
  items?: PFXLogisticsInfo[];
  nextToken?: string;
}

export interface PFXModelMarketingInfoConnection {
  __typename?: string;
  items?: PFXMarketingInfo[];
  nextToken?: string;
}

export interface PFXModelOrderConnection {
  __typename?: string;
  items?: PFXOrder[];
  nextToken?: string;
}

export interface PFXModelOutOfBoundsConnection {
  __typename?: string;
  items?: PFXOutOfBounds[];
  nextToken?: string;
}

export interface PFXModelPOSInfoConnection {
  __typename?: string;
  items?: PFXPOSInfo[];
  nextToken?: string;
}

export interface PFXModelPaymentsInfoConnection {
  __typename?: string;
  items?: PFXPaymentsInfo[];
  nextToken?: string;
}

export interface PFXModelPotentialUserConnection {
  __typename?: string;
  items?: PFXPotentialUser[];
  nextToken?: string;
}

export interface PFXModelPromoCodeConnection {
  __typename?: string;
  items?: PFXPromoCode[];
  nextToken?: string;
}

export interface PFXModelPushNotificationsConnection {
  __typename?: string;
  items?: PFXPushNotifications[];
  nextToken?: string;
}

export interface PFXModelSKUConnection {
  __typename?: string;
  items?: PFXSKU[];
  nextToken?: string;
}

export interface PFXModelSubItemConnection {
  __typename?: string;
  items?: PFXSubItem[];
  nextToken?: string;
}

export interface PFXModelSubItemTemplateConnection {
  __typename?: string;
  items?: PFXSubItemTemplate[];
  nextToken?: string;
}

export interface PFXModelTemplateConnection {
  __typename?: string;
  items?: PFXTemplate[];
  nextToken?: string;
}

export interface PFXModelTemplateStylesConnection {
  __typename?: string;
  items?: PFXTemplateStyles[];
  nextToken?: string;
}

export interface PFXModelTransactionPTConnection {
  __typename?: string;
  items?: PFXTransactionPT[];
  nextToken?: string;
}

export interface PFXModelTransactionRBConnection {
  __typename?: string;
  items?: PFXTransactionRB[];
  nextToken?: string;
}

export interface PFXModelUserCleverlynkConnection {
  __typename?: string;
  items?: PFXUserCleverlynk[];
  nextToken?: string;
}

export interface PFXModelUserConnection {
  __typename?: string;
  items?: PFXUser[];
  nextToken?: string;
}

export interface PFXModelUserTemplateConnection {
  __typename?: string;
  items?: PFXUserTemplate[];
  nextToken?: string;
}

export interface ActivateHubsterMutation {
  activateHubster: boolean;
}

export interface AssignCleverlynkMutation {
  assignCleverlynk: boolean;
}

export interface CCreatePromoCodeMutation {
  cCreatePromoCode: string;
}

export interface CCreateTemplateMutation {
  cCreateTemplate: PFXTemplate;
}

export interface CDeletePOSInfoHubsterMutation {
  cDeletePOSInfoHubster: PFXPOSInfo;
}

export interface CDeletePOSInfoToteatMutation {
  cDeletePOSInfoToteat: PFXPOSInfo;
}

export interface CDeletePromoCodesMutation {
  cDeletePromoCodes: boolean;
}

export interface CDeleteTemplateMutation {
  cDeleteTemplate: PFXTemplate;
}

export interface CUpdateOrderMutation {
  cUpdateOrder: PFXOrder;
}

export interface CUpdateOrderStatusMutation {
  cUpdateOrderStatus: PFXOrder;
}

export interface CUpdatePromoCodeMutation {
  cUpdatePromoCode: boolean;
}

export interface CUpdateTemplateMutation {
  cUpdateTemplate: PFXTemplate;
}

export interface CUpdateTemplateAdminMutation {
  cUpdateTemplateAdmin: PFXTemplate;
}

export interface CUpdateTemplateIntuiPosMutation {
  cUpdateTemplateIntuiPos: PFXTemplate;
}

export interface CUpdateTemplatePirPosMutation {
  cUpdateTemplatePirPos: PFXTemplate;
}

export interface CUpdateTemplatePirPosNoSeparationMutation {
  cUpdateTemplatePirPosNoSeparation: PFXTemplate;
}

export interface CUpdateTemplatePirPosRappiMutation {
  cUpdateTemplatePirPosRappi: PFXTemplate;
}

export interface CancelServiceMutation {
  cancelService: PFXOrder;
}

export interface ChangeOrderMutation {
  changeOrder: PFXOrder;
}

export interface ChangePlanMutation {
  changePlan: PFXPlan;
}

export interface CreateAccountMutation {
  createAccount: boolean;
}

export interface CreateBillingInfoMutation {
  createBillingInfo: PFXBillingInfo;
}

export interface CreateCartMutation {
  createCart: PFXCart;
}

export interface CreateCleverlynkMutation {
  createCleverlynk: PFXCleverlynk;
}

export interface CreateCleverlynkGroupMutation {
  createCleverlynkGroup: PFXCleverlynkGroup;
}

export interface CreateCleverlynkPromoCodeMutation {
  createCleverlynkPromoCode: PFXCleverlynkPromoCode;
}

export interface CreateCompanyMutation {
  createCompany: PFXCompany;
}

export interface CreateContactMutation {
  createContact: PFXContact;
}

export interface CreateCustomerCouponMutation {
  createCustomerCoupon: PFXCustomerCoupon;
}

export interface CreateCustomerFormInfoMutation {
  createCustomerFormInfo: PFXCustomerFormInfo;
}

export interface CreateCustomerPromoCodeMutation {
  createCustomerPromoCode: PFXCustomerPromoCode;
}

export interface CreateExternalChannelMutation {
  createExternalChannel: PFXExternalChannel;
}

export interface CreateInvoiceInfoMutation {
  createInvoiceInfo: PFXInvoiceInfo;
}

export interface CreateItemMutation {
  createItem: PFXItem;
}

export interface CreateItemTemplateMutation {
  createItemTemplate: PFXItemTemplate;
}

export interface CreateLandingPageMutation {
  createLandingPage: PFXLandingPage;
}

export interface CreateLogisticsInfoMutation {
  createLogisticsInfo: PFXLogisticsInfo;
}

export interface CreateMarketingInfoMutation {
  createMarketingInfo: PFXMarketingInfo;
}

export interface CreateOrderMutation {
  createOrder: PFXOrder;
}

export interface CreateOutOfBoundsMutation {
  createOutOfBounds: PFXOutOfBounds;
}

export interface CreatePOSInfoMutation {
  createPOSInfo: PFXPOSInfo;
}

export interface CreatePaymentsInfoMutation {
  createPaymentsInfo: PFXPaymentsInfo;
}

export interface CreatePotentialUserMutation {
  createPotentialUser: PFXPotentialUser;
}

export interface CreatePromoCodeMutation {
  createPromoCode: PFXPromoCode;
}

export interface CreatePushNotificationsMutation {
  createPushNotifications: PFXPushNotifications;
}

export interface CreateSKUMutation {
  createSKU: PFXSKU;
}

export interface CreateServiceMutation {
  createService: PFXOrder;
}

export interface CreateStoreMUMutation {
  createStoreMU: string;
}

export interface CreateSubItemMutation {
  createSubItem: PFXSubItem;
}

export interface CreateSubItemTemplateMutation {
  createSubItemTemplate: PFXSubItemTemplate;
}

export interface CreateTemplateMutation {
  createTemplate: PFXTemplate;
}

export interface CreateTemplateStylesMutation {
  createTemplateStyles: PFXTemplateStyles;
}

export interface CreateTransactionPTMutation {
  createTransactionPT: PFXTransactionPT;
}

export interface CreateTransactionRBMutation {
  createTransactionRB: PFXTransactionRB;
}

export interface CreateUserMutation {
  createUser: PFXUser;
}

export interface CreateUserCleverlynkMutation {
  createUserCleverlynk: PFXUserCleverlynk;
}

export interface CreateUserTemplateMutation {
  createUserTemplate: PFXUserTemplate;
}

export interface DecrementCleverlynkRendersMutation {
  decrementCleverlynkRenders: boolean;
}

export interface DeleteBillingInfoMutation {
  deleteBillingInfo: PFXBillingInfo;
}

export interface DeleteCartMutation {
  deleteCart: PFXCart;
}

export interface DeleteCleverlynkMutation {
  deleteCleverlynk: PFXCleverlynk;
}

export interface DeleteCleverlynkGroupMutation {
  deleteCleverlynkGroup: PFXCleverlynkGroup;
}

export interface DeleteCleverlynkPromoCodeMutation {
  deleteCleverlynkPromoCode: PFXCleverlynkPromoCode;
}

export interface DeleteCompanyMutation {
  deleteCompany: PFXCompany;
}

export interface DeleteContactMutation {
  deleteContact: PFXContact;
}

export interface DeleteCustomerCouponMutation {
  deleteCustomerCoupon: PFXCustomerCoupon;
}

export interface DeleteCustomerFormInfoMutation {
  deleteCustomerFormInfo: PFXCustomerFormInfo;
}

export interface DeleteCustomerPromoCodeMutation {
  deleteCustomerPromoCode: PFXCustomerPromoCode;
}

export interface DeleteExternalChannelMutation {
  deleteExternalChannel: PFXExternalChannel;
}

export interface DeleteInvoiceInfoMutation {
  deleteInvoiceInfo: PFXInvoiceInfo;
}

export interface DeleteItemMutation {
  deleteItem: PFXItem;
}

export interface DeleteItemGlobalMutation {
  deleteItemGlobal: string[];
}

export interface DeleteItemTemplateMutation {
  deleteItemTemplate: PFXItemTemplate;
}

export interface DeleteItemsInCatalogsMutation {
  deleteItemsInCatalogs: boolean;
}

export interface DeleteLandingPageMutation {
  deleteLandingPage: PFXLandingPage;
}

export interface DeleteLogisticsInfoMutation {
  deleteLogisticsInfo: PFXLogisticsInfo;
}

export interface DeleteMarketingInfoMutation {
  deleteMarketingInfo: PFXMarketingInfo;
}

export interface DeleteOrderMutation {
  deleteOrder: PFXOrder;
}

export interface DeleteOutOfBoundsMutation {
  deleteOutOfBounds: PFXOutOfBounds;
}

export interface DeletePOSInfoMutation {
  deletePOSInfo: PFXPOSInfo;
}

export interface DeletePaymentsInfoMutation {
  deletePaymentsInfo: PFXPaymentsInfo;
}

export interface DeletePotentialUserMutation {
  deletePotentialUser: PFXPotentialUser;
}

export interface DeletePromoCodeMutation {
  deletePromoCode: PFXPromoCode;
}

export interface DeletePushNotificationsMutation {
  deletePushNotifications: PFXPushNotifications;
}

export interface DeleteSKUMutation {
  deleteSKU: PFXSKU;
}

export interface DeleteSubItemMutation {
  deleteSubItem: PFXSubItem;
}

export interface DeleteSubItemGlobalMutation {
  deleteSubItemGlobal: string[];
}

export interface DeleteSubItemTemplateMutation {
  deleteSubItemTemplate: PFXSubItemTemplate;
}

export interface DeleteSubItemsInCatalogsMutation {
  deleteSubItemsInCatalogs: boolean;
}

export interface DeleteTemplateMutation {
  deleteTemplate: PFXTemplate;
}

export interface DeleteTemplateStylesMutation {
  deleteTemplateStyles: PFXTemplateStyles;
}

export interface DeleteTransactionPTMutation {
  deleteTransactionPT: PFXTransactionPT;
}

export interface DeleteTransactionRBMutation {
  deleteTransactionRB: PFXTransactionRB;
}

export interface DeleteUserMutation {
  deleteUser: PFXUser;
}

export interface DeleteUserCleverlynkMutation {
  deleteUserCleverlynk: PFXUserCleverlynk;
}

export interface DeleteUserTemplateMutation {
  deleteUserTemplate: PFXUserTemplate;
}

export interface DuplicateTemplateMutation {
  duplicateTemplate: PFXTemplate;
}

export interface GenerateCartMutation {
  generateCart: PFXCart;
}

export interface GenerateUserMutation {
  generateUser: boolean;
}

export interface InsertClientForNewsletterMutation {
  insertClientForNewsletter: PFXGenericResponse;
}

export interface InsertCustomerFormInfoMutation {
  insertCustomerFormInfo: PFXCustomerFormInfo;
}

export interface InsertItemMutation {
  insertItem: string;
}

export interface InsertOrderMutation {
  insertOrder: PFXOrder;
}

export interface InsertSubItemMutation {
  insertSubItem: string;
}

export interface PropagateItemInCatalogMutation {
  propagateItemInCatalog: PFXItemUpdateRes;
}

export interface PropagateSubItemInCatalogMutation {
  propagateSubItemInCatalog: PFXSubItemUpdateRes;
}

export interface PushRappiMenuMutation {
  pushRappiMenu: boolean;
}

export interface RemoveCleverlynkGroupMutation {
  removeCleverlynkGroup: boolean;
}

export interface RemoveCustomerFormInfoMutation {
  removeCustomerFormInfo: PFXCustomerFormInfo;
}

export interface RemoveUserMutation {
  removeUser: boolean;
}

export interface RevertOrderMutation {
  revertOrder: PFXRevertOrderRes;
}

export interface RevertTransactionMutation {
  revertTransaction: string;
}

export interface SaveMPCredsMutation {
  saveMPCreds: PFXMPSaveResponse;
}

export interface SavePICredsMutation {
  savePICreds: boolean;
}

export interface SavePushSubscriptionMutation {
  savePushSubscription: boolean;
}

export interface SaveRPCredsMutation {
  saveRPCreds: PFXPaymentsInfo;
}

export interface SetCleverlynkInfoMutation {
  setCleverlynkInfo: PFXCleverlynk;
}

export interface SetCompanyConfigsMutation {
  setCompanyConfigs: boolean;
}

export interface SetCompanyDataMutation {
  setCompanyData: boolean;
}

export interface SetImageForOrderMutation {
  setImageForOrder: string;
}

export interface SetLogisticsInfoMutation {
  setLogisticsInfo: boolean;
}

export interface SetMarketingInfoMutation {
  setMarketingInfo: boolean;
}

export interface SetPaymentsInfoMutation {
  setPaymentsInfo: boolean;
}

export interface SetUserMutation {
  setUser: boolean;
}

export interface SetUserTemplateConnectionsMutation {
  setUserTemplateConnections: PFXUserConnectionResponse;
}

export interface TemplateAppendItemsToCategoriesMutation {
  templateAppendItemsToCategories: boolean;
}

export interface UnassignCleverlynkMutation {
  unassignCleverlynk: boolean;
}

export interface UnlinkRPCredsMutation {
  unlinkRPCreds: boolean;
}

export interface UpdateBillingInfoMutation {
  updateBillingInfo: PFXBillingInfo;
}

export interface UpdateCartMutation {
  updateCart: PFXCart;
}

export interface UpdateCleverlynkMutation {
  updateCleverlynk: PFXCleverlynk;
}

export interface UpdateCleverlynkFlexibleMutation {
  updateCleverlynkFlexible: PFXCleverlynk;
}

export interface UpdateCleverlynkGroupMutation {
  updateCleverlynkGroup: PFXCleverlynkGroup;
}

export interface UpdateCleverlynkPromoCodeMutation {
  updateCleverlynkPromoCode: PFXCleverlynkPromoCode;
}

export interface UpdateCleverlynkStatusMutation {
  updateCleverlynkStatus: PFXCleverlynk;
}

export interface UpdateCompanyMutation {
  updateCompany: PFXCompany;
}

export interface UpdateContactMutation {
  updateContact: PFXContact;
}

export interface UpdateCustomerCouponMutation {
  updateCustomerCoupon: PFXCustomerCoupon;
}

export interface UpdateCustomerFormInfoMutation {
  updateCustomerFormInfo: PFXCustomerFormInfo;
}

export interface UpdateCustomerPromoCodeMutation {
  updateCustomerPromoCode: PFXCustomerPromoCode;
}

export interface UpdateExternalChannelMutation {
  updateExternalChannel: PFXExternalChannel;
}

export interface UpdateInvoiceInfoMutation {
  updateInvoiceInfo: PFXInvoiceInfo;
}

export interface UpdateItemMutation {
  updateItem: PFXItem;
}

export interface UpdateItemTemplateMutation {
  updateItemTemplate: PFXItemTemplate;
}

export interface UpdateLandingPageMutation {
  updateLandingPage: PFXLandingPage;
}

export interface UpdateLogisticsInfoMutation {
  updateLogisticsInfo: PFXLogisticsInfo;
}

export interface UpdateMarketingInfoMutation {
  updateMarketingInfo: PFXMarketingInfo;
}

export interface UpdateOnboardingSuggestionsMutation {
  updateOnboardingSuggestions: PFXOnboardingSuggestions;
}

export interface UpdateOrderMutation {
  updateOrder: PFXOrder;
}

export interface UpdateOutOfBoundsMutation {
  updateOutOfBounds: PFXOutOfBounds;
}

export interface UpdatePOSInfoMutation {
  updatePOSInfo: PFXPOSInfo;
}

export interface UpdatePaymentsInfoMutation {
  updatePaymentsInfo: PFXPaymentsInfo;
}

export interface UpdatePotentialUserMutation {
  updatePotentialUser: PFXPotentialUser;
}

export interface UpdatePromoCodeMutation {
  updatePromoCode: PFXPromoCode;
}

export interface UpdatePushNotificationsMutation {
  updatePushNotifications: PFXPushNotifications;
}

export interface UpdateSKUMutation {
  updateSKU: PFXSKU;
}

export interface UpdateSubItemMutation {
  updateSubItem: PFXSubItem;
}

export interface UpdateSubItemTemplateMutation {
  updateSubItemTemplate: PFXSubItemTemplate;
}

export interface UpdateTemplateMutation {
  updateTemplate: PFXTemplate;
}

export interface UpdateTemplateStylesMutation {
  updateTemplateStyles: PFXTemplateStyles;
}

export interface UpdateTransactionPTMutation {
  updateTransactionPT: PFXTransactionPT;
}

export interface UpdateTransactionRBMutation {
  updateTransactionRB: PFXTransactionRB;
}

export interface UpdateUserMutation {
  updateUser: PFXUser;
}

export interface UpdateUserCleverlynkMutation {
  updateUserCleverlynk: PFXUserCleverlynk;
}

export interface UpdateUserTemplateMutation {
  updateUserTemplate: PFXUserTemplate;
}

export interface UpsertActiveIntegrationsMutation {
  upsertActiveIntegrations: PFXIntegrations;
}

export interface UpsertBillingContactMutation {
  upsertBillingContact: PFXAlegra;
}

export interface UpsertCleverlynkMutation {
  upsertCleverlynk: string;
}

export interface UpsertCleverlynkGroupMutation {
  upsertCleverlynkGroup: string;
}

export interface UpsertLandingPageMutation {
  upsertLandingPage: boolean;
}

export interface UpsertPOSInfoDeliverectMutation {
  upsertPOSInfoDeliverect: PFXPOSInfo;
}

export interface UpsertPOSInfoHubsterMutation {
  upsertPOSInfoHubster: PFXPOSInfo;
}

export interface UpsertPOSInfoIntuiPosMutation {
  upsertPOSInfoIntuiPos: PFXPOSInfo;
}

export interface UpsertPOSInfoPirPosMutation {
  upsertPOSInfoPirPos: PFXPOSInfo;
}

export interface UpsertPOSInfoPirPosRappiMutation {
  upsertPOSInfoPirPosRappi: PFXPOSInfo;
}

export interface UpsertPOSInfoToteatMutation {
  upsertPOSInfoToteat: PFXPOSInfo;
}

export interface UpsertSKUMutation {
  upsertSKU: boolean;
}

export interface UpsertWompiCardPSMutation {
  upsertWompiCardPS: PFXWompiCardPSInfo;
}

export interface UpsertWompiNequiPSMutation {
  upsertWompiNequiPS: PFXWompiNequiPSInfo;
}

export interface PFXNQCredsRes {
  __typename?: string;
  hasNQ?: boolean;
  urls?: string[];
}

export interface PFXNavbarStyles {
  __typename?: string;
  headerColor?: string;
  navbarColor?: string;
  navbarFontFamily?: string;
  navbarTextColor?: string;
}

export interface PFXNavigationComponent {
  __typename?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  opaqueBackground?: PFXOpaqueBackgroundProperties;
  subtitle?: PFXTextAttributes;
  title?: PFXTextAttributes;
}

export interface PFXOFCredsRes {
  __typename?: string;
  hasOF?: boolean;
  password?: string;
  username?: string;
}

export interface PFXOFInfo {
  __typename?: string;
  password?: string;
  username?: string;
}

export interface PFXOnboardingCompletion {
  __typename?: string;
  adminCompanyContact?: boolean;
  adminCompanyData?: boolean;
  adminIntegrations?: boolean;
  adminMarketing?: boolean;
  adminPaymentMethods?: boolean;
  adminTermsAndConditions?: boolean;
  cleverlynkGroups?: boolean;
  cleverlynks?: boolean;
  contacts?: boolean;
  createCleverlynks?: boolean;
  createProduct?: boolean;
  createTemplates?: boolean;
  home?: boolean;
  orders?: boolean;
  templates?: boolean;
  users?: boolean;
}

export interface PFXOnboardingSuggestions {
  __typename?: string;
  hasSkippedCleverynks?: boolean;
  hasSkippedLogisticsIntegrations?: boolean;
  hasSkippedMarketingIntegrations?: boolean;
  hasSkippedPaymentsIntegrations?: boolean;
  hasSkippedTemplates?: boolean;
}

export interface PFXOpaqueBackgroundProperties {
  __typename?: string;
  alpha?: string;
  color?: string;
}

export interface PFXOpenClosedCleverlynks {
  __typename?: string;
  closed?: PFXCleverlynk[];
  open?: PFXCleverlynk[];
}

export interface PFXOptionIntuiPOSResponse {
  __typename?: string;
  name?: string;
  nameInIntuipos?: string;
}

export interface PFXOrder {
  __typename?: string;
  authorizationCode?: string;
  bankTransferImage?: string;
  check?: boolean;
  cleverlynk?: PFXCleverlynk;
  cleverlynkId?: string;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  deliveryInfo?: PFXDeliveryInfo;
  errors?: any;
  externalReference?: string;
  extras?: any;
  fulfillmentType?: PFXOrderFulfillmentType;
  googleClientId?: string;
  hasDomiprimeService?: boolean;
  hidden?: boolean;
  history?: PFXOrderHistory[];
  id?: string;
  identification?: string;
  items?: PFXItemForOrder[];
  mandatoryCriteria?: PFXCriteriaForOrder[];
  mattersForSubscription?: boolean;
  mensajerosId?: string;
  metadata?: any;
  muTrackingLink?: string;
  newOrder?: boolean;
  note?: PFXOrderNote;
  oficiosId?: string;
  optionalCriteria?: PFXCriteriaForOrder[];
  orderStatusDetail?: number;
  origin?: PFXOriginChannel;
  paymentMethod?: string;
  paymentStatus?: number;
  picapId?: string;
  picapTrackingLink?: string;
  price?: PFXPrice;
  promoCodeId?: string;
  rapiboyId?: string;
  shouldSendEmail?: boolean;
  status?: number;
  updatedAt?: string;
  userMarketingConsent?: boolean;
  yapayaId?: string;
}

export interface PFXOrderAnalytics {
  __typename?: string;
  LastEvaluatedKey?: PFXLastEvalutedKeyIndex;
  accumulatedOrders?: PFXTimeBarGraph[];
  accumulatedSales?: PFXTimeBarGraph[];
  cumulativeAvgOrderPrice?: PFXTimeBarGraph[];
  paymentMethodAmountOrders?: PFXBarGraph[];
  paymentMethodSales?: PFXBarGraph[];
  paymentTypeAmountOrders?: PFXBarGraph[];
  paymentTypeSales?: PFXBarGraph[];
  rollingAverage?: PFXTimeBarGraph[];
  timeRangeOrders?: PFXTimeBarGraphAverage[];
  timeRangeSales?: PFXTimeBarGraphAverage[];
  topCompaniesOrders?: PFXBarGraph[];
  topCompaniesOrdesrWeek?: PFXBarGraph[];
  topCompaniesSales?: PFXBarGraph[];
  topCompaniesSalesWeek?: PFXBarGraph[];
}

export interface PFXOrderHistory {
  __typename?: string;
  date?: string;
  status?: number;
}

export interface PFXOrderNote {
  __typename?: string;
  note?: string;
  priority?: PFXNotePriority;
}

export interface PFXOrderStatusNotificationPolicy {
  __typename?: string;
  sendMail?: boolean;
  status?: number;
}

export interface PFXOrdersByDateRangeResponse {
  __typename?: string;
  items?: PFXOrder[];
  lastEvaluatedKey?: PFXOrdersIndexKey;
}

export interface PFXOrdersIndexKey {
  __typename?: string;
  cleverlynkId?: string;
  createdAt?: string;
  id?: string;
}

export interface PFXOutOfBounds {
  __typename?: string;
  address?: PFXDeliveryInfo;
  cleverlynk?: PFXCleverlynk;
  cleverlynkId?: string;
  createdAt?: string;
  id?: string;
  identification?: string;
  items?: PFXItemForOrder[];
  mandatoryCriteria?: PFXCriteriaForOrder[];
  optionalCriteria?: PFXCriteriaForOrder[];
  updatedAt?: string;
}

export interface PFXPICredsRes {
  __typename?: string;
  defaultDimensions?: PFXPicapDimensions;
  hasPI?: boolean;
  token?: string;
}

export interface PFXPIInfo {
  __typename?: string;
  defaultDimensions?: PFXPicapDimensions;
  token?: string;
}

export interface PFXPOSInfo {
  __typename?: string;
  cleverlynkId?: string;
  createdAt?: string;
  deliverect?: PFXDLInfo;
  hubster?: PFXHBInfo;
  intuipos?: PFXIPInfo;
  pirpos?: PFXPPInfo;
  pirposRappi?: PFXPPRInfo;
  siesa?: PFXSSInfo;
  toteat?: PFXTTInfo;
  updatedAt?: string;
}

export interface PFXPPInfo {
  __typename?: string;
  activeOrders?: boolean;
  hasPirPos?: boolean;
  token?: string;
}

export interface PFXPPLCredsRes {
  __typename?: string;
  apiClient?: string;
  apiSecret?: string;
  hasPPL?: boolean;
}

export interface PFXPPLInfo {
  __typename?: string;
  apiClient?: string;
  apiSecret?: string;
}

export interface PFXPPRInfo {
  __typename?: string;
  activeOrders?: boolean;
  hasPirPosRappi?: boolean;
  rappiId?: string;
  token?: string;
}

export interface PFXPTCredsRes {
  __typename?: string;
  card?: boolean;
  clientAppCode?: string;
  clientAppKey?: string;
  hasPT?: boolean;
  serverAppCode?: string;
  serverAppKey?: string;
}

export interface PFXPTInfo {
  __typename?: string;
  card?: boolean;
  clientAppCode?: string;
  clientAppKey?: string;
  serverAppCode?: string;
  serverAppKey?: string;
  webhookId?: string;
}

export interface PFXPUCredsRes {
  __typename?: string;
  accountId?: string;
  apiKey?: string;
  apiLogin?: string;
  hasPU?: boolean;
  merchantId?: string;
}

export interface PFXPUInfo {
  __typename?: string;
  accountId?: string;
  apiKey?: string;
  apiLogin?: string;
  merchantId?: string;
}

export interface PFXPZCredsRes {
  __typename?: string;
  card?: boolean;
  formRedirectKey?: string;
  hasPZ?: boolean;
  password?: string;
  privateKey?: string;
  pse?: boolean;
  publicKey?: string;
  user?: string;
}

export interface PFXPZInfo {
  __typename?: string;
  card?: boolean;
  formRedirectKey?: string;
  password?: string;
  privateKey?: string;
  pse?: boolean;
  publicKey?: string;
  user?: string;
}

export interface PFXPage {
  __typename?: string;
  components?: PFXLandingComponent[];
  componentsOrder?: string[];
  footerComponent?: PFXFooterComponent;
  id?: string;
  mainComponent?: PFXMainComponent;
}

export interface PFXPaymentMethods {
  __typename?: string;
  bankTransfer?: boolean;
  cash?: boolean;
  dataphone?: boolean;
  ePayCo?: boolean;
  mercadoPago?: boolean;
  nequi?: boolean;
  noPayment?: boolean;
  payU?: boolean;
  paymentez?: boolean;
  paypal?: boolean;
  payzen?: boolean;
  rappi?: boolean;
  redeban?: boolean;
  showroom?: boolean;
  stripe?: boolean;
  stripeExternal?: boolean;
  userPercentageEC?: number;
  userPercentageMP?: number;
  userPercentageNQ?: number;
  userPercentageRB?: number;
  userPercentageWompi?: number;
  wompi?: boolean;
}

export interface PFXPaymentsInfo {
  __typename?: string;
  bankTransfer?: PFXBTInfo;
  companyId?: string;
  createdAt?: string;
  epayco?: PFXECInfo;
  mercadoPago?: PFXMPInfo;
  payU?: PFXPUInfo;
  paymentez?: PFXPTInfo;
  paypal?: PFXPPLInfo;
  payzen?: PFXPZInfo;
  rappi?: PFXRPInfo;
  redeban?: PFXRBInfo;
  stripe?: PFXSTInfo;
  updatedAt?: string;
  wompi?: PFXWPInfo;
}

export interface PFXPermissions {
  __typename?: string;
  changePricesTemplate?: boolean;
  createCleverlynk?: boolean;
  createCouponCodes?: boolean;
  createPromoCodes?: boolean;
  createTemplate?: boolean;
  deleteCleverlynk?: boolean;
  deleteCouponCodes?: boolean;
  deleteItemsTemplate?: boolean;
  deletePromoCodes?: boolean;
  deleteTemplate?: boolean;
  manageBilling?: boolean;
  manageCleverlynkGroup?: boolean;
  manageClients?: boolean;
  manageCompanyData?: boolean;
  manageIntegrations?: boolean;
  manageInventory?: boolean;
  manageLandingPage?: boolean;
  manageTemplateStyles?: boolean;
  manageUsers?: boolean;
  updateCleverlynk?: boolean;
  updateCouponCodes?: boolean;
  updateOrder?: boolean;
  updatePromoCodes?: boolean;
  updateTemplate?: boolean;
  viewAnalytics?: boolean;
}

export interface PFXPlan {
  __typename?: string;
  fixedOrder?: number;
  fixedSubscription?: number;
  implementation?: number;
  name?: string;
  percentageOrder?: number;
  percentageOrderInPerson?: number;
}

export interface PFXPotentialUser {
  __typename?: string;
  company?: string;
  createdAt?: string;
  email?: string;
  id?: string;
  lastName?: string;
  name?: string;
  phoneNumber?: string;
  source?: string;
  updatedAt?: string;
}

export interface PFXPrice {
  __typename?: string;
  currency?: PFXCurrency;
  delivery?: number;
  deliveryExternal?: number;
  discount?: number;
  discountCode?: string;
  subTotal?: number;
  tax?: number;
  tip?: number;
  tipExternal?: number;
  total?: number;
  totalExternal?: number;
  transaction?: number;
  transactionExternal?: number;
}

export interface PFXPromoCode {
  __typename?: string;
  active?: boolean;
  amountUsed?: number;
  cleverlynks?: PFXModelCleverlynkPromoCodeConnection;
  code?: string;
  companyId?: string;
  createdAt?: string;
  cumulative?: boolean;
  id?: string;
  name?: string;
  restrictions?: PFXPromoRestriction[];
  type?: PFXPromoCodeType;
  updatedAt?: string;
  value?: number;
}

export interface PFXPromoCodeResponse {
  __typename?: string;
  active?: boolean;
  amountUsed?: number;
  cleverlynks?: string[];
  code?: string;
  cumulative?: boolean;
  id?: string;
  name?: string;
  restrictions?: PFXPromoRestriction[];
  status?: number;
  type?: PFXPromoCodeType;
  updatedAt?: string;
  value?: number;
}

export interface PFXPromoRestriction {
  __typename?: string;
  amount?: number;
  beginDate?: string;
  endDate?: string;
  price?: number;
  type?: PFXPromoRestrictionType;
  weekRange?: PFXWeekRange;
}

export interface PFXPushNotifications {
  __typename?: string;
  createdAt?: string;
  subscription?: any;
  updatedAt?: string;
  userName?: string;
}

export interface AvailablePromoCodesInClynkQuery {
  availablePromoCodesInClynk: boolean;
}

export interface CalculateServiceQuery {
  calculateService: number;
}

export interface CheckCredentialsQuery {
  checkCredentials: PFXCredentialsResponse;
}

export interface FetchBillingContactQuery {
  fetchBillingContact: PFXAlegra;
}

export interface FetchCartQuery {
  fetchCart: PFXCart;
}

export interface FetchCleverlynkQuery {
  fetchCleverlynk: PFXCleverlynk;
}

export interface FetchCleverlynkGroupQuery {
  fetchCleverlynkGroup: PFXCleverlynkGroup;
}

export interface FetchCleverlynkGroupsQuery {
  fetchCleverlynkGroups: PFXCleverlynkGroup[];
}

export interface FetchCleverlynkInfoForRenderQuery {
  fetchCleverlynkInfoForRender: PFXCleverlynk;
}

export interface FetchCleverlynksQuery {
  fetchCleverlynks: PFXCleverlynk[];
}

export interface FetchContactsQuery {
  fetchContacts: PFXFetchContactsResponse;
}

export interface FetchCustomerFormInfoQuery {
  fetchCustomerFormInfo: PFXCustomerFormInfo;
}

export interface FetchDashboardBillingDataQuery {
  fetchDashboardBillingData: PFXBillingDashboardData;
}

export interface FetchInvoicesQuery {
  fetchInvoices: PFXInvoiceInfo[];
}

export interface FetchItemsByNameQuery {
  fetchItemsByName: PFXModelItemConnection;
}

export interface FetchLandingPageQuery {
  fetchLandingPage: PFXLandingPage;
}

export interface FetchLandingPageInfoForRenderQuery {
  fetchLandingPageInfoForRender: PFXLandingPageResponse;
}

export interface FetchOrderQuery {
  fetchOrder: PFXOrder;
}

export interface FetchPromoCodeQuery {
  fetchPromoCode: PFXPromoCode;
}

export interface FetchPromoCodesQuery {
  fetchPromoCodes: PFXFetchPromoCodesResponse;
}

export interface FetchSKUsQuery {
  fetchSKUs: PFXSKU[];
}

export interface FetchSubItemsQuery {
  fetchSubItems: PFXSubItem[];
}

export interface FetchTemplateQuery {
  fetchTemplate: PFXTemplate;
}

export interface FetchTemplateInfoForRenderQuery {
  fetchTemplateInfoForRender: PFXTemplateForRenderResponse;
}

export interface FetchTemplatesQuery {
  fetchTemplates: PFXFetchTemplatesResponse;
}

export interface FetchUserQuery {
  fetchUser: PFXUser;
}

export interface FetchUsersQuery {
  fetchUsers: PFXUser[];
}

export interface FetchWompiPSInfoQuery {
  fetchWompiPSInfo: PFXWompiPSInfo;
}

export interface GetActiveIntegrationsForCompanyQuery {
  getActiveIntegrationsForCompany: PFXIntegrations;
}

export interface GetAnnouncementsForUserQuery {
  getAnnouncementsForUser: PFXAnnouncement[];
}

export interface GetAvailableItemsInCleverlynkQuery {
  getAvailableItemsInCleverlynk: PFXItemsInCleverlynk;
}

export interface GetBaseURLQuery {
  getBaseURL: string;
}

export interface GetBillingInfoQuery {
  getBillingInfo: PFXBillingInfo;
}

export interface GetCartQuery {
  getCart: PFXCart;
}

export interface GetCategoriesFromTemplatesQuery {
  getCategoriesFromTemplates: PFXCatalogResponse[];
}

export interface GetCleverlynkQuery {
  getCleverlynk: PFXCleverlynk;
}

export interface GetCleverlynkGroupQuery {
  getCleverlynkGroup: PFXCleverlynkGroup;
}

export interface GetCleverlynkPromoCodeQuery {
  getCleverlynkPromoCode: PFXCleverlynkPromoCode;
}

export interface GetCompanyQuery {
  getCompany: PFXCompany;
}

export interface GetCompanyConfigsQuery {
  getCompanyConfigs: PFXCompanyConfigs;
}

export interface GetCompanyDataQuery {
  getCompanyData: PFXCompanyData;
}

export interface GetComparableItemIdInClynkQuery {
  getComparableItemIdInClynk: PFXGenericResponse;
}

export interface GetContactQuery {
  getContact: PFXContact;
}

export interface GetCustomerCouponQuery {
  getCustomerCoupon: PFXCustomerCoupon;
}

export interface GetCustomerFormInfoQuery {
  getCustomerFormInfo: PFXCustomerFormInfo;
}

export interface GetCustomerPromoCodeQuery {
  getCustomerPromoCode: PFXCustomerPromoCode;
}

export interface GetDiscountCodeQuery {
  getDiscountCode: PFXDiscountCode;
}

export interface GetExternalChannelQuery {
  getExternalChannel: PFXExternalChannel;
}

export interface GetHostnameForCleverlynksQuery {
  getHostnameForCleverlynks: string;
}

export interface GetInvoiceInfoQuery {
  getInvoiceInfo: PFXInvoiceInfo;
}

export interface GetItemQuery {
  getItem: PFXItem;
}

export interface GetItemTemplateQuery {
  getItemTemplate: PFXItemTemplate;
}

export interface GetItemsFromTemplatesQuery {
  getItemsFromTemplates: PFXItemsFromTemplate[];
}

export interface GetLandingPageQuery {
  getLandingPage: PFXLandingPage;
}

export interface GetLogisticsInfoQuery {
  getLogisticsInfo: PFXLogisticsInfo;
}

export interface GetMarketingInfoQuery {
  getMarketingInfo: PFXMarketingInfo;
}

export interface GetOrderQuery {
  getOrder: PFXOrder;
}

export interface GetOutOfBoundsQuery {
  getOutOfBounds: PFXOutOfBounds;
}

export interface GetPOSInfoQuery {
  getPOSInfo: PFXPOSInfo;
}

export interface GetPOSTokensQuery {
  getPOSTokens: PFXPOSInfo;
}

export interface GetPaymentsInfoQuery {
  getPaymentsInfo: PFXPaymentsInfo;
}

export interface GetPotentialUserQuery {
  getPotentialUser: PFXPotentialUser;
}

export interface GetPromoCodeQuery {
  getPromoCode: PFXPromoCode;
}

export interface GetPushNotificationsQuery {
  getPushNotifications: PFXPushNotifications;
}

export interface GetSKUQuery {
  getSKU: PFXSKU;
}

export interface GetSubItemQuery {
  getSubItem: PFXSubItem;
}

export interface GetSubItemTemplateQuery {
  getSubItemTemplate: PFXSubItemTemplate;
}

export interface GetSubitemsFromTemplateQuery {
  getSubitemsFromTemplate: PFXSubitemsFromTemplate[];
}

export interface GetTemplateQuery {
  getTemplate: PFXTemplate;
}

export interface GetTemplateStylesQuery {
  getTemplateStyles: PFXTemplateStyles;
}

export interface GetTransactionPTQuery {
  getTransactionPT: PFXTransactionPT;
}

export interface GetTransactionRBQuery {
  getTransactionRB: PFXTransactionRB;
}

export interface GetUserQuery {
  getUser: PFXUser;
}

export interface GetUserCleverlynkQuery {
  getUserCleverlynk: PFXUserCleverlynk;
}

export interface GetUserTemplateQuery {
  getUserTemplate: PFXUserTemplate;
}

export interface IntuiposReportItemsQuery {
  intuiposReportItems: PFXIntuiPOSResponse;
}

export interface ListBillingInfosQuery {
  listBillingInfos: PFXModelBillingInfoConnection;
}

export interface ListCartsQuery {
  listCarts: PFXModelCartConnection;
}

export interface ListCatalogsWithItemQuery {
  listCatalogsWithItem: PFXTemplate[];
}

export interface ListCatalogsWithSubItemQuery {
  listCatalogsWithSubItem: PFXTemplate[];
}

export interface ListCleverlynkGroupsQuery {
  listCleverlynkGroups: PFXModelCleverlynkGroupConnection;
}

export interface ListCleverlynkPromoCodesQuery {
  listCleverlynkPromoCodes: PFXModelCleverlynkPromoCodeConnection;
}

export interface ListCleverlynksQuery {
  listCleverlynks: PFXModelCleverlynkConnection;
}

export interface ListCompanysQuery {
  listCompanys: PFXModelCompanyConnection;
}

export interface ListContactsQuery {
  listContacts: PFXModelContactConnection;
}

export interface ListCustomerCouponsQuery {
  listCustomerCoupons: PFXModelCustomerCouponConnection;
}

export interface ListCustomerFormInfosQuery {
  listCustomerFormInfos: PFXModelCustomerFormInfoConnection;
}

export interface ListCustomerPromoCodesQuery {
  listCustomerPromoCodes: PFXModelCustomerPromoCodeConnection;
}

export interface ListExternalChannelsQuery {
  listExternalChannels: PFXModelExternalChannelConnection;
}

export interface ListInvoiceInfosQuery {
  listInvoiceInfos: PFXModelInvoiceInfoConnection;
}

export interface ListItemTemplatesQuery {
  listItemTemplates: PFXModelItemTemplateConnection;
}

export interface ListItemsQuery {
  listItems: PFXModelItemConnection;
}

export interface ListLandingPagesQuery {
  listLandingPages: PFXModelLandingPageConnection;
}

export interface ListLogisticsInfosQuery {
  listLogisticsInfos: PFXModelLogisticsInfoConnection;
}

export interface ListMarketingInfosQuery {
  listMarketingInfos: PFXModelMarketingInfoConnection;
}

export interface ListOrdersQuery {
  listOrders: PFXModelOrderConnection;
}

export interface ListOutOfBoundssQuery {
  listOutOfBoundss: PFXModelOutOfBoundsConnection;
}

export interface ListPOSInfosQuery {
  listPOSInfos: PFXModelPOSInfoConnection;
}

export interface ListPaymentsInfosQuery {
  listPaymentsInfos: PFXModelPaymentsInfoConnection;
}

export interface ListPotentialUsersQuery {
  listPotentialUsers: PFXModelPotentialUserConnection;
}

export interface ListPromoCodesQuery {
  listPromoCodes: PFXModelPromoCodeConnection;
}

export interface ListPushNotificationssQuery {
  listPushNotificationss: PFXModelPushNotificationsConnection;
}

export interface ListSKUsQuery {
  listSKUs: PFXModelSKUConnection;
}

export interface ListSubItemTemplatesQuery {
  listSubItemTemplates: PFXModelSubItemTemplateConnection;
}

export interface ListSubItemsQuery {
  listSubItems: PFXModelSubItemConnection;
}

export interface ListTemplateStylessQuery {
  listTemplateStyless: PFXModelTemplateStylesConnection;
}

export interface ListTemplatesQuery {
  listTemplates: PFXModelTemplateConnection;
}

export interface ListTransactionPTsQuery {
  listTransactionPTs: PFXModelTransactionPTConnection;
}

export interface ListTransactionRBsQuery {
  listTransactionRBs: PFXModelTransactionRBConnection;
}

export interface ListUserCleverlynksQuery {
  listUserCleverlynks: PFXModelUserCleverlynkConnection;
}

export interface ListUserTemplatesQuery {
  listUserTemplates: PFXModelUserTemplateConnection;
}

export interface ListUsersQuery {
  listUsers: PFXModelUserConnection;
}

export interface OpenCleverlynksForGroupQuery {
  openCleverlynksForGroup: PFXOpenClosedCleverlynks;
}

export interface OrdersByCleverlynkIdCreatedAtQuery {
  ordersByCleverlynkIdCreatedAt: PFXModelOrderConnection;
}

export interface TrackServiceQuery {
  trackService: PFXServiceInfo;
}

export interface VerifyPromoCodeQuery {
  verifyPromoCode: PFXVerifyPromoCodeResponse;
}

export interface PFXRACredsRes {
  __typename?: string;
  defaultAddress?: PFXAddressLatLng;
  hasRA?: boolean;
  token?: string;
}

export interface PFXRAInfo {
  __typename?: string;
  defaultAddress?: PFXAddressLatLng;
  token?: string;
}

export interface PFXRBCredsRes {
  __typename?: string;
  allowsInstallments?: boolean;
  card?: boolean;
  clientAppCode?: string;
  clientAppKey?: string;
  hasRB?: boolean;
  pse?: boolean;
  serverAppCode?: string;
  serverAppKey?: string;
}

export interface PFXRBInfo {
  __typename?: string;
  allowsInstallments?: boolean;
  card?: boolean;
  clientAppCode?: string;
  clientAppKey?: string;
  pse?: boolean;
  serverAppCode?: string;
  serverAppKey?: string;
}

export interface PFXRPCredsRes {
  __typename?: string;
  clientId?: string;
  clientSecret?: string;
  hasRP?: boolean;
}

export interface PFXRPExternalInfo {
  __typename?: string;
  clientId?: string;
  clientSecret?: string;
  country?: PFXRappiCountry;
  token?: string;
  webhookSecretMenuApproved?: string;
  webhookSecretMenuRejected?: string;
  webhookSecretNewOrder?: string;
  webhookSecretOrderEventCancelled?: string;
  webhookSecretOtherOrderEvent?: string;
  webhookSecretPing?: string;
  webhookSecretStoreConnectivity?: string;
}

export interface PFXRPICredsRes {
  __typename?: string;
  hasRPI?: boolean;
}

export interface PFXRPInfo {
  __typename?: string;
  accountId?: string;
  clientId?: string;
  clientSecret?: string;
  token?: string;
}

export interface PFXRangePrice {
  __typename?: string;
  price?: number;
  range?: number[];
}

export interface PFXRenderInfo {
  __typename?: string;
  renderDescription?: string;
  renderImageURL?: string;
  renderTitle?: string;
}

export interface PFXRevertOrderRes {
  __typename?: string;
  status?: PFXRevertOrderStatus;
}

export interface PFXSKU {
  __typename?: string;
  company?: PFXCompany;
  companyConnectionId?: string;
  companyId?: string;
  createdAt?: string;
  sku?: string;
  stock?: number;
  updatedAt?: string;
}

export interface PFXSSInfo {
  __typename?: string;
  activeOrders?: boolean;
  siesaId?: string;
  siesaName?: string;
}

export interface PFXSTCredsRes {
  __typename?: string;
  hasST?: boolean;
  publishableKey?: string;
}

export interface PFXSTInfo {
  __typename?: string;
  publishableKey?: string;
  secretKey?: string;
  webhookKey?: string;
}

export interface PFXScheduledDelivery {
  __typename?: string;
  dayMargin?: number;
  dispatchHours?: string[][][];
  hourMargin?: number;
  ignoreDeliveryDays?: string[];
  selectedDeliveryDays?: string[];
  weekdays?: boolean[];
}

export interface PFXServiceInfo {
  __typename?: string;
  deliveryPersonInfo?: PFXDeliveryPersonInfo;
  externalId?: string;
  status?: PFXServiceStatus;
  updatedAt?: string;
}

export interface PFXServiceTip {
  __typename?: string;
  message?: string;
  type?: PFXServiceTipType;
}

export interface PFXSocialComponent {
  __typename?: string;
  backgroundColor?: string;
  iconColor?: string;
  margins?: PFXMarginMultipliers;
  phoneNumber?: string;
  socialMedia?: PFXSocialMediaInfo;
  subtitle?: PFXTextAttributes;
  title?: PFXTextAttributes;
}

export interface PFXSocialMediaInfo {
  __typename?: string;
  facebook?: boolean;
  instagram?: boolean;
  pinterest?: boolean;
  tiktok?: boolean;
  twitter?: boolean;
  youtube?: boolean;
}

export interface PFXSocials {
  __typename?: string;
  page?: PFXSocialsPages;
  url?: string;
}

export interface PFXStatusMailPolicy {
  __typename?: string;
  sendMail?: boolean;
  status?: number;
}

export interface PFXSubItem {
  __typename?: string;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  id?: string;
  subItemData?: PFXSubItemData;
  templates?: PFXModelSubItemTemplateConnection;
  updatedAt?: string;
}

export interface PFXSubItemChosenOption {
  __typename?: string;
  controlsInventory?: boolean;
  hidden?: boolean;
  id?: string;
  input?: string;
  label?: string;
  posReference?: string;
  quantity?: number;
  sku?: string;
  subitemId?: string;
  subitemType?: PFXSubitemDataType;
  value?: number;
}

export interface PFXSubItemData {
  __typename?: string;
  choiceLimit?: number;
  choiceMinimum?: number;
  controlsInventory?: boolean;
  gid?: string;
  hidden?: boolean;
  id?: string;
  mandatory?: boolean;
  multiple?: boolean;
  name?: string;
  options?: PFXSubItemOption[];
  posReference?: string;
  sku?: string;
  timeFrame?: PFXSubitemDataChronologicalType;
  type?: PFXSubitemDataType;
}

export interface PFXSubItemForOrder {
  __typename?: string;
  choiceLimit?: number;
  controlsInventory?: boolean;
  id?: string;
  mandatory?: boolean;
  multiple?: boolean;
  name?: string;
  options?: PFXSubItemOption[];
}

export interface PFXSubItemOption {
  __typename?: string;
  hidden?: boolean;
  id?: string;
  label?: string;
  posReference?: string;
  sku?: string;
  value?: number;
}

export interface PFXSubItemTemplate {
  __typename?: string;
  companyId?: string;
  createdAt?: string;
  id?: string;
  subItem?: PFXSubItem;
  subItemId?: string;
  template?: PFXTemplate;
  templateId?: string;
  updatedAt?: string;
}

export interface PFXSubItemUpdateRes {
  __typename?: string;
  SubItem?: PFXSubItem;
  failedTemplates?: string[];
}

export interface PFXSubdomainForCleverlynk {
  __typename?: string;
  subdomain?: string;
}

export interface PFXSubitemFromTemplate {
  __typename?: string;
  itemId?: string;
  itemName?: string;
  subitem?: PFXSubItemData;
  templateId?: string;
}

export interface PFXSubitemIntuiPOSResponse {
  __typename?: string;
  name?: string;
  options?: PFXOptionIntuiPOSResponse[];
}

export interface PFXSubitemsFromTemplate {
  __typename?: string;
  subitems?: PFXSubitemFromTemplate[];
  templateId?: string;
  templateName?: string;
}

export interface PFXTTInfo {
  __typename?: string;
  activeCatalog?: boolean;
  activeOrders?: boolean;
  apiId?: string;
  apiToken?: string;
  hasToteat?: boolean;
  localNumber?: string;
  nameInToteat?: string;
  restaurantId?: string;
  webhookToken?: string;
}

export interface PFXTakeOut {
  __typename?: string;
  criteriaType?: PFXCriteriaType;
  label?: string;
  values?: string[];
}

export interface PFXTaxInfo {
  __typename?: string;
  shouldApplyTax?: boolean;
  tax?: number;
}

export interface PFXTemplate {
  __typename?: string;
  banners?: PFXBanner[];
  cardSize?: PFXCardType;
  catalog?: any;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  currency?: PFXCurrency;
  disableNavbarFade?: boolean;
  drawerNavbarWhenMobile?: boolean;
  hideItemsWhenSoldOut?: boolean;
  id?: string;
  items?: PFXModelItemTemplateConnection;
  logo?: string;
  name?: string;
  owner?: PFXUser;
  ownerId?: string;
  showAllProductsTab?: boolean;
  socialsFooter?: boolean;
  styles?: PFXTemplateStylesStructure;
  subitems?: PFXModelSubItemTemplateConnection;
  type?: string;
  updatedAt?: string;
  upsellingIds?: string[];
  users?: PFXModelUserTemplateConnection;
}

export interface PFXTemplateForRenderResponse {
  __typename?: string;
  banners?: PFXBanner[];
  cardSize?: PFXCardType;
  categories?: any;
  currency?: PFXCurrency;
  disableNavbarFade?: boolean;
  drawerNavbarWhenMobile?: boolean;
  hideItemsWhenSoldOut?: boolean;
  id?: string;
  items?: any;
  logo?: string;
  name?: string;
  showAllProductsTab?: boolean;
  socialsFooter?: boolean;
  structure?: any;
  styles?: PFXTemplateStylesStructure;
  type?: string;
  upsellingIds?: string[];
}

export interface PFXTemplateStyles {
  __typename?: string;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  id?: string;
  name?: string;
  stylesStructure?: PFXTemplateStylesStructure;
  updatedAt?: string;
}

export interface PFXTemplateStylesStructure {
  __typename?: string;
  backgroundStyles?: PFXBackgroundStyles;
  cardStyles?: PFXCardStyles;
  footerStyles?: PFXFooterStyles;
  navbarStyles?: PFXNavbarStyles;
  titleStyles?: PFXTitleStyles;
}

export interface PFXTextAttributes {
  __typename?: string;
  alignment?: string;
  color?: string;
  content?: string;
  fontFamily?: string;
  fontSizeMultiplier?: number;
  fontWeight?: string;
}

export interface PFXTimeBarGraph {
  __typename?: string;
  time?: number;
  value?: number;
}

export interface PFXTimeBarGraphAverage {
  __typename?: string;
  avg?: number;
  test?: string;
  time?: number;
  value?: number;
}

export interface PFXTimezoneSelection {
  __typename?: string;
  value?: string;
}

export interface PFXTitleStyles {
  __typename?: string;
  color?: string;
  disableCategoryDivider?: boolean;
  drawerCategoryTitleAlignment?: string;
  drawerCategoryTitleColor?: string;
  drawerCategoryTitleSize?: string;
  drawerCategoryTitleWeight?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
  textFontFamily?: string;
}

export interface PFXTransactionPT {
  __typename?: string;
  createdAt?: string;
  date?: string;
  id?: string;
  orderId?: string;
  status?: number;
  updatedAt?: string;
}

export interface PFXTransactionRB {
  __typename?: string;
  createdAt?: string;
  date?: string;
  id?: string;
  orderId?: string;
  status?: number;
  updatedAt?: string;
}

export interface PFXUpsellingComponent {
  __typename?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  button?: PFXButtonAttributes;
  categoryIds?: PFXElementIdWithTemplateReference[];
  margins?: PFXMarginMultipliers;
  opaqueBackground?: PFXOpaqueBackgroundProperties;
  productIds?: PFXElementIdWithTemplateReference[];
  title?: PFXTextAttributes;
}

export interface PFXUser {
  __typename?: string;
  bankTransferImages?: string[];
  cleverlynks?: PFXModelUserCleverlynkConnection;
  commission?: PFXCommission;
  company?: PFXCompany;
  companyId?: string;
  createdAt?: string;
  defaultCriteria?: PFXCriteria[];
  defaultOrdersTimeRange?: PFXOrderTimeRangeType;
  geolocationInfo?: PFXGeolocationInfoUser;
  ignoreEmails?: boolean;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority;
  name?: string;
  onboardingSuggestions?: PFXOnboardingSuggestions;
  permissions?: PFXPermissions;
  phone?: string;
  templates?: PFXModelUserTemplateConnection;
  updatedAt?: string;
  userEmail?: string;
  userName?: string;
}

export interface PFXUserCleverlynk {
  __typename?: string;
  cleverlynk?: PFXCleverlynk;
  cleverlynkId?: string;
  createdAt?: string;
  id?: string;
  updatedAt?: string;
  user?: PFXUser;
  userName?: string;
}

export interface PFXUserConnectionLite {
  __typename?: string;
  id?: string;
  userName?: string;
}

export interface PFXUserConnectionResponse {
  __typename?: string;
  success?: boolean;
  users?: PFXUserConnectionLite[];
}

export interface PFXUserTemplate {
  __typename?: string;
  createdAt?: string;
  id?: string;
  template?: PFXTemplate;
  templateId?: string;
  updatedAt?: string;
  user?: PFXUser;
  userName?: string;
}

export interface PFXVerifyPromoCodeResponse {
  __typename?: string;
  cleverlynkPromoCodeId?: string;
  customerPromoCodeId?: string;
  promoCode?: PFXPromoCode;
}

export interface PFXWPCredsRes {
  __typename?: string;
  hasWP?: boolean;
  publicKey?: string;
}

export interface PFXWPInfo {
  __typename?: string;
  privateKey?: string;
  publicKey?: string;
  secret?: string;
}

export interface PFXWeekRange {
  __typename?: string;
  fri?: number[][];
  mon?: number[][];
  sat?: number[][];
  sun?: number[][];
  thu?: number[][];
  tue?: number[][];
  wed?: number[][];
}

export interface PFXWompiCardPSInfo {
  __typename?: string;
  cardHolder?: string;
  cardName?: string;
  customerEmail?: string;
  expiresAt?: string;
  expiryMonth?: string;
  expiryYear?: string;
  installments?: number;
  paymentSourceId?: string;
  paymentSourceStatus?: string;
  verified?: boolean;
  works?: boolean;
}

export interface PFXWompiNequiPSInfo {
  __typename?: string;
  customerEmail?: string;
  name?: string;
  paymentSourceId?: string;
  paymentSourceStatus?: string;
  phoneNumber?: string;
  userAcceptedStatus?: string;
  verified?: boolean;
  works?: boolean;
}

export interface PFXWompiPSInfo {
  __typename?: string;
  currentPS?: PFXWompiPS;
  nextMonthPS?: PFXWompiPS;
  wompiCard?: PFXWompiCardPSInfo;
  wompiNequi?: PFXWompiNequiPSInfo;
}

export interface PFXYPYCredsRes {
  __typename?: string;
  email?: string;
  hasYPY?: boolean;
  password?: string;
}

export interface PFXYPYInfo {
  __typename?: string;
  email?: string;
  password?: string;
}

export enum PFXBannerSize {
  LARGE = "LARGE",
  NORMAL = "NORMAL",
}

export enum PFXBasicBasicArrangementElement {
  BODY = "BODY",
  BUTTON = "BUTTON",
  TITLE = "TITLE",
}

export enum PFXCardType {
  LARGE = "LARGE",
  LIST = "LIST",
  NORMAL = "NORMAL",
  VERTICAL = "VERTICAL",
}

export enum PFXCleverlynkGroupType {
  GEO = "GEO",
  SELECT = "SELECT",
}

export enum PFXClynkCountry {
  ar = "ar",
  bo = "bo",
  br = "br",
  cl = "cl",
  co = "co",
  cr = "cr",
  de = "de",
  ec = "ec",
  es = "es",
  fr = "fr",
  gt = "gt",
  hn = "hn",
  it = "it",
  mx = "mx",
  ni = "ni",
  pa = "pa",
  pe = "pe",
  pr = "pr",
  pt = "pt",
  py = "py",
  sv = "sv",
  us = "us",
  uy = "uy",
  ve = "ve",
}

export enum PFXClynkLanguage {
  de = "de",
  en = "en",
  es = "es",
  fr = "fr",
  it = "it",
  pt = "pt",
}

export enum PFXCommissionType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum PFXComponentType {
  BASIC = "BASIC",
  NAVIGATION = "NAVIGATION",
  SOCIAL = "SOCIAL",
  UPSELLING = "UPSELLING",
}

export enum PFXCouponRestrictionType {
  VALID_DATE_RANGE = "VALID_DATE_RANGE",
}

export enum PFXCriteriaType {
  CALENDAR = "CALENDAR",
  DROPDOWN = "DROPDOWN",
  PHONE_NUMBER = "PHONE_NUMBER",
  TEXTFIELD = "TEXTFIELD",
}

export enum PFXCurrency {
  COP = "COP",
  MXN = "MXN",
  PEN = "PEN",
  USD = "USD",
}

export enum PFXDeliveryPriceType {
  DOMIPRIME = "DOMIPRIME",
  FIXED = "FIXED",
  GEO = "GEO",
  MENSAJEROS = "MENSAJEROS",
  PICAP = "PICAP",
  PRICE = "PRICE",
}

export enum PFXDeliveryTypeExclusiveness {
  DELIVERY = "DELIVERY",
  TAKEOUT = "TAKEOUT",
}

export enum PFXDiscountCodeType {
  FIXED = "FIXED",
  FREE_DELIVERY = "FREE_DELIVERY",
  PERCENTAGE = "PERCENTAGE",
}

export enum PFXIdentificationTypeAlegra {
  CC = "CC",
  CE = "CE",
  DIE = "DIE",
  FOREIGN_NIT = "FOREIGN_NIT",
  NIT = "NIT",
  NUIP = "NUIP",
  PP = "PP",
  RC = "RC",
  TE = "TE",
  TI = "TI",
}

export enum PFXKindOfPersonAlegra {
  LEGAL_ENTITY = "LEGAL_ENTITY",
  PERSON_ENTITY = "PERSON_ENTITY",
}

export enum PFXLandingSubtype {
  ADDRESS = "ADDRESS",
  BASIC = "BASIC",
  CAROUSEL = "CAROUSEL",
  CATEGORY = "CATEGORY",
  EXPANDED = "EXPANDED",
  GALLERY = "GALLERY",
  MULTIPLE = "MULTIPLE",
  NEWSLETTER = "NEWSLETTER",
  SELECTOR = "SELECTOR",
  SOCIALMEDIA = "SOCIALMEDIA",
  VIDEO = "VIDEO",
}

export enum PFXLogisticsProvider {
  DP = "DP",
  MU = "MU",
  OF = "OF",
  PI = "PI",
  RA = "RA",
  YPY = "YPY",
}

export enum PFXLogoShape {
  CIRCLE = "CIRCLE",
  RECTANGLE = "RECTANGLE",
}

export enum PFXMUCity {
  BARANQUILLA = "BARANQUILLA",
  BOGOTA = "BOGOTA",
  BUCARAMANGA = "BUCARAMANGA",
  CALI = "CALI",
  CARTAGENA = "CARTAGENA",
  IBAGUE = "IBAGUE",
  MEDELLIN = "MEDELLIN",
  MEXICO = "MEXICO",
  MONTERREY = "MONTERREY",
  SANTA_MARTA = "SANTA_MARTA",
}

export enum PFXMUCourierType {
  BAG = "BAG",
  ENVELOPE = "ENVELOPE",
  NONE = "NONE",
  RACK = "RACK",
  TRUNK = "TRUNK",
}

export enum PFXMailSubscriptionPriority {
  ALL = "ALL",
  JUST_ACCEPTED = "JUST_ACCEPTED",
  NONE = "NONE",
}

export enum PFXMainComponentType {
  BASIC = "BASIC",
  CAROUSEL = "CAROUSEL",
  NAVIGATION = "NAVIGATION",
}

export enum PFXModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}

export enum PFXModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PFXNotePriority {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum PFXOFEquipment {
  BOLSO_OFICIOS = "BOLSO_OFICIOS",
  CAJON_GRANDE = "CAJON_GRANDE",
  CAJON_MEDIANO = "CAJON_MEDIANO",
}

export enum PFXOrderFulfillmentType {
  DELIVERY = "DELIVERY",
  IN_PERSON = "IN_PERSON",
  TAKEOUT = "TAKEOUT",
}

export enum PFXOrderTimeRangeType {
  ALL = "ALL",
  DAY = "DAY",
  MONTH = "MONTH",
  SEMESTER = "SEMESTER",
  WEEK = "WEEK",
}

export enum PFXOriginChannel {
  CLEVERLYNK = "CLEVERLYNK",
  RAPPI = "RAPPI",
}

export enum PFXPaymentMethod {
  CASH = "CASH",
  DATAPHONE = "DATAPHONE",
  ONLINE = "ONLINE",
}

export enum PFXPicapDimensions {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
  VERY_SMALL = "VERY_SMALL",
}

export enum PFXPromoCodeType {
  COURTESY = "COURTESY",
  FIXED = "FIXED",
  FREE_DELIVERY = "FREE_DELIVERY",
  PERCENTAGE = "PERCENTAGE",
}

export enum PFXPromoRestrictionType {
  MIN_PRICE = "MIN_PRICE",
  MIN_PRODUCTS_AMOUNT = "MIN_PRODUCTS_AMOUNT",
  NUMBER_SALES_PER_CLIENT = "NUMBER_SALES_PER_CLIENT",
  SPECIFIC_PRODUCT = "SPECIFIC_PRODUCT",
  USAGE_AMOUNT = "USAGE_AMOUNT",
  VALID_DATE_RANGE = "VALID_DATE_RANGE",
  WEEK_RANGE = "WEEK_RANGE",
}

export enum PFXRappiCountry {
  AR = "AR",
  BR = "BR",
  CH = "CH",
  CO = "CO",
  CR = "CR",
  DEV = "DEV",
  EC = "EC",
  ME = "ME",
  PE = "PE",
  UR = "UR",
}

export enum PFXRevertOrderStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum PFXServiceStatus {
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
  PICKED_UP = "PICKED_UP",
  SCHEDULED = "SCHEDULED",
}

export enum PFXServiceTipType {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export enum PFXSocialsPages {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  PINTEREST = "PINTEREST",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum PFXStatus {
  DISABLED = "DISABLED",
  END = "END",
  START = "START",
}

export enum PFXSubitemDataChronologicalType {
  BOTH = "BOTH",
  FUTURE = "FUTURE",
  PAST = "PAST",
}

export enum PFXSubitemDataType {
  DATE = "DATE",
  TEXTFIELD = "TEXTFIELD",
}

export enum PFXTextAlignEnum {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum PFXTextVerticalAlignEnum {
  BOTTOM = "BOTTOM",
  CENTER = "CENTER",
  TOP = "TOP",
}

export enum PFXWompiPS {
  CARD = "CARD",
  NEQUI = "NEQUI",
}

export interface PFXACInfoInput {
  __typename?: string;
  sendEmail?: boolean | null;
}

export interface PFXAddressCityLatLngInput {
  __typename?: string;
  address: string;
  city: string;
  lat: number;
  lng: number;
}

export interface PFXAddressLatLngInput {
  __typename?: string;
  address: string;
  lat: number;
  lon: number;
}

export interface PFXAlegraCurrencyInput {
  __typename?: string;
  code: string;
  exchangeRate: number;
  symbol: string;
}

export interface PFXAlegraInput {
  __typename?: string;
  address: string;
  alegraId?: string | null;
  businessName?: string | null;
  city: string;
  country: string;
  department: string;
  email: string;
  firstName?: string | null;
  greatContributor: boolean;
  identificationNumber: string;
  identificationType: PFXIdentificationTypeAlegra;
  kindOfPerson: PFXKindOfPersonAlegra;
  lastName?: string | null;
  nationalConsmptionTax: boolean;
  notResponsibleForConsumption: boolean;
  notVatResponsible: boolean;
  ordinaryRegime: boolean;
  phonePrimary?: string | null;
  selfRetainer: boolean;
  specialRegime: boolean;
  vatSalesTax: boolean;
  withholdingAgent: boolean;
  zipCode?: string | null;
}

export interface PFXBTInfoInput {
  __typename?: string;
  urls?: Array<string> | null;
}

export interface PFXBackgroundStylesInput {
  __typename?: string;
  backgroundColor: string;
  backgroundRepeat: string;
  backgroundSize: string;
  backgroundUrl?: string | null;
  useBackgroundImage: boolean;
}

export interface PFXBankTransferInfoInput {
  __typename?: string;
  urls: Array<string>;
}

export interface PFXBannerInput {
  __typename?: string;
  backgroundColor?: string | null;
  desktopImage?: string | null;
  desktopSize?: PFXBannerSize | null;
  footer?: string | null;
  id: string;
  image?: string | null;
  imageMobileXCut?: number | null;
  mobileImage?: string | null;
  mobileSize?: PFXBannerSize | null;
  notEraseable?: boolean | null;
  opaqueBackground?: boolean | null;
  size?: PFXBannerSize | null;
  subtitle?: string | null;
  text?: string | null;
  textAlign?: PFXTextAlignEnum | null;
  textColor?: string | null;
  textFontFamily?: string | null;
  textVerticalAlign?: PFXTextVerticalAlignEnum | null;
  title?: string | null;
  type: string;
}

export interface PFXBarGraphInput {
  __typename?: string;
  name: string;
  value: number;
}

export interface PFXButtonAttributesInput {
  __typename?: string;
  backgroundColor: string;
  borderRadius: number;
  sizeMultiplier: number;
  text: PFXTextAttributesInput;
}

export interface PFXCalculateServiceInput {
  __typename?: string;
  domiprime?: PFXDPCalculateInput | null;
  mensajeros?: PFXMUCalculateInput | null;
  picap?: PFXPICalculateInput | null;
}

export interface PFXCardStylesInput {
  __typename?: string;
  borderRadius: string;
  buttonColor: string;
  cardColor: string;
  cardsAlignment?: string | null;
  largeCardFontSize?: number | null;
  secondColor: string;
  shadow: string;
  shadowSize: string;
  textButtonColor: string;
}

export interface PFXCatalogItemInput {
  __typename?: string;
  attachment?: string | null;
  beginDayHour?: string | null;
  decorative?: boolean | null;
  deliveryDelay?: number | null;
  description?: string | null;
  discount?: PFXItemDiscountInput | null;
  gid?: string | null;
  hidden?: boolean | null;
  id?: string | null;
  images?: Array<string | null> | null;
  maxAmountPerInstance?: number | null;
  maxOrderHour?: string | null;
  name: string;
  new?: boolean | null;
  posReference?: string | null;
  price?: number | null;
  priceWithDiscount?: number | null;
  sku?: string | null;
  soldOut?: boolean | null;
  subitems?: Array<PFXSubItemDataInput> | null;
  upsellingId?: string | null;
}

export interface PFXCategoriesToModify {
  __typename?: string;
  categories: Array<string>;
  templateId: string;
}

export interface PFXCategoryTemplatesInput {
  __typename?: string;
  categoriesIds?: Array<string> | null;
  templateId: string;
}

export interface PFXChangeCleverlynkStatusInput {
  __typename?: string;
  id: string;
  status?: PFXStatus | null;
}

export interface PFXCommissionInput {
  __typename?: string;
  type: PFXCommissionType;
  value: number;
}

export interface PFXCompanyConfigsInput {
  __typename?: string;
  bankTransferInfoURLs?: Array<string> | null;
  defaultTemplateStyles?: PFXTemplateStylesStructureInput | null;
  hiddenColumns?: Array<string> | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  notificationSubscriptions?: any | null;
  orderStatusNotificationPolicy?: Array<PFXOrderStatusNotificationPolicyInput> | null;
  statusMailPolicies?: Array<PFXStatusMailPolicyInput> | null;
  userMarketingConsent?: boolean | null;
  wildcardAliases?: Array<string> | null;
}

export interface PFXCompanyDataInput {
  __typename?: string;
  defaultCleverlynkId?: string | null;
  hyperlinks?: Array<PFXHyperlinksInput> | null;
  logoUrl?: string | null;
  mails?: Array<PFXCompanyMailInput> | null;
  name?: string | null;
  phones?: Array<string> | null;
  primaryColor?: string | null;
  socials?: Array<PFXSocialsInput> | null;
  subdomain?: string | null;
  taxInfo?: PFXTaxInfoInput | null;
  termsURL?: string | null;
}

export interface PFXCompanyMailInput {
  __typename?: string;
  mail: string;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
}

export interface PFXCouponRestrictionInput {
  __typename?: string;
  beginDate?: string | null;
  endDate?: string | null;
  type: PFXCouponRestrictionType;
}

export interface PFXCreateAccountInput {
  __typename?: string;
  companyName: string;
  email: string;
  logoUrl?: string | null;
  name: string;
  password: string;
  phone?: string | null;
  plan: number;
  primaryColor?: string | null;
  subdomain: string;
  userName: string;
}

export interface PFXCreateBillingInfoInput {
  __typename?: string;
  alegra?: PFXAlegraInput | null;
  cancelDate?: string | null;
  companyId: string;
  currency?: PFXAlegraCurrencyInput | null;
  currentPS?: PFXWompiPS | null;
  currentPlan: PFXPlanInput;
  dayToPay: number;
  draft?: boolean | null;
  ignore?: string | null;
  lastInvoiceDate?: string | null;
  launchDate?: string | null;
  nextMonthPS?: PFXWompiPS | null;
  nextMonthPlan?: PFXPlanInput | null;
  wompiCard?: PFXWompiCardPSInfoInput | null;
  wompiNequi?: PFXWompiNequiPSInfoInput | null;
}

export interface PFXCreateCartInput {
  __typename?: string;
  bankTransferImage?: string | null;
  check?: boolean | null;
  cleverlynkId: string;
  companyId: string;
  createdAt?: string | null;
  deliveryInfo?: PFXDeliveryInfoInput | null;
  extras?: any | null;
  fulfillmentType?: PFXOrderFulfillmentType | null;
  googleClientId?: string | null;
  hidden: boolean;
  history?: Array<PFXOrderHistoryInput> | null;
  id?: string | null;
  identification?: string | null;
  items: Array<PFXItemForOrderInput>;
  mandatoryCriteria: Array<PFXCriteriaForOrderInput>;
  mattersForSubscription?: boolean | null;
  newOrder: boolean;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
  orderStatusDetail?: number | null;
  paymentMethod: string;
  paymentStatus: number;
  price: PFXPriceInput;
  shouldSendEmail?: boolean | null;
  status: number;
  userMarketingConsent?: boolean | null;
}

export interface PFXCreateCleverlynkGroupInput {
  __typename?: string;
  cities?: Array<string> | null;
  companyId: string;
  id?: string | null;
  image?: string | null;
  landingPageId?: string | null;
  mandatory?: boolean | null;
  name: string;
  openListFirst?: boolean | null;
  title?: string | null;
  type: PFXCleverlynkGroupType;
  unifiedLandingPage?: boolean | null;
}

export interface PFXCreateCleverlynkInput {
  __typename?: string;
  abandonedCartEmail?: boolean | null;
  amountOrders: number;
  amountRenders: number;
  amountSales: number;
  bankTransferImage?: string | null;
  cleverlynkGroupId: string;
  clynkHours?: Array<Array<string> | null> | null;
  companyId: string;
  country?: PFXClynkCountry | null;
  criteria: Array<PFXCriteriaInput>;
  deliveryDays?: PFXDeliveryDaysInput | null;
  deliveryMethods?: PFXDeliveryMethodsInput | null;
  deliveryPrice?: PFXDeliveryPriceInput | null;
  deliveryTimes?: PFXDeliveryTimesInput | null;
  discount?: number | null;
  discountCodes?: Array<PFXDiscountCodeInput | null> | null;
  domiprimeSiteId?: string | null;
  email?: string | null;
  expirationDate?: string | null;
  geolocationInfo?: PFXGeolocationInfoInput | null;
  hubserActive?: boolean | null;
  hubsterActive?: boolean | null;
  id?: string | null;
  inPerson?: boolean | null;
  isUsingDeliveryTimes?: boolean | null;
  isUsingGeolocation?: boolean | null;
  landingPageId?: string | null;
  language?: PFXClynkLanguage | null;
  lastView?: string | null;
  maxOrders?: number | null;
  maxRenders?: number | null;
  maximumAmount?: number | null;
  minimumAmount?: number | null;
  muStoreId?: string | null;
  name: string;
  newOrdersAmount: number;
  ofCityCode?: number | null;
  ordersByDateRange?: PFXOrdersByDateRangeResponseInput | null;
  ownerId: string;
  paymentMethods: PFXPaymentMethodsInput;
  phone?: string | null;
  pinHash?: string | null;
  rappiStoreId?: string | null;
  renderInfo?: PFXRenderInfoInput | null;
  salt?: string | null;
  sendMessage?: string | null;
  serviceTip?: PFXServiceTipInput | null;
  status: PFXStatus;
  tags?: Array<string> | null;
  takeOut?: PFXTakeOutInput | null;
  taxInfo?: PFXTaxInfoInput | null;
  templateId: string;
  timezone?: PFXTimezoneSelectionInput | null;
  tip?: string | null;
  usesDiscountCodes?: boolean | null;
  whatsapp?: string | null;
  yapayaOfficeId?: number | null;
  zendesk?: string | null;
}

export interface PFXCreateCleverlynkPromoCodeInput {
  __typename?: string;
  cleverlynkId: string;
  id?: string | null;
  promoCodeId: string;
}

export interface PFXCreateCompanyInput {
  __typename?: string;
  URLParam?: string | null;
  abandonedCartEmail?: boolean | null;
  activeIntegrations?: PFXIntegrationsInput | null;
  bankTransferInfoURL?: string | null;
  bankTransferInfoURLs?: Array<string> | null;
  cleverlynkFee?: number | null;
  crossDomainGA?: string | null;
  defaultCleverlynkId?: string | null;
  defaultTax?: PFXTaxInfoInput | null;
  defaultTemplateStyles?: PFXTemplateStylesStructureInput | null;
  domain?: string | null;
  facebookDomainVerification?: string | null;
  googleAdsId?: string | null;
  googleAnalyticsId?: string | null;
  googleTagManagerId?: string | null;
  hiddenColumns?: Array<string> | null;
  hyperlinks?: Array<PFXHyperlinksInput | null> | null;
  id?: string | null;
  logoUrl: string;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  mails: Array<PFXCompanyMailInput>;
  maxUsers: number;
  name: string;
  nequiURL?: string | null;
  newGoogleAnalyticsId?: string | null;
  notificationSubscriptions?: any | null;
  orderStatusNotificationPolicy?: Array<PFXOrderStatusNotificationPolicyInput> | null;
  ownerId: string;
  phones: Array<string>;
  pixelId?: string | null;
  primaryColor?: string | null;
  socials?: Array<PFXSocialsInput | null> | null;
  statusMailPolicies?: Array<PFXStatusMailPolicyInput> | null;
  subdomain: string;
  termsURL?: string | null;
  tsvGenerated?: boolean | null;
  userMarketingConsent?: boolean | null;
  wildcardAliases?: Array<string> | null;
}

export interface PFXCreateContactInput {
  __typename?: string;
  address?: string | null;
  companyConnectionId: string;
  companyId: string;
  email: string;
  failedOrders: number;
  lastName?: string | null;
  lastPurchase?: string | null;
  name?: string | null;
  phone?: string | null;
  successfulOrders: number;
  totalPurchased?: number | null;
  userMarketingConsent?: boolean | null;
  wildcards?: any | null;
}

export interface PFXCreateCustomerCouponInput {
  __typename?: string;
  companyId: string;
  email: string;
  id?: string | null;
  name: string;
  restrictions?: Array<PFXCouponRestrictionInput> | null;
  value: number;
}

export interface PFXCreateCustomerFormInfoInput {
  __typename?: string;
  email: string;
  mandatory?: any | null;
  mandatoryData?: Array<PFXCriteriaForOrderInput> | null;
  optional?: any | null;
  optionalData?: Array<PFXCriteriaForOrderInput> | null;
}

export interface PFXCreateCustomerPromoCodeInput {
  __typename?: string;
  email: string;
  id?: string | null;
  promoCodeId: string;
  uses?: number | null;
}

export interface PFXCreateExternalChannelInput {
  __typename?: string;
  companyId: string;
  rappi?: PFXRPExternalInfoInput | null;
}

export interface PFXCreateInvoiceInfoInput {
  __typename?: string;
  amount: number;
  companyId: string;
  companyName: string;
  date: string;
  endInvoiceDate: string;
  invoiceId: string;
  invoiceName?: string | null;
  items?: PFXInvoiceItemsInput | null;
  paid: number;
  startInvoiceDate: string;
}

export interface PFXCreateItemInput {
  __typename?: string;
  attachment?: string | null;
  beginDayHour?: string | null;
  companyId: string;
  decorative?: boolean | null;
  deliveryDelay?: number | null;
  description?: string | null;
  discount?: PFXItemDiscountInput | null;
  gid?: string | null;
  hidden?: boolean | null;
  id?: string | null;
  imagePlaceholder?: string | null;
  images?: Array<string | null> | null;
  maxAmountPerInstance?: number | null;
  maxOrderHour?: string | null;
  name: string;
  new?: boolean | null;
  posReference?: string | null;
  price?: number | null;
  sku?: string | null;
  soldOut?: boolean | null;
  subitems?: Array<PFXSubItemDataInput> | null;
  upsellingId?: string | null;
}

export interface PFXCreateItemTemplateInput {
  __typename?: string;
  companyId: string;
  id?: string | null;
  itemId: string;
  templateId: string;
}

export interface PFXCreateLandingPageInput {
  __typename?: string;
  active?: boolean | null;
  id?: string | null;
  pages: Array<PFXPageInput>;
}

export interface PFXCreateLogisticsInfoInput {
  __typename?: string;
  companyId: string;
  domiprime?: PFXDPInfoInput | null;
  mensajerosUrbanos?: PFXMUInfoInput | null;
  oficios?: PFXOFInfoInput | null;
  picap?: PFXPIInfoInput | null;
  rapiboy?: PFXRAInfoInput | null;
  yapaya?: PFXYPYInfoInput | null;
}

export interface PFXCreateMarketingInfoInput {
  __typename?: string;
  abandonedCart?: PFXACInfoInput | null;
  companyId: string;
  ekkofy?: PFXEKInfoInput | null;
  facebookPixel?: PFXFBInfoInput | null;
  googleAnalytics?: PFXGAInfoInput | null;
  googleTagManager?: PFXGTMInfoInput | null;
  googleVerification?: PFXGVInfoInput | null;
  instagram?: PFXIGInfoInput | null;
}

export interface PFXCreateOrderInput {
  __typename?: string;
  authorizationCode?: string | null;
  bankTransferImage?: string | null;
  check?: boolean | null;
  cleverlynkId: string;
  cleverlynkPromoCodeId?: string | null;
  companyId: string;
  createdAt?: string | null;
  customerPromoCodeId?: string | null;
  deliveryInfo?: PFXDeliveryInfoInput | null;
  externalReference?: string | null;
  extras?: any | null;
  fulfillmentType?: PFXOrderFulfillmentType | null;
  googleClientId?: string | null;
  hidden: boolean;
  history?: Array<PFXOrderHistoryInput> | null;
  id?: string | null;
  identification?: string | null;
  items: Array<PFXItemForOrderInput>;
  mandatoryCriteria: Array<PFXCriteriaForOrderInput>;
  mattersForSubscription?: boolean | null;
  mensajerosId?: string | null;
  muTrackingLink?: string | null;
  newOrder: boolean;
  note?: PFXOrderNoteInput | null;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
  orderStatusDetail?: number | null;
  origin?: PFXOriginChannel | null;
  paymentMethod: string;
  paymentStatus: number;
  picapId?: string | null;
  picapTrackingLink?: string | null;
  price: PFXPriceInput;
  promoCodeId?: string | null;
  rapiboyId?: string | null;
  rapiboyTrackingLink?: string | null;
  shouldSendEmail?: boolean | null;
  status: number;
  userMarketingConsent?: boolean | null;
}

export interface PFXCreateOutOfBoundsInput {
  __typename?: string;
  address?: PFXDeliveryInfoInput | null;
  cleverlynkId: string;
  id?: string | null;
  identification?: string | null;
  items?: Array<PFXItemForOrderInput> | null;
  mandatoryCriteria?: Array<PFXCriteriaForOrderInput> | null;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
}

export interface PFXCreatePOSInfoInput {
  __typename?: string;
  cleverlynkId: string;
  deliverect?: PFXDLInfoInput | null;
  hubster?: PFXHBInfoInput | null;
  intuipos?: PFXIPInfoInput | null;
  pirpos?: PFXPPInfoInput | null;
  pirposRappi?: PFXPPRInfoInput | null;
  siesa?: PFXSSInfoInput | null;
  toteat?: PFXTTInfoInput | null;
}

export interface PFXCreatePaymentsInfoInput {
  __typename?: string;
  bankTransfer?: PFXBTInfoInput | null;
  companyId: string;
  epayco?: PFXECInfoInput | null;
  mercadoPago?: PFXMPInfoInput | null;
  payU?: PFXPUInfoInput | null;
  paymentez?: PFXPTInfoInput | null;
  paypal?: PFXPPLInfoInput | null;
  payzen?: PFXPZInfoInput | null;
  rappi?: PFXRPInfoInput | null;
  redeban?: PFXRBInfoInput | null;
  stripe?: PFXSTInfoInput | null;
  wompi?: PFXWPInfoInput | null;
}

export interface PFXCreatePotentialUserInput {
  __typename?: string;
  company: string;
  email: string;
  id?: string | null;
  lastName: string;
  name: string;
  phoneNumber: string;
  source: string;
}

export interface PFXCreatePromoCodeInput {
  __typename?: string;
  active?: boolean | null;
  amountUsed: number;
  code: string;
  companyId: string;
  cumulative: boolean;
  id?: string | null;
  name?: string | null;
  restrictions?: Array<PFXPromoRestrictionInput> | null;
  type: PFXPromoCodeType;
  value?: number | null;
}

export interface PFXCreatePushNotificationsInput {
  __typename?: string;
  subscription?: any | null;
  userName: string;
}

export interface PFXCreateSKUInput {
  __typename?: string;
  companyConnectionId: string;
  companyId: string;
  sku: string;
  stock: number;
}

export interface PFXCreateServiceDPInput {
  __typename?: string;
  address?: PFXAddressLatLngInput | null;
  indications?: string | null;
  orderId: string;
  scheduledAt?: string | null;
}

export interface PFXCreateServiceInput {
  __typename?: string;
  domiprime?: PFXCreateServiceDPInput | null;
  mensajeros?: PFXCreateServiceMUInput | null;
  oficios?: PFXCreateServiceOFInput | null;
  picap?: PFXCreateServicePIInput | null;
  rapiboy?: PFXCreateServiceRAInput | null;
  yapaya?: PFXCreateServiceYPYInput | null;
}

export interface PFXCreateServiceMUInput {
  __typename?: string;
  address?: PFXMUAddress | null;
  courierType?: PFXMUCourierType | null;
  indications?: string | null;
  orderId: string;
  scheduledAt?: string | null;
}

export interface PFXCreateServiceOFInput {
  __typename?: string;
  address?: PFXAddressLatLngInput | null;
  equipment: PFXOFEquipment;
  indications?: string | null;
  orderId: string;
  scheduledAt?: string | null;
}

export interface PFXCreateServicePIInput {
  __typename?: string;
  address?: PFXAddressLatLngInput | null;
  dimensions?: PFXPicapDimensions | null;
  indications?: string | null;
  orderId: string;
  scheduledAt?: string | null;
}

export interface PFXCreateServiceRAInput {
  __typename?: string;
  address?: PFXAddressLatLngInput | null;
  indications?: string | null;
  orderId: string;
  scheduledAt?: string | null;
}

export interface PFXCreateServiceYPYInput {
  __typename?: string;
  address?: PFXAddressLatLngInput | null;
  indications?: string | null;
  orderId: string;
}

export interface PFXCreateSubItemInput {
  __typename?: string;
  companyId: string;
  id?: string | null;
  subItemData: PFXSubItemDataInput;
}

export interface PFXCreateSubItemTemplateInput {
  __typename?: string;
  companyId: string;
  id?: string | null;
  subItemId: string;
  templateId: string;
}

export interface PFXCreateTemplateInput {
  __typename?: string;
  banners: Array<PFXBannerInput>;
  cardSize?: PFXCardType | null;
  catalog: any;
  companyId: string;
  currency?: PFXCurrency | null;
  disableNavbarFade?: boolean | null;
  drawerNavbarWhenMobile?: boolean | null;
  hideItemsWhenSoldOut?: boolean | null;
  id?: string | null;
  logo?: string | null;
  name: string;
  ownerId: string;
  showAllProductsTab: boolean;
  socialsFooter?: boolean | null;
  styles: PFXTemplateStylesStructureInput;
  type: string;
  upsellingIds?: Array<string | null> | null;
}

export interface PFXCreateTemplateStylesInput {
  __typename?: string;
  companyId: string;
  id?: string | null;
  name: string;
  stylesStructure: PFXTemplateStylesStructureInput;
}

export interface PFXCreateTransactionPTInput {
  __typename?: string;
  date: string;
  id?: string | null;
  orderId: string;
  status: number;
}

export interface PFXCreateTransactionRBInput {
  __typename?: string;
  date: string;
  id?: string | null;
  orderId: string;
  status: number;
}

export interface PFXCreateUserCleverlynkInput {
  __typename?: string;
  cleverlynkId: string;
  id?: string | null;
  userName: string;
}

export interface PFXCreateUserInput {
  __typename?: string;
  bankTransferImages?: Array<string> | null;
  commission?: PFXCommissionInput | null;
  companyId: string;
  defaultCriteria?: Array<PFXCriteriaInput> | null;
  defaultOrdersTimeRange?: PFXOrderTimeRangeType | null;
  geolocationInfo?: PFXGeolocationInfoUserInput | null;
  ignoreEmails?: boolean | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  name?: string | null;
  onboardingSuggestions?: PFXOnboardingSuggestionsInput | null;
  permissions: PFXPermissionsInput;
  phone?: string | null;
  userEmail: string;
  userName: string;
}

export interface PFXCreateUserTemplateInput {
  __typename?: string;
  id?: string | null;
  templateId: string;
  userName: string;
}

export interface PFXCriteriaForOrderInput {
  __typename?: string;
  criteriaType?: PFXCriteriaType | null;
  deliveryTypeExclusiveness?: PFXDeliveryTypeExclusiveness | null;
  label: string;
  value: string;
}

export interface PFXCriteriaInput {
  __typename?: string;
  addition: boolean;
  characterLimit?: number | null;
  criteriaType: PFXCriteriaType;
  deliveryTypeExclusiveness?: PFXDeliveryTypeExclusiveness | null;
  label: string;
  mandatory: boolean;
  values?: Array<string> | null;
}

export interface PFXCustomUpdateOrderInput {
  __typename?: string;
  deliveryInfo?: PFXDeliveryTimeInfoInput | null;
  hidden?: boolean | null;
  note?: PFXOrderNoteInput | null;
  paymentStatus?: number | null;
  status?: number | null;
}

export interface PFXCustomerPromoCodeInput {
  __typename?: string;
  email: string;
  id: string;
  promoCodeId: string;
  uses?: number | null;
}

export interface PFXDLInfoInput {
  __typename?: string;
  activeCatalog?: boolean | null;
  activeOrders?: boolean | null;
  channelLinkId?: string | null;
  hasDeliverect?: boolean | null;
}

export interface PFXDPCalculateInput {
  __typename?: string;
  cleverlynkId: string;
  companyId: string;
  deliveryAddress: PFXAddressCityLatLngInput;
  dispatchAddress: PFXAddressCityLatLngInput;
}

export interface PFXDPInfoInput {
  __typename?: string;
  nit: string;
}

export interface PFXDeleteBillingInfoInput {
  __typename?: string;
  companyId: string;
}

export interface PFXDeleteCartInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteCleverlynkGroupInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteCleverlynkInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteCleverlynkPromoCodeInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteCompanyInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteContactInput {
  __typename?: string;
  companyId: string;
  email: string;
}

export interface PFXDeleteCustomerCouponInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteCustomerFormInfoInput {
  __typename?: string;
  email: string;
}

export interface PFXDeleteCustomerPromoCodeInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteExternalChannelInput {
  __typename?: string;
  companyId: string;
}

export interface PFXDeleteInvoiceInfoInput {
  __typename?: string;
  date: string;
  invoiceId: string;
}

export interface PFXDeleteItemInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteItemTemplateInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteLandingPageInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteLogisticsInfoInput {
  __typename?: string;
  companyId: string;
}

export interface PFXDeleteMarketingInfoInput {
  __typename?: string;
  companyId: string;
}

export interface PFXDeleteOrderInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteOutOfBoundsInput {
  __typename?: string;
  id: string;
}

export interface PFXDeletePOSInfoInput {
  __typename?: string;
  cleverlynkId: string;
}

export interface PFXDeletePaymentsInfoInput {
  __typename?: string;
  companyId: string;
}

export interface PFXDeletePotentialUserInput {
  __typename?: string;
  id: string;
}

export interface PFXDeletePromoCodeInput {
  __typename?: string;
  id: string;
}

export interface PFXDeletePushNotificationsInput {
  __typename?: string;
  userName: string;
}

export interface PFXDeleteSKUInput {
  __typename?: string;
  companyId: string;
  sku: string;
}

export interface PFXDeleteSubItemInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteSubItemTemplateInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteTemplateInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteTemplateStylesInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteTransactionPTInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteTransactionRBInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteUserCleverlynkInput {
  __typename?: string;
  id: string;
}

export interface PFXDeleteUserInput {
  __typename?: string;
  userName: string;
}

export interface PFXDeleteUserTemplateInput {
  __typename?: string;
  id: string;
}

export interface PFXDeletionTargetForItemsInput {
  __typename?: string;
  itemId: string;
  templateId: string;
}

export interface PFXDeletionTargetForSubItemsInput {
  __typename?: string;
  itemId: string;
  subitemId: string;
  templateId: string;
}

export interface PFXDeliveryDaysInput {
  __typename?: string;
  delay: number;
  delivery?: Array<string | null> | null;
  ignoreDeliveryDays: Array<string>;
  selectedDeliveryDays: Array<string>;
  startDelivery?: Array<string | null> | null;
  timeFrame?: number | null;
  weekdays: Array<boolean>;
}

export interface PFXDeliveryInfoInput {
  __typename?: string;
  deliveryAdditionalInfo?: string | null;
  deliveryAddress?: string | null;
  deliveryCity?: string | null;
  deliveryDate?: string | null;
  deliveryHourRange?: Array<string | null> | null;
  deliveryLocation?: PFXLocationInput | null;
  deliveryPropertyTypeInfo?: string | null;
  deliveryTime?: string | null;
}

export interface PFXDeliveryMethodsInput {
  __typename?: string;
  mensajerosUrbanos?: boolean | null;
  picap?: boolean | null;
}

export interface PFXDeliveryPriceInput {
  __typename?: string;
  basePrice?: number | null;
  deliveryAddress?: string | null;
  minPrice?: number | null;
  percentageCovered?: number | null;
  price?: number | null;
  pricePerUnit?: number | null;
  type: PFXDeliveryPriceType;
  unitDistance?: number | null;
  values?: Array<PFXRangePriceInput> | null;
}

export interface PFXDeliveryTimeInfoInput {
  __typename?: string;
  deliveryDate: string;
  deliveryHourRange: Array<string | null>;
}

export interface PFXDeliveryTimesInput {
  __typename?: string;
  immediateDeliveryRange?: Array<number> | null;
  openHours?: Array<Array<string> | null> | null;
  scheduledDelivery?: PFXScheduledDeliveryInput | null;
}

export interface PFXDiscountCodeInput {
  __typename?: string;
  code: string;
  minPrice?: number | null;
  type: PFXDiscountCodeType;
  value?: number | null;
}

export interface PFXECInfoInput {
  __typename?: string;
  card: boolean;
  client: string;
  fee: number;
  pKey: string;
  privateKey?: string | null;
  pse: boolean;
  publicKey: string;
}

export interface PFXEKInfoInput {
  __typename?: string;
  apiToken: string;
  companyCode: string;
}

export interface PFXExlcusiveStartKey {
  __typename?: string;
  id: string;
}

export interface PFXFBInfoInput {
  __typename?: string;
  accessToken?: string | null;
  pixelId?: string | null;
}

export interface PFXFooterComponentInput {
  __typename?: string;
  alignment: string;
  backgroundColor: string;
  color: string;
  hidden: boolean;
}

export interface PFXFooterStylesInput {
  __typename?: string;
  alignment?: string | null;
  backgroundColor: string;
  textColor: string;
}

export interface PFXGAInfoInput {
  __typename?: string;
  crossDomainGA?: string | null;
  googleAnalyticsId?: string | null;
}

export interface PFXGTMInfoInput {
  __typename?: string;
  googleTagManagerId?: string | null;
}

export interface PFXGVInfoInput {
  __typename?: string;
  googleCodeVerification: string;
}

export interface PFXGenerateUserInput {
  __typename?: string;
  bankTransferImages?: Array<string> | null;
  commission?: PFXCommissionInput | null;
  companyId: string;
  defaultCriteria?: Array<PFXCriteriaInput> | null;
  defaultOrdersTimeRange?: PFXOrderTimeRangeType | null;
  geolocationInfo?: PFXGeolocationInfoUserInput | null;
  ignoreEmails?: boolean | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  name?: string | null;
  onboardingSuggestions?: PFXOnboardingSuggestionsInput | null;
  permissions: PFXPermissionsInput;
  phone?: string | null;
  userEmail: string;
  userName: string;
}

export interface PFXGeolocationInfoInput {
  __typename?: string;
  location: PFXLocationInput;
  polyType: string;
  polygon: Array<PFXLocationInput>;
  radius: number;
}

export interface PFXGeolocationInfoUserInput {
  __typename?: string;
  info?: PFXGeolocationInfoInput | null;
  minPrice: number;
  pricePerUnit: number;
  unitDistance: number;
}

export interface PFXHBInfoInput {
  __typename?: string;
  activeOrders?: boolean | null;
  hasHubster?: boolean | null;
}

export interface PFXHyperlinksInput {
  __typename?: string;
  name: string;
  url: string;
}

export interface PFXIGInfoInput {
  __typename?: string;
  facebookDomainVerification?: string | null;
  tsvGenerated?: boolean | null;
}

export interface PFXIPInfoInput {
  __typename?: string;
  activeOrders?: boolean | null;
  apiKey?: string | null;
  deliveryProductId?: string | null;
  hasIntuiPOS?: boolean | null;
  storeId?: string | null;
  storeId2?: string | null;
  storeName?: string | null;
}

export interface PFXIncomeOriginDataInput {
  __typename?: string;
  name: string;
  value: number;
}

export interface PFXIncomeOriginInput {
  __typename?: string;
  ExclusiveStartKey?: PFXExlcusiveStartKey | null;
  endDate?: string | null;
  incomeOriginData: Array<PFXIncomeOriginDataInput | null>;
  startDate?: string | null;
}

export interface PFXIntegrationsInput {
  __typename?: string;
  deliverect?: boolean | null;
  hubster?: boolean | null;
  intuipos?: boolean | null;
  pirpos?: boolean | null;
  pirposRappi?: boolean | null;
  toteat?: boolean | null;
}

export interface PFXInvoiceItemsInput {
  __typename?: string;
  amountOrders?: number | null;
  amountOrdersInPerson?: number | null;
  chargeableMonths?: number | null;
  fixedOrder?: number | null;
  fixedOrderVAT?: number | null;
  fixedSubscription?: number | null;
  fixedSubscriptionVAT?: number | null;
  implementation?: number | null;
  implementationVAT?: number | null;
  percentageOrder?: number | null;
  percentageOrderInPerson?: number | null;
  percentageOrderInPersonVAT?: number | null;
  percentageOrderVAT?: number | null;
  salesOrders?: number | null;
  salesOrdersInPerson?: number | null;
}

export interface PFXItemDiscountInput {
  __typename?: string;
  amount?: number | null;
  type: string;
}

export interface PFXItemForOrderInput {
  __typename?: string;
  chosenOptions?: Array<PFXSubItemChosenOptionInput> | null;
  deliveryDelay?: number | null;
  frankieReference?: string | null;
  id: string;
  images: Array<string>;
  maxAmountPerInstance?: number | null;
  maxOrderHour?: string | null;
  name: string;
  og_price: number;
  posReference?: string | null;
  price: number;
  quantity: number;
  sku?: string | null;
  subitems: Array<PFXSubItemDataInput>;
}

export interface PFXLandingComponentInput {
  __typename?: string;
  data: any;
  hidden: boolean;
  id: string;
  subtype?: PFXLandingSubtype | null;
  type?: PFXComponentType | null;
}

export interface PFXLastEvalutedKeyIndexInput {
  __typename?: string;
  id: string;
}

export interface PFXLocationInput {
  __typename?: string;
  lat: number;
  lng: number;
}

export interface PFXLogoAttributesInput {
  __typename?: string;
  activate: boolean;
  alignment: string;
  shape: PFXLogoShape;
  sizeMultiplier: number;
}

export interface PFXMPInfoInput {
  __typename?: string;
  accessToken: string;
  card: boolean;
  fixedCommission: number;
  pse: boolean;
  refreshToken: string;
  refreshedAt?: string | null;
  variableCommission: number;
}

export interface PFXMUAddress {
  __typename?: string;
  address: string;
  city: string;
  lat?: number | null;
  lon?: number | null;
}

export interface PFXMUCalculateInput {
  __typename?: string;
  cityNumber: number;
  cleverlynkId: string;
  companyId: string;
  coordinates: Array<PFXMUAddress>;
  declaredValue: number;
  parkingSurcharge: number;
  paymentMethod: PFXPaymentMethod;
  utcDate: string;
}

export interface PFXMUCreateStoreInput {
  __typename?: string;
  storeAddress: string;
  storeCity: PFXMUCity;
  storeName: string;
  storeParkingCost?: number | null;
  storePhone: string;
  storeSchedule?: string | null;
}

export interface PFXMUInfoInput {
  __typename?: string;
  clientId: string;
  clientSecretKey: string;
  extraCost: number;
  idCompany?: number | null;
  idUser: number;
  stores?: Array<PFXMUStoreInput> | null;
}

export interface PFXMUStoreInput {
  __typename?: string;
  storeAddress: string;
  storeCity: PFXMUCity;
  storeId: string;
  storeName: string;
  storeParkingCost?: number | null;
  storePhone: string;
  storeSchedule?: string | null;
}

export interface PFXMainComponentInput {
  __typename?: string;
  backgroundColor: string;
  backgroundImage?: string | null;
  button: PFXButtonAttributesInput;
  logo: PFXLogoAttributesInput;
  margins: PFXMarginMultipliersInput;
  opaqueBackground?: PFXOpaqueBackgroundPropertiesInput | null;
  title: PFXTextAttributesInput;
  type: PFXMainComponentType;
  verticalAlignment: string;
}

export interface PFXMarginMultipliersInput {
  __typename?: string;
  x: number;
  y: number;
}

export interface PFXModelBillingInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelBillingInfoConditionInput | null> | null;
  cancelDate?: PFXModelStringInput | null;
  currentPS?: PFXModelWompiPSInput | null;
  dayToPay?: PFXModelIntInput | null;
  draft?: PFXModelBooleanInput | null;
  ignore?: PFXModelStringInput | null;
  lastInvoiceDate?: PFXModelStringInput | null;
  launchDate?: PFXModelStringInput | null;
  nextMonthPS?: PFXModelWompiPSInput | null;
  not?: PFXModelBillingInfoConditionInput | null;
  or?: Array<PFXModelBillingInfoConditionInput | null> | null;
}

export interface PFXModelBillingInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelBillingInfoFilterInput | null> | null;
  cancelDate?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  currentPS?: PFXModelWompiPSInput | null;
  dayToPay?: PFXModelIntInput | null;
  draft?: PFXModelBooleanInput | null;
  ignore?: PFXModelStringInput | null;
  lastInvoiceDate?: PFXModelStringInput | null;
  launchDate?: PFXModelStringInput | null;
  nextMonthPS?: PFXModelWompiPSInput | null;
  not?: PFXModelBillingInfoFilterInput | null;
  or?: Array<PFXModelBillingInfoFilterInput | null> | null;
}

export interface PFXModelBooleanInput {
  __typename?: string;
  attributeExists?: boolean | null;
  attributeType?: PFXModelAttributeTypes | null;
  eq?: boolean | null;
  ne?: boolean | null;
}

export interface PFXModelCardTypeInput {
  __typename?: string;
  eq?: PFXCardType | null;
  ne?: PFXCardType | null;
}

export interface PFXModelCartConditionInput {
  __typename?: string;
  and?: Array<PFXModelCartConditionInput | null> | null;
  bankTransferImage?: PFXModelStringInput | null;
  check?: PFXModelBooleanInput | null;
  cleverlynkId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  createdAt?: PFXModelStringInput | null;
  extras?: PFXModelStringInput | null;
  fulfillmentType?: PFXModelOrderFulfillmentTypeInput | null;
  googleClientId?: PFXModelStringInput | null;
  hidden?: PFXModelBooleanInput | null;
  identification?: PFXModelStringInput | null;
  mattersForSubscription?: PFXModelBooleanInput | null;
  newOrder?: PFXModelBooleanInput | null;
  not?: PFXModelCartConditionInput | null;
  or?: Array<PFXModelCartConditionInput | null> | null;
  orderStatusDetail?: PFXModelIntInput | null;
  paymentMethod?: PFXModelStringInput | null;
  paymentStatus?: PFXModelIntInput | null;
  shouldSendEmail?: PFXModelBooleanInput | null;
  status?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
}

export interface PFXModelCartFilterInput {
  __typename?: string;
  and?: Array<PFXModelCartFilterInput | null> | null;
  bankTransferImage?: PFXModelStringInput | null;
  check?: PFXModelBooleanInput | null;
  cleverlynkId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  createdAt?: PFXModelStringInput | null;
  extras?: PFXModelStringInput | null;
  fulfillmentType?: PFXModelOrderFulfillmentTypeInput | null;
  googleClientId?: PFXModelStringInput | null;
  hidden?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  identification?: PFXModelStringInput | null;
  mattersForSubscription?: PFXModelBooleanInput | null;
  newOrder?: PFXModelBooleanInput | null;
  not?: PFXModelCartFilterInput | null;
  or?: Array<PFXModelCartFilterInput | null> | null;
  orderStatusDetail?: PFXModelIntInput | null;
  paymentMethod?: PFXModelStringInput | null;
  paymentStatus?: PFXModelIntInput | null;
  shouldSendEmail?: PFXModelBooleanInput | null;
  status?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
}

export interface PFXModelCleverlynkConditionInput {
  __typename?: string;
  abandonedCartEmail?: PFXModelBooleanInput | null;
  amountOrders?: PFXModelIntInput | null;
  amountRenders?: PFXModelIntInput | null;
  amountSales?: PFXModelFloatInput | null;
  and?: Array<PFXModelCleverlynkConditionInput | null> | null;
  bankTransferImage?: PFXModelStringInput | null;
  cleverlynkGroupId?: PFXModelIDInput | null;
  clynkHours?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  country?: PFXModelClynkCountryInput | null;
  discount?: PFXModelFloatInput | null;
  domiprimeSiteId?: PFXModelStringInput | null;
  email?: PFXModelStringInput | null;
  expirationDate?: PFXModelStringInput | null;
  hubserActive?: PFXModelBooleanInput | null;
  hubsterActive?: PFXModelBooleanInput | null;
  inPerson?: PFXModelBooleanInput | null;
  isUsingDeliveryTimes?: PFXModelBooleanInput | null;
  isUsingGeolocation?: PFXModelBooleanInput | null;
  landingPageId?: PFXModelIDInput | null;
  language?: PFXModelClynkLanguageInput | null;
  lastView?: PFXModelStringInput | null;
  maxOrders?: PFXModelIntInput | null;
  maxRenders?: PFXModelIntInput | null;
  maximumAmount?: PFXModelFloatInput | null;
  minimumAmount?: PFXModelFloatInput | null;
  muStoreId?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  newOrdersAmount?: PFXModelIntInput | null;
  not?: PFXModelCleverlynkConditionInput | null;
  ofCityCode?: PFXModelIntInput | null;
  or?: Array<PFXModelCleverlynkConditionInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  phone?: PFXModelStringInput | null;
  pinHash?: PFXModelStringInput | null;
  rappiStoreId?: PFXModelStringInput | null;
  salt?: PFXModelStringInput | null;
  sendMessage?: PFXModelStringInput | null;
  status?: PFXModelStatusInput | null;
  tags?: PFXModelStringInput | null;
  templateId?: PFXModelIDInput | null;
  tip?: PFXModelStringInput | null;
  usesDiscountCodes?: PFXModelBooleanInput | null;
  whatsapp?: PFXModelStringInput | null;
  yapayaOfficeId?: PFXModelIntInput | null;
  zendesk?: PFXModelStringInput | null;
}

export interface PFXModelCleverlynkFilterInput {
  __typename?: string;
  abandonedCartEmail?: PFXModelBooleanInput | null;
  amountOrders?: PFXModelIntInput | null;
  amountRenders?: PFXModelIntInput | null;
  amountSales?: PFXModelFloatInput | null;
  and?: Array<PFXModelCleverlynkFilterInput | null> | null;
  bankTransferImage?: PFXModelStringInput | null;
  cleverlynkGroupId?: PFXModelIDInput | null;
  clynkHours?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  country?: PFXModelClynkCountryInput | null;
  discount?: PFXModelFloatInput | null;
  domiprimeSiteId?: PFXModelStringInput | null;
  email?: PFXModelStringInput | null;
  expirationDate?: PFXModelStringInput | null;
  hubserActive?: PFXModelBooleanInput | null;
  hubsterActive?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  inPerson?: PFXModelBooleanInput | null;
  isUsingDeliveryTimes?: PFXModelBooleanInput | null;
  isUsingGeolocation?: PFXModelBooleanInput | null;
  landingPageId?: PFXModelIDInput | null;
  language?: PFXModelClynkLanguageInput | null;
  lastView?: PFXModelStringInput | null;
  maxOrders?: PFXModelIntInput | null;
  maxRenders?: PFXModelIntInput | null;
  maximumAmount?: PFXModelFloatInput | null;
  minimumAmount?: PFXModelFloatInput | null;
  muStoreId?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  newOrdersAmount?: PFXModelIntInput | null;
  not?: PFXModelCleverlynkFilterInput | null;
  ofCityCode?: PFXModelIntInput | null;
  or?: Array<PFXModelCleverlynkFilterInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  phone?: PFXModelStringInput | null;
  pinHash?: PFXModelStringInput | null;
  rappiStoreId?: PFXModelStringInput | null;
  salt?: PFXModelStringInput | null;
  sendMessage?: PFXModelStringInput | null;
  status?: PFXModelStatusInput | null;
  tags?: PFXModelStringInput | null;
  templateId?: PFXModelIDInput | null;
  tip?: PFXModelStringInput | null;
  usesDiscountCodes?: PFXModelBooleanInput | null;
  whatsapp?: PFXModelStringInput | null;
  yapayaOfficeId?: PFXModelIntInput | null;
  zendesk?: PFXModelStringInput | null;
}

export interface PFXModelCleverlynkGroupConditionInput {
  __typename?: string;
  and?: Array<PFXModelCleverlynkGroupConditionInput | null> | null;
  cities?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  image?: PFXModelStringInput | null;
  landingPageId?: PFXModelIDInput | null;
  mandatory?: PFXModelBooleanInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelCleverlynkGroupConditionInput | null;
  openListFirst?: PFXModelBooleanInput | null;
  or?: Array<PFXModelCleverlynkGroupConditionInput | null> | null;
  title?: PFXModelStringInput | null;
  type?: PFXModelCleverlynkGroupTypeInput | null;
  unifiedLandingPage?: PFXModelBooleanInput | null;
}

export interface PFXModelCleverlynkGroupFilterInput {
  __typename?: string;
  and?: Array<PFXModelCleverlynkGroupFilterInput | null> | null;
  cities?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  image?: PFXModelStringInput | null;
  landingPageId?: PFXModelIDInput | null;
  mandatory?: PFXModelBooleanInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelCleverlynkGroupFilterInput | null;
  openListFirst?: PFXModelBooleanInput | null;
  or?: Array<PFXModelCleverlynkGroupFilterInput | null> | null;
  title?: PFXModelStringInput | null;
  type?: PFXModelCleverlynkGroupTypeInput | null;
  unifiedLandingPage?: PFXModelBooleanInput | null;
}

export interface PFXModelCleverlynkGroupTypeInput {
  __typename?: string;
  eq?: PFXCleverlynkGroupType | null;
  ne?: PFXCleverlynkGroupType | null;
}

export interface PFXModelCleverlynkPromoCodeConditionInput {
  __typename?: string;
  and?: Array<PFXModelCleverlynkPromoCodeConditionInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  not?: PFXModelCleverlynkPromoCodeConditionInput | null;
  or?: Array<PFXModelCleverlynkPromoCodeConditionInput | null> | null;
  promoCodeId?: PFXModelIDInput | null;
}

export interface PFXModelCleverlynkPromoCodeFilterInput {
  __typename?: string;
  and?: Array<PFXModelCleverlynkPromoCodeFilterInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelCleverlynkPromoCodeFilterInput | null;
  or?: Array<PFXModelCleverlynkPromoCodeFilterInput | null> | null;
  promoCodeId?: PFXModelIDInput | null;
}

export interface PFXModelClynkCountryInput {
  __typename?: string;
  eq?: PFXClynkCountry | null;
  ne?: PFXClynkCountry | null;
}

export interface PFXModelClynkLanguageInput {
  __typename?: string;
  eq?: PFXClynkLanguage | null;
  ne?: PFXClynkLanguage | null;
}

export interface PFXModelCompanyConditionInput {
  __typename?: string;
  URLParam?: PFXModelStringInput | null;
  abandonedCartEmail?: PFXModelBooleanInput | null;
  and?: Array<PFXModelCompanyConditionInput | null> | null;
  bankTransferInfoURL?: PFXModelStringInput | null;
  bankTransferInfoURLs?: PFXModelStringInput | null;
  cleverlynkFee?: PFXModelIntInput | null;
  crossDomainGA?: PFXModelStringInput | null;
  defaultCleverlynkId?: PFXModelIDInput | null;
  domain?: PFXModelStringInput | null;
  facebookDomainVerification?: PFXModelStringInput | null;
  googleAdsId?: PFXModelStringInput | null;
  googleAnalyticsId?: PFXModelStringInput | null;
  googleTagManagerId?: PFXModelStringInput | null;
  hiddenColumns?: PFXModelStringInput | null;
  logoUrl?: PFXModelStringInput | null;
  mailSubscriptionPriority?: PFXModelMailSubscriptionPriorityInput | null;
  maxUsers?: PFXModelIntInput | null;
  name?: PFXModelStringInput | null;
  nequiURL?: PFXModelStringInput | null;
  newGoogleAnalyticsId?: PFXModelStringInput | null;
  not?: PFXModelCompanyConditionInput | null;
  notificationSubscriptions?: PFXModelStringInput | null;
  or?: Array<PFXModelCompanyConditionInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  phones?: PFXModelStringInput | null;
  pixelId?: PFXModelStringInput | null;
  primaryColor?: PFXModelStringInput | null;
  subdomain?: PFXModelStringInput | null;
  termsURL?: PFXModelStringInput | null;
  tsvGenerated?: PFXModelBooleanInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  wildcardAliases?: PFXModelStringInput | null;
}

export interface PFXModelCompanyFilterInput {
  __typename?: string;
  URLParam?: PFXModelStringInput | null;
  abandonedCartEmail?: PFXModelBooleanInput | null;
  and?: Array<PFXModelCompanyFilterInput | null> | null;
  bankTransferInfoURL?: PFXModelStringInput | null;
  bankTransferInfoURLs?: PFXModelStringInput | null;
  cleverlynkFee?: PFXModelIntInput | null;
  crossDomainGA?: PFXModelStringInput | null;
  defaultCleverlynkId?: PFXModelIDInput | null;
  domain?: PFXModelStringInput | null;
  facebookDomainVerification?: PFXModelStringInput | null;
  googleAdsId?: PFXModelStringInput | null;
  googleAnalyticsId?: PFXModelStringInput | null;
  googleTagManagerId?: PFXModelStringInput | null;
  hiddenColumns?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  logoUrl?: PFXModelStringInput | null;
  mailSubscriptionPriority?: PFXModelMailSubscriptionPriorityInput | null;
  maxUsers?: PFXModelIntInput | null;
  name?: PFXModelStringInput | null;
  nequiURL?: PFXModelStringInput | null;
  newGoogleAnalyticsId?: PFXModelStringInput | null;
  not?: PFXModelCompanyFilterInput | null;
  notificationSubscriptions?: PFXModelStringInput | null;
  or?: Array<PFXModelCompanyFilterInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  phones?: PFXModelStringInput | null;
  pixelId?: PFXModelStringInput | null;
  primaryColor?: PFXModelStringInput | null;
  subdomain?: PFXModelStringInput | null;
  termsURL?: PFXModelStringInput | null;
  tsvGenerated?: PFXModelBooleanInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  wildcardAliases?: PFXModelStringInput | null;
}

export interface PFXModelContactConditionInput {
  __typename?: string;
  address?: PFXModelStringInput | null;
  and?: Array<PFXModelContactConditionInput | null> | null;
  companyConnectionId?: PFXModelIDInput | null;
  failedOrders?: PFXModelIntInput | null;
  lastName?: PFXModelStringInput | null;
  lastPurchase?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelContactConditionInput | null;
  or?: Array<PFXModelContactConditionInput | null> | null;
  phone?: PFXModelStringInput | null;
  successfulOrders?: PFXModelIntInput | null;
  totalPurchased?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  wildcards?: PFXModelStringInput | null;
}

export interface PFXModelContactFilterInput {
  __typename?: string;
  address?: PFXModelStringInput | null;
  and?: Array<PFXModelContactFilterInput | null> | null;
  companyConnectionId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  email?: PFXModelStringInput | null;
  failedOrders?: PFXModelIntInput | null;
  lastName?: PFXModelStringInput | null;
  lastPurchase?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelContactFilterInput | null;
  or?: Array<PFXModelContactFilterInput | null> | null;
  phone?: PFXModelStringInput | null;
  successfulOrders?: PFXModelIntInput | null;
  totalPurchased?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  wildcards?: PFXModelStringInput | null;
}

export interface PFXModelCurrencyInput {
  __typename?: string;
  eq?: PFXCurrency | null;
  ne?: PFXCurrency | null;
}

export interface PFXModelCustomerCouponConditionInput {
  __typename?: string;
  and?: Array<PFXModelCustomerCouponConditionInput | null> | null;
  companyId?: PFXModelIDInput | null;
  email?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelCustomerCouponConditionInput | null;
  or?: Array<PFXModelCustomerCouponConditionInput | null> | null;
  value?: PFXModelFloatInput | null;
}

export interface PFXModelCustomerCouponFilterInput {
  __typename?: string;
  and?: Array<PFXModelCustomerCouponFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  email?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelCustomerCouponFilterInput | null;
  or?: Array<PFXModelCustomerCouponFilterInput | null> | null;
  value?: PFXModelFloatInput | null;
}

export interface PFXModelCustomerFormInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelCustomerFormInfoConditionInput | null> | null;
  mandatory?: PFXModelStringInput | null;
  not?: PFXModelCustomerFormInfoConditionInput | null;
  optional?: PFXModelStringInput | null;
  or?: Array<PFXModelCustomerFormInfoConditionInput | null> | null;
}

export interface PFXModelCustomerFormInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelCustomerFormInfoFilterInput | null> | null;
  email?: PFXModelStringInput | null;
  mandatory?: PFXModelStringInput | null;
  not?: PFXModelCustomerFormInfoFilterInput | null;
  optional?: PFXModelStringInput | null;
  or?: Array<PFXModelCustomerFormInfoFilterInput | null> | null;
}

export interface PFXModelCustomerPromoCodeConditionInput {
  __typename?: string;
  and?: Array<PFXModelCustomerPromoCodeConditionInput | null> | null;
  email?: PFXModelStringInput | null;
  not?: PFXModelCustomerPromoCodeConditionInput | null;
  or?: Array<PFXModelCustomerPromoCodeConditionInput | null> | null;
  promoCodeId?: PFXModelIDInput | null;
  uses?: PFXModelIntInput | null;
}

export interface PFXModelCustomerPromoCodeFilterInput {
  __typename?: string;
  and?: Array<PFXModelCustomerPromoCodeFilterInput | null> | null;
  email?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelCustomerPromoCodeFilterInput | null;
  or?: Array<PFXModelCustomerPromoCodeFilterInput | null> | null;
  promoCodeId?: PFXModelIDInput | null;
  uses?: PFXModelIntInput | null;
}

export interface PFXModelExternalChannelConditionInput {
  __typename?: string;
  and?: Array<PFXModelExternalChannelConditionInput | null> | null;
  not?: PFXModelExternalChannelConditionInput | null;
  or?: Array<PFXModelExternalChannelConditionInput | null> | null;
}

export interface PFXModelExternalChannelFilterInput {
  __typename?: string;
  and?: Array<PFXModelExternalChannelFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelExternalChannelFilterInput | null;
  or?: Array<PFXModelExternalChannelFilterInput | null> | null;
}

export interface PFXModelFloatInput {
  __typename?: string;
  attributeExists?: boolean | null;
  attributeType?: PFXModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
}

export interface PFXModelIDInput {
  __typename?: string;
  attributeExists?: boolean | null;
  attributeType?: PFXModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: PFXModelSizeInput | null;
}

export interface PFXModelIDKeyConditionInput {
  __typename?: string;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
}

export interface PFXModelIntInput {
  __typename?: string;
  attributeExists?: boolean | null;
  attributeType?: PFXModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
}

export interface PFXModelInvoiceInfoConditionInput {
  __typename?: string;
  amount?: PFXModelFloatInput | null;
  and?: Array<PFXModelInvoiceInfoConditionInput | null> | null;
  companyId?: PFXModelStringInput | null;
  companyName?: PFXModelStringInput | null;
  endInvoiceDate?: PFXModelStringInput | null;
  invoiceName?: PFXModelStringInput | null;
  not?: PFXModelInvoiceInfoConditionInput | null;
  or?: Array<PFXModelInvoiceInfoConditionInput | null> | null;
  paid?: PFXModelIntInput | null;
  startInvoiceDate?: PFXModelStringInput | null;
}

export interface PFXModelInvoiceInfoFilterInput {
  __typename?: string;
  amount?: PFXModelFloatInput | null;
  and?: Array<PFXModelInvoiceInfoFilterInput | null> | null;
  companyId?: PFXModelStringInput | null;
  companyName?: PFXModelStringInput | null;
  date?: PFXModelStringInput | null;
  endInvoiceDate?: PFXModelStringInput | null;
  invoiceId?: PFXModelIDInput | null;
  invoiceName?: PFXModelStringInput | null;
  not?: PFXModelInvoiceInfoFilterInput | null;
  or?: Array<PFXModelInvoiceInfoFilterInput | null> | null;
  paid?: PFXModelIntInput | null;
  startInvoiceDate?: PFXModelStringInput | null;
}

export interface PFXModelItemConditionInput {
  __typename?: string;
  and?: Array<PFXModelItemConditionInput | null> | null;
  attachment?: PFXModelStringInput | null;
  beginDayHour?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  decorative?: PFXModelBooleanInput | null;
  deliveryDelay?: PFXModelIntInput | null;
  description?: PFXModelStringInput | null;
  gid?: PFXModelStringInput | null;
  hidden?: PFXModelBooleanInput | null;
  imagePlaceholder?: PFXModelStringInput | null;
  images?: PFXModelStringInput | null;
  maxAmountPerInstance?: PFXModelIntInput | null;
  maxOrderHour?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  new?: PFXModelBooleanInput | null;
  not?: PFXModelItemConditionInput | null;
  or?: Array<PFXModelItemConditionInput | null> | null;
  posReference?: PFXModelStringInput | null;
  price?: PFXModelFloatInput | null;
  priceWithDiscount?: PFXModelFloatInput | null;
  sku?: PFXModelStringInput | null;
  soldOut?: PFXModelBooleanInput | null;
  upsellingId?: PFXModelStringInput | null;
}

export interface PFXModelItemFilterInput {
  __typename?: string;
  and?: Array<PFXModelItemFilterInput | null> | null;
  attachment?: PFXModelStringInput | null;
  beginDayHour?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  decorative?: PFXModelBooleanInput | null;
  deliveryDelay?: PFXModelIntInput | null;
  description?: PFXModelStringInput | null;
  gid?: PFXModelStringInput | null;
  hidden?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  imagePlaceholder?: PFXModelStringInput | null;
  images?: PFXModelStringInput | null;
  maxAmountPerInstance?: PFXModelIntInput | null;
  maxOrderHour?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  new?: PFXModelBooleanInput | null;
  not?: PFXModelItemFilterInput | null;
  or?: Array<PFXModelItemFilterInput | null> | null;
  posReference?: PFXModelStringInput | null;
  price?: PFXModelFloatInput | null;
  priceWithDiscount?: PFXModelFloatInput | null;
  sku?: PFXModelStringInput | null;
  soldOut?: PFXModelBooleanInput | null;
  upsellingId?: PFXModelStringInput | null;
}

export interface PFXModelItemTemplateConditionInput {
  __typename?: string;
  and?: Array<PFXModelItemTemplateConditionInput | null> | null;
  companyId?: PFXModelIDInput | null;
  itemId?: PFXModelIDInput | null;
  not?: PFXModelItemTemplateConditionInput | null;
  or?: Array<PFXModelItemTemplateConditionInput | null> | null;
  templateId?: PFXModelIDInput | null;
}

export interface PFXModelItemTemplateFilterInput {
  __typename?: string;
  and?: Array<PFXModelItemTemplateFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  itemId?: PFXModelIDInput | null;
  not?: PFXModelItemTemplateFilterInput | null;
  or?: Array<PFXModelItemTemplateFilterInput | null> | null;
  templateId?: PFXModelIDInput | null;
}

export interface PFXModelLandingPageConditionInput {
  __typename?: string;
  active?: PFXModelBooleanInput | null;
  and?: Array<PFXModelLandingPageConditionInput | null> | null;
  not?: PFXModelLandingPageConditionInput | null;
  or?: Array<PFXModelLandingPageConditionInput | null> | null;
}

export interface PFXModelLandingPageFilterInput {
  __typename?: string;
  active?: PFXModelBooleanInput | null;
  and?: Array<PFXModelLandingPageFilterInput | null> | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelLandingPageFilterInput | null;
  or?: Array<PFXModelLandingPageFilterInput | null> | null;
}

export interface PFXModelLogisticsInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelLogisticsInfoConditionInput | null> | null;
  not?: PFXModelLogisticsInfoConditionInput | null;
  or?: Array<PFXModelLogisticsInfoConditionInput | null> | null;
}

export interface PFXModelLogisticsInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelLogisticsInfoFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelLogisticsInfoFilterInput | null;
  or?: Array<PFXModelLogisticsInfoFilterInput | null> | null;
}

export interface PFXModelMailSubscriptionPriorityInput {
  __typename?: string;
  eq?: PFXMailSubscriptionPriority | null;
  ne?: PFXMailSubscriptionPriority | null;
}

export interface PFXModelMarketingInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelMarketingInfoConditionInput | null> | null;
  not?: PFXModelMarketingInfoConditionInput | null;
  or?: Array<PFXModelMarketingInfoConditionInput | null> | null;
}

export interface PFXModelMarketingInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelMarketingInfoFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelMarketingInfoFilterInput | null;
  or?: Array<PFXModelMarketingInfoFilterInput | null> | null;
}

export interface PFXModelOrderConditionInput {
  __typename?: string;
  and?: Array<PFXModelOrderConditionInput | null> | null;
  authorizationCode?: PFXModelStringInput | null;
  bankTransferImage?: PFXModelStringInput | null;
  check?: PFXModelBooleanInput | null;
  cleverlynkId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  createdAt?: PFXModelStringInput | null;
  errors?: PFXModelStringInput | null;
  externalReference?: PFXModelStringInput | null;
  extras?: PFXModelStringInput | null;
  fulfillmentType?: PFXModelOrderFulfillmentTypeInput | null;
  googleClientId?: PFXModelStringInput | null;
  hasDomiprimeService?: PFXModelBooleanInput | null;
  hidden?: PFXModelBooleanInput | null;
  identification?: PFXModelStringInput | null;
  mattersForSubscription?: PFXModelBooleanInput | null;
  mensajerosId?: PFXModelStringInput | null;
  metadata?: PFXModelStringInput | null;
  muTrackingLink?: PFXModelStringInput | null;
  newOrder?: PFXModelBooleanInput | null;
  not?: PFXModelOrderConditionInput | null;
  oficiosId?: PFXModelStringInput | null;
  or?: Array<PFXModelOrderConditionInput | null> | null;
  orderStatusDetail?: PFXModelIntInput | null;
  origin?: PFXModelOriginChannelInput | null;
  paymentMethod?: PFXModelStringInput | null;
  paymentStatus?: PFXModelIntInput | null;
  picapId?: PFXModelStringInput | null;
  picapTrackingLink?: PFXModelStringInput | null;
  promoCodeId?: PFXModelIDInput | null;
  rapiboyId?: PFXModelStringInput | null;
  shouldSendEmail?: PFXModelBooleanInput | null;
  status?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  yapayaId?: PFXModelStringInput | null;
}

export interface PFXModelOrderFilterInput {
  __typename?: string;
  and?: Array<PFXModelOrderFilterInput | null> | null;
  authorizationCode?: PFXModelStringInput | null;
  bankTransferImage?: PFXModelStringInput | null;
  check?: PFXModelBooleanInput | null;
  cleverlynkId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  createdAt?: PFXModelStringInput | null;
  errors?: PFXModelStringInput | null;
  externalReference?: PFXModelStringInput | null;
  extras?: PFXModelStringInput | null;
  fulfillmentType?: PFXModelOrderFulfillmentTypeInput | null;
  googleClientId?: PFXModelStringInput | null;
  hasDomiprimeService?: PFXModelBooleanInput | null;
  hidden?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  identification?: PFXModelStringInput | null;
  mattersForSubscription?: PFXModelBooleanInput | null;
  mensajerosId?: PFXModelStringInput | null;
  metadata?: PFXModelStringInput | null;
  muTrackingLink?: PFXModelStringInput | null;
  newOrder?: PFXModelBooleanInput | null;
  not?: PFXModelOrderFilterInput | null;
  oficiosId?: PFXModelStringInput | null;
  or?: Array<PFXModelOrderFilterInput | null> | null;
  orderStatusDetail?: PFXModelIntInput | null;
  origin?: PFXModelOriginChannelInput | null;
  paymentMethod?: PFXModelStringInput | null;
  paymentStatus?: PFXModelIntInput | null;
  picapId?: PFXModelStringInput | null;
  picapTrackingLink?: PFXModelStringInput | null;
  promoCodeId?: PFXModelIDInput | null;
  rapiboyId?: PFXModelStringInput | null;
  shouldSendEmail?: PFXModelBooleanInput | null;
  status?: PFXModelFloatInput | null;
  userMarketingConsent?: PFXModelBooleanInput | null;
  yapayaId?: PFXModelStringInput | null;
}

export interface PFXModelOrderFulfillmentTypeInput {
  __typename?: string;
  eq?: PFXOrderFulfillmentType | null;
  ne?: PFXOrderFulfillmentType | null;
}

export interface PFXModelOrderTimeRangeTypeInput {
  __typename?: string;
  eq?: PFXOrderTimeRangeType | null;
  ne?: PFXOrderTimeRangeType | null;
}

export interface PFXModelOriginChannelInput {
  __typename?: string;
  eq?: PFXOriginChannel | null;
  ne?: PFXOriginChannel | null;
}

export interface PFXModelOutOfBoundsConditionInput {
  __typename?: string;
  and?: Array<PFXModelOutOfBoundsConditionInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  identification?: PFXModelStringInput | null;
  not?: PFXModelOutOfBoundsConditionInput | null;
  or?: Array<PFXModelOutOfBoundsConditionInput | null> | null;
}

export interface PFXModelOutOfBoundsFilterInput {
  __typename?: string;
  and?: Array<PFXModelOutOfBoundsFilterInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  identification?: PFXModelStringInput | null;
  not?: PFXModelOutOfBoundsFilterInput | null;
  or?: Array<PFXModelOutOfBoundsFilterInput | null> | null;
}

export interface PFXModelPOSInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelPOSInfoConditionInput | null> | null;
  not?: PFXModelPOSInfoConditionInput | null;
  or?: Array<PFXModelPOSInfoConditionInput | null> | null;
}

export interface PFXModelPOSInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelPOSInfoFilterInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  not?: PFXModelPOSInfoFilterInput | null;
  or?: Array<PFXModelPOSInfoFilterInput | null> | null;
}

export interface PFXModelPaymentsInfoConditionInput {
  __typename?: string;
  and?: Array<PFXModelPaymentsInfoConditionInput | null> | null;
  not?: PFXModelPaymentsInfoConditionInput | null;
  or?: Array<PFXModelPaymentsInfoConditionInput | null> | null;
}

export interface PFXModelPaymentsInfoFilterInput {
  __typename?: string;
  and?: Array<PFXModelPaymentsInfoFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelPaymentsInfoFilterInput | null;
  or?: Array<PFXModelPaymentsInfoFilterInput | null> | null;
}

export interface PFXModelPotentialUserConditionInput {
  __typename?: string;
  and?: Array<PFXModelPotentialUserConditionInput | null> | null;
  company?: PFXModelStringInput | null;
  email?: PFXModelStringInput | null;
  lastName?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelPotentialUserConditionInput | null;
  or?: Array<PFXModelPotentialUserConditionInput | null> | null;
  phoneNumber?: PFXModelStringInput | null;
  source?: PFXModelStringInput | null;
}

export interface PFXModelPotentialUserFilterInput {
  __typename?: string;
  and?: Array<PFXModelPotentialUserFilterInput | null> | null;
  company?: PFXModelStringInput | null;
  email?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  lastName?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelPotentialUserFilterInput | null;
  or?: Array<PFXModelPotentialUserFilterInput | null> | null;
  phoneNumber?: PFXModelStringInput | null;
  source?: PFXModelStringInput | null;
}

export interface PFXModelPromoCodeConditionInput {
  __typename?: string;
  active?: PFXModelBooleanInput | null;
  amountUsed?: PFXModelIntInput | null;
  and?: Array<PFXModelPromoCodeConditionInput | null> | null;
  code?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  cumulative?: PFXModelBooleanInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelPromoCodeConditionInput | null;
  or?: Array<PFXModelPromoCodeConditionInput | null> | null;
  type?: PFXModelPromoCodeTypeInput | null;
  value?: PFXModelFloatInput | null;
}

export interface PFXModelPromoCodeFilterInput {
  __typename?: string;
  active?: PFXModelBooleanInput | null;
  amountUsed?: PFXModelIntInput | null;
  and?: Array<PFXModelPromoCodeFilterInput | null> | null;
  code?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  cumulative?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelPromoCodeFilterInput | null;
  or?: Array<PFXModelPromoCodeFilterInput | null> | null;
  type?: PFXModelPromoCodeTypeInput | null;
  value?: PFXModelFloatInput | null;
}

export interface PFXModelPromoCodeTypeInput {
  __typename?: string;
  eq?: PFXPromoCodeType | null;
  ne?: PFXPromoCodeType | null;
}

export interface PFXModelPushNotificationsConditionInput {
  __typename?: string;
  and?: Array<PFXModelPushNotificationsConditionInput | null> | null;
  not?: PFXModelPushNotificationsConditionInput | null;
  or?: Array<PFXModelPushNotificationsConditionInput | null> | null;
  subscription?: PFXModelStringInput | null;
}

export interface PFXModelPushNotificationsFilterInput {
  __typename?: string;
  and?: Array<PFXModelPushNotificationsFilterInput | null> | null;
  not?: PFXModelPushNotificationsFilterInput | null;
  or?: Array<PFXModelPushNotificationsFilterInput | null> | null;
  subscription?: PFXModelStringInput | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelSKUConditionInput {
  __typename?: string;
  and?: Array<PFXModelSKUConditionInput | null> | null;
  companyConnectionId?: PFXModelIDInput | null;
  not?: PFXModelSKUConditionInput | null;
  or?: Array<PFXModelSKUConditionInput | null> | null;
  stock?: PFXModelIntInput | null;
}

export interface PFXModelSKUFilterInput {
  __typename?: string;
  and?: Array<PFXModelSKUFilterInput | null> | null;
  companyConnectionId?: PFXModelIDInput | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelSKUFilterInput | null;
  or?: Array<PFXModelSKUFilterInput | null> | null;
  sku?: PFXModelStringInput | null;
  stock?: PFXModelIntInput | null;
}

export interface PFXModelSizeInput {
  __typename?: string;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
}

export interface PFXModelStatusInput {
  __typename?: string;
  eq?: PFXStatus | null;
  ne?: PFXStatus | null;
}

export interface PFXModelStringInput {
  __typename?: string;
  attributeExists?: boolean | null;
  attributeType?: PFXModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: PFXModelSizeInput | null;
}

export interface PFXModelStringKeyConditionInput {
  __typename?: string;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
}

export interface PFXModelSubItemConditionInput {
  __typename?: string;
  and?: Array<PFXModelSubItemConditionInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelSubItemConditionInput | null;
  or?: Array<PFXModelSubItemConditionInput | null> | null;
}

export interface PFXModelSubItemFilterInput {
  __typename?: string;
  and?: Array<PFXModelSubItemFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelSubItemFilterInput | null;
  or?: Array<PFXModelSubItemFilterInput | null> | null;
}

export interface PFXModelSubItemTemplateConditionInput {
  __typename?: string;
  and?: Array<PFXModelSubItemTemplateConditionInput | null> | null;
  companyId?: PFXModelIDInput | null;
  not?: PFXModelSubItemTemplateConditionInput | null;
  or?: Array<PFXModelSubItemTemplateConditionInput | null> | null;
  subItemId?: PFXModelIDInput | null;
  templateId?: PFXModelIDInput | null;
}

export interface PFXModelSubItemTemplateFilterInput {
  __typename?: string;
  and?: Array<PFXModelSubItemTemplateFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelSubItemTemplateFilterInput | null;
  or?: Array<PFXModelSubItemTemplateFilterInput | null> | null;
  subItemId?: PFXModelIDInput | null;
  templateId?: PFXModelIDInput | null;
}

export interface PFXModelTemplateConditionInput {
  __typename?: string;
  and?: Array<PFXModelTemplateConditionInput | null> | null;
  cardSize?: PFXModelCardTypeInput | null;
  catalog?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  currency?: PFXModelCurrencyInput | null;
  disableNavbarFade?: PFXModelBooleanInput | null;
  drawerNavbarWhenMobile?: PFXModelBooleanInput | null;
  hideItemsWhenSoldOut?: PFXModelBooleanInput | null;
  logo?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelTemplateConditionInput | null;
  or?: Array<PFXModelTemplateConditionInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  showAllProductsTab?: PFXModelBooleanInput | null;
  socialsFooter?: PFXModelBooleanInput | null;
  type?: PFXModelStringInput | null;
  upsellingIds?: PFXModelStringInput | null;
}

export interface PFXModelTemplateFilterInput {
  __typename?: string;
  and?: Array<PFXModelTemplateFilterInput | null> | null;
  cardSize?: PFXModelCardTypeInput | null;
  catalog?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  currency?: PFXModelCurrencyInput | null;
  disableNavbarFade?: PFXModelBooleanInput | null;
  drawerNavbarWhenMobile?: PFXModelBooleanInput | null;
  hideItemsWhenSoldOut?: PFXModelBooleanInput | null;
  id?: PFXModelIDInput | null;
  logo?: PFXModelStringInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelTemplateFilterInput | null;
  or?: Array<PFXModelTemplateFilterInput | null> | null;
  ownerId?: PFXModelIDInput | null;
  showAllProductsTab?: PFXModelBooleanInput | null;
  socialsFooter?: PFXModelBooleanInput | null;
  type?: PFXModelStringInput | null;
  upsellingIds?: PFXModelStringInput | null;
}

export interface PFXModelTemplateStylesConditionInput {
  __typename?: string;
  and?: Array<PFXModelTemplateStylesConditionInput | null> | null;
  companyId?: PFXModelIDInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelTemplateStylesConditionInput | null;
  or?: Array<PFXModelTemplateStylesConditionInput | null> | null;
}

export interface PFXModelTemplateStylesFilterInput {
  __typename?: string;
  and?: Array<PFXModelTemplateStylesFilterInput | null> | null;
  companyId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelTemplateStylesFilterInput | null;
  or?: Array<PFXModelTemplateStylesFilterInput | null> | null;
}

export interface PFXModelTransactionPTConditionInput {
  __typename?: string;
  and?: Array<PFXModelTransactionPTConditionInput | null> | null;
  date?: PFXModelStringInput | null;
  not?: PFXModelTransactionPTConditionInput | null;
  or?: Array<PFXModelTransactionPTConditionInput | null> | null;
  orderId?: PFXModelStringInput | null;
  status?: PFXModelIntInput | null;
}

export interface PFXModelTransactionPTFilterInput {
  __typename?: string;
  and?: Array<PFXModelTransactionPTFilterInput | null> | null;
  date?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelTransactionPTFilterInput | null;
  or?: Array<PFXModelTransactionPTFilterInput | null> | null;
  orderId?: PFXModelStringInput | null;
  status?: PFXModelIntInput | null;
}

export interface PFXModelTransactionRBConditionInput {
  __typename?: string;
  and?: Array<PFXModelTransactionRBConditionInput | null> | null;
  date?: PFXModelStringInput | null;
  not?: PFXModelTransactionRBConditionInput | null;
  or?: Array<PFXModelTransactionRBConditionInput | null> | null;
  orderId?: PFXModelStringInput | null;
  status?: PFXModelIntInput | null;
}

export interface PFXModelTransactionRBFilterInput {
  __typename?: string;
  and?: Array<PFXModelTransactionRBFilterInput | null> | null;
  date?: PFXModelStringInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelTransactionRBFilterInput | null;
  or?: Array<PFXModelTransactionRBFilterInput | null> | null;
  orderId?: PFXModelStringInput | null;
  status?: PFXModelIntInput | null;
}

export interface PFXModelUserCleverlynkConditionInput {
  __typename?: string;
  and?: Array<PFXModelUserCleverlynkConditionInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  not?: PFXModelUserCleverlynkConditionInput | null;
  or?: Array<PFXModelUserCleverlynkConditionInput | null> | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelUserCleverlynkFilterInput {
  __typename?: string;
  and?: Array<PFXModelUserCleverlynkFilterInput | null> | null;
  cleverlynkId?: PFXModelIDInput | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelUserCleverlynkFilterInput | null;
  or?: Array<PFXModelUserCleverlynkFilterInput | null> | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelUserConditionInput {
  __typename?: string;
  and?: Array<PFXModelUserConditionInput | null> | null;
  bankTransferImages?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  defaultOrdersTimeRange?: PFXModelOrderTimeRangeTypeInput | null;
  ignoreEmails?: PFXModelBooleanInput | null;
  mailSubscriptionPriority?: PFXModelMailSubscriptionPriorityInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelUserConditionInput | null;
  or?: Array<PFXModelUserConditionInput | null> | null;
  phone?: PFXModelStringInput | null;
  userEmail?: PFXModelStringInput | null;
}

export interface PFXModelUserFilterInput {
  __typename?: string;
  and?: Array<PFXModelUserFilterInput | null> | null;
  bankTransferImages?: PFXModelStringInput | null;
  companyId?: PFXModelIDInput | null;
  defaultOrdersTimeRange?: PFXModelOrderTimeRangeTypeInput | null;
  ignoreEmails?: PFXModelBooleanInput | null;
  mailSubscriptionPriority?: PFXModelMailSubscriptionPriorityInput | null;
  name?: PFXModelStringInput | null;
  not?: PFXModelUserFilterInput | null;
  or?: Array<PFXModelUserFilterInput | null> | null;
  phone?: PFXModelStringInput | null;
  userEmail?: PFXModelStringInput | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelUserTemplateConditionInput {
  __typename?: string;
  and?: Array<PFXModelUserTemplateConditionInput | null> | null;
  not?: PFXModelUserTemplateConditionInput | null;
  or?: Array<PFXModelUserTemplateConditionInput | null> | null;
  templateId?: PFXModelIDInput | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelUserTemplateFilterInput {
  __typename?: string;
  and?: Array<PFXModelUserTemplateFilterInput | null> | null;
  id?: PFXModelIDInput | null;
  not?: PFXModelUserTemplateFilterInput | null;
  or?: Array<PFXModelUserTemplateFilterInput | null> | null;
  templateId?: PFXModelIDInput | null;
  userName?: PFXModelIDInput | null;
}

export interface PFXModelWompiPSInput {
  __typename?: string;
  eq?: PFXWompiPS | null;
  ne?: PFXWompiPS | null;
}

export interface PFXNavbarStylesInput {
  __typename?: string;
  headerColor: string;
  navbarColor: string;
  navbarFontFamily?: string | null;
  navbarTextColor: string;
}

export interface PFXOFInfoInput {
  __typename?: string;
  password: string;
  username: string;
}

export interface PFXOnboardingSuggestionsInput {
  __typename?: string;
  hasSkippedCleverynks?: boolean | null;
  hasSkippedLogisticsIntegrations?: boolean | null;
  hasSkippedMarketingIntegrations?: boolean | null;
  hasSkippedPaymentsIntegrations?: boolean | null;
  hasSkippedTemplates?: boolean | null;
}

export interface PFXOpaqueBackgroundPropertiesInput {
  __typename?: string;
  alpha: string;
  color: string;
}

export interface PFXOrderAnalyticsInput {
  __typename?: string;
  ExclusiveStartKey?: PFXLastEvalutedKeyIndexInput | null;
  currentPaymentMethodAmountOrders: Array<PFXBarGraphInput>;
  currentPaymentMethodSales: Array<PFXBarGraphInput>;
  currentPaymentTypeAmountOrders: Array<PFXBarGraphInput>;
  currentPaymentTypeSales: Array<PFXBarGraphInput>;
  currentTimeRangeOrders: Array<PFXTimeBarGraphAverageInput>;
  currentTimeRangeSales: Array<PFXTimeBarGraphAverageInput>;
  currentTopCompaniesOrders: Array<PFXBarGraphInput>;
  currentTopCompaniesOrdersWeek: Array<PFXBarGraphInput>;
  currentTopCompaniesSales: Array<PFXBarGraphInput>;
  currentTopCompaniesSalesWeek: Array<PFXBarGraphInput>;
}

export interface PFXOrderHistoryInput {
  __typename?: string;
  date: string;
  status: number;
}

export interface PFXOrderNoteInput {
  __typename?: string;
  note: string;
  priority: PFXNotePriority;
}

export interface PFXOrderStatusNotificationPolicyInput {
  __typename?: string;
  sendMail: boolean;
  status: number;
}

export interface PFXOrdersByDateRangeResponseInput {
  __typename?: string;
  lastEvaluatedKey?: PFXOrdersIndexKeyInput | null;
}

export interface PFXOrdersIndexKeyInput {
  __typename?: string;
  cleverlynkId: string;
  createdAt: string;
  id: string;
}

export interface PFXPICalculateInput {
  __typename?: string;
  cleverlynkId: string;
  companyId: string;
  declaredValue: number;
  deliveryAddress: PFXAddressLatLngInput;
  dispatchAddress: PFXAddressLatLngInput;
  indications?: string | null;
  paymentMethod: PFXPaymentMethod;
  reference: string;
  utcDate: string;
}

export interface PFXPIInfoInput {
  __typename?: string;
  defaultDimensions: PFXPicapDimensions;
  token: string;
}

export interface PFXPPInfoInput {
  __typename?: string;
  activeOrders?: boolean | null;
  hasPirPos?: boolean | null;
  token?: string | null;
}

export interface PFXPPLInfoInput {
  __typename?: string;
  apiClient: string;
  apiSecret: string;
}

export interface PFXPPRInfoInput {
  __typename?: string;
  activeOrders?: boolean | null;
  hasPirPosRappi?: boolean | null;
  rappiId?: string | null;
  token?: string | null;
}

export interface PFXPTInfoInput {
  __typename?: string;
  card: boolean;
  clientAppCode?: string | null;
  clientAppKey?: string | null;
  serverAppCode: string;
  serverAppKey: string;
  webhookId?: string | null;
}

export interface PFXPUInfoInput {
  __typename?: string;
  accountId: string;
  apiKey: string;
  apiLogin: string;
  merchantId: string;
}

export interface PFXPZInfoInput {
  __typename?: string;
  card: boolean;
  formRedirectKey: string;
  password: string;
  privateKey?: string | null;
  pse: boolean;
  publicKey: string;
  user: string;
}

export interface PFXPageInput {
  __typename?: string;
  components: Array<PFXLandingComponentInput>;
  componentsOrder: Array<string>;
  footerComponent: PFXFooterComponentInput;
  id: string;
  mainComponent: PFXMainComponentInput;
}

export interface PFXPaymentMethodsInput {
  __typename?: string;
  bankTransfer?: boolean | null;
  cash?: boolean | null;
  dataphone?: boolean | null;
  ePayCo?: boolean | null;
  mercadoPago?: boolean | null;
  nequi?: boolean | null;
  noPayment?: boolean | null;
  payU?: boolean | null;
  paymentez?: boolean | null;
  paypal?: boolean | null;
  payzen?: boolean | null;
  rappi?: boolean | null;
  redeban?: boolean | null;
  showroom?: boolean | null;
  stripe?: boolean | null;
  stripeExternal?: boolean | null;
  userPercentageEC?: number | null;
  userPercentageMP?: number | null;
  userPercentageNQ?: number | null;
  userPercentageRB?: number | null;
  userPercentageWompi?: number | null;
  wompi?: boolean | null;
}

export interface PFXPermissionsInput {
  __typename?: string;
  changePricesTemplate?: boolean | null;
  createCleverlynk?: boolean | null;
  createCouponCodes?: boolean | null;
  createPromoCodes?: boolean | null;
  createTemplate?: boolean | null;
  deleteCleverlynk?: boolean | null;
  deleteCouponCodes?: boolean | null;
  deleteItemsTemplate?: boolean | null;
  deletePromoCodes?: boolean | null;
  deleteTemplate?: boolean | null;
  manageBilling?: boolean | null;
  manageCleverlynkGroup?: boolean | null;
  manageClients?: boolean | null;
  manageCompanyData?: boolean | null;
  manageIntegrations?: boolean | null;
  manageInventory?: boolean | null;
  manageLandingPage?: boolean | null;
  manageTemplateStyles?: boolean | null;
  manageUsers?: boolean | null;
  updateCleverlynk?: boolean | null;
  updateCouponCodes?: boolean | null;
  updateOrder?: boolean | null;
  updatePromoCodes?: boolean | null;
  updateTemplate?: boolean | null;
  viewAnalytics?: boolean | null;
}

export interface PFXPlanInput {
  __typename?: string;
  fixedOrder: number;
  fixedSubscription: number;
  implementation: number;
  name?: string | null;
  percentageOrder: number;
  percentageOrderInPerson?: number | null;
}

export interface PFXPriceInput {
  __typename?: string;
  currency?: PFXCurrency | null;
  delivery: number;
  deliveryExternal?: number | null;
  discount?: number | null;
  discountCode?: string | null;
  subTotal: number;
  tax?: number | null;
  tip?: number | null;
  tipExternal?: number | null;
  total: number;
  totalExternal?: number | null;
  transaction?: number | null;
  transactionExternal?: number | null;
}

export interface PFXPromoCodeInput {
  __typename?: string;
  active?: boolean | null;
  code: string;
  companyId: string;
  cumulative: boolean;
  id?: string | null;
  name?: string | null;
  restrictions?: Array<PFXPromoRestrictionInput> | null;
  type: PFXPromoCodeType;
  value?: number | null;
}

export interface PFXPromoRestrictionInput {
  __typename?: string;
  amount?: number | null;
  beginDate?: string | null;
  endDate?: string | null;
  price?: number | null;
  type: PFXPromoRestrictionType;
  uses?: number | null;
  weekRange?: PFXWeekRangeInput | null;
}

export interface PFXPropagationTargetInput {
  __typename?: string;
  itemIds?: Array<string> | null;
  templateId: string;
}

export interface PFXRAInfoInput {
  __typename?: string;
  token?: string | null;
}

export interface PFXRBInfoInput {
  __typename?: string;
  allowsInstallments?: boolean | null;
  card: boolean;
  clientAppCode?: string | null;
  clientAppKey?: string | null;
  pse: boolean;
  serverAppCode: string;
  serverAppKey: string;
}

export interface PFXRPExternalInfoInput {
  __typename?: string;
  clientId: string;
  clientSecret: string;
  country: PFXRappiCountry;
  token?: string | null;
  webhookSecretMenuApproved?: string | null;
  webhookSecretMenuRejected?: string | null;
  webhookSecretNewOrder?: string | null;
  webhookSecretOrderEventCancelled?: string | null;
  webhookSecretOtherOrderEvent?: string | null;
  webhookSecretPing?: string | null;
  webhookSecretStoreConnectivity?: string | null;
}

export interface PFXRPInfoInput {
  __typename?: string;
  accountId: string;
  clientId: string;
  clientSecret: string;
  token: string;
}

export interface PFXRangePriceInput {
  __typename?: string;
  price: number;
  range?: Array<number | null> | null;
}

export interface PFXRenderInfoInput {
  __typename?: string;
  renderDescription: string;
  renderImageURL?: string | null;
  renderTitle: string;
}

export interface PFXSSInfoInput {
  __typename?: string;
  activeOrders?: boolean | null;
  siesaId: string;
  siesaName?: string | null;
}

export interface PFXSTInfoInput {
  __typename?: string;
  publishableKey: string;
  secretKey: string;
  webhookKey: string;
}

export interface PFXScheduledDeliveryInput {
  __typename?: string;
  dayMargin: number;
  dispatchHours: Array<Array<Array<string> | null> | null>;
  hourMargin: number;
  ignoreDeliveryDays: Array<string>;
  selectedDeliveryDays: Array<string>;
  weekdays: Array<boolean>;
}

export interface PFXServiceTipInput {
  __typename?: string;
  message: string;
  type: PFXServiceTipType;
}

export interface PFXSetCleverlynkInfoInput {
  __typename?: string;
  id: string;
  renderInfo?: PFXUpsertRenderInfoInput | null;
  sendMessage?: string | null;
  templateId?: string | null;
}

export interface PFXSetLogisticsInfoInput {
  __typename?: string;
  domiprime?: PFXDPInfoInput | null;
  mensajerosUrbanos?: PFXMUInfoInput | null;
  oficios?: PFXOFInfoInput | null;
  picap?: PFXPIInfoInput | null;
  rapiboy?: PFXRAInfoInput | null;
  yapaya?: PFXYPYInfoInput | null;
}

export interface PFXSetMarketingInfoInput {
  __typename?: string;
  abandonedCart?: PFXACInfoInput | null;
  ekkofy?: PFXEKInfoInput | null;
  facebookPixel?: PFXFBInfoInput | null;
  googleAnalytics?: PFXGAInfoInput | null;
  googleTagManager?: PFXGTMInfoInput | null;
  googleVerification?: PFXGVInfoInput | null;
  instagram?: PFXIGInfoInput | null;
}

export interface PFXSetPaymentsInfoInput {
  __typename?: string;
  bankTransfer?: PFXBankTransferInfoInput | null;
  epayco?: PFXECInfoInput | null;
  mercadoPago?: PFXMPInfoInput | null;
  payU?: PFXPUInfoInput | null;
  paymentez?: PFXPTInfoInput | null;
  payzen?: PFXPZInfoInput | null;
  rappi?: PFXRPInfoInput | null;
  redeban?: PFXRBInfoInput | null;
  stripe?: PFXSTInfoInput | null;
  wompi?: PFXWPInfoInput | null;
}

export interface PFXSetUserInput {
  __typename?: string;
  bankTransferImages?: Array<string> | null;
  commission?: PFXCommissionInput | null;
  companyId?: string | null;
  defaultCriteria?: Array<PFXCriteriaInput> | null;
  defaultOrdersTimeRange?: PFXOrderTimeRangeType | null;
  geolocationInfo?: PFXGeolocationInfoUserInput | null;
  ignoreEmails?: boolean | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  name?: string | null;
  onboardingSuggestions?: PFXOnboardingSuggestionsInput | null;
  permissions?: PFXPermissionsInput | null;
  phone?: string | null;
  userEmail?: string | null;
  userName: string;
}

export interface PFXSocialsInput {
  __typename?: string;
  page: PFXSocialsPages;
  url: string;
}

export interface PFXStartBillingInput {
  __typename?: string;
  currentPlan: PFXPlanInput;
  dayToPay: number;
  lastInvoiceDate?: string | null;
}

export interface PFXStatusMailPolicyInput {
  __typename?: string;
  sendMail: boolean;
  status: number;
}

export interface PFXSubItemChosenOptionInput {
  __typename?: string;
  controlsInventory: boolean;
  hidden: boolean;
  id: string;
  input?: string | null;
  label: string;
  posReference?: string | null;
  quantity?: number | null;
  sku: string;
  subitemId: string;
  subitemType?: PFXSubitemDataType | null;
  value?: number | null;
}

export interface PFXSubItemDataInput {
  __typename?: string;
  choiceLimit?: number | null;
  choiceMinimum?: number | null;
  controlsInventory?: boolean | null;
  gid?: string | null;
  hidden?: boolean | null;
  id: string;
  mandatory?: boolean | null;
  multiple?: boolean | null;
  name: string;
  options?: Array<PFXSubItemOptionInput> | null;
  posReference?: string | null;
  sku?: string | null;
  timeFrame?: PFXSubitemDataChronologicalType | null;
  type?: PFXSubitemDataType | null;
}

export interface PFXSubItemOptionInput {
  __typename?: string;
  hidden: boolean;
  id: string;
  label: string;
  posReference?: string | null;
  sku: string;
  value: number;
}

export interface PFXTTInfoInput {
  __typename?: string;
  activeCatalog?: boolean | null;
  activeOrders?: boolean | null;
  apiId?: string | null;
  apiToken?: string | null;
  hasToteat?: boolean | null;
  localNumber?: string | null;
  nameInToteat?: string | null;
  restaurantId?: string | null;
  webhookToken?: string | null;
}

export interface PFXTakeOutInput {
  __typename?: string;
  criteriaType: PFXCriteriaType;
  label: string;
  values: Array<string>;
}

export interface PFXTaxInfoInput {
  __typename?: string;
  shouldApplyTax?: boolean | null;
  tax?: number | null;
}

export interface PFXTemplateStylesStructureInput {
  __typename?: string;
  backgroundStyles: PFXBackgroundStylesInput;
  cardStyles: PFXCardStylesInput;
  footerStyles?: PFXFooterStylesInput | null;
  navbarStyles: PFXNavbarStylesInput;
  titleStyles: PFXTitleStylesInput;
}

export interface PFXTextAttributesInput {
  __typename?: string;
  alignment: string;
  color: string;
  content: string;
  fontFamily: string;
  fontSizeMultiplier: number;
  fontWeight: string;
}

export interface PFXTimeBarGraphAverageInput {
  __typename?: string;
  avg: number;
  time: number;
  value: number;
}

export interface PFXTimeBarGraphInput {
  __typename?: string;
  time: number;
  value: number;
}

export interface PFXTimezoneSelectionInput {
  __typename?: string;
  value: string;
}

export interface PFXTitleStylesInput {
  __typename?: string;
  color: string;
  disableCategoryDivider?: boolean | null;
  drawerCategoryTitleAlignment?: string | null;
  drawerCategoryTitleColor?: string | null;
  drawerCategoryTitleSize?: string | null;
  drawerCategoryTitleWeight?: string | null;
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  textAlign: string;
  textFontFamily: string;
}

export interface PFXUpdateBillingInfoInput {
  __typename?: string;
  alegra?: PFXAlegraInput | null;
  cancelDate?: string | null;
  companyId: string;
  currency?: PFXAlegraCurrencyInput | null;
  currentPS?: PFXWompiPS | null;
  currentPlan?: PFXPlanInput | null;
  dayToPay?: number | null;
  draft?: boolean | null;
  ignore?: string | null;
  lastInvoiceDate?: string | null;
  launchDate?: string | null;
  nextMonthPS?: PFXWompiPS | null;
  nextMonthPlan?: PFXPlanInput | null;
  wompiCard?: PFXWompiCardPSInfoInput | null;
  wompiNequi?: PFXWompiNequiPSInfoInput | null;
}

export interface PFXUpdateCartInput {
  __typename?: string;
  bankTransferImage?: string | null;
  check?: boolean | null;
  cleverlynkId?: string | null;
  companyId?: string | null;
  createdAt?: string | null;
  deliveryInfo?: PFXDeliveryInfoInput | null;
  extras?: any | null;
  fulfillmentType?: PFXOrderFulfillmentType | null;
  googleClientId?: string | null;
  hidden?: boolean | null;
  history?: Array<PFXOrderHistoryInput> | null;
  id: string;
  identification?: string | null;
  items?: Array<PFXItemForOrderInput> | null;
  mandatoryCriteria?: Array<PFXCriteriaForOrderInput> | null;
  mattersForSubscription?: boolean | null;
  newOrder?: boolean | null;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
  orderStatusDetail?: number | null;
  paymentMethod?: string | null;
  paymentStatus?: number | null;
  price?: PFXPriceInput | null;
  shouldSendEmail?: boolean | null;
  status?: number | null;
  userMarketingConsent?: boolean | null;
}

export interface PFXUpdateCleverlynkFlexibleInput {
  __typename?: string;
  domiprimeSiteId?: string | null;
  geolocationInfo?: PFXUpsertGeolocationInfoInput | null;
  id: string;
  yapayaOfficeId?: number | null;
}

export interface PFXUpdateCleverlynkGroupInput {
  __typename?: string;
  cities?: Array<string> | null;
  companyId?: string | null;
  id: string;
  image?: string | null;
  landingPageId?: string | null;
  mandatory?: boolean | null;
  name?: string | null;
  openListFirst?: boolean | null;
  title?: string | null;
  type?: PFXCleverlynkGroupType | null;
  unifiedLandingPage?: boolean | null;
}

export interface PFXUpdateCleverlynkInput {
  __typename?: string;
  abandonedCartEmail?: boolean | null;
  amountOrders?: number | null;
  amountRenders?: number | null;
  amountSales?: number | null;
  bankTransferImage?: string | null;
  cleverlynkGroupId?: string | null;
  clynkHours?: Array<Array<string> | null> | null;
  companyId?: string | null;
  country?: PFXClynkCountry | null;
  criteria?: Array<PFXCriteriaInput> | null;
  deliveryDays?: PFXDeliveryDaysInput | null;
  deliveryMethods?: PFXDeliveryMethodsInput | null;
  deliveryPrice?: PFXDeliveryPriceInput | null;
  deliveryTimes?: PFXDeliveryTimesInput | null;
  discount?: number | null;
  discountCodes?: Array<PFXDiscountCodeInput | null> | null;
  domiprimeSiteId?: string | null;
  email?: string | null;
  expirationDate?: string | null;
  geolocationInfo?: PFXGeolocationInfoInput | null;
  hubserActive?: boolean | null;
  hubsterActive?: boolean | null;
  id: string;
  inPerson?: boolean | null;
  isUsingDeliveryTimes?: boolean | null;
  isUsingGeolocation?: boolean | null;
  landingPageId?: string | null;
  language?: PFXClynkLanguage | null;
  lastView?: string | null;
  maxOrders?: number | null;
  maxRenders?: number | null;
  maximumAmount?: number | null;
  minimumAmount?: number | null;
  muStoreId?: string | null;
  name?: string | null;
  newOrdersAmount?: number | null;
  ofCityCode?: number | null;
  ordersByDateRange?: PFXOrdersByDateRangeResponseInput | null;
  ownerId?: string | null;
  paymentMethods?: PFXPaymentMethodsInput | null;
  phone?: string | null;
  pinHash?: string | null;
  rappiStoreId?: string | null;
  renderInfo?: PFXRenderInfoInput | null;
  salt?: string | null;
  sendMessage?: string | null;
  serviceTip?: PFXServiceTipInput | null;
  status?: PFXStatus | null;
  tags?: Array<string> | null;
  takeOut?: PFXTakeOutInput | null;
  taxInfo?: PFXTaxInfoInput | null;
  templateId?: string | null;
  timezone?: PFXTimezoneSelectionInput | null;
  tip?: string | null;
  usesDiscountCodes?: boolean | null;
  whatsapp?: string | null;
  yapayaOfficeId?: number | null;
  zendesk?: string | null;
}

export interface PFXUpdateCleverlynkPromoCodeInput {
  __typename?: string;
  cleverlynkId?: string | null;
  id: string;
  promoCodeId?: string | null;
}

export interface PFXUpdateCompanyInput {
  __typename?: string;
  URLParam?: string | null;
  abandonedCartEmail?: boolean | null;
  activeIntegrations?: PFXIntegrationsInput | null;
  bankTransferInfoURL?: string | null;
  bankTransferInfoURLs?: Array<string> | null;
  cleverlynkFee?: number | null;
  crossDomainGA?: string | null;
  defaultCleverlynkId?: string | null;
  defaultTax?: PFXTaxInfoInput | null;
  defaultTemplateStyles?: PFXTemplateStylesStructureInput | null;
  domain?: string | null;
  facebookDomainVerification?: string | null;
  googleAdsId?: string | null;
  googleAnalyticsId?: string | null;
  googleTagManagerId?: string | null;
  hiddenColumns?: Array<string> | null;
  hyperlinks?: Array<PFXHyperlinksInput | null> | null;
  id: string;
  logoUrl?: string | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  mails?: Array<PFXCompanyMailInput> | null;
  maxUsers?: number | null;
  name?: string | null;
  nequiURL?: string | null;
  newGoogleAnalyticsId?: string | null;
  notificationSubscriptions?: any | null;
  orderStatusNotificationPolicy?: Array<PFXOrderStatusNotificationPolicyInput> | null;
  ownerId?: string | null;
  phones?: Array<string> | null;
  pixelId?: string | null;
  primaryColor?: string | null;
  socials?: Array<PFXSocialsInput | null> | null;
  statusMailPolicies?: Array<PFXStatusMailPolicyInput> | null;
  subdomain?: string | null;
  termsURL?: string | null;
  tsvGenerated?: boolean | null;
  userMarketingConsent?: boolean | null;
  wildcardAliases?: Array<string> | null;
}

export interface PFXUpdateContactInput {
  __typename?: string;
  address?: string | null;
  companyConnectionId?: string | null;
  companyId: string;
  email: string;
  failedOrders?: number | null;
  lastName?: string | null;
  lastPurchase?: string | null;
  name?: string | null;
  phone?: string | null;
  successfulOrders?: number | null;
  totalPurchased?: number | null;
  userMarketingConsent?: boolean | null;
  wildcards?: any | null;
}

export interface PFXUpdateCustomerCouponInput {
  __typename?: string;
  companyId?: string | null;
  email?: string | null;
  id: string;
  name?: string | null;
  restrictions?: Array<PFXCouponRestrictionInput> | null;
  value?: number | null;
}

export interface PFXUpdateCustomerFormInfoInput {
  __typename?: string;
  email: string;
  mandatory?: any | null;
  mandatoryData?: Array<PFXCriteriaForOrderInput> | null;
  optional?: any | null;
  optionalData?: Array<PFXCriteriaForOrderInput> | null;
}

export interface PFXUpdateCustomerPromoCodeInput {
  __typename?: string;
  email?: string | null;
  id: string;
  promoCodeId?: string | null;
  uses?: number | null;
}

export interface PFXUpdateExternalChannelInput {
  __typename?: string;
  companyId: string;
  rappi?: PFXRPExternalInfoInput | null;
}

export interface PFXUpdateInvoiceInfoInput {
  __typename?: string;
  amount?: number | null;
  companyId?: string | null;
  companyName?: string | null;
  date: string;
  endInvoiceDate?: string | null;
  invoiceId: string;
  invoiceName?: string | null;
  items?: PFXInvoiceItemsInput | null;
  paid?: number | null;
  startInvoiceDate?: string | null;
}

export interface PFXUpdateItemInput {
  __typename?: string;
  attachment?: string | null;
  beginDayHour?: string | null;
  companyId?: string | null;
  decorative?: boolean | null;
  deliveryDelay?: number | null;
  description?: string | null;
  discount?: PFXItemDiscountInput | null;
  gid?: string | null;
  hidden?: boolean | null;
  id: string;
  imagePlaceholder?: string | null;
  images?: Array<string | null> | null;
  maxAmountPerInstance?: number | null;
  maxOrderHour?: string | null;
  name?: string | null;
  new?: boolean | null;
  posReference?: string | null;
  price?: number | null;
  sku?: string | null;
  soldOut?: boolean | null;
  subitems?: Array<PFXSubItemDataInput> | null;
  upsellingId?: string | null;
}

export interface PFXUpdateItemTemplateInput {
  __typename?: string;
  companyId?: string | null;
  id: string;
  itemId?: string | null;
  templateId?: string | null;
}

export interface PFXUpdateLandingPageInput {
  __typename?: string;
  active?: boolean | null;
  id: string;
  pages?: Array<PFXPageInput> | null;
}

export interface PFXUpdateLogisticsInfoInput {
  __typename?: string;
  companyId: string;
  domiprime?: PFXDPInfoInput | null;
  mensajerosUrbanos?: PFXMUInfoInput | null;
  oficios?: PFXOFInfoInput | null;
  picap?: PFXPIInfoInput | null;
  rapiboy?: PFXRAInfoInput | null;
  yapaya?: PFXYPYInfoInput | null;
}

export interface PFXUpdateMarketingInfoInput {
  __typename?: string;
  abandonedCart?: PFXACInfoInput | null;
  companyId: string;
  ekkofy?: PFXEKInfoInput | null;
  facebookPixel?: PFXFBInfoInput | null;
  googleAnalytics?: PFXGAInfoInput | null;
  googleTagManager?: PFXGTMInfoInput | null;
  googleVerification?: PFXGVInfoInput | null;
  instagram?: PFXIGInfoInput | null;
}

export interface PFXUpdateOrderInput {
  __typename?: string;
  authorizationCode?: string | null;
  bankTransferImage?: string | null;
  check?: boolean | null;
  cleverlynkId?: string | null;
  companyId?: string | null;
  createdAt?: string | null;
  deliveryInfo?: PFXDeliveryInfoInput | null;
  externalReference?: string | null;
  extras?: any | null;
  fulfillmentType?: PFXOrderFulfillmentType | null;
  googleClientId?: string | null;
  hidden?: boolean | null;
  history?: Array<PFXOrderHistoryInput> | null;
  id: string;
  identification?: string | null;
  items?: Array<PFXItemForOrderInput> | null;
  mandatoryCriteria?: Array<PFXCriteriaForOrderInput> | null;
  mattersForSubscription?: boolean | null;
  mensajerosId?: string | null;
  muTrackingLink?: string | null;
  newOrder?: boolean | null;
  note?: PFXOrderNoteInput | null;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
  orderStatusDetail?: number | null;
  paymentMethod?: string | null;
  paymentStatus?: number | null;
  picapId?: string | null;
  picapTrackingLink?: string | null;
  price?: PFXPriceInput | null;
  rapiboyId?: string | null;
  rapiboyTrackingLink?: string | null;
  shouldSendEmail?: boolean | null;
  status?: number | null;
  userMarketingConsent?: boolean | null;
}

export interface PFXUpdateOutOfBoundsInput {
  __typename?: string;
  address?: PFXDeliveryInfoInput | null;
  cleverlynkId?: string | null;
  id: string;
  identification?: string | null;
  items?: Array<PFXItemForOrderInput> | null;
  mandatoryCriteria?: Array<PFXCriteriaForOrderInput> | null;
  optionalCriteria?: Array<PFXCriteriaForOrderInput> | null;
}

export interface PFXUpdatePOSInfoInput {
  __typename?: string;
  cleverlynkId: string;
  deliverect?: PFXDLInfoInput | null;
  hubster?: PFXHBInfoInput | null;
  intuipos?: PFXIPInfoInput | null;
  pirpos?: PFXPPInfoInput | null;
  pirposRappi?: PFXPPRInfoInput | null;
  siesa?: PFXSSInfoInput | null;
  toteat?: PFXTTInfoInput | null;
}

export interface PFXUpdatePaymentsInfoInput {
  __typename?: string;
  bankTransfer?: PFXBTInfoInput | null;
  companyId: string;
  epayco?: PFXECInfoInput | null;
  mercadoPago?: PFXMPInfoInput | null;
  payU?: PFXPUInfoInput | null;
  paymentez?: PFXPTInfoInput | null;
  paypal?: PFXPPLInfoInput | null;
  payzen?: PFXPZInfoInput | null;
  rappi?: PFXRPInfoInput | null;
  redeban?: PFXRBInfoInput | null;
  stripe?: PFXSTInfoInput | null;
  wompi?: PFXWPInfoInput | null;
}

export interface PFXUpdatePotentialUserInput {
  __typename?: string;
  company?: string | null;
  email?: string | null;
  id: string;
  lastName?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  source?: string | null;
}

export interface PFXUpdatePromoCodeInput {
  __typename?: string;
  active?: boolean | null;
  code?: string | null;
  companyId?: string | null;
  cumulative?: boolean | null;
  id: string;
  name?: string | null;
  restrictions?: Array<PFXPromoRestrictionInput> | null;
  type?: PFXPromoCodeType | null;
  value?: number | null;
}

export interface PFXUpdatePushNotificationsInput {
  __typename?: string;
  subscription?: any | null;
  userName: string;
}

export interface PFXUpdateSKUInput {
  __typename?: string;
  companyConnectionId?: string | null;
  companyId: string;
  sku: string;
  stock?: number | null;
}

export interface PFXUpdateSubItemInput {
  __typename?: string;
  companyId?: string | null;
  id: string;
  subItemData?: PFXSubItemDataInput | null;
}

export interface PFXUpdateSubItemTemplateInput {
  __typename?: string;
  companyId?: string | null;
  id: string;
  subItemId?: string | null;
  templateId?: string | null;
}

export interface PFXUpdateTemplateInput {
  __typename?: string;
  banners?: Array<PFXBannerInput> | null;
  cardSize?: PFXCardType | null;
  catalog?: any | null;
  companyId?: string | null;
  currency?: PFXCurrency | null;
  disableNavbarFade?: boolean | null;
  drawerNavbarWhenMobile?: boolean | null;
  hideItemsWhenSoldOut?: boolean | null;
  id: string;
  logo?: string | null;
  name?: string | null;
  ownerId?: string | null;
  showAllProductsTab?: boolean | null;
  socialsFooter?: boolean | null;
  styles?: PFXTemplateStylesStructureInput | null;
  type?: string | null;
  upsellingIds?: Array<string | null> | null;
}

export interface PFXUpdateTemplateStylesInput {
  __typename?: string;
  companyId?: string | null;
  id: string;
  name?: string | null;
  stylesStructure?: PFXTemplateStylesStructureInput | null;
}

export interface PFXUpdateTransactionPTInput {
  __typename?: string;
  date?: string | null;
  id: string;
  orderId?: string | null;
  status?: number | null;
}

export interface PFXUpdateTransactionRBInput {
  __typename?: string;
  date?: string | null;
  id: string;
  orderId?: string | null;
  status?: number | null;
}

export interface PFXUpdateUserCleverlynkInput {
  __typename?: string;
  cleverlynkId?: string | null;
  id: string;
  userName?: string | null;
}

export interface PFXUpdateUserInput {
  __typename?: string;
  bankTransferImages?: Array<string> | null;
  commission?: PFXCommissionInput | null;
  companyId?: string | null;
  defaultCriteria?: Array<PFXCriteriaInput> | null;
  defaultOrdersTimeRange?: PFXOrderTimeRangeType | null;
  geolocationInfo?: PFXGeolocationInfoUserInput | null;
  ignoreEmails?: boolean | null;
  mailSubscriptionPriority?: PFXMailSubscriptionPriority | null;
  name?: string | null;
  onboardingSuggestions?: PFXOnboardingSuggestionsInput | null;
  permissions?: PFXPermissionsInput | null;
  phone?: string | null;
  userEmail?: string | null;
  userName: string;
}

export interface PFXUpdateUserTemplateInput {
  __typename?: string;
  id: string;
  templateId?: string | null;
  userName?: string | null;
}

export interface PFXUpsertCleverlynkGroupInput {
  __typename?: string;
  cities?: Array<string> | null;
  companyId: string;
  id?: string | null;
  image?: string | null;
  landingPageId?: string | null;
  mandatory?: boolean | null;
  name: string;
  openListFirst?: boolean | null;
  title?: string | null;
  type: PFXCleverlynkGroupType;
  unifiedLandingPage?: boolean | null;
}

export interface PFXUpsertCleverlynkInput {
  __typename?: string;
  abandonedCartEmail?: boolean | null;
  bankTransferImage?: string | null;
  clynkHours?: Array<Array<string> | null> | null;
  criteria: Array<PFXUpsertCriteriaInput>;
  deliveryPrice?: PFXUpsertDeliveryPriceInput | null;
  deliveryTimes: PFXUpsertDeliveryTimesInput;
  discount?: number | null;
  discountCodes?: Array<PFXUpsertDiscountCodeInput> | null;
  domiprimeSiteId?: string | null;
  expirationDate?: string | null;
  geolocationInfo?: PFXUpsertGeolocationInfoInput | null;
  id: string;
  inPerson?: boolean | null;
  isUsingDeliveryTimes?: boolean | null;
  isUsingGeolocation?: boolean | null;
  maxOrders?: number | null;
  maximumAmount?: number | null;
  minimumAmount?: number | null;
  muStoreId?: string | null;
  name: string;
  ofCityCode?: number | null;
  ownerId: string;
  paymentMethods: PFXUpsertPaymentMethodsInput;
  pinHash?: string | null;
  renderInfo?: PFXUpsertRenderInfoInput | null;
  salt?: string | null;
  serviceTip?: PFXUpsertServiceTipInput | null;
  takeOut?: PFXUpsertTakeOutInput | null;
  taxInfo?: PFXTaxInfoInput | null;
  templateId: string;
  timezone?: PFXTimezoneSelectionInput | null;
  userPercentageDelivery?: number | null;
  usesDiscountCodes?: boolean | null;
  yapayaOfficeId?: number | null;
}

export interface PFXUpsertCriteriaInput {
  __typename?: string;
  addition: boolean;
  characterLimit?: number | null;
  criteriaType: PFXCriteriaType;
  deliveryTypeExclusiveness?: PFXDeliveryTypeExclusiveness | null;
  label: string;
  mandatory: boolean;
  values?: Array<string> | null;
}

export interface PFXUpsertDLInfo {
  __typename?: string;
  activeCatalog?: boolean | null;
  activeOrders?: boolean | null;
  channelLinkId?: string | null;
}

export interface PFXUpsertDeliveryPriceInput {
  __typename?: string;
  basePrice?: number | null;
  deliveryAddress: string;
  minPrice?: number | null;
  percentageCovered?: number | null;
  price: number;
  pricePerUnit: number;
  type: PFXDeliveryPriceType;
  unitDistance: number;
  values?: Array<PFXUpsertRangePriceInput> | null;
}

export interface PFXUpsertDeliveryTimesInput {
  __typename?: string;
  immediateDeliveryRange?: Array<number> | null;
  openHours: Array<Array<string> | null>;
  scheduledDelivery?: PFXUpsertScheduledDeliveryInput | null;
}

export interface PFXUpsertDiscountCodeInput {
  __typename?: string;
  code: string;
  minPrice?: number | null;
  type: PFXDiscountCodeType;
  value?: number | null;
}

export interface PFXUpsertGeolocationInfoInput {
  __typename?: string;
  location: PFXUpsertLocationInput;
  polyType: string;
  polygon: Array<PFXUpsertLocationInput>;
  radius: number;
}

export interface PFXUpsertHBInfo {
  __typename?: string;
  activeOrders?: boolean | null;
}

export interface PFXUpsertIPInfo {
  __typename?: string;
  activeOrders?: boolean | null;
  apiKey?: string | null;
  deliveryProductId?: string | null;
  storeId?: string | null;
  storeId2?: string | null;
  storeName?: string | null;
}

export interface PFXUpsertIntegrations {
  __typename?: string;
  deliverect?: boolean | null;
  hubster?: boolean | null;
  intuipos?: boolean | null;
  pirpos?: boolean | null;
  pirposRappi?: boolean | null;
  toteat?: boolean | null;
}

export interface PFXUpsertLandingPageInput {
  __typename?: string;
  active?: boolean | null;
  id: string;
  pages?: Array<PFXPageInput> | null;
}

export interface PFXUpsertLocationInput {
  __typename?: string;
  lat: number;
  lng: number;
}

export interface PFXUpsertPOSInfoInput {
  __typename?: string;
  cleverlynkId: string;
  intuipos?: PFXUpsertIPInfo | null;
  pirpos?: PFXUpsertPPInfo | null;
  toteat?: PFXUpsertTTInfo | null;
}

export interface PFXUpsertPPInfo {
  __typename?: string;
  activeOrders?: boolean | null;
  token?: string | null;
}

export interface PFXUpsertPPRInfo {
  __typename?: string;
  activeOrders?: boolean | null;
  rappiId?: string | null;
  token?: string | null;
}

export interface PFXUpsertPaymentMethodsInput {
  __typename?: string;
  bankTransfer: boolean;
  cash: boolean;
  dataphone: boolean;
  ePayCo: boolean;
  mercadoPago: boolean;
  nequi: boolean;
  noPayment: boolean;
  payU: boolean;
  paymentez: boolean;
  paypal: boolean;
  payzen: boolean;
  rappi: boolean;
  redeban: boolean;
  showroom: boolean;
  stripe: boolean;
  stripeExternal: boolean;
  userPercentageEC: number;
  userPercentageMP: number;
  userPercentageNQ: number;
  userPercentageRB: number;
  userPercentageWompi: number;
  wompi: boolean;
}

export interface PFXUpsertRangePriceInput {
  __typename?: string;
  price: number;
  range: Array<number | null>;
}

export interface PFXUpsertRenderInfoInput {
  __typename?: string;
  renderDescription: string;
  renderImageURL?: string | null;
  renderTitle: string;
}

export interface PFXUpsertScheduledDeliveryInput {
  __typename?: string;
  dayMargin: number;
  dispatchHours: Array<Array<Array<string>> | null>;
  hourMargin: number;
  ignoreDeliveryDays: Array<string>;
  selectedDeliveryDays: Array<string>;
  weekdays: Array<boolean>;
}

export interface PFXUpsertServiceTipInput {
  __typename?: string;
  message: string;
  type: PFXServiceTipType;
}

export interface PFXUpsertTTInfo {
  __typename?: string;
  activeCatalog?: boolean | null;
  activeOrders?: boolean | null;
  apiId?: string | null;
  apiToken?: string | null;
  localNumber?: string | null;
  restaurantId?: string | null;
}

export interface PFXUpsertTakeOutInput {
  __typename?: string;
  criteriaType: PFXCriteriaType;
  label: string;
  values: Array<string>;
}

export interface PFXWPInfoInput {
  __typename?: string;
  privateKey: string;
  publicKey: string;
  secret: string;
}

export interface PFXWeekRangeInput {
  __typename?: string;
  fri: Array<Array<number>>;
  mon: Array<Array<number>>;
  sat: Array<Array<number>>;
  sun: Array<Array<number>>;
  thu: Array<Array<number>>;
  tue: Array<Array<number>>;
  wed: Array<Array<number>>;
}

export interface PFXWompiCardPSInfoInput {
  __typename?: string;
  cardHolder: string;
  cardName: string;
  customerEmail: string;
  expiresAt: string;
  expiryMonth?: string | null;
  expiryYear: string;
  installments: number;
  paymentSourceId: string;
  paymentSourceStatus: string;
  verified?: boolean | null;
  works?: boolean | null;
}

export interface PFXWompiNequiPSInfoInput {
  __typename?: string;
  customerEmail: string;
  name: string;
  paymentSourceId: string;
  paymentSourceStatus: string;
  phoneNumber: string;
  userAcceptedStatus: string;
  verified?: boolean | null;
  works?: boolean | null;
}

export interface PFXWompiUpsertCardInput {
  __typename?: string;
  acceptance_token?: string | null;
  card_holder: string;
  customer_email: string;
  exp_month: string;
  exp_year: string;
  expires_at: string;
  id: string;
  installments: string;
  name: string;
}

export interface PFXWompiUpsertNequiInput {
  __typename?: string;
  acceptance_token: string;
  customer_email: string;
  id: string;
  name: string;
  phone_number: string;
  status: string;
}

export interface PFXYPYInfoInput {
  __typename?: string;
  email: string;
  password: string;
}
