import React, { useEffect, useState, useRef } from "react";
//@ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import useMapService from "../../hooks/MapServiceHooks/useMapService";
import AddressInput from "./AddressInput";
import { selectDeliveryInfo, selectGoogleLoaded, selectUserLocation } from "../../redux/slices/mapServiceSlice";
import { useSelector } from "react-redux";
import MapSnackbar from "./MapSnackbar";
import { IMapProvider } from "./DeliveryInput";
import { ILatLng, selectCleverlynk, selectIsMobile } from "../../redux/slices/cleverlynkSlice";

interface IMap {
  mapProvider: IMapProvider;
  city: string;
  countryCode: string;
  toggleSelected: () => void;
}

const DynamicMap = ({ mapProvider, toggleSelected }: IMap) => {
  const googleLoaded = useSelector(selectGoogleLoaded);
  const deliveryInfo = useSelector(selectDeliveryInfo);
  const cleverlynk = useSelector(selectCleverlynk);
  const userLocation = useSelector(selectUserLocation);
  const isMobile = useSelector(selectIsMobile);

  const { getCoordinatesArrayFromClynk } = useMapService();

  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  const marker = useRef<any>(null);
  const area = useRef<any>(null);

  const [lng, setLng] = useState(-74.0259);
  const [lat, setLat] = useState(4.746);
  const [zoom, setZoom] = useState(11);
  const block = useRef<number>(4);

  useEffect(() => {
    setLng(deliveryInfo.coordinates?.lng ?? -74.0259);
    setLat(deliveryInfo.coordinates?.lat ?? 4.746);
    setZoom(15);
  }, [deliveryInfo.coordinates]);

  //google maps services
  const geocoder = useRef<google.maps.Geocoder | null>(null);

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;
  const mapService = useMapService();

  useEffect(() => {
    if (mapProvider !== IMapProvider.LUPAP && !geocoder.current && googleLoaded) {
      geocoder.current = new google.maps.Geocoder();
    }
  }, [mapProvider, googleLoaded]);

  useEffect(() => {
    return () => map.current && map.current.remove();
  }, []);

  useEffect(() => {
    if (map.current && deliveryInfo.byAddress) {
      block.current = 0;
      map.current.setCenter([deliveryInfo.coordinates?.lng, deliveryInfo.coordinates?.lat]);
      map.current.setZoom(15);
    }
  }, [deliveryInfo]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/gregorioospina/ckwehc3p40dst14nl5sjwnyxl",
      center: [
        deliveryInfo.coordinates?.lng ?? userLocation?.lng ?? lng,
        deliveryInfo.coordinates?.lat ?? userLocation?.lat ?? lat,
      ],
      zoom: !!deliveryInfo.coordinates?.lng ? 15 : zoom,
    });
    marker.current = new mapboxgl.Marker({
      color: "#21c387",
      draggable: false,
    });
  });

  useEffect(() => {
    if (!map.current) return;
    // wait for map to initialize
    else {
      map.current!.on("move", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      if (block.current > 1) {
        map.current.on("drag", () => {
          const iconbutton: any = document.getElementById("address-input-icon-button");
          const elem: any = document.getElementById("map-service-search-field");
          if (iconbutton) iconbutton.classList.add("Mui-disabled");
          if (elem) elem.value = "Cargando...";
        });
        map.current.on("dragend", () =>
          mapService.queryWithCoordinatesDebounce(
            { coordinates: { lng, lat } },
            block.current,
            mapProvider,
            geocoder.current
          )
        );
        map.current.on("zoomend", () =>
          mapService.queryWithCoordinatesDebounce(
            { coordinates: { lng, lat } },
            block.current,
            mapProvider,
            geocoder.current
          )
        );
      }
      block.current = block.current + 1;
    }
    if (!marker.current) return;
    else marker.current.setLngLat([map.current.getCenter().lng, map.current.getCenter().lat]).addTo(map.current);

    if (area.current) return;
    else {
      area.current = true;
      map.current!.on("load", async () => {
        const elem: any = document.getElementById("map-service-search-field");
        if (elem) elem.value = deliveryInfo.address ?? "";
        const coordinates = getCoordinatesArrayFromClynk(cleverlynk?.geolocationInfo!);
        await map.current!.addSource(`source-coverage`, {
          type: "geojson",
          data: {
            promoteId: "este",
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates,
            },
          },
        });
        await map.current!.addLayer({
          id: `layer-coverage`,
          type: "fill",
          source: `source-coverage`,
          paint: {
            "fill-color": "#91f5de",
            "fill-opacity": 0.25,
          },
        });
      });
    }
  });

  return (
    <div style={{ position: "relative", width: "100%", marginTop: 5 }}>
      <AddressInput toggleSelected={toggleSelected} />
      <MapSnackbar lupap={mapProvider === IMapProvider.LUPAP} />
      <div
        ref={mapContainer}
        style={{
          height: isMobile ? window.innerHeight * 0.6 : 500,
          maxHeight: window.innerHeight * 0.6,
          width: "inherit",
          borderRadius: "12px",
        }}></div>
    </div>
  );
};
export default DynamicMap;
