import { createStyles, Grid, makeStyles, Paper, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IOrderState } from "../../pages/OrderSummary";
import { selectOrderState, selectOrderSummary } from "../../redux/slices/cleverlynkSlice";
import InfoSummary from "./InfoSummary";
import ProductsSummary from "./ProductsSummary";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

enum OrderSummaryStage {
  "INFO",
  "PRODUCTS",
  "MAP",
  "TRANSFER_PROOF",
}

interface ISummaryTabs {}

const SummaryTabs = (props: ISummaryTabs) => {
  const [stage, setStage] = useState<OrderSummaryStage>(OrderSummaryStage.INFO);

  const orderSummary = useSelector(selectOrderSummary);
  const orderState = useSelector(selectOrderState);

  const { t } = useTranslation(["ordersummary"]);

  const tabs = useMemo(
    () => (
      <Tabs key={"order-summary-tabs"} value={stage} onChange={(e, v) => setStage(v)} classes={{}}>
        <Tab value={OrderSummaryStage.INFO} label={<Typography>{t("ordersummary:info")}</Typography>} />
        <Tab value={OrderSummaryStage.PRODUCTS} label={<Typography>{t("ordersummary:products")}</Typography>} />
        {orderState === IOrderState.PENDING && orderSummary?.paymentStatus === 203 && (
          <Tab
            value={OrderSummaryStage.TRANSFER_PROOF}
            label={<Typography>{t("ordersummary:pendingTransfer.receipt")}</Typography>}
          />
        )}
        {/* <Tab value={OrderSummaryStage.MAP} label={<Typography>Mapa</Typography>} /> */}
      </Tabs>
    ),
    [orderState, orderSummary?.paymentStatus, stage]
  );

  const transferProof = React.useMemo(
    () => (
      <div style={{ maxWidth: 500 }}>
        <Grid container>
          <Grid item xs={12}>
            <img
              src={orderSummary?.bankTransferImage}
              style={{ borderRadius: "9px", boxShadow: "3pt 3pt 4px 0px #0000001f" }}
              height={"100%"}
              width={"100%"}
              alt="info-bancaria"
            />
          </Grid>
        </Grid>
      </div>
    ),
    [orderSummary]
  );

  const content = useMemo(() => {
    switch (stage) {
      case OrderSummaryStage.INFO:
        return <InfoSummary />;
      case OrderSummaryStage.PRODUCTS:
        return <ProductsSummary />;
      case OrderSummaryStage.TRANSFER_PROOF:
        return transferProof;
      default:
        break;
    }
  }, [transferProof, stage]);

  return (
    <div style={{ margin: "60px 5px" }}>
      {orderState !== IOrderState.TRANSFER && tabs}
      <div style={{ margin: "10px" }}>{content}</div>
    </div>
  );
};
export default SummaryTabs;
