import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import {
  selectCleverlynk,
  selectCleverlynkGroup,
  selectCompany,
  selectCreateCart,
  selectItemsInCart,
  selectPrice,
} from "../../redux/slices/cleverlynkSlice";
import { useSelector } from "react-redux";
import { useCleverlynkContext } from "../../contexts/CleverlynkContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    closeButton: {
      position: "absolute",
      right: 4,
      top: 4,
      color: theme.palette.grey[500],
    },
    root: {
      //@ts-ignore
      zIndex: "1308 !important",
    },
  })
);

export interface ISendCheckoutModal {
  open: boolean;
  onClose: () => void;
  companyName: string;
}

const SendCheckoutModal = (props: ISendCheckoutModal) => {
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("Estamos generando el link");
  const [showGroup, setShowGroup] = useState(false);
  const classes = useStyles();

  const cleverlynkGroup = useSelector(selectCleverlynkGroup);
  const cleverlynk = useSelector(selectCleverlynk);
  const itemsInCart = useSelector(selectItemsInCart);
  const company = useSelector(selectCompany);

  const createSendOrderSupervisor = useSelector(selectCreateCart);

  const price = useSelector(selectPrice);
  const { handleCreateSendOrder } = useCleverlynkFunctions();
  const { baseDomain } = useCleverlynkContext();

  const handleSendClose = () => {
    props.onClose();
    setUrl("");
    setMessage("Estamos generando el link");
  };

  useEffect(() => {
    if (createSendOrderSupervisor.status === "succeeded") {
      if (showGroup)
        setUrl(`${baseDomain}/${cleverlynk?.id}/share?send=${createSendOrderSupervisor.orderId}&start=true`);
      else
        setUrl(
          `${baseDomain}/${cleverlynk?.id}${cleverlynkGroup ? "/redirect" : ""}/share?send=${
            createSendOrderSupervisor.orderId
          }`
        );
      setMessage("Copiar al portapapeles");
    } else if (createSendOrderSupervisor.status === "failed") {
      console.error(createSendOrderSupervisor.error);
      setMessage("Hubo un error, intenta nuevamente");
      setUrl("disabled");
    }
  }, [createSendOrderSupervisor]);

  useEffect(() => {
    if (props.open) {
      handleCreateSendOrder({ price, items: itemsInCart, deliveryDate: undefined, deliveryHourRange: undefined });
    }
  }, [props.open, showGroup]);

  return (
    <Dialog onClose={handleSendClose} open={props.open} maxWidth="xs" classes={{ root: classes.root }}>
      <DialogTitle>
        Envía este Carrito
        <IconButton className={classes.closeButton} onClick={() => handleSendClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Envía este carrito de compras a otra persona o guarda el enlace y haz la compra en otro momento.
        </DialogContentText>
        {url === "disabled" && <DialogContentText>{message}</DialogContentText>}
        <FormControlLabel
          control={<Switch checked={showGroup} onChange={ev => setShowGroup(ev.target.checked)} />}
          label="Abrir en el primer paso"
        />
      </DialogContent>
      <DialogActions>
        <div style={{ position: "relative", width: "100%" }}>
          <CopyToClipboard text={`${company?.name} te envía el siguiente Cleverlynk, ${url}`}>
            <Button
              id="copy-share-link"
              variant="contained"
              color="primary"
              fullWidth
              disabled={url === "" || url === "disabled"}
              onClick={() => setMessage("👍 Copiado!")}>
              {message}
            </Button>
          </CopyToClipboard>
          {url === "" && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SendCheckoutModal;
