import { makeStyles, createStyles, Box, Typography, Theme, Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IKImage } from "imagekitio-react";
import { RootState } from "../../redux/rootReducer";
import { currencyFormatter } from "../../redux/slices/cleverlynkSlice";
import { selectItemById, selectSkuById, selectCurrency } from "../../redux/slices/templateSlice";
import fallbackImage from "../../images/fallback_item.png";

interface IRecentItemCard {
  onClick: () => void;
  id: string;
  index: number;
}

const RecentItemCard = ({ id, onClick, index }: IRecentItemCard) => {
  const item = useSelector((state: RootState) => selectItemById(state, id));
  const currency = useSelector(selectCurrency);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: 0,
        position: "relative",
      },
      discount: {
        position: "absolute",
        top: "12%",
        left: "35%",
        width: "115%",
        transform: "rotate(45deg)",
        msTransform: "rotate(45deg)",
        WebkitTransform: "rotate(45deg)",
        color: "white",
        textAlign: "center",
        background: "#ff4b4b",
        padding: "3px 0px",
      },
      priceWithDiscount: {
        "&.list": {
          textAlign: "end",
        },
        opacity: 0.7,
        MozOpacity: 0.7,
        textDecoration: "line-through",
        paddingRight: 8,
        textDecorationColor: "rgba(250, 0, 0, 1)",
      },
      text: {
        lineHeight: 1.45,
        maxWidth: "95%",
        marginRight: "auto",
        overflow: "hidden",
        textOverflow: "ellipsis",
        msTextOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&.description": {
          fontSize: "0.9rem",
          maxWidth: "95%",
          color: "#ccc",
          marginTop: 8,
        },
        "&.price": {
          textAlign: "right",
          maxWidth: "95%",
        },
      },
      root: {
        height: 100,
        boxShadow: `2pt 2pt 5px -1px #d2d2d2`,
        borderRadius: 9,
        marginTop: 10,
        paddingLeft: 5,
        position: "relative",
        overflow: "hidden",
        backgroundColor: "white",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "whitesmoke",
        },
      },
      actionAreaList: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
      },
      imageGrid: {
        borderRadius: "5pc",
        boxShadow: "#dadada 1pt 1pt 3px 1px",
      },
      contentList: {
        textAlign: "start",
        margin: "auto",
        padding: 5,
      },
      actionsList: {
        padding: 8,
      },
    })
  );

  const classes = useStyles();

  const path = React.useMemo(
    () =>
      item?.images && item?.images.length > 0 && item?.images[0] !== ""
        ? {
            fallback: false,
            url: item?.images[0].split("cleverlynk-imgs.s3.amazonaws.com")[1]?.replace("resized-", ""),
          }
        : { fallback: true, url: fallbackImage },
    [item?.images]
  );

  return (
    <Grid item container xs={12} className={classes.root} key={`${id}-${item?.name}`} onClick={onClick}>
      {!item?.decorative && item?.discount?.type !== "none" && item?.priceWithDiscount! > 0 && (
        <Box className={classes.discount}>
          <Typography style={{ fontSize: "0.75rem", color: "inherit" }} variant="caption" component="p">
            {item?.discount?.type === "percentage"
              ? `${item?.discount?.amount}% Off`
              : `-${currencyFormatter(item?.discount?.amount, currency)}`}
          </Typography>
        </Box>
      )}
      <Grid item xs={2} container alignContent="center">
        {path.fallback ? (
          <img src={path.url} width="100%" className={classes.imageGrid} alt="fallbackitem" />
        ) : (
          <IKImage
            id={`${id}-${item?.name}-image-recently`}
            key={`${id}-${item?.name}-image-recently`}
            className={classes.imageGrid}
            style={{ maxWidth: "100%", position: "relative" }}
            path={path.url}
            transformation={[
              {
                height: 90,
                width: 90,
              },
            ]}
            loading="lazy"
          />
        )}
      </Grid>
      <Grid item container xs={7} direction="column" justifyContent="center" style={{ paddingLeft: 6 }}>
        <Typography className={classes.text} variant="subtitle2" component="p" id={`cart-item-name-${index}`}>
          {item?.name ?? ""}
        </Typography>
        <Typography className={`description ${classes.text}`} variant="subtitle1" id={`cart-item-description-${index}`}>
          {item?.description ? item?.description.slice(0, 50) : ""}
        </Typography>
      </Grid>
      <Grid item xs={3} container alignContent="center">
        {item?.priceWithDiscount! > 0 && (
          <Typography
            className={`price ${classes.text}`}
            variant="subtitle1"
            component="p"
            id={`cart-item-price-${index}`}>
            {currencyFormatter(item?.priceWithDiscount, currency)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default RecentItemCard;
