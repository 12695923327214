import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { addIdentifications, selectSavedIdentifications } from "../redux/slices/userFormSlice";

const COOKIE_NAME = "clynk-identification";
const TEN_YEARS_FROM_NOW = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

export default function useIdentificationCookies() {
  const dispatch = useDispatch();

  const savedIdentifications = useSelector(selectSavedIdentifications);

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  const init = () => {
    const list: string[] = (cookies[COOKIE_NAME] ?? { identifications: [] }).identifications;
    dispatch(addIdentifications(list));
  };

  useEffect(() => {
    setCookie(COOKIE_NAME, JSON.stringify({ identifications: savedIdentifications }), {
      path: "/",
      expires: TEN_YEARS_FROM_NOW,
    });
  }, [savedIdentifications, setCookie]);

  const handleDeleteCookie = id => {
    // dispatch(deleteAddressCookie(id));
  };

  const handleAddIdentificationCookie = (a: string) => {
    dispatch(addIdentifications([a]));
  };

  return {
    init,
    savedIdentifications,
    handleAddIdentificationCookie,
    handleDeleteCookie,
  };
}
