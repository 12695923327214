import {
  Paper,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import { ExpandLess, Instagram, Mail, Phone, WhatsApp } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useUserFormFunctions from "../../hooks/useUserFormFunctions";
import { PFXSocials, PFXSocialsPages } from "../../models/Interfaces";
import { selectCompany, selectCleverlynk, selectOrderSummary } from "../../redux/slices/cleverlynkSlice";
import { useTranslation } from "react-i18next";
import "../../i18n/config";
import { ICompanyContactInfo } from "../Footer/FooterContactInfo";

enum ContactType {
  "INSTAGRAM",
  "WHATSAPP",
  "PHONE",
  "MAIL",
}

interface IContactStore {}

const ContactStore = (props: IContactStore) => {
  const company = useSelector(selectCompany);
  const cleverlynk = useSelector(selectCleverlynk);
  const orderSummary = useSelector(selectOrderSummary);

  const [selected, setSelected] = useState<number | undefined>(undefined);
  const { isValidEmail } = useUserFormFunctions();

  const { t } = useTranslation("ordersummary");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        height: 65,
        width: 65,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "whitesmoke",
        },
      },
      iconbutton: {
        height: "100%",
        width: "100%",
      },
      listbutton: {
        boxShadow: "0pt 1pt 7px -4px #000000ad",
        background: "white",
        borderRadius: 12,
        marginTop: 5,
      },
      listbuttontext: {
        "&:hover": {
          color: "blue",
          textDecoration: "underline",
        },
      },
    })
  );

  const classes = useStyles();

  const contacts: ICompanyContactInfo = useMemo(() => {
    let addAction: ICompanyContactInfo = {
      whatsapp: [],
      phone: [],
      email: [],
      instagram: undefined,
    };

    // if (cleverlynk?.phone && cleverlynk?.phone.length > 6) addAction.phone.push(cleverlynk.phone);
    // if (company?.phones && company?.phones!.length > 0) addAction.phone.push(...company.phones);

    // if (cleverlynk?.whatsapp && cleverlynk.whatsapp.length > 6) addAction.whatsapp.push(cleverlynk.whatsapp);
    // if (company?.phones && company?.phones!.length > 0)
    //   addAction.whatsapp.push(...company.phones.filter(p => p.includes("+573")));

    // if (cleverlynk?.email && isValidEmail(cleverlynk.email)) addAction.email.push(cleverlynk.email);
    // if (company?.mails && company?.mails.length > 0) addAction.email.push(...(company.mails as string[]));

    if (cleverlynk?.phone && cleverlynk?.phone.length > 6) addAction.phone.push(cleverlynk.phone);

    if (cleverlynk?.whatsapp && cleverlynk.whatsapp.length > 6) addAction.whatsapp.push(cleverlynk.whatsapp);
    else if (company?.phones && company?.phones!.length > 0 && company?.phones[0].includes("+573"))
      addAction.phone.push(...company.phones);

    if (cleverlynk?.email && isValidEmail(cleverlynk.email)) addAction.email.push(cleverlynk.email);
    else if (company?.mails && company?.mails.length > 0) addAction.email.push(company.mails[0].mail ?? "");

    let ig = company?.socials?.find((s: PFXSocials) => s.page === PFXSocialsPages.INSTAGRAM);
    if (ig) addAction.instagram = ig.url;

    return addAction;
  }, [cleverlynk, company?.mails, company?.phones]);

  const renderSubList = (items: string[], name: string, icon: any, action: string) => (
    <List>
      <ListItem>
        <ListItemText primary={<b>{name}</b>} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => setSelected(undefined)}>
            <ExpandLess />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {items.map((c, i) => (
        <ListItem className={classes.listbutton} button onClick={() => window.open(action + c)} key={i}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={c} className={classes.listbuttontext} />
        </ListItem>
      ))}
    </List>
  );

  const collapsableContent = React.useMemo(() => {
    switch (selected) {
      case ContactType.INSTAGRAM:
        window?.open(contacts.instagram, "_blank")?.focus();
        return null;
      case ContactType.WHATSAPP:
        const wspUrl = `https://api.whatsapp.com/send?text=Hola, me gustaría tener más información sobre el pedido ${getSmallId(
          orderSummary?.id
        )}&phone=${contacts.whatsapp[0]}`;
        window.open(wspUrl, "_blank")?.focus();
        return null;
      case ContactType.MAIL:
        return contacts.email.length > 0 ? renderSubList(contacts.email, "Correo", <Mail />, `mailto:`) : null;
      case ContactType.PHONE:
        return contacts.phone.length > 0 ? renderSubList(contacts.phone, "Teléfonos", <Phone />, `tel:`) : null;
      default:
        break;
    }
  }, [selected, contacts, orderSummary, cleverlynk]);

  return (
    <div style={{ marginBottom: 20 }}>
      <Grid container justifyContent="space-between" alignContent="center">
        <Grid item xs={12}>
          <Grid item xs={12} style={{ padding: 0, paddingBottom: 10 }}>
            <Typography variant="h6">{t("contactUs")}</Typography>
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent="space-evenly">
            {contacts.instagram && contacts.instagram !== "" && (
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <IconButton className={classes.iconbutton} onClick={() => setSelected(ContactType.INSTAGRAM)}>
                    <Instagram style={{ fontSize: "1.8rem" }} />
                  </IconButton>
                </Paper>
              </Grid>
            )}
            {contacts.whatsapp.length > 0 && (
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <IconButton className={classes.iconbutton} onClick={() => setSelected(ContactType.WHATSAPP)}>
                    <WhatsApp style={{ fontSize: "1.8rem" }} />
                  </IconButton>
                </Paper>
              </Grid>
            )}
            {contacts.email.length > 0 && (
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <IconButton className={classes.iconbutton} onClick={() => setSelected(ContactType.MAIL)}>
                    <Mail style={{ fontSize: "1.8rem" }} />
                  </IconButton>
                </Paper>
              </Grid>
            )}
            {contacts.phone.length > 0 && (
              <Grid item xs={2}>
                <Paper className={classes.paper}>
                  <IconButton className={classes.iconbutton} onClick={() => setSelected(ContactType.PHONE)}>
                    <Phone style={{ fontSize: "1.8rem" }} />
                  </IconButton>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Collapse exit={selected === undefined} in={selected !== undefined}>
              {collapsableContent}
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default ContactStore;

function getSmallId(id: string | undefined) {
  return id ? (id!.includes("-") ? id!.split("-")[1] : id) : "";
}
