import { GraphQLResult } from "@aws-amplify/api";
import { PFXItemForOrder } from "../Interfaces";

export const getAvailableItemsInCleverlynkQuery = /* GraphQL */ `
  query q3($cleverlynkId: ID!, $itemsInCart: [ItemForOrderInput]) {
    getAvailableItemsInCleverlynk(cleverlynkId: $cleverlynkId, itemsInCart: $itemsInCart) {
      available {
        id
        price
        name
        quantity
        posReference
        deliveryDelay
        maxOrderHour
        subitems {
          choiceLimit
          controlsInventory
          id
          gid
          mandatory
          multiple
          posReference
          name
          hidden
          options {
            id
            label
            value
            posReference
            sku
            hidden
          }
          type
          timeFrame
        }
        images
        og_price
        sku
        chosenOptions {
          controlsInventory
          hidden
          posReference
          id
          label
          quantity
          sku
          subitemType
          subitemId
          value
          input
        }
        maxAmountPerInstance
      }
      unavailable {
        images
        name
        id
      }
    }
  }
`;

export interface IGetAvailableItemsInCleverlynk {
  available: PFXItemForOrder[];
  unavailable: PFXItemForOrder[];
}

export const getAvailableItemsInCleverlynk = (data: GraphQLResult<any>): IGetAvailableItemsInCleverlynk => {
  return {
    available: data.data.getAvailableItemsInCleverlynk.available,
    unavailable: data.data.getAvailableItemsInCleverlynk.unavailable,
  };
};
