import { GraphQLResult } from "@aws-amplify/api";
import { CalculateServiceQuery } from "../Interfaces";

export const calculateServiceQuery = /* GraphQL */ `
  query CalculateService($provider: LogisticsProvider!, $input: CalculateServiceInput!) {
    calculateService(provider: $provider, input: $input)
  }
`;

export function calculateService(servicePi: GraphQLResult<CalculateServiceQuery>): number {
  return servicePi.data?.calculateService ?? 0;
}
