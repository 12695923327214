import { createStyles, Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

interface ILoadingSkeleton {
  desktop?: boolean;
}

const LoadingSkeleton = (props: ILoadingSkeleton) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      skeleton: {
        backgroundColor: "white",
        borderRadius: "inherit",
      },
      skeletonWave: {
        background: "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0), transparent)",
        "&::after": {
          background: "linear-gradient(90deg, transparent, rgb(220 220 220 / 51%), transparent)",
        },
      },
      skeletonGridItem: {
        maxWidth: 500,
      },
      skeletonGridWrapper: {
        padding: props.desktop ? "15px 13%" : 15,
        width: "100%",
        margin: 0,
        marginTop: 15,
      },
    })
  );

  const classes = useStyles();

  return (
    <Grid container spacing={props.desktop ? 3 : 2} className={classes.skeletonGridWrapper} justifyContent="center">
      <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
        <Paper>
          <Skeleton
            classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
            variant="rect"
            height={90}
            animation="wave"
            width="100%"></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
        <Paper>
          <Skeleton
            classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
            variant="rect"
            height={90}
            animation="wave"
            width="100%"></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
        <Paper>
          <Skeleton
            classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
            variant="rect"
            height={200}
            animation="wave"
            width="100%"></Skeleton>
        </Paper>
      </Grid>
      <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
        <Paper>
          <Skeleton
            classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
            variant="rect"
            height={180}
            animation="wave"
            width="100%"></Skeleton>
        </Paper>
      </Grid>
      {props.desktop && (
        <React.Fragment>
          <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
            <Paper>
              <Skeleton
                classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
                variant="rect"
                height={90}
                animation="wave"
                width="100%"></Skeleton>
            </Paper>
          </Grid>
          <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
            <Paper>
              <Skeleton
                classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
                variant="rect"
                height={90}
                animation="wave"
                width="100%"></Skeleton>
            </Paper>
          </Grid>
          <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
            <Paper>
              <Skeleton
                classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
                variant="rect"
                height={200}
                animation="wave"
                width="100%"></Skeleton>
            </Paper>
          </Grid>
          <Grid item xs={props.desktop ? 6 : 12} className={classes.skeletonGridItem}>
            <Paper>
              <Skeleton
                classes={{ root: classes.skeleton, wave: classes.skeletonWave }}
                variant="rect"
                height={180}
                animation="wave"
                width="100%"></Skeleton>
            </Paper>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};
export default LoadingSkeleton;
