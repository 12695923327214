import {
  makeStyles,
  createStyles,
  Paper,
  Grid,
  Typography,
  Theme,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter, selectOrderSummary } from "../../redux/slices/cleverlynkSlice";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface ITransferPriceCard {
  desktop?: boolean;
}

const TransferPriceCard = (props: ITransferPriceCard) => {
  const orderSummary = useSelector(selectOrderSummary);
  const { t } = useTranslation("ordersummary");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      totalPaper: {
        backgroundColor: "#e4e4e4",
        maxWidth: 500,
        "&.mobile": {
          height: 60,
          backgroundColor: "#1a1a1a",
        },
      },
      miscItem: {
        color: "#1a1a1a",
        fontSize: "0.9rem",
      },
      totalItem: {
        backgroundColor: "#1a1a1a",
        borderRadius: 9,
        color: "#fff",
      },
    })
  );
  const classes = useStyles();

  return props.desktop ? (
    <Paper className={classes.totalPaper}>
      <List style={{ paddingBottom: 0 }}>
        <ListItem className={classes.miscItem}>
          <Grid
            container
            alignContent="center"
            justifyContent="space-between"
            style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
            <Typography style={{ fontWeight: 300 }}>{t("order")}</Typography>
            <Typography style={{ fontWeight: 300 }}>{currencyFormatter(orderSummary?.price?.subTotal)}</Typography>
          </Grid>
        </ListItem>
        {!!orderSummary?.price?.tip && (
          <ListItem className={classes.miscItem}>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
              style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
              <Typography style={{ fontWeight: 300 }}>{t("tip")}</Typography>
              <Typography style={{ fontWeight: 300 }}>{currencyFormatter(orderSummary?.price?.tip!)}</Typography>
            </Grid>
          </ListItem>
        )}
        {!!orderSummary?.price?.delivery && (
          <ListItem className={classes.miscItem}>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
              style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
              <Typography style={{ fontWeight: 300 }}>{t("deliveryCost")}</Typography>
              <Typography style={{ fontWeight: 300 }}>{currencyFormatter(orderSummary?.price?.delivery!)}</Typography>
            </Grid>
          </ListItem>
        )}
        {!!orderSummary?.price?.discount && (
          <ListItem className={classes.miscItem}>
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
              style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
              <Typography style={{ fontWeight: 300 }}>{t("discount")}</Typography>
              <Typography style={{ fontWeight: 300 }}>-{currencyFormatter(orderSummary?.price?.discount!)}</Typography>
            </Grid>
          </ListItem>
        )}
        <ListItem className={classes.totalItem}>
          <Grid
            container
            alignContent="center"
            justifyContent="space-between"
            style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
            <Typography style={{ fontWeight: 200 }} variant="h5">
              Total
            </Typography>
            <Typography style={{ fontWeight: 200 }} variant="h5">
              {currencyFormatter(orderSummary?.price?.total!)}
            </Typography>
          </Grid>
        </ListItem>
      </List>
    </Paper>
  ) : (
    <Paper className={`mobile ${classes.totalPaper}`}>
      <Grid
        container
        alignContent="center"
        justifyContent="space-between"
        style={{ height: "100%", paddingLeft: 20, paddingRight: 20 }}>
        <Typography style={{ color: "white", fontWeight: 200 }} variant="h5">
          Total
        </Typography>
        <Typography style={{ color: "white", fontWeight: 200 }} variant="h5">
          {currencyFormatter(orderSummary?.price?.total!)}
        </Typography>
      </Grid>
    </Paper>
  );
};
export default TransferPriceCard;
