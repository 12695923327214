import { createTheme, ThemeOptions } from "@material-ui/core";
import useTemplateStyles from "./useTemplateStyles";

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function hexaWithAToRGB(hex = "#1a1a1a") {
  const RGBA_color = hexToRGB(hex, 0.2)
    .replace(/[^\d,]/g, "")
    .split(",");
  const alpha = 0.2;
  const rgb = {
    r: Math.floor((1 - alpha) * 255 + alpha * Number(RGBA_color[0])),
    g: Math.floor((1 - alpha) * 255 + alpha * Number(RGBA_color[1])),
    b: Math.floor((1 - alpha) * 255 + alpha * Number(RGBA_color[2])),
  };
  return rgbToHex(rgb);
}

function rgbToHex(rgb) {
  return "#" + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
}

export function useClynkTheme() {
  const { titleStyles, cardStyles, backgroundStyles, primaryColor } = useTemplateStyles();

  const createClynkTheme = (background?: boolean, cards?: boolean) => {
    let templateTheme = {} as ThemeOptions;
    const fontWeight: "normal" | "bold" | "lighter" | undefined = titleStyles?.fontWeight! as
      | "normal"
      | "bold"
      | "lighter"
      | undefined;
    const textAlign: "center" | "left" | "right" | undefined = titleStyles?.textAlign
      ? (titleStyles?.textAlign.toLowerCase() as "center" | "left" | "right")
      : undefined;
    const textStyles = {
      color: titleStyles?.color ?? "#000",
      fontFamily: titleStyles?.fontFamily ?? "Inter",
      fontSize: titleStyles?.fontSize ?? "16px",
      fontWeight,
      textAlign,
      textFontFamily: titleStyles?.textFontFamily ?? "Roboto",
    };
    templateTheme.typography = {
      fontFamily: textStyles.fontFamily,
      h5: { ...textStyles },
      body2: { ...textStyles, fontWeight: "normal" },
      body1: { ...textStyles, fontWeight: "normal" },
      subtitle1: { ...textStyles, fontWeight: "normal" },
      subtitle2: { ...textStyles, fontWeight: "normal" },
      overline: {
        color: textStyles.color,
      },
      caption: {
        fontSize: "0.75rem",
      },
    };
    templateTheme.breakpoints = {
      ...templateTheme.breakpoints,
      values: {
        xs: 0,
        sm: 500,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    };
    templateTheme.zIndex = {
      tooltip: 5000,
      modal: 4000,
      snackbar: 4900,
      drawer: 1000,
    };
    templateTheme.palette = {
      primary: {
        main: cardStyles?.cardColor ?? primaryColor,
      },
      secondary: {
        main: textStyles.color,
      },
    };
    templateTheme.overrides = {
      ...templateTheme.overrides,
      MuiPaper: {
        root: {
          backgroundColor: cardStyles?.cardColor,
        },
      },
      MuiFormLabel: {
        root: {
          color: `${textStyles.color} !important`,
        },
      },
      MuiButton: {
        contained: {
          color: `${cardStyles?.textButtonColor}`,
          backgroundColor: `${cardStyles?.buttonColor}`,
          "&:hover": {
            backgroundColor: `${cardStyles?.buttonColor}bf`,
          },
        },
        containedPrimary: {
          color: `${cardStyles?.textButtonColor} !important`,
          backgroundColor: `${cardStyles?.buttonColor} !important`,
          "&:hover": {
            backgroundColor: `${cardStyles?.buttonColor}bf`,
          },
        },
        containedSecondary: {
          backgroundColor: "#cccccc !important",
          color: "#fff !important",
        },
      },
      MuiIconButton: {
        colorPrimary: {
          color: `${cardStyles?.textButtonColor} !important`,
          backgroundColor: `${cardStyles?.buttonColor} !important`,
        },
        colorSecondary: {
          color: `${textStyles.color} !important`,
          backgroundColor: `${cardStyles?.cardColor} !important`,
        },
      },
      MuiCard: cards
        ? {
            ...templateTheme.overrides?.MuiCard,
            root: {
              borderRadius: cardStyles?.borderRadius,
              backgroundColor: cardStyles?.cardColor,
              boxShadow: `${cardStyles?.shadowSize} ${cardStyles?.shadowSize} ${cardStyles?.shadow} 0px ${cardStyles?.secondColor}`,
              MozBoxShadow: `${cardStyles?.shadowSize} ${cardStyles?.shadowSize} ${cardStyles?.shadow} 0px ${cardStyles?.secondColor}`,
              WebkitBoxShadow: `${cardStyles?.shadowSize} ${cardStyles?.shadowSize} ${cardStyles?.shadow} 0px ${cardStyles?.secondColor}`,
            },
          }
        : {
            ...templateTheme.overrides?.MuiCard,
          },
      MuiContainer: background
        ? {
            ...templateTheme.overrides?.MuiContainer,
            root: {
              backgroundColor: backgroundStyles?.backgroundColor,
              backgroundImage: `url(${backgroundStyles?.useBackgroundImage ? backgroundStyles?.backgroundUrl : ""})`,
              backgroundSize: backgroundStyles?.backgroundSize,
              backgroundRepeat: backgroundStyles?.backgroundRepeat,
              backgroundAttachment: "fixed",
            },
          }
        : {
            ...templateTheme.overrides?.MuiContainer,
          },
    };

    return createTheme(templateTheme);
  };

  const createOrderSummaryTheme = (fontFamily?: string, primaryColor?: string, secondaryColor?: string) => {
    let templateTheme = {} as ThemeOptions;
    if (primaryColor && secondaryColor) {
      templateTheme.palette = {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
      };
    }
    templateTheme.typography = {
      fontFamily: fontFamily ?? "Jost",
    };
    templateTheme.overrides = {
      ...templateTheme.overrides,
      MuiTypography: {
        body1: {
          color: "#333333",
        },
        h6: {
          fontSize: "1.2rem",
          fontWeight: 300,
        },
        colorPrimary: {
          color: "#1a1a1a",
        },
      },
      MuiPaper: {
        elevation1: {
          borderRadius: 12,
          boxShadow: "2pt 2pt 5px -1px #d2d2d2",
        },
      },
      MuiTabs: {
        root: {
          height: 38,
          maxHeight: 38,
          minHeight: 38,
          marginLeft: "3%",
        },
        indicator: {
          backgroundColor: "black",
          width: "20px !important",
          marginLeft: 3,
        },
      },
      MuiTab: {
        root: {
          minWidth: 30,
          textTransform: "none",
          marginRight: 10,
          padding: "0px 3px",
        },
      },
    };

    return createTheme(templateTheme);
  };

  const createUserFormTheme = (fontFamily: string, secondaryColor: string) => {
    let templateTheme = {} as ThemeOptions;
    templateTheme.palette = {
      primary: {
        main: "#1a1a1a",
      },
      secondary: {
        main: secondaryColor,
      },
    };
    templateTheme.typography = {
      fontFamily: fontFamily ?? "Jost",
    };
    templateTheme.overrides = {
      ...templateTheme.overrides,
      MuiTypography: {
        body1: {
          color: "#1a1a1a",
        },
        subtitle1: {
          color: "#1a1a1a",
        },
        h6: {
          fontSize: "1.2rem",
          fontWeight: 300,
        },
        colorPrimary: {
          color: "#1a1a1a",
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: secondaryColor,
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: "#1a1a1a",
        },
      },
      MuiTab: {
        root: {
          minWidth: 30,
          textTransform: "none",
          marginRight: 10,
          padding: "0px 3px",
        },
      },
    };

    return createTheme(templateTheme);
  };

  return { createClynkTheme, createOrderSummaryTheme, createUserFormTheme };
}
