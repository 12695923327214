import { PFXOrder } from "../Interfaces";

export const subscribeOrderQuery = (orderId: string) => `
  subscription s {
    subscribeToOrder(id:"${orderId}"){
      id
      items {
        chosenOptions {
          label 
          quantity
          value
        }
        images
        name
        og_price
        price
        quantity
        }
      cleverlynkId
      identification
      mandatoryCriteria{
        label
        value
      }
      optionalCriteria{
        label
        value
      }
      paymentStatus
      status
      paymentMethod
      picapTrackingLink
      bankTransferImage
      muTrackingLink
      deliveryInfo {
        deliveryAddress
        deliveryLocation {
          lat
          lng
        }
        deliveryPropertyTypeInfo
        deliveryAdditionalInfo
        deliveryHourRange
      }
    }
  }
`;

export function mapOnNewOrderSubscription(createNewOrderSubscription: any) {
  return createNewOrderSubscription.value.data.subscribeToOrder as PFXOrder;
}
