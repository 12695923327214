export const insertCustomerFormInfo = /* GraphQL */ `
  mutation InsertCustomerFormInfo($input: CreateCustomerFormInfoInput!) {
    insertCustomerFormInfo(input: $input) {
      email
    }
  }
`;

export const removeCustomerFormInfo = /* GraphQL */ `
  mutation RemoveCustomerFormInfo($input: DeleteCustomerFormInfoInput!) {
    removeCustomerFormInfo(input: $input) {
      email
    }
  }
`;
