import { GraphQLResult } from "@aws-amplify/api";

export const insertClientForNewsletterQuery = /* GraphQL */ `
  mutation InsertClientForNewsletter($email: AWSEmail!, $companyId: ID!) {
    insertClientForNewsletter(email: $email, companyId: $companyId) {
      success
    }
  }
`;

export const insertClientForNewsletter = (data: GraphQLResult<any>): Boolean => {
  const d = data.data.insertClientForNewsletter;
  return d;
};
