export default function useDisableOverflow() {
  const disable = () => {
    const html: any = document.getElementsByTagName("html");
    const body: any = document.getElementsByTagName("body");

    if (html && body && html[0] && body[0]) {
      html[0].style.overflowY = "hidden";
      body[0].style.overflowY = "hidden";
    }
  };

  const enable = () => {
    const html: any = document.getElementsByTagName("html");
    const body: any = document.getElementsByTagName("body");

    if (html && body && html[0] && body[0]) {
      html[0].style.overflowY = "";
      body[0].style.overflowY = "";
    }
  };

  return { disable, enable };
}
