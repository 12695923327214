import { createStyles, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCleverlynkContext } from "../../contexts/CleverlynkContext";
import useCleverlynkFunctions from "../../hooks/useCleverlynkFunctions";
import { selectOrderSummary } from "../../redux/slices/cleverlynkSlice";

import { useTranslation } from "react-i18next";
import "../../i18n/config";

interface IRetryOrder {}

const RetryOrder = (props: IRetryOrder) => {
  const orderSummary = useSelector(selectOrderSummary);
  const { baseDomain } = useCleverlynkContext();

  const { t } = useTranslation("ordersummary");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 350,
        marginBottom: 10,
        transitionDuration: "300ms",
        transitionProperty: "background-color",
      },
      iconButton: {
        height: 160,
        width: 160,
        backgroundColor: "#ececec",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "1pt 1pt 7px -2px #00000080",
        borderRadius: "5pc",
        "&:hover": {
          animation: "$spin 1s",
        },
      },
      "@keyframes spin": {
        "0%": {
          boxShadow: "none",
        },
        "100%": { transform: "rotate(-360deg)" },
      },
      text: {
        color: "#9c9c9c",
        fontSize: "1rem",
        textTransform: "none",
        "&.button": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    })
  );
  const classes = useStyles();

  const retryOrder = React.useCallback(() => {
    window.location.href = `${baseDomain}/${orderSummary?.cleverlynkId}/share?send=${orderSummary?.id}`;
  }, [baseDomain, orderSummary?.cleverlynkId, orderSummary?.id]);

  // https://mr.test.clynk.me/CIDBN1Um/redirect/checkout?send=9Z8hgofanS-WUE83

  return (
    <Paper className={classes.root}>
      <Grid container style={{ height: "100%" }} justifyContent="center">
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Typography variant="body1" className={classes.text}>
            {t("retryOrderError")}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <div className={classes.iconButton}>
            <IconButton onClick={retryOrder}>
              <Replay style={{ fontSize: "6rem", color: "#ccc" }} />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="flex-start">
          <Typography variant="body1" className={`button ${classes.text}`} onClick={retryOrder}>
            {t("retryOrder")}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default RetryOrder;
